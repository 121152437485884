import { FC } from 'react';
import { useParams } from 'react-router-dom';
import ChildOutcomesCard from '../ChildOutcomesCard/ChildOutcomesCard';
import InlineAlert from 'components/alerts/InlineAlert';
import NoneYetPlaceholder from 'components/NoneYetPlaceholder';
import useOutcomes from '../useOutcomes';

const ChildOutcomesCardList: FC<{ isFormOpen: boolean }> = ({ isFormOpen }) => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: outcomes, error } = useOutcomes(childId);

  if (!outcomes) {
    return null;
  }

  const noChildOutcomesPlaceholder = isFormOpen ? null : (
    <NoneYetPlaceholder message="A child outcome has not yet been added for this child." />
  );

  return (
    <>
      {error && (
        <InlineAlert
          marginTop={4}
          status="warning"
          title="Hang on! We can't load this section right now."
          description="We'll keep trying."
        />
      )}
      {outcomes.length > 0
        ? outcomes.map(outcome => (
            <ChildOutcomesCard key={outcome.id} outcome={outcome} />
          ))
        : noChildOutcomesPlaceholder}
    </>
  );
};

export default ChildOutcomesCardList;
