import { FC } from 'react';
import { RadioGroupField, RadioOption } from 'components/formik';
import { kebabToSentenceCase, toUpperCaseSubstring } from 'lib';

import { contactConfidentiality } from './validation';

const formatLabel = (confString: string) => {
  return toUpperCaseSubstring(
    kebabToSentenceCase(confString as string),
    'ifsp'
  );
};

const ConfidentialityRadio: FC = () => {
  const confidentialityRadioButtons = contactConfidentiality.map(confString => {
    return (
      <RadioOption
        key={confString}
        value={confString}
        label={formatLabel(confString as string)}
      />
    );
  });

  return (
    <RadioGroupField name="confidentiality" label="Confidentiality">
      {confidentialityRadioButtons}
    </RadioGroupField>
  );
};

export default ConfidentialityRadio;
