import { Box, Flex, Heading, Stack } from '@chakra-ui/react';
import { DateField, DropDownField, TextareaField } from 'components/formik';
import { EndOfFormSpacing } from 'components/styled/forms/EndOfFormSpacing';
import { Formik } from 'formik';
import { ModalAndCancel } from 'components/styled/forms/ModalAndCancel';
import { TransitionTypes } from '@cssat/acorn-api-shared';
import { formSchema, initialValues, transitionTypeOptions } from './validation';
import { useCallback, useState } from 'react';
import { useMutateTransition } from '../useTransition';
import { useOpenFormContext } from 'contexts';
import { useParams } from 'react-router-dom';
import { usePopupToast, useReferralsByChildId } from 'hooks';
import { v4 as uuid } from 'uuid';
import InlineAlert from 'components/alerts/InlineAlert';

type FormFields = {
  dateOfTransition: Date;
  notes: string | undefined;
  transitionType: TransitionTypes;
};

const CompleteTransitionForm = () => {
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [, setOpenFormState] = useOpenFormContext();
  const { id: childId } = useParams<{ id: string }>();
  const createOrUpdateTransition = useMutateTransition(childId);
  const referralData = useReferralsByChildId(childId);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);

  const [successToast] = usePopupToast({
    status: 'success',
    title: `Exit successful`,
  });

  const [errorToast] = usePopupToast({
    status: 'error',
    title: `Sorry, we can’t complete the transition right now.`,
    description: 'Please check your connection and try again.',
  });

  const onSubmit = useCallback(
    async (values: FormFields) => {
      setSubmitError(false);

      const transition = {
        ...values,
        id: uuid(),
        dateOfTransition: values.dateOfTransition.toISOString(),
      };

      try {
        await createOrUpdateTransition(transition);
        await referralData.mutate();
        setOpenFormState(undefined);
        successToast();
      } catch (error) {
        errorToast();
        setSubmitError(true);
      }
    },
    [
      createOrUpdateTransition,
      errorToast,
      referralData,
      setOpenFormState,
      successToast,
    ]
  );

  return (
    <Formik
      validationSchema={formSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {formik => {
        return (
          <form
            onSubmit={e => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
            noValidate
            style={{ width: '100%' }}
          >
            {submitError && (
              <InlineAlert
                marginBottom={4}
                status="error"
                title="There was a problem completing this transition."
              />
            )}
            <Flex maxWidth="1000px" justifyContent="space-between">
              <Stack flexDir="column" spacing={4} width="100%">
                <Heading as="h4" variant="tertiary">
                  Exit child from Part C services
                </Heading>
                <DropDownField
                  name="transitionType"
                  label="Reasons for exit"
                  options={transitionTypeOptions}
                  required
                />
                <DateField
                  name="dateOfTransition"
                  label="Exit date"
                  onDayPickerHide={() => setCalendarOpen(false)}
                  onDayPickerShow={() => setCalendarOpen(true)}
                  required
                />
                <TextareaField name="notes" label="Exit notes (optional)" />
              </Stack>
              <Box>
                <ModalAndCancel
                  onCancel={() => setOpenFormState(undefined)}
                  disabled={false}
                  confirmText="Exit child"
                  text="This will exit the child from Part C services and their record will become locked."
                  subText={`Exit reason: ${formik.values.transitionType}`}
                />
              </Box>
            </Flex>
            {calendarOpen && <EndOfFormSpacing mb={28} />}
          </form>
        );
      }}
    </Formik>
  );
};

export default CompleteTransitionForm;
