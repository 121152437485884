import { Box, Button, Flex } from '@chakra-ui/react';
import { ReactComponent as UploadIcon } from 'icons/upload.svg';
import { useLatestReferral } from 'hooks';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import DocumentFormDrawer from '../DocumentFormDrawer';
import DocumentsTable from '../DocumentsTable/DocumentsTable';

const TabContent = () => {
  const [formIsOpen, setFormIsOpen] = useState(false);
  const { id: childId } = useParams<{ id: string }>();
  const referral = useLatestReferral(childId);
  const isActive = referral?.isActive;

  return (
    <Flex flexDir="column" alignItems="flex-end">
      <Button
        marginBottom={5}
        onClick={() => {
          setFormIsOpen(true);
        }}
        disabled={!isActive}
      >
        <Box as={UploadIcon} marginRight={1} />
        Upload new document
      </Button>
      <DocumentsTable />
      <DocumentFormDrawer
        isOpen={formIsOpen}
        onClose={() => setFormIsOpen(false)}
      />
    </Flex>
  );
};

export default TabContent;
