import { FC, useState } from 'react';
import { JumpLinkRefIds, SectionWrapper } from 'molecules';
import ReferralContent from './ReferralContent';
import ReferralSectionHeader from './ReferralSectionHeader';

const ReferralSection: FC = () => {
  const [showArchived, setShowArchived] = useState(false);

  return (
    <SectionWrapper id={JumpLinkRefIds.Referrals}>
      <ReferralSectionHeader
        showArchived={showArchived}
        setShowArchived={setShowArchived}
      />
      <ReferralContent showArchived={showArchived} />
    </SectionWrapper>
  );
};

export default ReferralSection;
