import { Box, Flex, Text } from '@chakra-ui/react';
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
import { FC } from 'react';
import { formatDateString } from 'lib';
import { space } from 'theme';

type MobileDateProps = {
  date: string;
  alternate: string;
};

export const MobileDate: FC<MobileDateProps> = ({ date, alternate }) => {
  const displayDate = isNaN(new Date(date).getTime())
    ? alternate
    : formatDateString(date);

  return (
    <Flex alignItems="center">
      <Box as={CalendarIcon} minWidth={4} minHeight={4} />
      <Box sx={{ flexGrow: 1 }} ml={space.half}>
        <Text tx="text" variant="bodyText">
          {displayDate}
        </Text>
      </Box>
    </Flex>
  );
};
