import {
  DetailSectionCheckMark,
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { Participant } from '@cssat/acorn-api-shared';

interface TeamMemberViewProps {
  readonly teamMember: Participant;
}

const TeamMemberView = ({
  teamMember: {
    contributedInformationActivity,
    eligibilityServicesActivity,
    name,
    participatedOutcomeActivity,
    presentLevelsActivity,
    role,
  },
}: TeamMemberViewProps) => {
  return (
    <DetailSectionRow>
      <DetailSectionColumn flexBasis="15%">
        <DetailSectionField label="Name" value={name} />
      </DetailSectionColumn>

      <DetailSectionDivider />

      <DetailSectionColumn flexBasis="15%">
        <DetailSectionField label="Role" showWhenEmpty value={role} />
      </DetailSectionColumn>

      <DetailSectionDivider />

      <DetailSectionColumn flexBasis="70%">
        <DetailSectionField label="Assessment activities" showWhenEmpty>
          {presentLevelsActivity && (
            <DetailSectionCheckMark text="Child’s Present Levels of Development" />
          )}
          {eligibilityServicesActivity && (
            <DetailSectionCheckMark text="Eligibility for Part C Services" />
          )}
          {contributedInformationActivity && (
            <DetailSectionCheckMark text="Contributed information for Summary of Functional Performance" />
          )}
          {participatedOutcomeActivity && (
            <DetailSectionCheckMark text="Participated in selection of Outcome Descriptor Statements" />
          )}
        </DetailSectionField>
      </DetailSectionColumn>
    </DetailSectionRow>
  );
};

export default TeamMemberView;
