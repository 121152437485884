import { ReactComponent as EditIcon } from 'icons/pencil.svg';
import { FormIconButton, JumpLinkRefIds } from 'molecules';
import { Tabs, useOpenFormContext } from 'contexts';
import { Text } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useLatestReferral } from 'hooks';
import { useParams } from 'react-router-dom';

const useCardHeaderProps = () => {
  const [openFormState, setOpenFormState] = useOpenFormContext();
  const { id: childId } = useParams<{ id: string }>();
  const referral = useLatestReferral(childId);

  const onClick = useCallback(() => {
    if (openFormState) {
      return;
    }

    setOpenFormState({
      tab: Tabs.Evaluations,
      primaryJumpLink: JumpLinkRefIds.FamilyDirectedAssessments,
      secondaryJumplink: JumpLinkRefIds.FamilyConcerns,
    });
  }, [openFormState, setOpenFormState]);

  return useMemo(() => {
    return {
      headerText: (
        <Text textStyle="detailSectionViewHeader">Family concerns</Text>
      ),
      buttons: (
        <FormIconButton
          onClick={onClick}
          aria-label={'Edit'}
          Icon={EditIcon}
          refId={'family-concerns'}
          tooltipProps={{ label: 'Edit' }}
          disabled={!referral?.isActive}
          data-testid="form-edit-icon"
        />
      ),
    };
  }, [referral?.isActive, onClick]);
};

export default useCardHeaderProps;
