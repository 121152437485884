import { FC } from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { paths } from 'lib';

export const DevControlSignInByUserId: FC = () => {
  const signInIfEnter = (e: any) => {
    if (e.key === 'Enter') {
      const url = paths.controls.assumeLogin({ userId: e.target.value });
      window.location.assign(url);
    }
  };
  return (
    <FormControl>
      <FormLabel>Sign in by user id</FormLabel>
      <Input onKeyDown={signInIfEnter} type="text" />
      <FormHelperText>Hit enter to sign in</FormHelperText>
    </FormControl>
  );
};
