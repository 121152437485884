import { ChakraProps, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { JumpLinkRefIds } from 'molecules';
import { useScrollRefIntoViewById } from 'hooks';

interface Props extends ChakraProps {
  readonly id: JumpLinkRefIds | string;
}

const SectionWrapper: FC<Props> = ({ children, id, ...props }) => {
  const sectionRef = useScrollRefIntoViewById(id);

  return (
    <Flex
      id={id}
      flexGrow={1}
      flexDirection="column"
      ref={sectionRef}
      {...props}
    >
      {children}
    </Flex>
  );
};

export default SectionWrapper;
