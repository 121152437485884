import { Box, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { space, textStyles } from 'theme';

const iconSize = '20px';

const StyledLink: FC<LinkProps> = ({ children, to }) => (
  <Flex
    as="li"
    alignItems="center"
    sx={{
      py: space.half,
      width: '100%',
      a: {
        cursor: 'pointer',
        display: 'flex',
        ':focus-visible': {
          boxShadow: 'outline',
          outline: 'none',
          borderRadius: 'md',
        },
      },
      svg: {
        minWidth: iconSize,
        minHeight: iconSize,
        height: iconSize,
        width: iconSize,
        fill: 'gray.700',
        marginBottom: '1.5px',
      },
      span: {
        ...textStyles.sidebarNavItem,
        color: 'gray.700',
        textDecoration: 'none',
        marginBottom: '1.5px',
      },
      ':hover': {
        span: {
          ...textStyles.sidebarNavItem[':hover'],
          color: 'teal.700',
          marginBottom: 0,
        },
        svg: {
          fill: 'teal.700',
        },
        path: {
          stroke: textStyles.sidebarNavItem[':hover'].color,
          strokeWidth: '0.2',
        },
      },
      ':active': {
        span: {
          ...textStyles.sidebarNavItem[':active'],
          color: 'teal.700',
          marginBottom: 0,
        },
        svg: {
          fill: 'teal.700',
        },
        path: {
          stroke: textStyles.sidebarNavItem[':active'].color,
          color: 'teal.700',
          strokeWidth: '0.3',
        },
      },
    }}
  >
    <Link to={to}>{children}</Link>
  </Flex>
);

const NavItem: FC<
  LinkProps & {
    isTextShown?: boolean;
    text: string;
    icon?: FC;
  }
> = ({ to, isTextShown = false, text, icon }) => (
  <>
    {icon ? (
      <>
        {isTextShown ? (
          <StyledLink to={to}>
            <Box as={icon} mr={space.one} />
            <Text as="span">{text}</Text>
          </StyledLink>
        ) : (
          <StyledLink to={to}>
            <Box as={icon} title={text} />
          </StyledLink>
        )}
      </>
    ) : (
      <>
        {isTextShown ? (
          <StyledLink to={to}>
            <Text as="span">{text}</Text>
          </StyledLink>
        ) : null}
      </>
    )}
  </>
);

export default NavItem;
