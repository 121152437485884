import { ReactComponent as EditIcon } from 'icons/pencil.svg';
import { FormIconButton, JumpLinkRefIds } from 'molecules';
import { Tabs, useOpenFormContext } from 'contexts';
import { useCallback, useMemo } from 'react';
import { useLatestReferral } from 'hooks';
import { useParams } from 'react-router-dom';

const useCardHeaderProps = () => {
  const [openFormState, setOpenFormState] = useOpenFormContext();
  const { id: childId } = useParams<{ id: string }>();
  const referral = useLatestReferral(childId);
  const isActive = referral?.isActive;

  const onClick = useCallback(() => {
    if (openFormState) {
      return;
    }

    setOpenFormState({
      tab: Tabs.Exit,
      primaryJumpLink: JumpLinkRefIds.TransitionPlanning,
    });
  }, [openFormState, setOpenFormState]);

  return useMemo(() => {
    return {
      buttons: (
        <FormIconButton
          onClick={onClick}
          aria-label={'Edit'}
          Icon={EditIcon}
          refId={JumpLinkRefIds.TransitionPlanning}
          tooltipProps={{ label: 'Edit' }}
          disabled={!isActive}
        />
      ),
    };
  }, [isActive, onClick]);
};

export default useCardHeaderProps;
