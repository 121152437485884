import { Collapse } from '@chakra-ui/react';
import { FC } from 'react';
import {
  useLatestReferral,
  usePastReferrals,
  useReferralsByChildId,
} from 'hooks';
import { useParams } from 'react-router';
import CardSkeleton from '../../CardSkeleton/CardSkeleton';
import InlineAlert from 'components/alerts/InlineAlert';
import ReferralCard from './ReferralCard';

interface Props {
  readonly showArchived: boolean;
}

const ReferralContent: FC<Props> = ({ showArchived }) => {
  const { id: childId } = useParams<{ id: string }>();
  const { error, data } = useReferralsByChildId(childId);
  const pastReferrals = usePastReferrals(childId);
  const latestReferral = useLatestReferral(childId);

  return (
    <>
      {error && (
        <InlineAlert
          marginTop={4}
          status="warning"
          title="This section may not be up to date."
          description="We'll keep trying to get the latest information."
        />
      )}
      <Collapse in={showArchived}>
        {data && pastReferrals && showArchived
          ? pastReferrals.map(referral => (
              <ReferralCard referral={referral} isArchived={true} />
            ))
          : null}
      </Collapse>
      {data ? <ReferralCard referral={latestReferral} /> : <CardSkeleton />}
    </>
  );
};

export default ReferralContent;
