import { ChildArchiveContactsContextProvider } from 'contexts';
import { DevControlAddArchivedContact } from '../DevControlAddContact/DevControlAddArchivedContact';
import { DevControlAddContact } from '../DevControlAddContact/DevControlAddContact';
import { Flex, Stack } from '@chakra-ui/react';
import { spaceUnitless } from 'theme';
import { useStickyItemsOffset } from 'hooks';
import ChildContactsSection from '../ChildContactsSection/ChildContactsSection';
import ChildDetailsSection from '../ChildDetailsSection/ChildDetailsSection';
import DevControl from 'components/DevControl';
import FamilyInfo from '../FamilyInfo/FamilyInfo';
import JumpLinksSection from '../JumpLinksSection/JumpLinksSection';
import PrimaryCareProviderSection from '../PrimaryCareProviderSection/PrimaryCareProviderSection';
import ReferralSection from '../ReferralsSection/ReferralSection';

const TabContent = () => {
  const jumpLinksOffset = useStickyItemsOffset();

  return (
    <ChildArchiveContactsContextProvider>
      <Flex width="100%" alignItems="flex-start">
        <JumpLinksSection
          ml="-4"
          mt="-2"
          minWidth="180px"
          position="sticky"
          top={jumpLinksOffset + spaceUnitless.one}
        />
        <Stack spacing={12} width="100%" marginLeft={16}>
          <ChildDetailsSection />
          <DevControl>
            <DevControlAddArchivedContact />
            <DevControlAddContact />
          </DevControl>
          <ChildContactsSection />
          <FamilyInfo />
          <PrimaryCareProviderSection />
          <ReferralSection />
        </Stack>
      </Flex>
    </ChildArchiveContactsContextProvider>
  );
};

export default TabContent;
