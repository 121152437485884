import {
  Avatar,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from '@chakra-ui/react';
import { paths } from 'lib';
import { useUserContext } from 'contexts';

const UserProfile: FC = () => {
  const { user } = useUserContext();

  const userFullName =
    user?.givenName && user.familyName
      ? `${user.givenName} ${user.familyName}`
      : undefined;

  return user ? (
    <Menu variant="userProfile">
      <MenuButton
        variant="userProfile"
        as={IconButton}
        icon={
          <Avatar
            data-testid="userProfile"
            variant="userProfile"
            size="sm"
            name={userFullName}
          />
        }
      />

      <MenuList textStyle="bodyText">
        <MenuGroup title={userFullName ? userFullName : 'Acorn User'}>
          <MenuItem as={Link} href={paths.auth.logout()}>
            Log out
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  ) : null;
};

export default UserProfile;
