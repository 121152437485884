import * as yup from 'yup';
import {
  yupOptionalLongString,
  yupOptionalString,
  yupPhoneTestValidator,
} from 'lib';

export const formSchema = yup.object({
  prioritiesAndGoals: yupOptionalLongString(),
  planningStepsTaken: yupOptionalLongString(),
  partBPotential: yup.boolean(),
  specialEdContactInfoName: yupOptionalString(),
  specialEdContactInfoPhoneGeneric: yupOptionalString().test(
    yupPhoneTestValidator
  ),
  specialEdContactInfoEmail: yupOptionalString().email('Must be a valid email'),
});

export type FormFields = yup.InferType<typeof formSchema>;

export const initialValues = {
  prioritiesAndGoals: undefined,
};
