import { FC } from 'react';
import { useChildDetailsContext, useUserContext } from 'contexts';
import ChildDetails from './ChildDetails/ChildDetails';
import Referral from './Referral/Referral';

const CreateReferral: FC = () => {
  const [childDetails] = useChildDetailsContext();

  const { user } = useUserContext();
  const organization = user?.organizations[0];

  if (!organization) {
    throw new Error(
      'CreateReferral component mounted without user organizations available'
    );
  }

  return (
    <>
      {childDetails ? (
        <Referral organizationId={organization.id} />
      ) : (
        <ChildDetails />
      )}
    </>
  );
};

export { CreateReferral };
