import { ChildRecord } from '@cssat/acorn-api-shared';
import { validateMailingAndPhysicalAddress } from 'lib';

const adaptApiToFormFields = (
  primaryCareProvider: ChildRecord['primaryCareProvider']
) => {
  const { id, ...rest } = primaryCareProvider ?? {};
  const { isAddressTheSame } =
    validateMailingAndPhysicalAddress(primaryCareProvider);
  return {
    ...rest,
    isAddressSame: isAddressTheSame,
  };
};

export default adaptApiToFormFields;
