import { ReactComponent as AddIcon } from 'icons/new.svg';
import { FormIconButton, JumpLinkRefIds } from 'molecules';
import { Tabs, useOpenFormContext } from 'contexts';
import { iconButtonStyles } from 'pages/ChildRecord/styles';
import { useCallback, useMemo } from 'react';
import { useLatestReferral } from 'hooks';
import { useParams } from 'react-router-dom';
import useChildContacts from '../useChildContacts';

const useIconButton = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data } = useChildContacts(childId);
  const referral = useLatestReferral(childId);
  const isActive = referral?.isActive;

  const [openFormState, setOpenFormState] = useOpenFormContext();
  const onOpenAddForm = useCallback(() => {
    if (
      openFormState &&
      (openFormState.primaryJumpLink !== JumpLinkRefIds.ChildContacts ||
        openFormState.secondaryJumplink)
    ) {
      return;
    }

    setOpenFormState({
      tab: Tabs.Overview,
      primaryJumpLink: JumpLinkRefIds.ChildContacts,
    });
  }, [openFormState, setOpenFormState]);

  const loaded = !!data;

  return useMemo(() => {
    const formIconProps = loaded ? {} : { disabled: true };
    return (
      <FormIconButton
        {...formIconProps}
        onClick={onOpenAddForm}
        aria-label="Add"
        refId={JumpLinkRefIds.ChildContacts}
        Icon={AddIcon}
        sx={iconButtonStyles}
        tooltipProps={{ label: 'Add contact' }}
        disabled={!isActive}
      />
    );
  }, [isActive, loaded, onOpenAddForm]);
};

export default useIconButton;
