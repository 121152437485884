import { ChildContact } from '@cssat/acorn-api-shared';
import { FormFields, formSchema } from './validation';
import {
  UnspecifiedPreferredContact,
  validateMailingAndPhysicalAddress,
} from 'lib';

const adaptApiToForm = (childContact?: ChildContact): FormFields => {
  const { isAddressTheSame } = validateMailingAndPhysicalAddress(childContact);
  const confidentiality = childContact?.confidentiality ?? undefined;

  const preferredContactMethod =
    childContact?.preferredContactMethod ?? UnspecifiedPreferredContact;
  return childContact
    ? {
        ...childContact,
        name: childContact.name,
        isAddressSame: isAddressTheSame,
        confidentiality,
        preferredContactMethod,
      }
    : { ...formSchema.default(), preferredContactMethod };
};

export default adaptApiToForm;
