import {
  Box,
  Button,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { MobileDate } from 'components/styled/dates';
import { PageHeader } from 'molecules';
import { ReactComponent as ServicesIcon } from 'icons/services.svg';
import {
  Status,
  TrackedSession,
} from '@cssat/acorn-api-shared/dist/service-delivery';
import {
  Table,
  TableBody,
  TableCell,
  TableColHead,
  TableHeader,
  TableRow,
} from 'components/styled/tables';
import {
  authenticatedFetch,
  enumToDisplay,
  fetchKids,
  fetchServicePlans,
  formatDateString,
  formatISODate,
  paths,
} from 'lib';
import { colors, space } from 'theme';
import { useHistory } from 'react-router-dom';
import { useTrainingUserContext } from 'contexts';
import useSWR from 'swr';

type StatusPillProps = {
  status: Status;
  display: string;
};
const StatusPill: FC<StatusPillProps> = ({ display, status }) => {
  let statusColor: string = '';
  switch (status) {
    case Status.AsPlanned:
      statusColor = colors.green[500];
      break;
    case Status.WithChanges:
      statusColor = colors.orange[500];
      break;
    case Status.NotDelivered:
      statusColor = colors.red[500];
      break;
  }

  return (
    <Box
      display="inline-block"
      px={space.one}
      py={space.quarter}
      sx={{ border: `2px solid ${statusColor}`, borderRadius: 49 }}
    >
      {display}
    </Box>
  );
};

const NoTrackedSessions: FC = () => {
  const iconSize = '20px';
  return (
    <Flex flexDirection="column" alignItems="center">
      <Box
        sx={{
          width: iconSize,
          height: iconSize,
          svg: {
            width: iconSize,
            height: iconSize,
            path: {
              fill: 'gray.600',
            },
          },
        }}
      >
        <ServicesIcon />
      </Box>
      <Text variant="bodyText" sx={{ color: 'gray.600', mt: space.one }}>
        Track your first service to see it here
      </Text>
    </Flex>
  );
};

interface TrackedSessionsDisplayer {
  results: TrackedSessionUI[];
}

const TrackedSessions: FC<TrackedSessionsDisplayer> = ({ results }) => {
  return (
    <Table
      sx={{
        display: ['none', 'none', 'table'],
      }}
      data-testid="desktopTable"
    >
      <TableHeader>
        <TableRow>
          <TableColHead>Child</TableColHead>
          <TableColHead>Service</TableColHead>
          <TableColHead>Length</TableColHead>
          <TableColHead>Type</TableColHead>
          <TableColHead>Setting</TableColHead>
          <TableColHead>Date delivered</TableColHead>
          <TableColHead>Status</TableColHead>
          <TableColHead>Date tracked</TableColHead>
        </TableRow>
      </TableHeader>
      <TableBody data-testid="tracked-sessions-rows">
        {results.map((sessionData, idx) => (
          <TableRow key={idx}>
            <TableCell>{sessionData.childName}</TableCell>
            <TableCell>{sessionData.serviceName}</TableCell>
            <TableCell>{sessionData.durationDisplay}</TableCell>
            <TableCell>{sessionData.intensityDisplay}</TableCell>
            <TableCell>{sessionData.settingDisplay}</TableCell>
            <TableCell>{formatDateString(sessionData.dateOfService)}</TableCell>
            <TableCell>{sessionData.statusDisplay}</TableCell>
            <TableCell>{formatISODate(sessionData.createdAt)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const SessionDataText: FC<{
  label: string;
  value: TrackedSessionUI[keyof TrackedSessionUI];
}> = ({ label, value }) => (
  <div>
    <Text as={'span'} fontWeight="medium">{`${label}: `}</Text>
    {value}
  </div>
);

const TrackedSessionsMobile: FC<TrackedSessionsDisplayer> = ({ results }) => {
  return (
    <Table display={['table', 'table', 'none']} data-testid="mobileTable">
      <TableBody data-testid="tracked-sessions-rows">
        {results.map((sessionData, idx) => (
          <TableRow key={idx}>
            <TableCell sx={{ padding: space.one }}>
              <Heading as="span" variant="tertiary" mb={space.quarter}>
                {sessionData.childName}
              </Heading>
              <Box mb={space.half}>
                <MobileDate date={sessionData.dateOfService} alternate="-" />
              </Box>
              <Box mb={space.one}>
                <StatusPill
                  status={sessionData.status as Status}
                  display={sessionData.statusDisplay || ''}
                />
              </Box>

              <Box
                textStyle="bodyText"
                sx={{
                  lineHeight: 'base',
                  whiteSpace: 'normal',
                  '& > *': { mb: space.half, '&:last-child': { mb: 0 } },
                }}
              >
                <SessionDataText
                  label="Service"
                  value={sessionData.serviceName}
                />
                <SessionDataText
                  label="Length"
                  value={sessionData.durationDisplay}
                />
                <SessionDataText
                  label="Type"
                  value={sessionData.intensityDisplay}
                />
                <SessionDataText
                  label="Setting"
                  value={sessionData.settingDisplay}
                />
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

interface TrackedSessionUI extends TrackedSession {
  childName: string;
  serviceName: string;
  durationDisplay: string;
  settingDisplay: string;
  intensityDisplay: string;
  statusDisplay: string;
}

export const TrackedSessionsList: FC = () => {
  const { user, logout } = useTrainingUserContext();
  const [results, setResults] = useState<TrackedSessionUI[] | undefined>();

  const history = useHistory();

  const providerId = user ? user.email : 'no-provider-logged-in';

  const { data } = useSWR<TrackedSession[]>(
    paths.serviceDelivery.list({ providerId }),
    authenticatedFetch
  );

  /* Decorate API data rows with human-readable names */
  useEffect(() => {
    if (!data) return;
    const kidIds = data.map(row => row.childId);
    const servicePlanIds = data.map(row => row.servicePlanId);

    const fetchOtherData = async () => {
      const kids = await fetchKids(kidIds);
      const services = await fetchServicePlans(servicePlanIds);

      const mapped = data.map(row => {
        let newRow = row as TrackedSessionUI;
        const kid = kids.find((k: any) => k.id === row.childId);
        if (kid) {
          newRow.childName = kid.name;
        }

        newRow.statusDisplay = enumToDisplay(newRow.status);

        newRow.durationDisplay = newRow.duration
          ? `${newRow.duration} min`
          : '--';
        newRow.intensityDisplay = newRow.intensity
          ? enumToDisplay(newRow.intensity?.toString())
          : '--';
        newRow.settingDisplay = newRow.setting
          ? enumToDisplay(newRow.setting?.toString())
          : '--';

        const matchingService = services.find(
          service => service.id === row.servicePlanId
        );
        if (matchingService) {
          newRow.serviceName = matchingService.name;

          if (newRow.status === Status.WithChanges) {
            newRow.durationDisplay =
              newRow.duration === undefined
                ? `${matchingService.duration} min`
                : `*${newRow.duration} min`;
            newRow.settingDisplay =
              newRow.setting === undefined
                ? `${enumToDisplay(matchingService.setting)}`
                : `*${enumToDisplay(newRow.setting)}`;
            newRow.intensityDisplay =
              newRow.intensity === undefined
                ? `${enumToDisplay(matchingService.intensity)}`
                : `*${enumToDisplay(newRow.intensity)}`;
          }
        }

        return newRow;
      });

      setResults(mapped);
    };

    fetchOtherData();
  }, [data]);

  const isLoading = !results;
  const loadedDataIsEmpty = results && !results.length;

  let loadableSessionsData;
  if (isLoading) {
    loadableSessionsData = <Text>Loading...</Text>;
  } else if (loadedDataIsEmpty) {
    loadableSessionsData = <NoTrackedSessions />;
  } else if (results) {
    loadableSessionsData = (
      <>
        <TrackedSessions
          aria-describedby="with-changes-legend"
          results={results}
        />
        <TrackedSessionsMobile
          aria-describedby="with-changes-legend"
          results={results}
        />
      </>
    );
  }

  const hasWithChangesItems: boolean | undefined = results?.some(
    (item: TrackedSessionUI) => item?.statusDisplay === 'With changes'
  );

  return (
    <>
      <PageHeader
        title="Service Tracking"
        subTitle={`${user?.name} (${user?.email})`}
      >
        <Button
          variant="ghost"
          onClick={() => {
            logout();
          }}
        >
          Sign out
        </Button>
        <Button
          onClick={() => {
            history.push('/services/track');
          }}
        >
          Track a service
        </Button>
      </PageHeader>
      <Tabs>
        <TabList>
          <Tab>Tracked services</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {loadableSessionsData}
            {hasWithChangesItems && (
              <Text id="with-changes-legend" mt={space.one} variant="bodyText">
                * Changed from the service plan.
              </Text>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
