import {
  CreateTrackedSessionAsPlanned,
  Status,
} from '@cssat/acorn-api-shared/dist/service-delivery';
import { FormFields } from 'pages/Services/TrackSessionForm/validation';
import { ServicePlan } from 'pages/Services/types';
import { TrainingUser } from 'contexts';
import { v4 as uuid } from 'uuid';

export const adaptAsPlannedForm = (
  values: FormFields,
  user: TrainingUser,
  service: ServicePlan
): CreateTrackedSessionAsPlanned => {
  return {
    childId: values.child,
    duration: service.duration,
    intensity: service.intensity,
    setting: service.setting,
    servicePlanId: values.service,
    serviceTypeId: service.serviceTypeId,
    dateOfService: values.dateOfService!.toISOString(),
    providerId: user.email,
    providerName: user.name,
    status: Status.AsPlanned,
    trackedSessionId: uuid(),
    ...(values.note ? { note: values.note } : {}),
  } as CreateTrackedSessionAsPlanned;
};
