// NOTE: body is overridden to allow a convenience of passing in an object that will be stringified
// if this redefinition causes headaches, perhaps refactor to include an explicit `body` param in `authenticatedPost`
// example: authenticatedPost(input, body, init)
interface AcornRequestInit extends Omit<RequestInit, 'body'> {
  body?: RequestInit['body'] | object;
}

class ApiPostError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ApiPostError';
  }
}

const authenticatedPost = (input: RequestInfo, init?: AcornRequestInit) => {
  const { body, ...rest } = init || {};
  const fetchOptions: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    ...rest,
  };

  if (!(body instanceof FormData)) {
    fetchOptions.body = JSON.stringify(body);
  } else {
    fetchOptions.body = body;
  }

  return fetch(input, fetchOptions).then(res => {
    if (!res.ok) {
      throw new ApiPostError(res.statusText);
    }
  });
};

export default authenticatedPost;
