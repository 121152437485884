import { FC } from 'react';

const developerControlsEnabled = process.env.REACT_APP_DEV_CONTROLS === 'true';

const DevControl: FC = ({ children }) => {
  if (developerControlsEnabled) {
    return <>{children}</>;
  }
  return null;
};

export default DevControl;
