import { PopupToast } from 'components/alerts/PopupToast';
import { useCallback, useMemo } from 'react';
import { useToast as useChakraToast } from '@chakra-ui/react';
import type { UsePopupToastOptions } from 'components/alerts/PopupToast';

const defaultOptions: Partial<UsePopupToastOptions> = {
  position: 'top',
  isClosable: true,
};

type UsePopupToastReturnHelpers = Pick<
  ReturnType<typeof useChakraToast>,
  'close' | 'closeAll' | 'update' | 'isActive'
>;

type TriggerToast = () => void;
type UsePopupToastReturn = [TriggerToast, UsePopupToastReturnHelpers];

export const usePopupToast = (
  options: UsePopupToastOptions
): UsePopupToastReturn => {
  const toast = useChakraToast({
    render: renderProps => {
      const { close, closeAll, update, isActive } = toast;

      const toastProps = {
        ...defaultOptions,
        ...options,
        close,
        closeAll,
        update,
        isActive,
        ...renderProps,
      };

      return <PopupToast {...toastProps} />;
    },
    ...defaultOptions,
    ...options,
  });

  const triggerToast: TriggerToast = useCallback(() => toast(), [toast]);
  const toastHelpers: UsePopupToastReturnHelpers = useMemo(
    () => ({
      close: toast.close,
      closeAll: toast.closeAll,
      update: toast.update,
      isActive: toast.isActive,
    }),
    [toast.close, toast.closeAll, toast.isActive, toast.update]
  );

  return [triggerToast, toastHelpers];
};
