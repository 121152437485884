import { SessionUser } from '@cssat/acorn-api-shared';
import { authenticatedFetch, paths } from 'lib';
import useSWR from 'swr';

const useOrganizationUsers = (orgId?: string) => {
  const path = orgId
    ? paths.organization.findUsers({ organizationId: orgId })
    : null;

  return useSWR<SessionUser[]>(path, authenticatedFetch);
};

export default useOrganizationUsers;
