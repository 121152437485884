import { FC, createContext, useContext, useState } from 'react';

type ChildArchiveContactsContextType = [
  boolean,
  (showArchived: boolean) => void
];

const ChildArchiveContactsContext =
  createContext<ChildArchiveContactsContextType>([false, () => {}]);

export const useChildArchiveContactsContext = () =>
  useContext(ChildArchiveContactsContext);

export const ChildArchiveContactsContextProvider: FC = ({ children }) => {
  const value = useState<boolean>(false);

  return (
    <ChildArchiveContactsContext.Provider value={value}>
      {children}
    </ChildArchiveContactsContext.Provider>
  );
};
