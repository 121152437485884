import { DataCard, JumpLinkRefIds } from 'molecules';
import { ESITService } from '@cssat/acorn-api-shared';
import { FC } from 'react';
import { useESITServiceIdContext, useOpenFormContext } from 'contexts';
import CardSkeleton from 'pages/ChildRecord/CardSkeleton/CardSkeleton';
import ESITServicesForm from '../ESITServicesForm/ESITServicesForm';
import ESITServicesView from './ESITServicesView';
import useCardHeaderProps from './useCardHeaderProps';

interface ESITServicesCardProps {
  readonly service: ESITService;
}

const ESITServicesCard: FC<ESITServicesCardProps> = ({
  service,
}: ESITServicesCardProps) => {
  const [openFormState] = useOpenFormContext();
  const [esitServiceFormId] = useESITServiceIdContext();

  const isFormOpen =
    esitServiceFormId === service.id &&
    openFormState?.primaryJumpLink === JumpLinkRefIds.ESITServices;
  const headerProps = useCardHeaderProps(service);

  return (
    <>
      {service ? (
        <DataCard headerProps={headerProps}>
          {isFormOpen ? (
            <ESITServicesForm service={service} />
          ) : (
            <ESITServicesView service={service} />
          )}
        </DataCard>
      ) : (
        <CardSkeleton />
      )}
    </>
  );
};

export default ESITServicesCard;
