export enum JumpLinkRefIds {
  ChildContacts = 'child-contacts',
  ChildDetails = 'child-details',
  Referrals = 'referrals',
  Eligibility = 'eligibility',
  EvaluationConsentForms = 'evaluation-consent-forms',
  Evaluations = 'evaluations',
  FamilyConcerns = 'family-concerns',
  FamilyDirectedAssessments = 'family-directed-assessments',
  FamilyInformation = 'family-information',
  MedicalDiagnoses = 'medical-diagnoses',
  PrimaryCareProvider = 'primary-care-provider',
  RoutinesAndActivities = 'routines-and-activities',
  TeamMembers = 'team-members',
  LatestCos = 'latest-cos',
  CosInfo = 'cos-info',
  CosComplete = 'cos-complete',
  PositiveSkills = 'positive-skills',
  AcquiringSkills = 'acquiring-skills',
  TakingActions = 'taking-actions',
  TransitionPlanning = 'transition-planning',
  TransitionConference = 'transition-conference',
  CompleteTransition = 'complete-transition',
  ChildOutcomes = 'child-outcomes',
  ESITServices = 'esit-services',
}

export const JumpLinkTabIndexes: Record<JumpLinkRefIds, number> = {
  [JumpLinkRefIds.ChildContacts]: 0,
  [JumpLinkRefIds.ChildDetails]: 0,
  [JumpLinkRefIds.Referrals]: 0,
  [JumpLinkRefIds.Eligibility]: 1,
  [JumpLinkRefIds.EvaluationConsentForms]: 1,
  [JumpLinkRefIds.Evaluations]: 1,
  [JumpLinkRefIds.FamilyConcerns]: 1,
  [JumpLinkRefIds.FamilyDirectedAssessments]: 1,
  [JumpLinkRefIds.FamilyInformation]: 0,
  [JumpLinkRefIds.MedicalDiagnoses]: 1,
  [JumpLinkRefIds.PrimaryCareProvider]: 0,
  [JumpLinkRefIds.RoutinesAndActivities]: 1,
  [JumpLinkRefIds.TeamMembers]: 1,
  [JumpLinkRefIds.LatestCos]: 2,
  [JumpLinkRefIds.CosInfo]: 2,
  [JumpLinkRefIds.CosComplete]: 2,
  [JumpLinkRefIds.PositiveSkills]: 2,
  [JumpLinkRefIds.AcquiringSkills]: 2,
  [JumpLinkRefIds.TakingActions]: 2,
  [JumpLinkRefIds.ChildOutcomes]: 3,
  [JumpLinkRefIds.ESITServices]: 3,
  [JumpLinkRefIds.TransitionPlanning]: 5,
  [JumpLinkRefIds.TransitionConference]: 5,
  [JumpLinkRefIds.CompleteTransition]: 5,
};
