import { Box, Button, Tooltip } from '@chakra-ui/react';
import {
  DetailSectionDivider,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { Domain } from '@cssat/acorn-api-shared';
import { FC, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import {
  QUALIFYING_PERCENTAGE_DELAY,
  QUALIFYING_STANDARD_DEVIATION,
  isQualifyingPercentageDelay,
  isQualifyingStandardDeviation,
} from '../helpers';
import { ReactComponent as QualifyingIcon } from 'icons/qualifying.svg';
interface Props {
  readonly domainName: string;
  readonly domain: Domain;
}

const badgeStyles = {
  px: 2,
  py: 2,
  borderRadius: 5,
  display: 'flex',
  justifyContent: 'flex-end',
};

const DomainView: FC<Props> = ({ domainName, domain }) => {
  const [showReport, setShowReport] = useState(false);
  const isHearingOrVision = domain?.results;

  const standardDeviationIsQualifying = isQualifyingStandardDeviation(
    domain?.standardDeviation
  );

  const percentageDelayIsQualifying = isQualifyingPercentageDelay(
    domain?.percentageDelay
  );

  return (
    <>
      <DetailSectionDivider orientation="horizontal" />
      <DetailSectionRow justifyContent="space-between">
        <Flex alignItems="center">
          <Text textStyle="sectionFieldViewValue" mr={2}>
            {domainName}
          </Text>
          {!isHearingOrVision && domain.presentLevelOfDevelopment && (
            <Button
              variant="link"
              width="fit-content"
              onClick={() => setShowReport(!showReport)}
            >
              {showReport ? 'Hide full report' : 'See full report'}
            </Button>
          )}
        </Flex>
        <Flex alignItems="center">
          <Flex justifyContent="flex-end" width={163}>
            {domain.standardDeviation && (
              <Tooltip
                isDisabled={!standardDeviationIsQualifying}
                label={`A standard deviation of ${QUALIFYING_STANDARD_DEVIATION} or less is a qualifying score`}
                aria-label={`A standard deviation of ${QUALIFYING_STANDARD_DEVIATION} or less is a qualifying score`}
              >
                <Flex
                  sx={badgeStyles}
                  bgColor={standardDeviationIsQualifying ? 'teal.50' : ''}
                >
                  {standardDeviationIsQualifying && <QualifyingIcon />}
                  <Box ml={2}>
                    {parseFloat(domain.standardDeviation.toFixed(2))} std. dev
                  </Box>
                </Flex>
              </Tooltip>
            )}
          </Flex>
          <Flex justifyContent="flex-end" width={154}>
            {domain.percentageDelay && (
              <Tooltip
                isDisabled={!percentageDelayIsQualifying}
                label={`A percentage delay of ${QUALIFYING_PERCENTAGE_DELAY}% or greater is a qualifying score`}
                aria-label={`A percentage delay of ${QUALIFYING_PERCENTAGE_DELAY}% or greater is a qualifying score`}
              >
                <Flex
                  sx={badgeStyles}
                  bgColor={percentageDelayIsQualifying ? 'teal.50' : ''}
                >
                  {percentageDelayIsQualifying && <QualifyingIcon />}
                  <Box ml={2}>
                    {parseFloat(domain.percentageDelay.toFixed(2))}% delay
                  </Box>
                </Flex>
              </Tooltip>
            )}
          </Flex>
          <Text sx={badgeStyles} width={103}>
            {domain.ageEquivalence &&
              `${parseFloat(domain.ageEquivalence.toFixed(1))} months`}
          </Text>
        </Flex>
      </DetailSectionRow>
      {domain.results && (
        <DetailSectionRow mt={4}>
          <Text textStyle="bodyText">{domain?.results}</Text>
        </DetailSectionRow>
      )}
      {showReport && (
        <DetailSectionRow mt={4}>
          <Text textStyle="bodyText">{domain.presentLevelOfDevelopment}</Text>
        </DetailSectionRow>
      )}
    </>
  );
};

export default DomainView;
