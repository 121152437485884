import { FC } from 'react';
import { SWRConfig } from 'swr';
import { swrFetcher } from 'lib';

export type SWRConfigType = Parameters<typeof SWRConfig>[0]['value'];

export const SWRConfigWithFetcher: FC<{ config?: SWRConfigType }> = ({
  config,
  children,
}) => {
  const value = config || {};
  value.fetcher ||= swrFetcher;

  return <SWRConfig value={value}>{children}</SWRConfig>;
};

export const TestSWRConfig: FC = ({ children }) => (
  <SWRConfigWithFetcher config={{ dedupingInterval: 0 }}>
    {children}
  </SWRConfigWithFetcher>
);
