import { FC, ReactNode } from 'react';
import {
  Radio,
  RadioProps,
  Text,
  useRadioGroupContext,
} from '@chakra-ui/react';
import { forms } from 'theme';
import { useField } from 'formik';
import camelcase from 'lodash.camelcase';

export interface RadioOptionProps extends RadioProps {
  name?: string;
  label?: string;
  conditionalChildren?: ReactNode;
}

export const RadioOption: FC<RadioOptionProps> = ({
  value,
  label,
  colorScheme = 'teal',
  children,
  conditionalChildren,
  isDisabled,
}) => {
  const group = useRadioGroupContext();
  const [field] = useField(group.name);
  const id = camelcase(`${group.name} is ${value}`);

  const isSelected = () => field.value === value;
  const labelVariant = isSelected()
    ? forms.classicRadio.selectedLabel
    : forms.classicRadio.unselectedLabel;

  return (
    <>
      <Radio
        colorScheme={colorScheme}
        value={value}
        id={id}
        data-testid={id}
        isDisabled={isDisabled}
      >
        {label && <Text sx={{ ...labelVariant }}>{label}</Text>}
        {children}
      </Radio>
      {field.value === value && conditionalChildren}
    </>
  );
};
