import { Box, BoxProps, Text, TextProps } from '@chakra-ui/react';
import { FC } from 'react';
import { cards } from 'theme';

export interface CardHeaderProps extends Omit<TextProps, 'css'> {}

export const CardHeader: FC<CardHeaderProps> = props => (
  <Text textStyle="bodyText" {...props}>
    <strong>{props.children}</strong>
  </Text>
);

export const InfoCard: FC = ({ children }) => (
  <Box sx={{ ...cards.info }}>{children}</Box>
);

export interface SecondaryBoxProps extends Omit<BoxProps, 'css'> {}
export const SecondaryBox: FC<SecondaryBoxProps> = props => (
  <Box sx={{ ...cards.secondaryBox }} {...props}>
    {props.children}
  </Box>
);
