import { Box, BoxProps, Flex, FlexProps } from '@chakra-ui/react';
import { FC } from 'react';

export const PageContainer: FC = ({ children }) => (
  <Box mx={[5, 5, 32]} mt={[4, 4, 4]} mb={12} width="100%" maxWidth="1274px">
    {children}
  </Box>
);

interface BreakpointBoxProps extends Omit<BoxProps, 'css'> {
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
}
export const BreakpointBox: FC<BreakpointBoxProps> = ({
  children,
  mobile = false,
  tablet = false,
  desktop = false,
  ...props
}) => {
  const breakArray = [
    mobile ? 'block' : 'none',
    tablet ? 'block' : 'none',
    desktop ? 'block' : 'none',
  ];

  return (
    <Box display={breakArray} {...props}>
      {children}
    </Box>
  );
};

export const CenteredStack: FC = props => (
  <Flex alignItems="center" flexDirection="column" {...props}>
    {props.children}
  </Flex>
);

type JustifiedRowProps = Pick<FlexProps, 'alignItems' | 'flexWrap'>;
export const JustifiedRow: FC<JustifiedRowProps> = props => {
  return (
    <Flex
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
      {...props}
    >
      {props.children}
    </Flex>
  );
};
