import {
  ContactRadioControlMap,
  PartialContactRadioControlMap,
  defaultContactRadioControls,
} from './constants';
import { FC } from 'react';
import { RadioGroupField, RadioOption } from 'components/formik';
import { camelCaseToKebab } from 'lib';
import { contactInputFields } from 'molecules/ContactInputSection';

interface Props {
  readonly fieldOverrides?: PartialContactRadioControlMap;
}

const mergeFieldOverrides = (fieldOverrides: PartialContactRadioControlMap) => {
  const mergedOverrides: PartialContactRadioControlMap = {};

  for (const key in defaultContactRadioControls) {
    let castedKey = key as keyof ContactRadioControlMap;
    mergedOverrides[castedKey] = {
      ...defaultContactRadioControls[castedKey],
      ...fieldOverrides[castedKey],
    };
  }

  return mergedOverrides as ContactRadioControlMap;
};

const ContactRadioSection: FC<Props> = ({ fieldOverrides }) => {
  const { contactRadio, unknown, mailingAddress, ...radioOptions } =
    mergeFieldOverrides(fieldOverrides ?? {});

  if (!contactRadio.isVisible) {
    return null;
  }

  const getUnnestedValue = (fieldValue: string) => {
    const splitValue = fieldValue.split('.');
    return splitValue[splitValue.length - 1];
  };

  const getOptionValue = (fieldValue: string) =>
    camelCaseToKebab(getUnnestedValue(fieldValue));

  return (
    <RadioGroupField
      name={contactRadio.name}
      label={contactRadio.label}
      required={contactRadio.required}
    >
      {contactInputFields.map(contactType => {
        const override = radioOptions[contactType];

        if (!override.isVisible) {
          return null;
        }

        return (
          <RadioOption
            key={override.name}
            value={getOptionValue(override.name)}
            label={override.label}
          />
        );
      })}
      {mailingAddress.isVisible && (
        <RadioOption
          value={getOptionValue(mailingAddress.name)}
          label={mailingAddress.label}
        />
      )}
      {unknown.isVisible && (
        <RadioOption
          value={getOptionValue(unknown.name)}
          label={unknown.label}
        />
      )}
    </RadioGroupField>
  );
};

export default ContactRadioSection;
