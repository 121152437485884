import * as yup from 'yup';
import { FamilyInfo } from '@cssat/acorn-api-shared';
import { yupNullableString } from 'lib';

export const formSchema = yup
  .object({
    countyId: yup.number().nullable().notRequired(),
    schoolDistrictId: yup.number().nullable().notRequired(),
    livesInHome: yupNullableString(),
    formLanguageId: yup.number().nullable().notRequired(),
  })
  .defined();

export type FormFields = yup.InferType<typeof formSchema>;

export const coerceInitialValues = (familyInfo?: FamilyInfo) => {
  const initialValues = { ...familyInfo };

  if (familyInfo?.countyId === null) {
    initialValues.countyId = undefined;
  }

  if (familyInfo?.schoolDistrictId === null) {
    initialValues.schoolDistrictId = undefined;
  }

  if (familyInfo?.formLanguageId === null) {
    initialValues.formLanguageId = undefined;
  }

  return initialValues;
};
