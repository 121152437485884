import { ReactComponent as AddIcon } from 'icons/add.svg';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import {
  Checkbox,
  DateField,
  RadioGroupField,
  RadioOption,
  RadioPill,
  TextareaField,
} from 'components/formik';
import { FC, useState } from 'react';
import { FormFields } from './validation';
import {
  Intensity,
  Setting,
} from '@cssat/acorn-api-shared/dist/service-delivery';
import { LegendaryLabel } from 'components/styled/forms';
import { SecondaryBox } from 'components/styled/cards';
import { ServicePlan } from 'pages/Services/types';
import { enumToDisplay } from 'lib/string';
import { space } from 'theme';
import { startOfToday, subYears } from 'date-fns';
import { useFormikContext } from 'formik';

const presentIntensity = (intensity: Intensity) => enumToDisplay(intensity);
const presentSetting = (setting: Setting) => {
  let suffix = '';
  switch (setting) {
    case Setting.Correspondence:
      suffix = ' (email/letter)';
  }

  return `${enumToDisplay(setting)} ${suffix}`;
};

const durationIntervalOptions = [15, 30, 45, 60, 75, 90, 105, 120] as const;

const intensityOptions = [Intensity.Individual, Intensity.Group] as const;

const settingOptions = [
  Setting.Telepractice,
  Setting.AgencySponsoredGroup,
  Setting.ChildCareCenter,
  Setting.CommunityGroup,
  Setting.HomeVisit,
  Setting.ClinicVisit,
  Setting.Correspondence,
  Setting.OfficeVisit,
  Setting.PhoneCall,
] as const;

export const SectionOption03WithChanges: FC<{
  service: ServicePlan | undefined;
}> = ({ service }) => {
  const [noteAdded, setNoteAdded] = useState<boolean>(false);

  const { values, touched, errors } = useFormikContext<FormFields>();

  return (
    <>
      <DateField
        name="dateOfService"
        label="Date delivered"
        required
        disabledDays={{
          after: startOfToday(),
          before: subYears(startOfToday(), 3),
        }}
      />
      {noteAdded ? (
        <Box mt={space.two}>
          <TextareaField
            autoFocus={true}
            name="note"
            label="Note"
            data-testid="track-session-notes"
            onBlur={e => {
              if (e.target.value.trim() === '') {
                setNoteAdded(false);
              }
            }}
          />
        </Box>
      ) : (
        <Button
          marginTop={4}
          variant="ghost"
          leftIcon={<AddIcon />}
          onClick={() => setNoteAdded(true)}
        >
          Add note (optional)
        </Button>
      )}
      <FormControl
        name="whatChanged"
        isInvalid={touched['whatChanged'] && !!errors['whatChanged']}
        isRequired={true}
        {...(touched['whatChanged'] && !!errors['whatChanged']
          ? {
              borderRight: '1px solid',
              borderColor: 'red.500',
              paddingRight: 4,
            }
          : {})}
      >
        <FormLabel as={LegendaryLabel} textStyle="bigLabel" marginTop={4}>
          What changed from the plan?
        </FormLabel>
        <Checkbox
          label="Session length"
          name="whatChanged"
          value="duration"
          mb={4}
        />
        {values['whatChanged'] && values['whatChanged'].includes('duration') && (
          <SecondaryBox mb={space.one}>
            <RadioGroupField
              name="duration"
              type="number"
              label="How long was the session?"
              direction="horizontal"
              required
            >
              {durationIntervalOptions
                .filter(duration => service && service.duration !== duration)
                .map(duration => (
                  <RadioPill
                    key={`${duration}min`}
                    value={duration}
                  >{`${duration} min`}</RadioPill>
                ))}
            </RadioGroupField>
          </SecondaryBox>
        )}
        <Checkbox label="Type" name="whatChanged" value="intensity" mb={4} />
        {values['whatChanged'] && values['whatChanged'].includes('intensity') && (
          <SecondaryBox mb={space.one}>
            <RadioGroupField
              name="intensity"
              label="What was the service type?"
              direction="horizontal"
              required
            >
              {intensityOptions
                .filter(intensity => service && service.intensity !== intensity)
                .map(intensity => (
                  <RadioPill key={intensity} value={intensity}>
                    {presentIntensity(intensity)}
                  </RadioPill>
                ))}
            </RadioGroupField>
          </SecondaryBox>
        )}
        <Checkbox label="Setting" name="whatChanged" value="setting" mb={4} />
        {values['whatChanged'] && values['whatChanged'].includes('setting') && (
          <SecondaryBox>
            <RadioGroupField
              name="setting"
              label="What was the setting?"
              required
            >
              {settingOptions
                .filter(setting => service && service.setting !== setting)
                .map(setting => (
                  <RadioOption
                    key={`setting-${setting}`}
                    value={setting}
                    label={presentSetting(setting)}
                  />
                ))}
            </RadioGroupField>
          </SecondaryBox>
        )}
        <FormErrorMessage data-testid="whatChangedError" marginBottom={2}>
          {errors['whatChanged']}
        </FormErrorMessage>
      </FormControl>
    </>
  );
};
