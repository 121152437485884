import { ReactComponent as ArchiveIcon } from 'icons/archive.svg';
import { ReactComponent as EditIcon } from 'icons/pencil.svg';
import { Evaluation } from '@cssat/acorn-api-shared';
import { FormIconButton, JumpLinkRefIds } from 'molecules';
import { Tabs, useOpenFormContext } from 'contexts';
import { ReactComponent as UnarchiveIcon } from 'icons/unarchive.svg';
import { useCallback, useMemo } from 'react';
import { useLatestReferral } from 'hooks';
import { useParams } from 'react-router-dom';

interface Props {
  readonly evaluation: Evaluation;
  onWarning(): void;
}

const toolNameMap: Record<string, string> = {
  'normed-tools-tests': 'Normed tools',
  'vision-evaluation': 'Vision assessment',
  'hearing-evaluation': 'Hearing assessment',
  'caregiver-report': 'Caregiver report',
  'clinical-observation': 'Clinical observation',
  'medical-records': 'Medical records',
  'educational-records': 'Educational records',
  other: 'Other',
};

const useCardHeaderProps = ({ evaluation, onWarning }: Props) => {
  const [openFormState, setOpenFormState] = useOpenFormContext();
  const { id: childId } = useParams<{ id: string }>();
  const referral = useLatestReferral(childId);

  const onClick = useCallback(() => {
    if (openFormState) {
      return;
    }

    setOpenFormState({
      tab: Tabs.Evaluations,
      primaryJumpLink: JumpLinkRefIds.Evaluations,
      secondaryJumplink: evaluation.id,
    });
  }, [evaluation.id, openFormState, setOpenFormState]);

  return useMemo(() => {
    const showEditButton = !evaluation.isArchived;

    return {
      isArchived: evaluation.isArchived,
      headerTags: [
        {
          text: toolNameMap[evaluation.methodOfAssessment.toString()],
          key: evaluation.methodOfAssessment.toString(),
        },
      ],
      buttons: (
        <>
          <FormIconButton
            mr={2}
            Icon={evaluation.isArchived ? UnarchiveIcon : ArchiveIcon}
            onClick={onWarning}
            aria-label={`${
              evaluation.isArchived ? 'Unarchive' : 'Archive'
            } Evaluation and Assessment`}
            tooltipProps={{
              label: evaluation.isArchived ? 'Unarchive' : 'Archive',
            }}
            disabled={!referral?.isActive}
          />
          {showEditButton && (
            <FormIconButton
              onClick={onClick}
              aria-label={'Edit Evaluation'}
              Icon={EditIcon}
              refId={evaluation.id}
              tooltipProps={{ label: 'Edit' }}
              disabled={!referral?.isActive}
            />
          )}
        </>
      ),
    };
  }, [
    evaluation.isArchived,
    evaluation.methodOfAssessment,
    evaluation.id,
    onWarning,
    referral?.isActive,
    onClick,
  ]);
};

export default useCardHeaderProps;
