import * as yup from 'yup';
import { ChildContact } from '@cssat/acorn-api-shared';

import {
  preferredContactMethods,
  yupOptionalString,
  yupPhoneTestValidator,
  yupRequireAddressWhenPreferredContact,
  yupRequiredBoolean,
  yupRequiredString,
  yupRequiredWhenPreferredContact,
  yupZipTestValidator,
} from 'lib';

export const contactConfidentiality: ChildContact['confidentiality'][] = [
  'display-on-ifsp',
  'show-name-only',
  'do-not-display-on-ifsp',
];

export const formSchema = yup
  .object({
    name: yupRequiredString('Contact name is required'),

    isPrimaryContact: yupRequiredBoolean(false),
    childLivesHere: yupRequiredBoolean(false),
    interpreterNeeded: yupRequiredBoolean(false),
    isSurrogateParent: yupRequiredBoolean(false),
    transportationNeeded: yupRequiredBoolean(false),

    isAddressSame: yupRequiredBoolean(true),

    relationship: yupOptionalString(),
    physicalAddress1: yupRequireAddressWhenPreferredContact('Address1'),
    physicalAddress2: yupRequireAddressWhenPreferredContact('Address2'),
    physicalCity: yupRequireAddressWhenPreferredContact('City'),
    physicalState: yupRequireAddressWhenPreferredContact('State'),
    physicalZip:
      yupRequireAddressWhenPreferredContact('Zip').test(yupZipTestValidator),
    mailingAddress1: yupRequireAddressWhenPreferredContact('Address1'),
    mailingAddress2: yupRequireAddressWhenPreferredContact('Address2'),
    mailingCity: yupRequireAddressWhenPreferredContact('City'),
    mailingState: yupRequireAddressWhenPreferredContact('State'),
    mailingZip:
      yupRequireAddressWhenPreferredContact('Zip').test(yupZipTestValidator),
    phoneHome: yupRequiredWhenPreferredContact('phone-home', 'home phone').test(
      yupPhoneTestValidator
    ),
    phoneWork: yupRequiredWhenPreferredContact('phone-work', 'work phone').test(
      yupPhoneTestValidator
    ),
    phoneCell: yupRequiredWhenPreferredContact('phone-cell', 'cell phone').test(
      yupPhoneTestValidator
    ),
    email: yupRequiredWhenPreferredContact('email', 'email').email(
      'Email address must be valid'
    ),
    primaryLanguage: yupOptionalString(),
    otherLanguages: yupOptionalString(),
    notes: yupOptionalString(),

    preferredContactMethod: yup.string().oneOf(preferredContactMethods),
    confidentiality: yup.string().oneOf(contactConfidentiality),
  })
  .defined();

export type FormFields = yup.InferType<typeof formSchema>;
