import { Box, BoxProps, Link, LinkProps } from '@chakra-ui/react';
import { FC } from 'react';
import {
  NavLink as RouterLink,
  NavLinkProps as RouterLinkProps,
} from 'react-router-dom';

import { ReactComponent as OpenInNewTabIcon } from 'icons/open-in-new-tab.svg';

const newTabIcon = (
  <Box mr={3} width="15px" height="15px" display="inline-block">
    <OpenInNewTabIcon />
  </Box>
);

export const ExternalLink: FC<LinkProps & BoxProps> = ({
  href,
  children,
  ...props
}) => (
  <Box as={Link} href={href} isExternal {...props}>
    {newTabIcon}
    {children}
  </Box>
);

export const InternalLinkNewTab: FC<LinkProps & BoxProps & RouterLinkProps> = ({
  to,
  children,
  ...props
}) => (
  <Link as={RouterLink} to={to} isExternal width="fit-content" {...props}>
    {newTabIcon}
    {children}
  </Link>
);
