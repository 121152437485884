import { Box, Heading, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { DetailSectionDivider } from 'components/styled/forms/DetailSection';
import { FormSection } from 'components/styled/forms/FormSection';
import {
  RadioGroupField,
  RadioPill,
  TextField,
  TextareaField,
} from 'components/formik';
import { Transition } from '@cssat/acorn-api-shared';
import { formSchema, initialValues } from './validation';
import { useParams } from 'react-router-dom';
import { useReferralsByChildId } from 'hooks';
import ChildSectionFormikWrapper from 'pages/ChildRecord/ChildSectionFormikWrapper/ChildSectionFormikWrapper';
import adaptApiToForm from './adaptApiToForm';
import useTransition, { useMutateTransition } from '../useTransition';

const TransitionPlanningForm = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: transition } = useTransition(childId);
  const createOrUpdateTransition = useMutateTransition(childId);
  const { mutate: refetchReferral } = useReferralsByChildId(childId);

  const adaptedInitialValues = transition
    ? adaptApiToForm(transition)
    : initialValues;

  return (
    <ChildSectionFormikWrapper
      formikProps={{
        validationSchema: formSchema,
        initialValues: adaptedInitialValues,
      }}
      successToastProps={{
        title: `Transition saved successfully`,
      }}
      errorToastProps={{
        title: `Sorry, we can’t save the transition right now.`,
        description: 'Please check your connection and try again.',
      }}
      humanReadableFormName="Transition - Planning"
      inlineAlertMessage="There was a problem completing this transition."
      apiRequest={async values => {
        await createOrUpdateTransition(values as Transition);
        await refetchReferral();
      }}
    >
      {formik => {
        return (
          <>
            <FormSection>
              <Heading as="h4" variant="tertiary">
                Priorities and goals
              </Heading>
              <Text textStyle="bodyText">
                What are the priorities and goals for the child’s transition?
              </Text>
              <TextareaField
                name="prioritiesAndGoals"
                label="Priorities and goals"
              />
            </FormSection>

            <DetailSectionDivider orientation="horizontal" />

            <FormSection>
              <Heading as="h4" variant="tertiary">
                Transition planning steps
              </Heading>
              <Text textStyle="bodyText">
                What steps were taken in order to plan for this child’s
                transition? Some steps you might consider are:
              </Text>
              <Box pl={2}>
                <UnorderedList textStyle="bodyText" spacing={4}>
                  <ListItem>
                    Discuss with parents what “transition” from ESIT services
                    means, including eligibility and age guidelines for early
                    intervention services and what can be done to plan for this
                    transition.
                  </ListItem>
                  <ListItem>
                    Discuss with parents possible program options (including
                    preschool special education services; Head Start; child
                    care, and other community services) that may be available
                    when child is no longer eligible.
                  </ListItem>
                  <ListItem>
                    Assist parents to understand their rights and to develop
                    advocacy skills.
                  </ListItem>
                  <ListItem>
                    Provide LEA and SEA notification that the child is
                    potentially eligibile for Part B services (including child’s
                    name, address, phone number, and date of birth).
                  </ListItem>
                  <ListItem>
                    With parental consent, transfer records, information
                    (including evaluation, assessments and the IFSP).
                  </ListItem>
                  <ListItem>
                    Provide opportunity for parents to meet and receive
                    information from the local education agency or other
                    community program representatives as appropriate.
                  </ListItem>
                  <ListItem>
                    Establish procedures to prepare the child for changes in
                    service delivery, including steps to help the child adjust
                    to and function in a new setting (i.e. visit the new
                    program, meet with program staff prior to the child’s first
                    day, help family secure materials and supplies that will be
                    needed, such as a backpack.)
                  </ListItem>
                  <ListItem>
                    If the child is transitioning to Part B, review with parents
                    the program options for their child from the child’s third
                    birthday through the remainder of the school year.
                  </ListItem>
                  <ListItem>
                    With parental agreement, schedule the transition conference
                    (at least 90 days and no more than 9 months before the
                    child’s third birthday) and invite participants including
                    parents, ESIT services personnel, local education agency,
                    Head Start, and other community providers as appropriate.
                  </ListItem>
                </UnorderedList>
              </Box>
              <TextareaField
                name="planningStepsTaken"
                label="Transition planning steps taken"
              />
            </FormSection>

            <DetailSectionDivider orientation="horizontal" />

            <FormSection>
              <Heading as="h4" variant="tertiary">
                Potential eligibility for Part B services
              </Heading>
              <Text textStyle="bodyText">
                Before determining potentially eligible status, review the
                Potentially Eligible Table from the Washington State DEL-OSPI
                Transition Agreement. Selecting “yes” will allow the Local
                Education Agency (LEA) and the State Education Agency (SEA)
                access to this child’s records once the child is within 9 months
                of their third birthday. This report will be made availble to
                the early childhood special education contact and will serve as
                a notification of the team’s decision that the child is
                potentially eligible for Part B services.
              </Text>
              <RadioGroupField
                name="partBPotential"
                label="In your professional opinion, is this child potentially eligible for Part B services?"
                direction="horizontal"
              >
                <RadioPill value="true" label="Yes" />
                <RadioPill value="false" label="No" />
              </RadioGroupField>
            </FormSection>

            {formik.values.partBPotential === 'true' && (
              <>
                <DetailSectionDivider orientation="horizontal" />

                <FormSection>
                  <Heading as="h4" variant="tertiary">
                    Special education liaison
                  </Heading>
                  <Text textStyle="bodyText">
                    A special education liaison is any person that works with
                    children in Part B Services (ex: a psychologist, a director,
                    etc.).
                  </Text>
                  <TextField
                    name="specialEdContactInfoName"
                    label="Contact name"
                  />
                  <TextField
                    name="specialEdContactInfoPhoneGeneric"
                    label="Phone number"
                  />
                  <TextField
                    name="specialEdContactInfoEmail"
                    label="Email address"
                  />
                </FormSection>
              </>
            )}
          </>
        );
      }}
    </ChildSectionFormikWrapper>
  );
};

export default TransitionPlanningForm;
