import * as yup from 'yup';
import { ageExpectedSkillsReverseIndices } from '../CosSection/helpers';
import { yupRequiredString } from 'lib';

export const formSchema = yup
  .object({
    level: yup
      .string()
      .required('Please select one of these options')
      .test({
        name: 'isvalid',
        test: (val): boolean => {
          const num = parseInt(val);
          return num > 0 && num <= ageExpectedSkillsReverseIndices.length;
        },
        message: 'Invalid option',
      }),
    summary: yupRequiredString('A summary is required'),
    hasShownImprovement: yup.boolean().when(['level', 'prevLevel'], {
      is: (level, prevLevel) => parseInt(level || '') < (prevLevel || NaN),
      then: yup.boolean().required('Answering is required'),
      otherwise: yup.boolean().optional(),
    }),
    hasShownImprovementNotes: yup.string().when('hasShownImprovement', {
      is: val => val === 'true',
      then: yup
        .string()
        .required(
          'Please indicate new skills or behaviors since the last outcomes summary'
        ),
      otherwise: yup.string().optional(),
    }),
  })
  .defined();

export const adaptSkillsFields = (
  values: yup.InferType<typeof formSchema>
) => ({
  level: parseInt(values.level),
  summary: values.summary,
  hasShownImprovement: values.hasShownImprovement,
  hasShownImprovementNotes: values.hasShownImprovementNotes,
});
