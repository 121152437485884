import 'react-day-picker/src/style.css';
import { Box } from '@chakra-ui/react';
import { FC, FocusEvent, LegacyRef, forwardRef, useContext } from 'react';
import { FocusContext } from 'contexts';
import {
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { InputProps } from '@chakra-ui/input';
import { colors, space } from 'theme';
import {
  dateFormatDisplayShort,
  formatDateObject,
  parseDate_MDYYYY,
} from 'lib';
import { isDate, isSameDay } from 'date-fns';
import { useField } from 'formik';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { AcornFormField } from 'components/formik/AcornFormField';
import {
  AfterModifier,
  BeforeAfterModifier,
  BeforeModifier,
  DateUtils,
  DayPickerInputProps,
  DaysOfWeekModifier,
  FunctionModifier,
  Modifier,
  RangeModifier,
} from 'react-day-picker';
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
import { useDebounceOnChange } from 'hooks';

export interface DateFieldProps extends DayPickerInputProps {
  name: string;
  label: string;
  variant?: string;
  sublabel?: string;
  required?: boolean;
  validateOnBlur?: boolean;
  disabledDays?:
    | Date
    | RangeModifier
    | BeforeModifier
    | AfterModifier
    | BeforeAfterModifier
    | DaysOfWeekModifier
    | FunctionModifier
    | Modifier[]
    | undefined;
}

const CalendarInput: FC<InputProps> = forwardRef(
  (props, ref: LegacyRef<HTMLInputElement>) => (
    <InputGroup>
      <Input ref={ref} {...props}></Input>
      <InputRightElement zIndex={0} pointerEvents="none">
        <Box sx={{ 'svg path': { fill: 'teal.700' } }}>
          <CalendarIcon />
        </Box>
      </InputRightElement>
    </InputGroup>
  )
);

export const DateField: FC<DateFieldProps> = props => {
  const {
    name,
    label,
    sublabel,
    placeholder = 'mm/dd/yyyy',
    required,
    validateOnBlur = true,
    disabledDays,
    variant,
    inputProps,
    onDayPickerShow,
    onDayPickerHide,
  } = props;
  const [field, , helperProps] = useField(name);
  const { setFocus } = useContext(FocusContext);
  const [value, onChange] = useDebounceOnChange<Date | string>(
    field.value,
    field.onChange,
    '',
    (value: string) => value
  );
  return (
    <AcornFormField name={name} required={required}>
      {label && (
        <FormLabel
          htmlFor={name}
          data-testid={`label-${name}`}
          variant={variant ?? ''}
        >
          {label}
        </FormLabel>
      )}
      {sublabel && (
        <Text mb={space.quarter} variant="bodyText">
          {sublabel}
        </Text>
      )}
      <DayPickerInput
        onDayPickerShow={onDayPickerShow}
        onDayPickerHide={onDayPickerHide}
        component={CalendarInput}
        value={value}
        inputProps={{
          ...field,
          ...inputProps,
          textStyle: 'bodyText',
          id: name,
          'data-testid': name,
          name,
          onChange: onChange,
          onBlur: (e: FocusEvent<HTMLInputElement>) => {
            setFocus(false);
            if (validateOnBlur) {
              field.onBlur(e);
            }

            const parsedDate = parseDate_MDYYYY(e.currentTarget.value);
            if (!parsedDate) return;
            if (
              isDate(value) &&
              (value as Date).getTime() === parsedDate.getTime()
            ) {
              return;
            }
            helperProps.setValue(parsedDate);
          },
          onFocus: () => setFocus(true),
        }}
        dayPickerProps={{
          showOutsideDays: true,
          disabledDays,
          labels: {
            previousMonth: 'Prev',
            nextMonth: 'Next',
          },
          modifiers: {
            today: new Date(),
          },
          modifiersStyles: {
            today: {
              backgroundColor: `${colors.gray[100]}`,
              color: `${colors.gray[700]}`,
              fontWeight: 'normal',
            },
          },
          onDayClick: (day: Date) => {
            if (isDate(field.value) && isSameDay(day, field.value)) return;

            // Day picker sets the hour to noon, manual entry sets the time to 0; this ensures consistency
            day.setHours(0);

            helperProps.setValue(day);
          },
        }}
        formatDate={(date: Date) => formatDateObject(date, 'MM/dd/yyyy')}
        format={dateFormatDisplayShort}
        placeholder={placeholder}
        parseDate={(dateString: string) => {
          if (!DateUtils.isDate(new Date(dateString))) {
            return;
          } else {
            return new Date(dateString);
          }
        }}
      />
    </AcornFormField>
  );
};
