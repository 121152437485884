import { ReactComponent as AddIcon } from 'icons/new.svg';
import { FormIconButton, JumpLinkRefIds } from 'molecules';
import { Tabs, useOpenFormContext } from 'contexts';
import { iconButtonStyles } from 'pages/ChildRecord/styles';
import { useCallback, useMemo } from 'react';
import { useLatestReferral } from 'hooks';
import { useParams } from 'react-router-dom';
import useDiagnoses from '../useDiagnoses';

const useIconButton = () => {
  const [openFormState, setOpenFormState] = useOpenFormContext();
  const { id: childId } = useParams<{ id: string }>();
  const { data } = useDiagnoses(childId);
  const referral = useLatestReferral(childId);
  const isActive = referral?.isActive;

  const onOpenAddForm = useCallback(() => {
    if (openFormState) return;

    setOpenFormState({
      tab: Tabs.Evaluations,
      primaryJumpLink: JumpLinkRefIds.MedicalDiagnoses,
    });
  }, [openFormState, setOpenFormState]);

  const loaded = !!data;

  return useMemo(() => {
    const formIconProps = loaded ? {} : { disabled: true };
    return (
      <FormIconButton
        {...formIconProps}
        onClick={onOpenAddForm}
        aria-label="Add"
        refId={JumpLinkRefIds.MedicalDiagnoses}
        Icon={AddIcon}
        sx={iconButtonStyles}
        tooltipProps={{ label: 'Add diagnosis' }}
        disabled={!isActive}
      />
    );
  }, [isActive, loaded, onOpenAddForm]);
};

export default useIconButton;
