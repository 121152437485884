import { ReactComponent as EditIcon } from 'icons/pencil.svg';
import { FC, useCallback, useMemo } from 'react';
import {
  FormIconButton,
  JumpLinkRefIds,
  SectionHeader,
  SectionWrapper,
} from 'molecules';
import { Tabs, useOpenFormContext } from 'contexts';
import { iconButtonStyles } from '../../styles';
import { useLatestReferral } from 'hooks';
import { useParams } from 'react-router-dom';
import DataCard from 'molecules/DataCard';
import FamilyInfoContent from './FamilyInfoContent';
import InlineAlert from 'components/alerts/InlineAlert';
import useFamilyInfo from './useFamilyInfo';

const FamilyInfo: FC = () => {
  const [openFormState, setOpenFormState] = useOpenFormContext();
  const { id: childId } = useParams<{ id: string }>();
  const { error } = useFamilyInfo(childId);
  const referral = useLatestReferral(childId);
  const isActive = referral?.isActive;

  const onClick = useCallback(() => {
    if (openFormState) {
      return;
    }

    setOpenFormState({
      tab: Tabs.Overview,
      primaryJumpLink: JumpLinkRefIds.FamilyInformation,
    });
  }, [openFormState, setOpenFormState]);

  const isFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.FamilyInformation;

  const headerProps = useMemo(() => {
    const formIconProps = error ? { disabled: true } : {};
    return {
      buttons: (
        <FormIconButton
          {...formIconProps}
          onClick={onClick}
          aria-label="Edit Family information"
          refId={JumpLinkRefIds.FamilyInformation}
          Icon={EditIcon}
          sx={iconButtonStyles}
          tooltipProps={{ label: 'Edit' }}
          disabled={!isActive}
        />
      ),
    };
  }, [error, isActive, onClick]);

  return (
    <SectionWrapper id={JumpLinkRefIds.FamilyInformation}>
      <SectionHeader headerText="Family information" isFormOpen={isFormOpen} />
      {error && !isFormOpen && (
        <InlineAlert
          marginTop={4}
          status="warning"
          title="Hang on! We can't load this section right now."
          description="We'll keep trying."
        />
      )}
      <DataCard headerProps={headerProps} hideHeader={error || isFormOpen}>
        <FamilyInfoContent isFormOpen={isFormOpen} />
      </DataCard>
    </SectionWrapper>
  );
};

export default FamilyInfo;
