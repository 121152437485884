import { Button } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { paths } from 'lib';

export const DevControlCreateChild: FC<{ onClick?: () => void }> = ({
  onClick,
}) => {
  const [saving, setSaving] = useState(false);

  const createChild = async () => {
    setSaving(true);

    const response = await fetch(paths.controls.createChild(), {
      credentials: 'include',
    });

    setSaving(response.status !== 201);
  };

  return saving ? (
    <Button isLoading>creating</Button>
  ) : (
    <Button
      colorScheme="blackAlpha"
      borderRadius="9px"
      onClick={async () => {
        await createChild();
        onClick && onClick();
      }}
    >
      Create Control Child
    </Button>
  );
};
