import { Box, Button } from '@chakra-ui/react';
import { ButtonGroup } from 'components/ButtonGroup';
import {
  CreateTrackedSession,
  CreateTrackedSessionAdapter,
  adaptCreateTrackedSession,
} from './formApiAdapters';
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  FormFields,
  TrackSessionSchema,
  initialValues,
} from './TrackSessionForm/validation';
import { Formik, FormikValues, useFormikContext } from 'formik';
import { ModalFormLayout } from 'molecules';
import { ServicePlan } from 'pages/Services/types';
import { authenticatedPost, paths } from 'lib';
import { gridColumns } from 'theme';
import { useHistory } from 'react-router-dom';
import { usePopupToast } from 'hooks';
import { useTrainingUserContext } from 'contexts';
import InlineAlert from 'components/alerts/InlineAlert';
import TrackSessionForm from './TrackSessionForm/TrackSessionForm';

export const TrackSession: FC = () => {
  const history = useHistory();
  const { user } = useTrainingUserContext();
  const [step, setStep] = useState<number>(1);
  const [service, setService] = useState<ServicePlan>();
  // eslint-disable-next-line
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [showSubmitError, setShowSubmitError] = useState<boolean>(false);
  const [errorToast] = usePopupToast({
    status: 'error',
    title: 'Sorry, we can’t track this session right now.',
    description: 'Please check your connection and try again.',
  });
  const [successToast] = usePopupToast({
    status: 'success',
    title: 'Service tracked',
  });

  let adaptFormToApi = useRef<CreateTrackedSessionAdapter | null>(null);
  useEffect(() => {
    if (!user) {
      history.push('/services');
    } else {
      adaptFormToApi.current = adaptCreateTrackedSession(user);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const formRef = useRef<FormikValues | null>();

  const onSubmit = async (values: FormFields) => {
    setShowSubmitError(false);
    setHasSubmitted(true);
    let sanitizedData: CreateTrackedSession;
    if (!adaptFormToApi || !adaptFormToApi.current) {
      return;
    }
    sanitizedData = await adaptFormToApi.current(values);

    try {
      await authenticatedPost(paths.serviceDelivery.track(), {
        body: sanitizedData,
      });
      history.push('/services');
      successToast();
    } catch {
      errorToast();
      setHasSubmitted(false);
      setShowSubmitError(true);
    }
  };

  const HeaderButtons: FC<{
    step: number;
    setStep: Dispatch<SetStateAction<number>>;
  }> = ({ step, setStep }) => {
    const { submitForm } = useFormikContext<FormFields>();
    const { values } = useFormikContext<FormFields>();

    return (
      <ButtonGroup>
        <Button
          display={['none', 'none', 'block']}
          variant="ghost"
          onClick={() => history.push('/services')}
        >
          Cancel
        </Button>

        {step === 1 && values.service && (
          <Button
            isDisabled={hasSubmitted}
            onClick={() => {
              setStep(2);
            }}
          >
            Next
          </Button>
        )}

        {step > 1 && (
          <Button
            isDisabled={hasSubmitted}
            type="submit"
            onClick={() => {
              submitForm();
            }}
          >
            Track service
          </Button>
        )}
        <Button
          display={['block', 'block', 'none']}
          variant="ghost"
          onClick={() => history.push('/services')}
        >
          Cancel
        </Button>
      </ButtonGroup>
    );
  };

  return (
    <Formik
      validationSchema={TrackSessionSchema}
      onSubmit={(values: FormFields) => {
        onSubmit(values);
      }}
      initialValues={initialValues}
      innerRef={theRef => {
        formRef.current = theRef;
      }}
    >
      <ModalFormLayout
        formButtons={<HeaderButtons step={step} setStep={setStep} />}
        title="Track a service"
      >
        {showSubmitError && (
          <InlineAlert
            marginBottom={4}
            data-testid="trackSessionErrorToast"
            status="error"
            title="Sorry, we can’t track this session right now."
            description="Please check your connection and try again."
          />
        )}
        <Box maxWidth={['100%', '100%', gridColumns(5)]}>
          <TrackSessionForm
            step={step}
            setStep={setStep}
            service={service}
            setService={setService}
          />
        </Box>
      </ModalFormLayout>
    </Formik>
  );
};
