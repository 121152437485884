import { ChakraProps } from '@chakra-ui/system';
import { FC, useMemo } from 'react';
import { Flex } from '@chakra-ui/layout';
import { JumpLinkRefIds, JumpLinks } from 'molecules';
import { useParams } from 'react-router';

const JumpLinksSection: FC<ChakraProps> = props => {
  const { id } = useParams<{ id: string }>();

  const linkConfigList = useMemo(
    () => [
      {
        to: `/children/${id}#${JumpLinkRefIds.ChildOutcomes}`,
        key: JumpLinkRefIds.ChildOutcomes,
        content: 'Child and family outcomes',
      },
      {
        to: `/children/${id}#${JumpLinkRefIds.ESITServices}`,
        key: JumpLinkRefIds.ESITServices,
        content: 'ESIT services',
      },
    ],
    [id]
  );

  return (
    <Flex flexDir="column" pr={4} {...props}>
      <JumpLinks id={id} linkConfigList={linkConfigList} />
    </Flex>
  );
};

export default JumpLinksSection;
