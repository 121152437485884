import { Transition } from '@cssat/acorn-api-shared';
import { authenticatedFetch, authenticatedPost, paths } from 'lib';
import useSWR from 'swr';

export type TransitionFormFields = Omit<Transition, 'id'>;

const useTransition = (childId: string) => {
  return useSWR<Transition>(
    paths.transition.get({ childId }),
    authenticatedFetch
  );
};

export const useMutateTransition = (childId: string) => {
  const { mutate } = useTransition(childId);
  return async (transition: Transition) => {
    await authenticatedPost(paths.transition.upsert({ childId }), {
      body: transition,
    });
    await mutate();
  };
};

export default useTransition;
