import { ChildMatchResult } from '@cssat/acorn-api-shared';
import { Divider } from '@chakra-ui/react';
import { FC } from 'react';
import { space } from 'theme';
import { useLatestReferral } from 'hooks';
import ActiveMatchPreview from './ActiveMatchPreview';
import InactiveMatchPreview from './InactiveMatchPreview';

const ChildMatchPreview: FC<{ currentSelection: ChildMatchResult }> = ({
  currentSelection,
}) => {
  const { childId } = currentSelection;
  const referral = useLatestReferral(childId);

  if (!referral) return null;

  return (
    <>
      <Divider orientation="horizontal" mt={space[12]} />
      {referral.isActive ? (
        <ActiveMatchPreview />
      ) : (
        <InactiveMatchPreview currentReferral={referral} />
      )}
    </>
  );
};

export default ChildMatchPreview;
