import { JumpLinkRefIds, SectionHeader, SectionWrapper } from 'molecules';
import TransitionPlanningCard from '../TransitionPlanningCard/TransitionPlanningCard';

const TransitionPlanningSection = () => {
  return (
    <SectionWrapper id={JumpLinkRefIds.TransitionPlanning} mb={12}>
      <SectionHeader headerText="Transition planning" isFormOpen={false} />
      <TransitionPlanningCard />
    </SectionWrapper>
  );
};

export default TransitionPlanningSection;
