import { Box, Heading, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import {
  DateField,
  RadioGroupField,
  RadioPill,
  TextareaField,
} from 'components/formik';
import { DetailSectionDivider } from 'components/styled/forms/DetailSection';
import { FormSection } from 'components/styled/forms/FormSection';

import { Transition } from '@cssat/acorn-api-shared';
import { formSchema, initialValues } from './validation';
import { useParams } from 'react-router-dom';
import { useReferralsByChildId } from 'hooks';
import ChildSectionFormikWrapper from 'pages/ChildRecord/ChildSectionFormikWrapper/ChildSectionFormikWrapper';
import UnderConstruction from 'components/UnderConstruction/UnderConstruction';
import adaptApiToForm from './adaptApiToForm';
import useTransition, { useMutateTransition } from '../useTransition';

const TransitionConferenceForm = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: transition } = useTransition(childId);
  const createOrUpdateTransition = useMutateTransition(childId);
  const { mutate: refetchReferral } = useReferralsByChildId(childId);

  const adaptedInitialValues = transition
    ? adaptApiToForm(transition)
    : initialValues;

  return (
    <ChildSectionFormikWrapper
      formikProps={{
        validationSchema: formSchema,
        initialValues: adaptedInitialValues,
      }}
      successToastProps={{
        title: `Transition saved successfully`,
      }}
      errorToastProps={{
        title: `Sorry, we can’t save the transition right now.`,
        description: 'Please check your connection and try again.',
      }}
      humanReadableFormName="Transition - Conference"
      inlineAlertMessage="There was a problem completing this transition."
      apiRequest={async values => {
        await createOrUpdateTransition(values as Transition);
        await refetchReferral();
      }}
    >
      {formik => {
        return (
          <>
            <FormSection>
              <Heading as="h4" variant="tertiary">
                Transition conference details
              </Heading>
              <Text textStyle="bodyText">
                The transition conference is required to be held for any child
                who is recieving Part C services before the child turns three
                years old.
              </Text>
              <RadioGroupField
                name="conferenceHappened"
                label="Was the Transition Conference held or declined?"
                direction="horizontal"
                afterChange={val => {
                  if (val === 'false') {
                    formik.setFieldValue('conferencePlannedDate', null);
                    formik.setFieldValue('conferenceActualDate', null);
                    formik.setFieldValue('conferenceCauseForDelay', null);
                    formik.setFieldValue('conferenceCauseForDelayReason', null);
                    formik.setFieldValue('conferenceSteps', null);
                  }

                  if (val === 'true') {
                    formik.setFieldValue('conferenceDeclinedDate', null);
                    formik.setFieldValue('conferenceDeclinedReason', null);
                  }
                }}
              >
                <RadioPill value="true" label="Conference was held" />
                <RadioPill value="false" label="Conference was declined" />
              </RadioGroupField>
              {formik.values.conferenceHappened === 'false' && (
                <>
                  <DateField
                    name="conferenceDeclinedDate"
                    label="Date declined"
                  />
                  <TextareaField
                    name="conferenceDeclinedReason"
                    label="Reason for declining conference"
                  />
                </>
              )}
              {formik.values.conferenceHappened === 'true' && (
                <>
                  <DateField
                    name="conferencePlannedDate"
                    label="Date guardian agreed to conference"
                  />
                  <DateField
                    name="conferenceActualDate"
                    label="Actual conference date"
                  />
                </>
              )}
              {/** If a user saves a delayed conference and then resaves
               * with the conference dates on time, they might save bad
               * data. This feels like an extreme edge case, but it would
               * require us to extend DateField which seemed risky at the time
               */}
              {formik.values.conferencePlannedDate &&
                formik.values.conferenceActualDate &&
                new Date(formik.values.conferencePlannedDate).getTime() !==
                  new Date(formik.values.conferenceActualDate).getTime() && (
                  <>
                    <RadioGroupField
                      name="conferenceCauseForDelay"
                      label="What was the cause for delay?"
                      direction="horizontal"
                    >
                      <RadioPill
                        value="Exceptional child/family reason"
                        label="Exceptional child/family reason"
                      />
                      <RadioPill value="Other" label="Other" />
                    </RadioGroupField>
                    <TextareaField
                      name="conferenceCauseForDelayReason"
                      label="Cause for delay"
                    />
                  </>
                )}
            </FormSection>

            {formik.values.conferenceHappened === 'true' && (
              <>
                <DetailSectionDivider orientation="horizontal" />

                <FormSection>
                  <Heading as="h4" variant="tertiary">
                    Transition conference team members
                  </Heading>
                  <Text textStyle="bodyText">
                    To add or remove transition conference team members listed
                    below, go to the team members tab
                  </Text>
                  <UnderConstruction />
                </FormSection>

                <DetailSectionDivider orientation="horizontal" />

                <FormSection>
                  <Heading as="h4" variant="tertiary">
                    Conference steps
                  </Heading>
                  <Text textStyle="bodyText">
                    What happened before, during and after the transition
                    conference? Some steps you might consider are:
                  </Text>
                  <Box pl={2}>
                    <UnorderedList textStyle="bodyText" spacing={2}>
                      <ListItem>
                        Decide what other activities need to be completed before
                        the child moves into the new service setting (including
                        enrollment, immunizations, transportation issues,
                        medical needs, etc.).
                      </ListItem>
                      <ListItem>
                        Review current evaluation and assessment information.
                        Decide if any further evaluations are needed to
                        determine eligibility to Part B or other programs prior
                        to transition.
                      </ListItem>
                      <ListItem>
                        Help schedule initial evaluation if the child is
                        potentially eligibile for preschool special education.
                      </ListItem>
                      <ListItem>
                        Decide if there is a need for post transition follow-up
                        (including service coordination, consultation with new
                        staff).
                      </ListItem>
                      <ListItem>
                        Any other transition related activities as discussed
                        before, during, or after the conference.
                      </ListItem>
                    </UnorderedList>
                  </Box>
                  <TextareaField
                    name="conferenceSteps"
                    label="Conference steps"
                  />
                </FormSection>
              </>
            )}
          </>
        );
      }}
    </ChildSectionFormikWrapper>
  );
};

export default TransitionConferenceForm;
