import { BreakpointBox } from 'components/styled/layout';
import { ChildMatchResult } from '@cssat/acorn-api-shared';
import { ChildSearch } from '../useChildMatch';
import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { HorizontalDivider } from 'components/styled/presentation';
import { InternalLinkNewTab } from 'components/ExternalLink';
import { RadioOption } from 'components/formik';
import { ReferralStatusBadge } from 'molecules';
import { Link as RouterLink } from 'react-router-dom';
import { dateFormatDisplayShort, formatISODate, toSentenceCase } from 'lib';
import { space } from 'theme';
import { useLatestReferral } from 'hooks';

const ChildMatchDetail: FC<{ label: string; value?: string }> = ({
  label,
  value,
}) => (
  <>
    <BreakpointBox desktop={true} tablet={true}>
      <Flex flexDirection="column" pb={space.one} mr={space.one}>
        <Text as="span" variant="bodyText">
          {`${label}:`}
        </Text>
        <Text as="span" variant="bodyText" fontWeight="medium">
          {value}
        </Text>
      </Flex>
    </BreakpointBox>
    <BreakpointBox mobile={true}>
      <Flex flexDirection={'row'} pb={space.one} mr={space.one} width={'100%'}>
        <Text as="span" variant="bodyText">
          {`${label}:`}
          <Text
            as="span"
            variant="bodyText"
            pl={space.half}
            fontWeight="medium"
          >
            {value}
          </Text>
        </Text>
      </Flex>
    </BreakpointBox>
  </>
);

export const PossibleChildMatch: FC<
  ChildMatchResult & {
    childMatch: ChildSearch;
  }
> = ({
  firstName,
  lastName,
  dateOfBirth,
  sexOfChild,
  otherSexOfChild,
  childId,
  assignedAgencyName,
  childMatch,
}) => {
  const checked = childMatch.state.childMatchValue === childId;
  const referral = useLatestReferral(childId);

  return (
    <>
      <Flex
        flexDirection="column"
        mt={space.one}
        p={space.one}
        sx={{
          cursor: 'pointer',
          borderStyle: 'solid',
          borderWidth: checked ? '2px' : '1px',
          borderRadius: 'md',
          borderColor: checked ? 'teal.600' : 'gray.400',
        }}
        onClick={() => {
          childMatch.selectChild(childId);
        }}
      >
        <Text
          as="label"
          htmlFor={childId}
          cursor="pointer"
          fontWeight={checked ? 'bold' : 'medium'}
        >
          <Flex alignItems="baseline" justifyContent="space-between">
            <RadioOption
              value={childId}
              id={childId}
              isChecked={checked}
              onChange={() => {
                childMatch.selectChild(childId);
              }}
            >
              <Text
                as="span"
                sx={{ position: 'absolute', marginTop: '-4px' }}
              >{`${firstName} ${lastName}`}</Text>
            </RadioOption>
            {referral && <ReferralStatusBadge active={referral.isActive} />}
          </Flex>
        </Text>
        <HorizontalDivider my={space.one} borderColor="gray.100" />

        <Flex
          flexDirection={['column', 'row', 'row']}
          flexWrap="wrap"
          justifyContent={[null, 'space-evenly', 'space-evenly']}
        >
          <ChildMatchDetail
            label="DOB"
            value={formatISODate(dateOfBirth, dateFormatDisplayShort)}
          />
          <ChildMatchDetail
            label="Gender"
            value={
              otherSexOfChild
                ? toSentenceCase(otherSexOfChild)
                : toSentenceCase(sexOfChild)
            }
          />
          <ChildMatchDetail label="Agency" value={assignedAgencyName} />
        </Flex>
      </Flex>
      {checked && (
        <Flex justifyContent="flex-end" my={space[2]}>
          <InternalLinkNewTab
            as={RouterLink}
            target="_new"
            to={`/children/${childId}`}
            textStyle="bodyText"
          >
            View child record in new tab
          </InternalLinkNewTab>
        </Flex>
      )}
    </>
  );
};
