import { FC } from 'react';
import { Flex, Text, TextProps } from '@chakra-ui/react';

// TOOD: https://chakra-ui.com/docs/data-display/list
export const ListItem: FC = ({ children }) => (
  <Flex
    as="li"
    textStyle="bodyText"
    sx={{
      '&::before': { content: '"•"', marginRight: '.3em' },
      lineHeight: 1.5,
    }}
  >
    <Text as="span">{children}</Text>
  </Flex>
);

export interface HeadingProps extends Omit<TextProps, 'css'> {
  level: number;
}
