import { AcornFormField } from './AcornFormField';
import {
  RadioGroup as ChakraRadioGroup,
  Flex,
  FormLabel,
  RadioGroupProps,
  Wrap,
} from '@chakra-ui/react';
import { FC } from 'react';
import { space } from 'theme';
import { useField } from 'formik';

export interface RadioGroupFieldProps extends RadioGroupProps {
  name: string;
  type?: 'string' | 'number';
  required?: boolean;
  label?: string;
  labelStyle?: string;
  direction?: 'horizontal' | 'vertical';
  afterChange?: (val: string) => void;
}

export const RadioGroupField: FC<RadioGroupFieldProps> = ({
  name,
  label,
  type = 'string',
  required = false,
  direction = 'vertical',
  textStyle,
  labelStyle,
  children,
  afterChange,
  ...props
}) => {
  const [field, , helpers] = useField(name);

  return (
    <AcornFormField
      name={name}
      required={required}
      errorStyle={{
        borderRight: '1px solid',
        borderColor: 'red.500',
        paddingRight: 4,
      }}
    >
      {label && (
        <FormLabel textStyle={labelStyle ?? 'label'} mb={space[3]}>
          {label}
        </FormLabel>
      )}
      <ChakraRadioGroup
        data-testid={`radioGroup-${field.name}`}
        name={name}
        value={field.value}
        onChange={val => {
          let value: any = val;
          if (type === 'number') {
            value = parseFloat(val.toString());
          }
          helpers.setValue(value);
          afterChange && afterChange(val);
        }}
        {...props}
      >
        {direction === 'horizontal' ? (
          <Wrap>{children}</Wrap>
        ) : (
          <Flex direction="column">{children}</Flex>
        )}
      </ChakraRadioGroup>
    </AcornFormField>
  );
};
