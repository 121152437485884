import { ReactComponent as AddIcon } from 'icons/new.svg';
import {
  DataCard,
  FormIconButton,
  JumpLinkRefIds,
  SectionHeader,
  SectionWrapper,
} from 'molecules';
import {
  Tabs,
  useAssessmentsArchiveEligibilityContext,
  useOpenFormContext,
} from 'contexts';
import { Text } from '@chakra-ui/react';
import { colors } from 'theme';
import { useCallback, useMemo } from 'react';
import { useLatestReferral } from 'hooks';
import { useParams } from 'react-router-dom';
import BaseButton from 'components/BaseButton';
import CardSkeleton from 'pages/ChildRecord/CardSkeleton/CardSkeleton';
import EligibilityCardList from './EligibilityCardList';
import EligibilityForm from './EligibilityForm/EligibilityForm';
import InlineAlert from 'components/alerts/InlineAlert';
import useCanAddEligibility from './useCanAddEligibility';
import useEligibilityDecisions from './useEligibilityDecisions';

const EligibilitySection = () => {
  const [openFormState, setOpenFormState] = useOpenFormContext();
  const [showArchived] = useAssessmentsArchiveEligibilityContext();
  const { toggleArchivedDecisions, inactiveDecisions, error, loaded } =
    useEligibilityDecisions();
  const canAddEligibility = useCanAddEligibility();
  const { id: childId } = useParams<{ id: string }>();
  const latestReferral = useLatestReferral(childId);
  const isActive = latestReferral?.isActive;

  const isFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.Eligibility;

  const onOpenAddForm = useCallback(() => {
    if (
      openFormState &&
      openFormState.primaryJumpLink !== JumpLinkRefIds.Eligibility
    ) {
      return;
    }

    setOpenFormState({
      tab: Tabs.Evaluations,
      primaryJumpLink: JumpLinkRefIds.Eligibility,
    });
  }, [openFormState, setOpenFormState]);

  const iconButton = useMemo(() => {
    const formIconProps =
      !canAddEligibility || !loaded || !isActive ? { disabled: true } : {};
    return (
      <FormIconButton
        {...formIconProps}
        onClick={onOpenAddForm}
        aria-label="Add"
        refId={JumpLinkRefIds.Eligibility}
        Icon={AddIcon}
        tooltipProps={{
          label:
            'Add diagnoses, one evaluation per domain, and team members before determining eligibility',
          'aria-label':
            'Add diagnoses, one evaluation per domain, and team members before determining eligibility',
        }}
      />
    );
  }, [canAddEligibility, isActive, loaded, onOpenAddForm]);

  const textButtonColor = colors.teal[700];
  const textButtonHoverColor = colors.teal[600];
  const textButtonStyles = {
    color: textButtonColor,
    textDecoration: 'underline',
    textDecorationColor: textButtonColor,
    ':hover': {
      color: textButtonHoverColor,
      textDecorationColor: textButtonHoverColor,
    },
  };

  const secondaryButton =
    !isFormOpen && inactiveDecisions.length ? (
      <BaseButton
        onClick={toggleArchivedDecisions}
        mr={4}
        sx={textButtonStyles}
      >
        <Text sx={textButtonStyles} textStyle="bodyText">
          {isActive ? (showArchived ? 'Hide' : 'Show') : 'View'} eligibility
          decisions
        </Text>
      </BaseButton>
    ) : null;

  return (
    <SectionWrapper id={JumpLinkRefIds.Eligibility} mb={12}>
      <SectionHeader
        headerText="Eligibility"
        isFormOpen={isFormOpen}
        iconButton={iconButton}
        secondaryButton={secondaryButton}
      />
      <InlineAlert
        marginTop={4}
        status="save no-op"
        title="Data here cannot be saved or edited."
        description="This section is currently for demonstration purposes only. To see the eligibility form, first add a Team Member, Diagnosis, and an Assessment and Evaluation with at least two domains completed. You will then be able to click the 'add' icon."
      />
      {error && (
        <InlineAlert
          marginTop={4}
          status="warning"
          title="Hang on! We can't load this section right now."
          description="We'll keep trying."
        />
      )}
      {isFormOpen && (
        <DataCard>
          <EligibilityForm />
        </DataCard>
      )}
      {loaded ? <EligibilityCardList /> : <CardSkeleton />}
    </SectionWrapper>
  );
};

export default EligibilitySection;
