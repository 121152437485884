import { FC, ReactElement } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
  ResponsiveValue,
  SystemStyleObject,
  Text,
} from '@chakra-ui/react';
import { colors } from 'theme';
import { useDebounceOnChange } from 'hooks';
import { useField } from 'formik';

export interface FlexedTextFieldProps extends InputProps {
  name: string;
  required?: boolean;
  conditional?: ReactElement;
  errorStyle?: SystemStyleObject;
  label?: string;
  subLabel?: string;
  sx?: SystemStyleObject;
  textStyle?: ResponsiveValue<string & {}>;
  type?: string;
}

export const FlexedTextField: FC<FlexedTextFieldProps> = ({
  name,
  errorStyle,
  required,
  label,
  subLabel,
  sx = {},
  conditional,
  type,
}) => {
  const [field, meta] = useField(name);
  const [value, onChange] = useDebounceOnChange(
    field.value,
    field.onChange,
    '',
    val => val
  );

  const isInvalid = meta.touched && Boolean(meta.error);
  const errorMsg = meta.touched && meta.error;

  return (
    <FormControl
      display="flex"
      flexDirection="column"
      isRequired={required}
      isInvalid={Boolean(errorMsg)}
      sx={errorStyle && isInvalid ? Object.assign(sx, errorStyle) : sx}
    >
      <FormLabel>{label}</FormLabel>
      <Flex sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
        <Input
          width={100}
          mr={2}
          {...field}
          value={value}
          type={type ?? 'string'}
          onChange={onChange}
        />
        {subLabel && (
          <Text color={colors.placeholder} fontSize="xs">
            {subLabel}
          </Text>
        )}
      </Flex>
      {errorMsg ? <FormErrorMessage>{errorMsg}</FormErrorMessage> : conditional}
    </FormControl>
  );
};
