import { FormFields } from './ReferralForm/validation';
import { adaptFormToApi } from './ReferralForm/apiAdapter';
import { authenticatedPost, paths } from 'lib';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { usePopupToast } from 'hooks';

const useSubmitReferral = () => {
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [showSubmitError, setShowSubmitError] = useState<boolean>(false);
  const history = useHistory();

  const [successToast] = usePopupToast({
    status: 'success',
    title: 'Child referred',
  });
  const [errorToast] = usePopupToast({
    status: 'error',
    title: 'Sorry, we can’t create this referral right now.',
    description: 'Please check your connection and try again.',
  });

  const onSubmit = useCallback(
    async (values: FormFields) => {
      setShowSubmitError(false);
      setHasSubmitted(true);

      const data = adaptFormToApi(values);

      const isUsingChildMatch = values.childMatch !== 'continueWithoutMatch';
      const childPagePath = `/children/${data.child.id}`;
      const redirectPath = isUsingChildMatch
        ? `${childPagePath}?newReferral=true`
        : childPagePath;

      try {
        await authenticatedPost(paths.referrals.create(), {
          body: data,
        });
        history.push(redirectPath);
        successToast();
      } catch {
        errorToast();
        setHasSubmitted(false);
        setShowSubmitError(true);
      }
    },
    [errorToast, history, successToast]
  );

  return { onSubmit, hasSubmitted, showSubmitError };
};

export default useSubmitReferral;
