import { JumpLinkRefIds, SectionHeader, SectionWrapper } from 'molecules';
import CompleteTransitionCard from '../CompleteTransitionCard/CompleteTransitionCard';

const CompleteTransitionSection = () => {
  return (
    <SectionWrapper id={JumpLinkRefIds.CompleteTransition} mb={12}>
      <SectionHeader
        headerText="Exit from Part C"
        bgColor="teal.700"
        textColor="white"
      />
      <CompleteTransitionCard />
    </SectionWrapper>
  );
};

export default CompleteTransitionSection;
