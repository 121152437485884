import { FC } from 'react';

import { ErrorBanner } from 'components/styled/errorBanner';
import { useModalContentContext } from 'contexts';

const ChildMatchErrorBanner: FC = () => {
  const [modalContentContainer] = useModalContentContext();

  const left = modalContentContainer
    ? window.pageXOffset + modalContentContainer.offsetLeft
    : 0;

  return (
    <ErrorBanner
      position={['absolute', 'absolute', 'fixed']}
      left={left}
      width={`calc(100% - ${left}px)`}
    >
      Sorry, we can’t search for duplicate records right now. Please check your
      connection.
    </ErrorBanner>
  );
};

export default ChildMatchErrorBanner;
