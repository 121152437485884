import { FC, useCallback } from 'react';
import { TextField } from 'components/formik';
import { useField } from 'formik';
import { useTeamMemberSuggestions } from './useTeamMemberSuggestions';
import SearchableDropDownField, {
  Item,
} from 'components/formik/SearchableDropDownField/SearchableDropDownField';

const roleFieldName = 'role';

export const SelectExistingTeamMember: FC = () => {
  const { teamMemberSuggestions } = useTeamMemberSuggestions();
  const [, , roleFieldHelper] = useField(roleFieldName);

  const onSelected = useCallback(
    (newItem: Item) => {
      if (!teamMemberSuggestions) return;
      const foundTeamMember = teamMemberSuggestions.find(
        teamMember => teamMember.id === newItem.id
      );

      roleFieldHelper.setValue(foundTeamMember?.role ?? '');
    },
    [roleFieldHelper, teamMemberSuggestions]
  );

  return (
    <>
      <SearchableDropDownField
        name="name"
        label="Participant name"
        menuItems={teamMemberSuggestions}
        required
        idName="teamMemberId"
        idRequired
        resetIdValue={''}
        onSelected={onSelected}
      />
      <TextField name={roleFieldName} label="Participant role" required />
    </>
  );
};
