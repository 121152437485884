import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { ChildOutcome } from '@cssat/acorn-api-shared';
import { DateField, TextField, TextareaField } from 'components/formik';
import { DetailSectionDivider } from 'components/styled/forms/DetailSection';
import { FC } from 'react';
import { FormSection } from 'components/styled/forms/FormSection';
import { coerceInitialValues, formSchema } from './validation';
import { useChildOutcomeIdContext, useOpenFormContext } from 'contexts';
import { useMutateChildOutcome } from '../useOutcomes';
import { useParams } from 'react-router-dom';
import ChildSectionFormikWrapper from 'pages/ChildRecord/ChildSectionFormikWrapper/ChildSectionFormikWrapper';
interface ChildOutcomesFormProps {
  readonly outcome?: ChildOutcome;
}

const ChildOutcomesForm: FC<ChildOutcomesFormProps> = ({
  outcome,
}: ChildOutcomesFormProps) => {
  const [, setOpenFormState] = useOpenFormContext();
  const [, setChildOutcomeFormId] = useChildOutcomeIdContext();
  const { id: childId } = useParams<{ id: string }>();
  const createOrUpdateOutcome = useMutateChildOutcome(childId);

  return (
    <ChildSectionFormikWrapper
      formikProps={{
        validationSchema: formSchema,
        initialValues: coerceInitialValues(outcome),
      }}
      successToastProps={{
        title: `Outcome saved successfully`,
      }}
      errorToastProps={{
        title: `Sorry, we can’t save the outcome right now.`,
        description: 'Please check your connection and try again.',
      }}
      humanReadableFormName="Services - Child and family outcomes"
      inlineAlertMessage="There was a problem completing this outcome."
      apiRequest={async values => {
        await createOrUpdateOutcome(values as ChildOutcome);
      }}
      onCancel={() => {
        setOpenFormState(undefined);
        setChildOutcomeFormId(undefined);
      }}
    >
      <>
        <FormSection>
          <TextField name="title" label="Outcome title" />
          <DateField name="startDate" label="Start date" />
          <DateField name="targetDate" label="Target date" />
        </FormSection>

        <DetailSectionDivider orientation="horizontal" />

        <FormSection>
          <Text textStyle="bodyText">
            Please answer the following four questions about this specific
            outcome.
          </Text>
          <Box marginLeft={6}>
            <UnorderedList textStyle="bodyText" spacing={0}>
              <ListItem>
                <b>
                  What would the family like to see happen for the child/family?
                </b>{' '}
                The outcome must be functional, measurable, and in the context
                of everyday routines and activities
              </ListItem>
              <ListItem>
                <b>
                  What’s happening now related to this outcome? What is the
                  family currently doing that supports achieving this outcome?
                </b>{' '}
                Describe the child and/or family’s functioning related to the
                desired change/outcome
              </ListItem>
              <ListItem>
                <b>
                  What are the ways in which the family and team will work
                  toward achieving this outcome? Who will help and what will
                  they do?
                </b>{' '}
                Describe the methods and strategies that will be used to support
                the child and family to achieve their outcomes within their
                daily ativities and routines. List who will do what, including
                both Early Intervention services and informal supports. These
                include family members, friends, neighbors, church, or other
                community organizations, special health care programs, parent
                education programs, etc
              </ListItem>
              <ListItem>
                <b>
                  How will we know we’ve made progress or if revisions need to
                  be made to the outcomes or services?
                </b>{' '}
                What criteria (ie: an observable action or behavior that shows
                progresss is being made), procedures (ie: observation, report,
                chart), and realistic timelines will be used?
              </ListItem>
            </UnorderedList>
          </Box>
          <TextareaField
            name="outcomeQuestions"
            label="Child and family outcome"
          />
        </FormSection>
      </>
    </ChildSectionFormikWrapper>
  );
};

export default ChildOutcomesForm;
