import { FieldControl } from 'molecules/types';

const contactInfoFields = [
  'name',
  'firstName',
  'middleName',
  'lastName',
  'relationship',
  'isPrimaryContact',
  'physicalAddress1',
  'physicalAddress2',
  'physicalCity',
  'physicalState',
  'physicalZip',
  'isAddressSame',
  'childLivesHere',
  'mailingAddress1',
  'mailingAddress2',
  'mailingCity',
  'mailingState',
  'mailingZip',
  'phoneHome',
  'phoneCell',
  'phoneWork',
  'phoneGeneric',
  'email',
  'fax',
  'preferredContactMethod',
] as const;

export type ContactInfoFields = typeof contactInfoFields[number];

export type PartialFieldControlMap = Partial<
  Record<ContactInfoFields, Partial<FieldControl>>
>;
export type FieldControlMap = Record<ContactInfoFields, FieldControl>;

export const defaultFieldControls: FieldControlMap = {
  name: {
    name: 'name',
    label: 'Full name',
    isVisible: true,
  },
  firstName: {
    name: 'firstName',
    label: 'First name',
    isVisible: true,
  },
  middleName: {
    name: 'middleName',
    label: 'Middle name',
    isVisible: true,
  },
  lastName: {
    name: 'lastName',
    label: 'Last name',
    isVisible: true,
  },
  relationship: {
    name: 'relationship',
    label: 'Relationship to child',
    isVisible: true,
  },
  isPrimaryContact: {
    name: 'isPrimaryContact',
    label: 'This person is a primary contact',
    isVisible: true,
  },
  physicalAddress1: {
    name: 'physicalAddress1',
    label: 'Address line 1',
    isVisible: true,
  },
  physicalAddress2: {
    name: 'physicalAddress2',
    label: 'Address line 2',
    isVisible: true,
  },
  physicalCity: {
    name: 'physicalCity',
    label: 'City',
    isVisible: true,
  },
  physicalState: {
    name: 'physicalState',
    label: 'State',
    isVisible: true,
  },
  physicalZip: {
    name: 'physicalZip',
    label: 'Zip code',
    isVisible: true,
  },
  isAddressSame: {
    name: 'isAddressSame',
    label: 'Physical address is the same as mailing address',
    isVisible: true,
  },
  childLivesHere: {
    name: 'childLivesHere',
    label: 'The child lives at this address',
    isVisible: true,
  },
  mailingAddress1: {
    name: 'mailingAddress1',
    label: 'Address line 1',
    isVisible: true,
  },
  mailingAddress2: {
    name: 'mailingAddress2',
    label: 'Address line 2',
    isVisible: true,
  },
  mailingCity: {
    name: 'mailingCity',
    label: 'City',
    isVisible: true,
  },
  mailingState: {
    name: 'mailingState',
    label: 'State',
    isVisible: true,
  },
  mailingZip: {
    name: 'mailingZip',
    label: 'Zip code',
    isVisible: true,
  },
  phoneHome: {
    name: 'phoneHome',
    label: 'Home phone',
    isVisible: true,
  },
  phoneCell: {
    name: 'phoneCell',
    label: 'Cell phone',
    isVisible: true,
  },
  phoneWork: {
    name: 'phoneWork',
    label: 'Work phone',
    isVisible: true,
  },
  phoneGeneric: {
    name: 'phoneGeneric',
    label: 'Generic phone',
    isVisible: true,
  },
  email: {
    name: 'email',
    label: 'Email address',
    isVisible: true,
  },
  fax: {
    name: 'fax',
    label: 'Fax number',
    isVisible: true,
  },
  preferredContactMethod: {
    name: 'preferredContactMethod',
    label: 'Preferred contact method',
    isVisible: true,
  },
};
