import { DataCard, JumpLinkRefIds } from 'molecules';
import { FC } from 'react';
import { useOpenFormContext } from 'contexts';
import { useParams } from 'react-router-dom';
import CardSkeleton from 'pages/ChildRecord/CardSkeleton/CardSkeleton';
import FamilyConcernsForm from './FamilyConcernsForm';
import FamilyConcernsView from './FamilyConcernsView';
import InlineAlert from 'components/alerts/InlineAlert';
import useCardHeaderProps from './useCardHeaderProps';
import useConcerns from './useConcerns';

const FamilyConcernsCard: FC = () => {
  const [openFormState] = useOpenFormContext();
  const headerProps = useCardHeaderProps();

  const isFormOpen =
    openFormState?.secondaryJumplink === JumpLinkRefIds.FamilyConcerns;

  const { id: childId } = useParams<{ id: string }>();
  const { data, error } = useConcerns(childId);

  return (
    <>
      {error && (
        <InlineAlert
          marginTop={4}
          status="warning"
          title="Hang on! We can't load this section right now."
          description="We'll keep trying."
        />
      )}
      {data ? (
        <DataCard
          id={JumpLinkRefIds.FamilyConcerns}
          headerProps={headerProps}
          hideHeader={isFormOpen}
        >
          {isFormOpen ? <FamilyConcernsForm /> : <FamilyConcernsView />}
        </DataCard>
      ) : (
        <CardSkeleton />
      )}
    </>
  );
};

export default FamilyConcernsCard;
