import { ChildMatchQuery } from 'lib';
import { ChildSearch } from '../ChildMatchSection/useChildMatch';
import { FormFields } from './validation';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash.debounce';

const adaptDateOfBirthForChildSearch = (dateOfBirth: Date) => {
  return dateOfBirth.toISOString().split('T')[0];
};

const searchDebounceWait = 1000;

const childMatchSearch = debounce(
  (query: ChildMatchQuery, childSearch: (query: ChildMatchQuery) => void) => {
    childSearch(query);
  },
  searchDebounceWait
);

const useHandleChildMatchSearch = (childMatch: ChildSearch) => {
  const { values } = useFormikContext<FormFields>();

  const { clearResults, search } = childMatch;

  useEffect(() => {
    const childFirstName = values.childFirstName.trim();
    const childLastName = values.childLastName.trim();
    const childDateOfBirth = values.childDateOfBirth;
    if (
      !childFirstName ||
      !childLastName ||
      !childDateOfBirth ||
      typeof childDateOfBirth === 'string'
    ) {
      childMatchSearch.cancel();
      clearResults();
      return;
    }

    childMatchSearch(
      {
        childFirstName,
        childLastName,
        dateOfBirth: adaptDateOfBirthForChildSearch(childDateOfBirth),
      },
      search
    );
  }, [
    search,
    clearResults,
    values.childFirstName,
    values.childLastName,
    values.childDateOfBirth,
  ]);
};

export default useHandleChildMatchSearch;
