import {
  AssessmentMethod,
  Evaluation,
  evaluationNormedTools,
} from '@cssat/acorn-api-shared';
import {
  DateField,
  RadioGroupField,
  RadioPill,
  TextField,
  TextareaField,
} from 'components/formik';
import { FC, useCallback } from 'react';
import { Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { FormFields, createEmptyEvaluation, formSchema } from './validation';
import { FormSection } from 'components/styled/forms/FormSection';
import { startOfToday } from 'date-fns';
import { useMutateEvaluationAndAssessment } from '../useEvaluations';
import { useParams } from 'react-router-dom';
import ChildSectionFormikWrapper from 'pages/ChildRecord/ChildSectionFormikWrapper/ChildSectionFormikWrapper';
import DomainForm from './DomainForm';
import SearchableDropDownField from 'components/formik/SearchableDropDownField/SearchableDropDownField';
import adaptApiToForm from './adaptApiToForm';
import adaptFormToApi from './adaptFormToApi';

interface EvaluationProps {
  readonly evaluation?: Evaluation;
}

const EvaluationForm: FC<EvaluationProps> = ({ evaluation }) => {
  const { id: childId } = useParams<{ id: string }>();
  const upsertEvaluationAndAssessment =
    useMutateEvaluationAndAssessment(childId);

  const onSubmit = useCallback(
    async (values: FormFields) => {
      const adapted = adaptFormToApi(values);
      await upsertEvaluationAndAssessment(adapted);
    },
    [upsertEvaluationAndAssessment]
  );

  const coercedInitialValues = evaluation
    ? adaptApiToForm(evaluation)
    : createEmptyEvaluation();

  return (
    <Flex flexGrow={1}>
      <ChildSectionFormikWrapper
        formikProps={{
          initialValues: coercedInitialValues,
          validationSchema: formSchema,
        }}
        apiRequest={onSubmit}
        humanReadableFormName="Child Evaluations - Evaluations and Assessments"
        successToastProps={{
          title: `Evaluation ${evaluation ? 'updated' : 'added'}`,
        }}
        errorToastProps={{
          title: `Sorry, we can’t ${
            evaluation ? 'update' : 'add'
          } the evaluation right now.`,
          description: 'Please check your connection and try again.',
        }}
        inlineAlertMessage={`There was a problem ${
          evaluation ? 'updating' : 'adding'
        } an evaluation.`}
        formProps={{ noValidate: true }}
      >
        {formik => {
          const isNormedMethod =
            formik.values.methodOfAssessment ===
            AssessmentMethod.NORMALIZED_TOOLS;
          return (
            <Flex justifyContent="space-between">
              <FormSection width="100%">
                <RadioGroupField
                  name="methodOfAssessment"
                  label="Method (choose one)"
                  direction="horizontal"
                  required
                >
                  <RadioPill value="normed-tools-tests" label="Normed tools" />
                  <RadioPill
                    value="vision-evaluation"
                    label="Vision assessment"
                  />
                  <RadioPill
                    value="hearing-evaluation"
                    label="Hearing assessment"
                  />
                  <RadioPill
                    value="caregiver-report"
                    label="Caregiver report"
                  />
                  <RadioPill
                    value="clinical-observation"
                    label="Clinical observation"
                  />
                  <RadioPill value="medical-records" label="Medical records" />
                  <RadioPill
                    value="educational-records"
                    label="Educational records"
                  />
                  <RadioPill value="other" label="Other" />
                </RadioGroupField>
                {isNormedMethod ? (
                  <Flex justifyContent="space-between">
                    <Flex flexGrow={3} mr={4}>
                      <SearchableDropDownField
                        menuItems={evaluationNormedTools}
                        name="evaluationToolName"
                        label="Normed tool name"
                        required
                        idName="evaluationToolId"
                        idRequired
                        resetIdValue={''}
                      />
                    </Flex>
                    <Flex>
                      <TextField
                        name="evaluationToolVersion"
                        label="Version (optional)"
                        textStyle="textFieldLabel"
                      />
                    </Flex>
                  </Flex>
                ) : (
                  <TextField
                    name="evaluationToolName"
                    label="Assessment name"
                    required
                  />
                )}
                <DateField
                  name="dateAdministered"
                  label={
                    isNormedMethod ? 'Date administered' : 'Assessment date'
                  }
                  disabledDays={{ after: startOfToday() }}
                />
                <TextField
                  name="administeredBy"
                  label={isNormedMethod ? 'Administered by' : 'Provided by'}
                />
                <TextField name="location" label="Location" />
                <FormControl name="domainsSelected">
                  <FormLabel>Domains (check all that apply)</FormLabel>

                  <DomainForm domainKey="adaptive" label="Adaptive" />

                  <DomainForm domainKey="cognitive" label="Cognitive" />

                  <DomainForm
                    domainKey="communicationExpressive"
                    label="Expressive communication"
                  />

                  <DomainForm
                    domainKey="communicationReceptive"
                    label="Receptive communication"
                  />

                  <DomainForm
                    domainKey="physicalMotorFine"
                    label="Physical: Fine motor"
                  />

                  <DomainForm
                    domainKey="physicalMotorGross"
                    label="Physical: Gross motor"
                  />

                  <DomainForm
                    domainKey="socialEmotional"
                    label="Social/Emotional"
                  />

                  <DomainForm domainKey="vision" label="Vision" />

                  <DomainForm domainKey="hearing" label="Hearing" />
                </FormControl>

                <TextareaField name="notes" label="Notes (optional)" />
              </FormSection>
            </Flex>
          );
        }}
      </ChildSectionFormikWrapper>
    </Flex>
  );
};

export default EvaluationForm;
