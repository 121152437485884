import { Box, Heading, Stack } from '@chakra-ui/react';
import {
  DateField,
  DropDownField,
  RadioGroupField,
  RadioOption,
  TextareaField,
} from 'components/formik';
import { FC, useCallback } from 'react';
import { Form, useFormikContext } from 'formik';
import { FormFields } from './validation';
import { Organization, useUserContext } from 'contexts';
import { endOfToday, isAfter, isBefore } from 'date-fns';

import { EndOfFormSpacing } from 'components/styled/forms/EndOfFormSpacing';
import { FormSection } from 'components/styled/forms/FormSection';
import { HorizontalDivider } from 'components/styled/presentation';
import { formStyles } from '../../styles';
import { referralReasons } from 'lib/referralReasonData';
import { useChildDetailsContext } from 'contexts/ChildDetailsContext';
import ChildDetailsSummary from './ChildDetailsSummary';
import ScrollToTop from 'components/ScrollToTop';

const ReferralShortForm: FC = () => {
  const formik = useFormikContext<FormFields>();
  const { user } = useUserContext();
  const [childDetails] = useChildDetailsContext();

  const assignedAgencyOptions = user
    ? user.organizations.map((o: Organization) => ({
        value: o.id,
        label: o.name,
      }))
    : [];

  const dateOfInitialReferralDisabledDays = useCallback(
    (day: Date): boolean => {
      if (!childDetails?.childDateOfBirth) return false;

      if (isBefore(day, childDetails?.childDateOfBirth)) {
        return true;
      }
      if (isAfter(day, endOfToday())) {
        return true;
      }
      return false;
    },
    [childDetails?.childDateOfBirth]
  );

  const familyContactDisabledDays = useCallback(
    (day: Date): boolean => {
      if (dateOfInitialReferralDisabledDays(day)) return true;
      if (formik.values.dateOfInitialReferral) {
        return isBefore(day, formik.values.dateOfInitialReferral);
      }
      return false;
    },
    [dateOfInitialReferralDisabledDays, formik.values.dateOfInitialReferral]
  );

  return (
    <Form style={formStyles}>
      <ScrollToTop />
      <ChildDetailsSummary />
      <FormSection>
        <DateField
          name="dateOfInitialReferral"
          label="Date referral was received"
          variant="headingLabel"
          required
          disabledDays={dateOfInitialReferralDisabledDays}
        />
      </FormSection>
      <HorizontalDivider mb={8} borderColor="gray.100" />
      <Box data-testid="referral-details-section">
        <Heading as="h2" variant="tertiary" mb={4}>
          Referral Details
        </Heading>
        <Stack spacing={8}>
          <DateField
            name="familyContactDate"
            label="If the family has already been contacted, enter the date they were reached (optional)"
            disabledDays={familyContactDisabledDays}
          />

          <RadioGroupField
            name="referralReason"
            required
            label="Reason for referral"
          >
            <RadioOption
              value="development-concerns"
              data-testid="reasonIsDevelopmentConcerns"
              label={referralReasons['development-concerns']}
            />
            <RadioOption
              value="development-concerns.chet-screening"
              data-testid="reasonIsDevelopmentConcernsChet"
              label={referralReasons['development-concerns.chet-screening']}
            />
            <RadioOption
              value="doh-ehddi"
              data-testid="reasonIsDohEhddi"
              label={referralReasons['doh-ehddi']}
            />
          </RadioGroupField>

          <DropDownField
            name={'assignedAgency'}
            label={'Assign to agency'}
            required
            options={assignedAgencyOptions}
          />
          <TextareaField
            name={'referralNotes'}
            label="Additional referral notes (optional)"
            placeholder="Anything else you would like to make note of (such as contact attempts made)."
            rows={8}
          />
        </Stack>
      </Box>
      <EndOfFormSpacing />
    </Form>
  );
};

export default ReferralShortForm;
