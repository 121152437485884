import { ChakraProps } from '@chakra-ui/system';
import { FC, useMemo } from 'react';
import { JumpLinkRefIds, JumpLinks } from 'molecules';
import { useChildArchiveContactsContext } from 'contexts';
import { useLocation, useParams } from 'react-router-dom';
import useChildContacts from '../useChildContacts';

const JumpLinksSection: FC<ChakraProps> = props => {
  const { id } = useParams<{ id: string }>();
  const { activeContacts, inactiveContacts } = useChildContacts(id);
  const [showArchived] = useChildArchiveContactsContext();
  const { search } = useLocation();

  const linkConfigList = useMemo(() => {
    const sublinkContacts = [
      ...(showArchived ? inactiveContacts : []),
      ...activeContacts,
    ];
    return [
      {
        to: `/children/${id}${search}#${JumpLinkRefIds.ChildDetails}`,
        key: JumpLinkRefIds.ChildDetails,
        content: 'Child details',
      },
      {
        to: `/children/${id}${search}#${JumpLinkRefIds.ChildContacts}`,
        content: 'Child contact(s)',
        key: JumpLinkRefIds.ChildContacts,
        subLinks: sublinkContacts.map(contact => ({
          to: `/children/${id}${search}#${contact.id}`,
          key: contact.id,
          content: contact.name,
        })),
      },
      {
        to: `/children/${id}${search}#${JumpLinkRefIds.FamilyInformation}`,
        key: JumpLinkRefIds.FamilyInformation,
        content: 'Family information',
      },
      {
        to: `/children/${id}${search}#${JumpLinkRefIds.PrimaryCareProvider}`,
        key: JumpLinkRefIds.PrimaryCareProvider,
        content: 'Primary care provider',
      },
      {
        to: `/children/${id}${search}#${JumpLinkRefIds.Referrals}`,
        key: JumpLinkRefIds.Referrals,
        content: 'Referrals',
      },
    ];
  }, [showArchived, inactiveContacts, activeContacts, id, search]);

  return <JumpLinks id={id} linkConfigList={linkConfigList} {...props} />;
};

export default JumpLinksSection;
