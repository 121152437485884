import { useEffect, useState } from 'react';
import { usePageHeaderContext, useTopBarContext } from 'contexts';

export const useStickyItemsOffset = () => {
  const [topBar] = useTopBarContext();
  const [pageHeaderContainer] = usePageHeaderContext();
  const [jumpLinksOffset, setJumpLinksOffset] = useState(0);

  useEffect(() => {
    if (topBar?.clientHeight && pageHeaderContainer?.clientHeight) {
      setJumpLinksOffset(
        topBar.clientHeight + pageHeaderContainer.clientHeight
      );
    }
  }, [pageHeaderContainer?.clientHeight, topBar?.clientHeight]);

  return jumpLinksOffset;
};
