import { ChangeEvent, FC, FocusEventHandler, useContext } from 'react';
import { FocusContext } from 'contexts';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  SelectProps,
} from '@chakra-ui/react';
import { createGroupedSelectOptions } from 'lib/referringPartyRole/data';
import { roles } from '@cssat/acorn-api-shared';
import { useField, useFormikContext } from 'formik';

const groupedOptions = createGroupedSelectOptions(roles);

const initialOption = <option value="">{''}</option>;

const RoleOptions: FC = () => (
  <>
    {groupedOptions.map(group => (
      <optgroup key={group.groupLabel} label={group.groupLabel}>
        {group.options.map(({ label, ...option }) => (
          <option key={option.value} {...option}>
            {label}
          </option>
        ))}
      </optgroup>
    ))}
  </>
);

interface ReferringPartyRoleProps extends SelectProps {
  name?: string;
  label?: string;
  defaultValue?: string;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  validateOnBlur?: boolean;
  required?: boolean;
}

const ReferringPartyRole: FC<ReferringPartyRoleProps> = ({
  name = 'referringParty.role',
  label = 'Role',
  validateOnBlur,
  onChange,
  maxWidth,
  required = true,
}) => {
  const { setFocus } = useContext(FocusContext);
  let { handleChange, handleBlur } = useFormikContext<any>();

  const [field, meta] = useField(name);
  let { touched, error } = meta;

  const value = field.value;
  const errorName = `errorFor${name[0].toUpperCase() + name.substr(1)}`;

  const focusHandler: FocusEventHandler = () => setFocus(true);
  const blurHandler: FocusEventHandler = e => {
    setFocus(false);
    if (validateOnBlur) {
      handleBlur(e);
    }
  };

  const dropDownSelectionChangeHandler = (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    handleChange(e);
    if (onChange) {
      onChange(e);
    }
  };
  return (
    <FormControl
      id={name}
      isInvalid={touched && Boolean(error)}
      isRequired={required}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select
        textStyle="bodyText"
        {...field}
        isInvalid={touched && Boolean(error)}
        isRequired={required}
        name={name}
        maxWidth={maxWidth}
        data-testid={name}
        onFocus={focusHandler}
        onChange={dropDownSelectionChangeHandler}
        onBlur={blurHandler}
        value={value}
      >
        {initialOption}
        <RoleOptions />
      </Select>
      <FormErrorMessage data-testid={errorName}>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default ReferringPartyRole;
