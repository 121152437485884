import { ReactComponent as AddIcon } from 'icons/add.svg';
import { AnnounceChange } from 'components/styled/forms/AnnounceChange';
import { Box, Button } from '@chakra-ui/react';
import { FC, FocusEvent, useState } from 'react';
import { RadioGroupField, RadioOption, TextField } from 'components/formik';
import { useField } from 'formik';

const SexOfChildFields: FC<{
  name: string;
  noteFieldName: string;
}> = ({ name, noteFieldName }) => {
  const [noteAdded, setNoteAdded] = useState<boolean>(false);
  const [field] = useField(noteFieldName);
  return (
    <RadioGroupField name={name} label="Gender" required>
      <RadioOption
        value="female"
        label="Female"
        data-testid="sexOfChildIsFemale"
      />
      <RadioOption value="male" label="Male" data-testid="sexOfChildIsMale" />
      <RadioOption
        value="other"
        label="Other"
        data-testid="sexOfChildIsOther"
        conditionalChildren={
          <AnnounceChange>
            {(field.value && field.value.length > 0) || noteAdded ? (
              <Box mt={1}>
                <TextField
                  textStyle="lightLabel"
                  autoFocus
                  name={noteFieldName}
                  label="Note (optional)"
                  onBlur={(e: FocusEvent<HTMLInputElement>) => {
                    if (e.target.value.trim() === '') {
                      setNoteAdded(false);
                    }
                  }}
                />
              </Box>
            ) : (
              <Button
                variant="ghost"
                leftIcon={<AddIcon />}
                onClick={() => setNoteAdded(true)}
              >
                Add note (optional)
              </Button>
            )}
          </AnnounceChange>
        }
      />
    </RadioGroupField>
  );
};

export default SexOfChildFields;
