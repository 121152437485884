import { FormFields } from './TrackSessionForm/validation';

import { Status } from '@cssat/acorn-api-shared';

import { TrainingUser } from 'contexts';
import { adaptAsPlannedForm } from './formApiAdapters/asPlanned';
import { adaptNotDeliveredForm } from './formApiAdapters/notDelivered';
import { adaptWithChangesForm } from './formApiAdapters/withChanges';
import { fetchServicePlan } from 'lib/fakeServiceData';

import {
  CreateTrackedSessionAsPlanned,
  CreateTrackedSessionNotDelivered,
  CreateTrackedSessionWithChanges,
} from '@cssat/acorn-api-shared/dist/service-delivery';
/*
 * Used by the formik adapter to adapt multiple types of things
 */
export type CreateTrackedSession =
  | CreateTrackedSessionAsPlanned
  | CreateTrackedSessionNotDelivered
  | CreateTrackedSessionWithChanges;

export type CreateTrackedSessionAdapter = (
  values: FormFields
) => Promise<CreateTrackedSession>;

export const adaptCreateTrackedSession = (
  user: TrainingUser
): CreateTrackedSessionAdapter => {
  return async (values: FormFields): Promise<CreateTrackedSession> => {
    const service = await fetchServicePlan(values.service);
    switch (values.serviceStatus) {
      case Status.AsPlanned:
        return adaptAsPlannedForm(values, user, service);
      case Status.NotDelivered:
        return adaptNotDeliveredForm(values, user, service);
      case Status.WithChanges:
        return adaptWithChangesForm(values, user, service);
      default:
        throw new Error('Incompatible form values provided');
    }
  };
};
