import {
  Flex,
  ListItem,
  PopoverBody,
  PopoverContent,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { UseComboboxReturnValue } from 'downshift';
import Fuse from 'fuse.js';
import React, { FC } from 'react';

import type { Item } from './SearchableDropDownField';

const getColors = (
  index: number,
  highlightedIndex: number,
  id: string,
  selectedId?: string
): { color: string; backgroundColor: string } => {
  if (index === highlightedIndex)
    return { backgroundColor: 'teal.50', color: 'gray.700' };
  if (id === selectedId) {
    return { backgroundColor: 'teal.600', color: 'white' };
  }
  return { backgroundColor: 'white', color: 'gray.700' };
};

type MenuProps = Pick<
  UseComboboxReturnValue<Fuse.FuseResult<Item> | null>,
  | 'getMenuProps'
  | 'getItemProps'
  | 'highlightedIndex'
  | 'selectedItem'
  | 'isOpen'
> & {
  searchResults: Fuse.FuseResult<Item>[];
  isSearchResultsAllResults: boolean;
};

const Menu: FC<MenuProps> = ({
  getMenuProps,
  getItemProps,
  highlightedIndex,
  selectedItem,
  isOpen,
  searchResults,
  isSearchResultsAllResults,
}) => (
  <PopoverContent
    width="inherit"
    {...getMenuProps()}
    display={isOpen && searchResults.length > 0 ? 'flex' : 'none'}
    borderColor="gray.300"
    overflowY="scroll"
    maxHeight={96}
  >
    {isOpen && (
      <PopoverBody padding={0}>
        {isSearchResultsAllResults && (
          <Flex
            as={Text}
            textStyle="bodyText"
            padding={3}
            fontWeight="semibold"
            fontFamily="secondary"
            color="gray.600"
            backgroundColor="gray.100"
            borderBottom="1px solid"
            borderColor="gray.300"
            cursor="default"
          >
            No matches. Showing all options
          </Flex>
        )}
        <UnorderedList listStyleType="none" marginInlineStart={0}>
          {searchResults.map((result, index) => (
            <ListItem
              key={result.item.id}
              {...getItemProps({ item: result, index })}
              padding={3}
              textStyle="bodyText"
              cursor="pointer"
              {...getColors(
                index,
                highlightedIndex,
                result.item.id,
                selectedItem?.item.id
              )}
              sx={{
                borderBottom: '1px solid',
                borderColor: 'gray.300',
                '&:first-of-type': {
                  borderTopRadius: 'md',
                },
                '&:last-of-type': {
                  borderBottom: 'none',
                  borderBottomRadius: 'md',
                },
              }}
            >
              {result.item.label}
            </ListItem>
          ))}
        </UnorderedList>
      </PopoverBody>
    )}
  </PopoverContent>
);

export default React.memo(Menu);
