import {
  counties,
  formLanguages,
  schoolDistricts,
} from '@cssat/acorn-api-shared';
import { useMemo } from 'react';

const useFamilyDropDownOptions = () =>
  useMemo(() => {
    return {
      counties: [
        ...counties.map(county => ({
          value: county.id,
          label: county.name,
        })),
      ],
      schoolDistricts: [
        ...schoolDistricts.map(schoolDistrict => ({
          value: schoolDistrict.id,
          label: schoolDistrict.name,
        })),
      ],
      formLanguages: [
        ...formLanguages.map(formLanguage => ({
          value: formLanguage.id,
          label: formLanguage.name,
        })),
      ],
    };
  }, []);

export default useFamilyDropDownOptions;
