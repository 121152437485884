import { RefObject, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useStickyItemsOffset } from 'hooks';

type UseScrollRefIntoViewById = (id?: string) => RefObject<HTMLDivElement>;
export const useScrollRefIntoViewById: UseScrollRefIntoViewById = id => {
  const { hash } = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  const offset = useStickyItemsOffset();

  useEffect(() => {
    if (!id) {
      return;
    }

    if (`#${id}` === hash && ref.current) {
      const refTop = ref.current.offsetTop;
      window.scrollTo({
        behavior: 'smooth',
        top: refTop - offset,
      });
    }
  }, [offset, hash, id]);

  return ref;
};
