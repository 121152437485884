import { ChakraProps } from '@chakra-ui/system';
import { FC, useMemo } from 'react';
import { Flex } from '@chakra-ui/layout';
import { JumpLinkRefIds, JumpLinks } from 'molecules';
import { useParams } from 'react-router';

const JumpLinksSection: FC<ChakraProps> = props => {
  const { id } = useParams<{ id: string }>();

  const linkConfigList = useMemo(
    () => [
      {
        to: `/children/${id}#${JumpLinkRefIds.TransitionPlanning}`,
        key: JumpLinkRefIds.TransitionPlanning,
        content: 'Transition planning',
      },
      {
        to: `/children/${id}#${JumpLinkRefIds.TransitionConference}`,
        key: JumpLinkRefIds.TransitionConference,
        content: 'Transition conference',
      },
      {
        to: `/children/${id}#${JumpLinkRefIds.CompleteTransition}`,
        key: JumpLinkRefIds.CompleteTransition,
        content: 'Exit from Part C',
      },
    ],
    [id]
  );

  return (
    <Flex flexDir="column" pr={4} {...props}>
      <JumpLinks id={id} linkConfigList={linkConfigList} />
    </Flex>
  );
};

export default JumpLinksSection;
