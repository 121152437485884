import { Box, Flex } from '@chakra-ui/react';
import { ReactComponent as ChecklistIcon } from 'icons/checklist.svg';
import { FC, SVGProps } from 'react';

interface NoneYetPlaceholderProps {
  readonly Icon?: FC<SVGProps<SVGSVGElement>>;
  readonly message: string;
}

const NoneYetPlaceholder: FC<NoneYetPlaceholderProps> = ({
  Icon = ChecklistIcon,
  message,
}: NoneYetPlaceholderProps) => {
  return (
    <Flex
      border="1px solid"
      borderColor="gray.300"
      borderRadius="md"
      py={3}
      px={6}
      marginTop={4}
      backgroundColor="white"
      color="placeholder"
    >
      <Box mr={2}>
        <Icon />
      </Box>
      <Box color="placeholder" textStyle="bodyText">
        {message}
      </Box>
    </Flex>
  );
};

export default NoneYetPlaceholder;
