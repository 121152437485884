import { FormFields as ChildDetailsForm } from '../pages/CreateReferral/ChildDetails/validation';
import { FC, createContext, useContext, useState } from 'react';

type ChildDetailsContextType = [
  ChildDetailsForm | undefined,
  (childDetails: ChildDetailsForm) => void
];

const ChildDetailsContext = createContext<ChildDetailsContextType>([
  undefined,
  () => {},
]);

export const useChildDetailsContext = () => useContext(ChildDetailsContext);

export const ChildDetailsContextProvider: FC = ({ children }) => {
  const value = useState<ChildDetailsForm>();

  return (
    <ChildDetailsContext.Provider value={value}>
      {children}
    </ChildDetailsContext.Provider>
  );
};
