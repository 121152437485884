import {
  Button,
  Divider,
  Heading,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { FC } from 'react';

const AccessibilityStatement: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        variant="link"
        sx={{
          color: 'white',
          textDecoration: 'underline',
          fontWeight: 'normal',
          _hover: {
            fontWeight: 'normal',
            color: `whiteAlpha.900`,
          },
          _active: {
            color: `whiteAlpha.300`,
          },
        }}
      >
        Accessibility Statement
      </Button>

      <Modal
        isOpen={isOpen} // isOpen
        onClose={onClose}
        scrollBehavior="inside"
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading as="h2" variant="primary">
              {`Accessibility Statement for ACORN`}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text textStyle="bodyText">
              {`Washington State Department of Children, Youth & Families is
              committed to ensuring digital accessibility for people with
              disabilities. We are continually improving the user experience for
              everyone, and applying the relevant accessibility standards.`}
            </Text>

            <Heading as="h3" variant="secondary" mt={4} mb={2}>
              {`Conformance status`}
            </Heading>
            <Text textStyle="bodyText">
              {`The `}
              <Link
                isExternal
                variant="inlineBodyText"
                href="https://www.w3.org/WAI/standards-guidelines/wcag/"
              >
                {`Web Content Accessibility Guidelines (WCAG)`}
              </Link>
              {` defines requirements for designers and developers to improve
              accessibility for people with disabilities. It defines three
              levels of conformance: Level A, Level AA, and Level AAA. ACORN is
              fully conformant with WCAG 2.0 level AA. Fully conformant means
              that the content fully conforms to the accessibility standard
              without any exceptions.`}
            </Text>

            <Heading as="h3" variant="secondary" mt={4} mb={2}>
              {`Feedback`}
            </Heading>
            <Text textStyle="bodyText">
              {`We welcome your feedback on the accessibility of ACORN. `}
              <Link
                isExternal
                variant="inlineBodyText"
                href="https://forms.gle/DBTAAC9Dz4yez4qQ9"
              >
                {`Please let us know if you encounter accessibility barriers on
                ACORN`}
              </Link>
              {`.`}
            </Text>

            <Heading as="h3" variant="secondary" mt={4} mb={2}>
              {`Technical specifications`}
            </Heading>
            <Text textStyle="bodyText">
              {`Accessibility of ACORN relies on the following technologies to 
              work with the particular combination of web browser and any 
              assistive technologies or plugins installed on your computer: `}
              <UnorderedList my={2} ml={8} spacing={1}>
                <ListItem>HTML</ListItem>
                <ListItem>WAI-ARIA</ListItem>
                <ListItem>CSS</ListItem>
                <ListItem>JavaScript</ListItem>
              </UnorderedList>
              {`These technologies are relied upon for
              conformance with the accessibility standards used.`}
            </Text>

            <Heading as="h3" variant="secondary" mt={4} mb={2}>
              {`Assessment approach`}
            </Heading>
            <Text textStyle="bodyText">
              {`Washington State Department of Children, Youth & Families 
              assessed the accessibility of ACORN by self-evaluation.`}
            </Text>

            <Divider my={4} />

            <Text textStyle="bodyText">
              {`This statement was created on 16 March 2021 using the `}
              <Link
                isExternal
                variant="inlineBodyText"
                href="https://www.w3.org/WAI/planning/statements/"
              >
                {`W3C Accessibility Statement Generator Tool`}
              </Link>
              {`.`}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AccessibilityStatement;
