import { FormFields } from 'pages/CreateReferral/ChildDetails/validation';
import { ReferralData } from '@cssat/acorn-api-shared';

export const constructChildDetails = (
  childDetails: FormFields,
  previousReferral: ReferralData | undefined
) => {
  if (previousReferral) {
    return {
      childFirstName: previousReferral.child.name.first,
      childLastName: previousReferral.child.name.last,
      childDateOfBirth: new Date(
        `${previousReferral.child.childDateOfBirth} 00:00:00.000`
      ),
      sexOfChild: previousReferral.child.sexOfChild,
      otherSexOfChild: childDetails?.otherSexOfChild,
    };
  }
  return childDetails;
};
