import { Box, Flex } from '@chakra-ui/react';
import { ContactInfoForm } from 'molecules';
import { FC, useCallback } from 'react';
import { FormSection } from 'components/styled/forms/FormSection';
import { PartialFieldControlMap } from 'molecules/ContactInfoForm/constants';
import { TextField, TextareaField } from 'components/formik';
import { adaptFormToApi } from './adaptFormToApi';
import { formSchema } from './validation';
import { useParams } from 'react-router-dom';
import ChildSectionFormikWrapper from 'pages/ChildRecord/ChildSectionFormikWrapper/ChildSectionFormikWrapper';
import adaptApiToFormFields from './adaptApiToFormFields';
import useChildRecord, {
  useMutatePrimaryCareProvider,
} from 'pages/ChildRecord/OverviewTab/useChildRecord';

export const PrimaryCareProviderForm: FC = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data } = useChildRecord(childId);
  const primaryCareProvider = data?.primaryCareProvider;
  const updatePrimaryCareProvider = useMutatePrimaryCareProvider(childId);

  const onSubmit = useCallback(
    async values => {
      const requestData = adaptFormToApi(values);
      await updatePrimaryCareProvider(requestData);
    },
    [updatePrimaryCareProvider]
  );

  const coercedInitialValues = adaptApiToFormFields(primaryCareProvider);

  const contactInfoOverrides: PartialFieldControlMap = {
    name: { label: 'Primary care provider’s name' },
    phoneGeneric: { label: 'Phone number', name: 'phone' },

    firstName: { isVisible: false },
    middleName: { isVisible: false },
    lastName: { isVisible: false },
    relationship: { isVisible: false },
    isPrimaryContact: { isVisible: false },
    childLivesHere: { isVisible: false },
    phoneHome: { isVisible: false },
    phoneWork: { isVisible: false },
    phoneCell: { isVisible: false },
    preferredContactMethod: { isVisible: false },
  };
  return (
    <Flex padding={6} flexGrow={1}>
      <ChildSectionFormikWrapper
        formikProps={{
          validationSchema: formSchema,
          initialValues: coercedInitialValues,
        }}
        successToastProps={{
          title: 'Primary care provider updated',
        }}
        errorToastProps={{
          title: `Sorry, we can’t update the Primary care provider right now.`,
          description: 'Please check your connection and try again.',
        }}
        humanReadableFormName="Child Overview - Primary Care Provider"
        inlineAlertMessage="There was a problem updating the primary care provider."
        apiRequest={onSubmit}
      >
        <Box width="100%">
          <FormSection>
            <TextField name="clinicName" label="Clinic name" />
          </FormSection>

          <ContactInfoForm fieldOverrides={contactInfoOverrides} />
          <FormSection>
            <TextareaField name="notes" label="Primary care provider notes" />
          </FormSection>
        </Box>
      </ChildSectionFormikWrapper>
    </Flex>
  );
};
