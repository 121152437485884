import { ButtonGroup, HTMLChakraProps, Link, Stack } from '@chakra-ui/react';
import { FC } from 'react';
import { Location } from 'history';
import { NavLink as RouterLink, match } from 'react-router-dom';
import { colors, fontSizes, space } from 'theme';

export const SectionLinkContainer: FC<HTMLChakraProps<'div'>> = ({
  children,
  ...props
}) => {
  return (
    <ButtonGroup spacing={0} {...props}>
      <Stack spacing={1}>{children}</Stack>
    </ButtonGroup>
  );
};

interface SectionLinkProps {
  readonly to: string;
  readonly content: string | JSX.Element;
}

export const SectionLink: FC<SectionLinkProps> = ({ content, to }) => {
  return (
    <Link
      as={RouterLink}
      to={to}
      exact
      variant="section"
      style={{ fontWeight: 500 }}
      activeStyle={{ fontWeight: 700, color: colors.teal[700] }}
      isActive={(match: match | null, location: Location) => {
        if (!match) {
          return false;
        }
        if (to === `${location.pathname}${location.hash}`) {
          return true;
        }
        return false;
      }}
    >
      {content}
    </Link>
  );
};

export const SectionSubLink: FC<SectionLinkProps> = ({ to, content }) => {
  return (
    <Link
      as={RouterLink}
      to={to}
      exact
      variant="section"
      style={{
        fontWeight: 400,
        fontSize: fontSizes.xxs,
        padding: space.half,
      }}
      activeStyle={{
        fontWeight: 500,
        color: colors.teal[700],
      }}
      isActive={(match: match | null, location: Location) => {
        if (!match) {
          return false;
        }
        if (to === `${location.pathname}${location.hash}`) {
          return true;
        }
        return false;
      }}
    >
      {content}
    </Link>
  );
};
