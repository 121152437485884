import { Box, Flex, FormLabel, Stack } from '@chakra-ui/react';
import { Checkbox } from 'components/formik';
import { FC, useCallback } from 'react';
import { FormFields, createEmptyTeamMember, formSchema } from './validation';
import { FormSection } from 'components/styled/forms/FormSection';
import { Participant } from '@cssat/acorn-api-shared';
import { SelectExistingTeamMember } from './SelectExistingTeamMember';
import {
  useMutateAddTeamMember,
  useMutateUpdateTeamMember,
} from '../useTeamMembers';
import { useOpenFormContext, useTeamMemberIdContext } from 'contexts';
import { useParams } from 'react-router-dom';
import ChildSectionFormikWrapper from 'pages/ChildRecord/ChildSectionFormikWrapper/ChildSectionFormikWrapper';

interface TeamMemberFormProps {
  readonly teamMember?: Participant;
}

const TeamMemberForm: FC<TeamMemberFormProps> = ({
  teamMember,
}: TeamMemberFormProps) => {
  const { id: childId } = useParams<{ id: string }>();
  const [, setTeamMemberFormId] = useTeamMemberIdContext();
  const [, setOpenFormState] = useOpenFormContext();
  const addTeamMember = useMutateAddTeamMember(childId);
  const updateTeamMember = useMutateUpdateTeamMember(childId);

  const onSubmit = useCallback(
    async (values: FormFields) => {
      teamMember ? await updateTeamMember(values) : await addTeamMember(values);
    },
    [addTeamMember, teamMember, updateTeamMember]
  );

  return (
    <Flex flexGrow={1}>
      <Box width="100%">
        <ChildSectionFormikWrapper
          onCancel={() => {
            setTeamMemberFormId(undefined);
            setOpenFormState(undefined);
          }}
          formikProps={{
            initialValues: teamMember ?? createEmptyTeamMember(),
            validationSchema: formSchema,
          }}
          humanReadableFormName="Team member form"
          inlineAlertMessage={`There was a problem ${
            teamMember ? 'updating' : 'adding'
          } a team member.`}
          apiRequest={onSubmit}
          successToastProps={{
            title: `Team member ${teamMember ? 'updated' : 'added'}`,
          }}
          errorToastProps={{
            title: `Sorry, we can’t ${
              teamMember ? 'update' : 'add'
            } the team member right now.`,
            description: 'Please check your connection and try again.',
          }}
        >
          <Stack flexDir="column" spacing={4} width="100%">
            <SelectExistingTeamMember />
            <FormSection>
              <Box>
                <FormLabel>
                  Assessment activities (Check all that apply)
                </FormLabel>
                <Stack spacing={2}>
                  <Checkbox
                    value="true"
                    name="presentLevelsActivity"
                    label="Child’s Present Levels of Development"
                  />
                  <Checkbox
                    value="true"
                    name="eligibilityServicesActivity"
                    label="Eligibility for Part C Services"
                  />
                  <Checkbox
                    value="true"
                    name="contributedInformationActivity"
                    label="Contributed information for Summary of Functional Performance"
                  />
                  <Checkbox
                    value="true"
                    name="participatedOutcomeActivity"
                    label="Participated in selection of Outcome Descriptor Statements"
                  />
                </Stack>
              </Box>
            </FormSection>
          </Stack>
        </ChildSectionFormikWrapper>
      </Box>
    </Flex>
  );
};

export default TeamMemberForm;
