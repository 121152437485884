import {
  Evaluation,
  EvaluationGetResponsePayload,
} from '@cssat/acorn-api-shared';
import { authenticatedFetch, authenticatedPost, paths } from 'lib';
import { useEvaluationsArchiveContext } from 'contexts';
import useSWR from 'swr';

const useEvaluationsAndAssessments = (childId: string) => {
  return useSWR<EvaluationGetResponsePayload>(
    paths.evaluationsAndAssessments.get({ childId }),
    authenticatedFetch
  );
};

export const useMutateEvaluationAndAssessment = (childId: string) => {
  const { mutate } = useEvaluationsAndAssessments(childId);

  return async (evaluation: Evaluation) => {
    await authenticatedPost(
      paths.evaluationsAndAssessments.upsert({ childId }),
      {
        body: evaluation,
      }
    );
    await mutate();
  };
};

export const useMutateToggleArchivedEvaluations = (childId: string) => {
  const { mutate } = useEvaluationsAndAssessments(childId);
  const [showArchived, setShowArchived] = useEvaluationsArchiveContext();

  return async () => {
    setShowArchived(!showArchived);
    await mutate();
  };
};

export default useEvaluationsAndAssessments;
