import { COS, CosInfoQuestion, CosSkills } from '@cssat/acorn-api-shared';

const participationQuestionIsValid = (question?: CosInfoQuestion) =>
  question?.confirmationAnswer === true ||
  (question?.confirmationAnswer === false && question?.notes);

export const skillsAreValid = (cosSkill?: CosSkills) =>
  cosSkill?.level && cosSkill?.summary;

export const familyParticipationIsValid = (cos: COS) => {
  const baseQuestionsValid =
    participationQuestionIsValid(cos.usedDecisionTreeForPrep) &&
    participationQuestionIsValid(cos.didFamilyParticipateDiscussion);
  if (!baseQuestionsValid) {
    return false;
  }

  // if the third question is true, check fourth question is valid
  if (cos.didFamilyParticipateSelection?.confirmationAnswer === true) {
    return participationQuestionIsValid(cos.usedESITDecisionTree);
  }

  // if the second question is true, check third question is valid
  if (cos.didFamilyParticipateDiscussion?.confirmationAnswer === true) {
    return participationQuestionIsValid(cos.didFamilyParticipateSelection);
  }

  return true;
};

export const isCompletable = (cos: COS) =>
  cos.dateStarted &&
  cos.sources.length >= 2 &&
  familyParticipationIsValid(cos) &&
  skillsAreValid(cos.acquiringSkills) &&
  skillsAreValid(cos.positiveSkills) &&
  skillsAreValid(cos.actionsSkills);
