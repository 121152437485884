import {
  ChildOutcomeIdContextProvider,
  ESITServiceIdContextProvider,
} from 'contexts';
import { Flex } from '@chakra-ui/layout';
import { spaceUnitless } from 'theme';
import { useStickyItemsOffset } from 'hooks';
import ChildOutcomesSection from '../ChildOutcomesSection/ChildOutcomesSection';
import ESITServicesSection from '../ESITServicesSection/ESITServicesSection';
import JumpLinksSection from '../JumpLinksSection/JumpLinksSection';

const TabContent = () => {
  const jumpLinksOffset = useStickyItemsOffset();

  return (
    <Flex width="100%" alignItems="flex-start">
      <JumpLinksSection
        ml="-4"
        mt="-2"
        position="sticky"
        width="220px"
        maxWidth="220px"
        top={jumpLinksOffset + spaceUnitless.one}
      />
      <Flex flexDir="column" width="100%">
        <ChildOutcomeIdContextProvider>
          <ChildOutcomesSection />
        </ChildOutcomeIdContextProvider>
        <ESITServiceIdContextProvider>
          <ESITServicesSection />
        </ESITServiceIdContextProvider>
      </Flex>
    </Flex>
  );
};

export default TabContent;
