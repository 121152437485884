import { FC } from 'react';
import { SideBarPageErrorBanner } from 'molecules';
import { useHealthCheck } from 'hooks';
import PageContent from './PageContent/PageContent';

export const ChildRecord: FC = () => {
  const { error } = useHealthCheck();

  return (
    <>
      {error && <SideBarPageErrorBanner />}
      <PageContent />
    </>
  );
};
