import { JumpLinkRefIds } from 'molecules';
import { Text } from '@chakra-ui/react';
import { colors } from 'theme';
import { useEvaluationsArchiveContext, useOpenFormContext } from 'contexts';
import { useLatestReferral } from 'hooks';
import { useMemo } from 'react';
import BaseButton from 'components/BaseButton';
import useEvaluationsAndAssessments, {
  useMutateToggleArchivedEvaluations,
} from './useEvaluations';

const textButtonStyles = {
  color: colors.teal[700],
  textDecoration: 'underline',
  textDecorationColor: colors.teal[700],
  ':hover': {
    color: colors.teal[600],
    textDecorationColor: colors.teal[600],
  },
};

const useSecondaryButton = (childId: string) => {
  const [openFormState] = useOpenFormContext();
  const [showArchived] = useEvaluationsArchiveContext();
  const { data: evalPayload } = useEvaluationsAndAssessments(childId);
  const referral = useLatestReferral(childId);
  const isActive = referral?.isActive;
  const hasArchivedEvals = evalPayload && evalPayload.archivedEvals.length > 0;

  const toggleArchived = useMutateToggleArchivedEvaluations(childId);

  const isFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.Evaluations;

  return useMemo(
    () =>
      !isFormOpen && hasArchivedEvals ? (
        <BaseButton onClick={toggleArchived} mr={4} sx={textButtonStyles}>
          <Text sx={textButtonStyles} textStyle="bodyText">
            {isActive ? (showArchived ? 'Hide' : 'Show') : 'View'} inactive
            evaluations and assessments
          </Text>
        </BaseButton>
      ) : null,
    [hasArchivedEvals, isActive, isFormOpen, showArchived, toggleArchived]
  );
};

export default useSecondaryButton;
