import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import { ChildMatchResult } from '@cssat/acorn-api-shared';
import { ChildSearch } from '../useChildMatch';
import { FC } from 'react';
import { PossibleChildMatch } from './PossibleChildMatch';
import { RadioGroupField } from 'components/formik';
import { space } from 'theme';
import ChildMatchCriteria from '../ChildMatchCriteria';
import ChildMatchPreview from './ChildMatchPreview/ChildMatchPreview';
import ContinueWithoutMatch from '../ContinueWithoutMatch';

interface PossibleChildMatchListProps {
  results: ChildMatchResult[];
  childMatch: ChildSearch;
}

const PossibleChildMatchList: FC<PossibleChildMatchListProps> = ({
  results,
  childMatch,
}) => {
  const childMatchValue = childMatch.state.childMatchValue || '';

  const isMatchSelected =
    childMatchValue !== '' && childMatchValue !== 'continueWithoutMatch';

  const currentSelection: ChildMatchResult | null = isMatchSelected
    ? results.find(result => result.childId === childMatchValue) || null
    : null;

  const headingCopy =
    results.length > 1
      ? 'Review possible duplicates'
      : 'Review possible duplicate';
  const subheadingCopy =
    results.length > 1
      ? `We found ${results.length} possible matches based on the info you’ve entered about this child.`
      : 'We found a possible match based on the info you’ve entered about this child.';

  return (
    <>
      <Box mt={space[12]}>
        <Heading as="h2" variant="tertiary" mb={space.one}>
          {headingCopy}
        </Heading>
        <Stack spacing={3} mb={space[6]}>
          <Text as="span" variant="bodyText">
            {subheadingCopy}
          </Text>
          <ChildMatchCriteria />
        </Stack>
        <RadioGroupField
          name={'childId'}
          value={childMatch.state.childMatchValue}
        >
          {results.map((result, index) => (
            <PossibleChildMatch
              key={index}
              childMatch={childMatch}
              {...result}
            />
          ))}
          <ContinueWithoutMatch childMatch={childMatch} />
        </RadioGroupField>
      </Box>

      {currentSelection && (
        <ChildMatchPreview currentSelection={currentSelection} />
      )}
    </>
  );
};

export default PossibleChildMatchList;
