import { ButtonProps, Flex, Tooltip, TooltipProps } from '@chakra-ui/react';
import { FC, SVGProps } from 'react';
import BaseButton from 'components/BaseButton';

export interface Props {
  readonly Icon: FC<SVGProps<SVGSVGElement>>;
  readonly hasTooltip?: boolean;
  readonly iconProps?: SVGProps<SVGSVGElement>;
  readonly buttonProps?: ButtonProps;
  readonly tooltipProps?: Partial<TooltipProps>;
}

const IconButton: FC<Props> = ({
  Icon,
  buttonProps,
  tooltipProps,
  iconProps,
  hasTooltip = false,
}) => {
  return hasTooltip ? (
    <Tooltip {...tooltipProps}>
      <Flex justifyContent="center" alignItems="center">
        <BaseButton {...buttonProps}>
          <Icon {...iconProps} />
        </BaseButton>
      </Flex>
    </Tooltip>
  ) : (
    <BaseButton {...buttonProps}>
      <Icon {...iconProps} />
    </BaseButton>
  );
};

export default IconButton;
