import { Address } from 'components/styled/Address';
import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionNotes,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { FC } from 'react';
import { Flex, Link } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { validateMailingAndPhysicalAddress } from 'lib';
import useChildRecord from 'pages/ChildRecord/OverviewTab/useChildRecord';

const PrimaryCareProviderCard: FC = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data } = useChildRecord(childId);
  const primaryCareProvider = data?.primaryCareProvider;
  const { isAddressTheSame, doesPhysicalAddressExist } =
    validateMailingAndPhysicalAddress(primaryCareProvider);

  const physicalAddress = doesPhysicalAddressExist && (
    <Address
      label="Address"
      line1={primaryCareProvider?.physicalAddress1}
      line2={primaryCareProvider?.physicalAddress2}
      city={primaryCareProvider?.physicalCity}
      state={primaryCareProvider?.physicalState}
      zip={primaryCareProvider?.physicalZip}
    />
  );

  const mailingAddress = !isAddressTheSame && (
    <DetailSectionField label="Mailing address">
      <Address
        line1={primaryCareProvider?.mailingAddress1}
        line2={primaryCareProvider?.mailingAddress2}
        city={primaryCareProvider?.mailingCity}
        state={primaryCareProvider?.mailingState}
        zip={primaryCareProvider?.mailingZip}
      />
    </DetailSectionField>
  );

  const email = primaryCareProvider?.email && (
    <Link
      variant="detailSectionFieldViewValue"
      width="fit-content"
      href={`mailto:${primaryCareProvider?.email}`}
      isExternal
    >
      {primaryCareProvider?.email}
    </Link>
  );

  return (
    <Flex direction="column" flexGrow={1}>
      <DetailSectionRow>
        <DetailSectionColumn flexBasis={'33%'}>
          <DetailSectionField
            label="Clinic name"
            value={primaryCareProvider?.clinicName}
            showWhenEmpty
          />
          <DetailSectionField
            label="Provider's name"
            value={primaryCareProvider?.name}
            showWhenEmpty
          />
          <DetailSectionField label="Address" showWhenEmpty>
            {physicalAddress}
          </DetailSectionField>
          {mailingAddress}
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn flexBasis={'66%'}>
          <DetailSectionField
            label="Phone number"
            value={primaryCareProvider?.phone}
            showWhenEmpty
          />
          <DetailSectionField
            label="Fax number"
            value={primaryCareProvider?.fax}
          />
          <DetailSectionField label="Email address">{email}</DetailSectionField>
        </DetailSectionColumn>
      </DetailSectionRow>
      <DetailSectionNotes notes={primaryCareProvider?.notes} />
    </Flex>
  );
};

export default PrimaryCareProviderCard;
