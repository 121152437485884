import { Alert, TruncatableTextBlock } from 'molecules';
import { ReactComponent as DeleteIcon } from 'icons/delete.svg';
import { DocumentMetadata } from '@cssat/acorn-api-shared';
import {
  SortDirection,
  Table,
  TableBody,
  TableCell,
  TableColHead,
  TableHeader,
  TableRow,
} from 'components/styled/tables';
import { Text } from '@chakra-ui/react';
import { getTabularDateFromISOString } from 'lib';
import {
  useGetDocumentDownloadUrl,
  useMutateArchiveDocument,
} from './useDocuments';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import BaseButton from 'components/BaseButton';
import useDocumentsTableConfig from './useDocumentsTableConfig';

export interface SortConfig {
  readonly sortColumn: keyof DocumentMetadata;
  readonly sortDirection: SortDirection;
}

const DocumentsTable = () => {
  const { id: childId } = useParams<{ id: string }>();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { tableData, onClick, getSortDirection } = useDocumentsTableConfig();
  const documentDownloadUrl = useGetDocumentDownloadUrl(childId);
  const archiveDocument = useMutateArchiveDocument(childId);
  const [fileIdToArchive, setFileIdToArchive] = useState<string>();

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableColHead
              onClick={() => onClick('title')}
              sortDirection={getSortDirection('title')}
            >
              Name
            </TableColHead>
            <TableColHead
              onClick={() => onClick('createdAt')}
              sortDirection={getSortDirection('createdAt')}
            >
              Date Uploaded
            </TableColHead>
            <TableColHead
              onClick={() => onClick('user')}
              sortDirection={getSortDirection('user')}
            >
              Uploaded By
            </TableColHead>
            <TableColHead>Notes</TableColHead>
            <TableColHead>Delete File</TableColHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tableData &&
            tableData.map(file => {
              return (
                <TableRow key={file.id}>
                  <TableCell>
                    <Text
                      as={'a'}
                      href={documentDownloadUrl(file.id)}
                      color="teal.600"
                      textDecoration="underline"
                    >
                      {file.title}
                    </Text>
                  </TableCell>
                  <TableCell>
                    {getTabularDateFromISOString(file.createdAt)}
                  </TableCell>
                  <TableCell>{`${file.user.givenName} ${file.user.familyName}`}</TableCell>
                  <TableCell sx={{ whiteSpace: 'normal', overflow: 'visible' }}>
                    <TruncatableTextBlock text={file.notes} />
                  </TableCell>

                  <TableCell>
                    <BaseButton
                      onClick={() => {
                        setFileIdToArchive(file.id);
                        setIsAlertOpen(true);
                      }}
                    >
                      <DeleteIcon />
                    </BaseButton>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <Alert
        headerText="Delete file"
        confirmText="Delete"
        cancelText="Go back"
        isOpen={isAlertOpen}
        setIsOpen={setIsAlertOpen}
        onConfirm={() => {
          fileIdToArchive && archiveDocument(fileIdToArchive);
          setFileIdToArchive(undefined);
          setIsAlertOpen(false);
        }}
      >
        <Text textStyle="bodyText" fontWeight="medium" fontSize="md">
          Are you sure you want to delete this file?
        </Text>
        <Text textStyle="bodyText" fontWeight="medium" fontSize="md">
          Note: This action is irreversible.
        </Text>
      </Alert>
    </>
  );
};

export default DocumentsTable;
