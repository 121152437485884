import { Box, Flex, Text } from '@chakra-ui/react';
import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionFieldValue,
  DetailSectionNotes,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { Diagnosis } from '@cssat/acorn-api-shared';
import { FC } from 'react';
import { ReactComponent as QualifyingIcon } from 'icons/qualifying.svg';
import { dateFormatDisplayShort, formatISODate } from 'lib';
import { useQualifyingDiagnosisTypes } from '../useKnownMedicalDiagnosisTypes';

interface MedicalDiagnosisViewProps {
  readonly diagnosis: Diagnosis;
}

const MedicalDiagnosisView: FC<MedicalDiagnosisViewProps> = ({
  diagnosis: {
    diagnosisName,
    determinesEligibility,
    dateOfDiagnosis,
    diagnosedBy: { name, title, organization },
    notes,
    dateReceived,
  },
}) => {
  const qualifyingDiagnosisTypes = useQualifyingDiagnosisTypes();

  const diagnosisIsQualifying = qualifyingDiagnosisTypes?.some(
    item => item.label === diagnosisName || determinesEligibility
  );

  return (
    <Flex flexGrow={1} direction="column">
      <DetailSectionRow>
        <DetailSectionColumn>
          <DetailSectionField label="Diagnosis">
            {diagnosisIsQualifying ? (
              <Flex>
                <Box paddingRight={1} backgroundColor="teal.50">
                  <QualifyingIcon />
                </Box>
                <Text
                  textStyle="sectionFieldViewValue"
                  backgroundColor="teal.50"
                >
                  {diagnosisName}
                </Text>
              </Flex>
            ) : (
              <Text textStyle="sectionFieldViewValue">{diagnosisName}</Text>
            )}
          </DetailSectionField>
        </DetailSectionColumn>

        <DetailSectionDivider />

        <DetailSectionColumn>
          <DetailSectionField
            label="Date of diagnosis"
            showWhenEmpty
            value={formatISODate(dateOfDiagnosis, dateFormatDisplayShort)}
          />
        </DetailSectionColumn>

        <DetailSectionDivider />

        <DetailSectionColumn>
          <DetailSectionField label="Diagnosed by">
            <Text textStyle="sectionFieldViewValue">
              {name}
              {title ? `, ${title}` : ''}
            </Text>
            <Text textStyle="sectionFieldViewValue">{organization}</Text>
            {!name && !title && !organization && (
              <Text textStyle="sectionFieldViewValue">-</Text>
            )}
          </DetailSectionField>
        </DetailSectionColumn>
      </DetailSectionRow>
      <DetailSectionNotes label="Other diagnosis information" notes={notes} />
      {dateReceived && (
        <>
          {!notes && <DetailSectionDivider orientation="horizontal" />}
          <Flex alignItems="center" mt={notes ? 6 : 0}>
            <Text textStyle="sectionFieldViewLabel" mr={2}>
              Report received
            </Text>
            <DetailSectionFieldValue
              value={formatISODate(dateReceived, dateFormatDisplayShort)}
            />
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default MedicalDiagnosisView;
