import { dateFormatData, parseDateFromString } from './formatDateTimes';
import { differenceInMonths, startOfDay, startOfToday } from 'date-fns';

export const DOBToAge = (dateString: string) => {
  const dateStringToDate = parseDateFromString(dateString, dateFormatData);
  const dateWithoutTime = startOfDay(dateStringToDate);
  const numberOfMonths = differenceInMonths(startOfToday(), dateWithoutTime);
  const unitString = numberOfMonths === 1 ? 'month' : 'months';
  return `${numberOfMonths} ${unitString}`;
};
