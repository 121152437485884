import { ReactComponent as ArrowDown } from 'icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'icons/arrow-up.svg';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { ComponentProps, FC, UIEventHandler } from 'react';
import { tables } from 'theme';

interface TableCellProps extends ComponentProps<any> {
  onClick?: UIEventHandler;
}

export const TableHeader: FC = ({ children }) => (
  <Box as={'thead'}>{children}</Box>
);

export const TableBody: FC<{
  'data-testid'?: string;
}> = ({ children, ...props }) => (
  <Box as={'tbody'} {...props}>
    {children}
  </Box>
);

interface TableRowProps extends BoxProps {}
export const TableRow: FC<TableRowProps> = ({ children, sx, ...props }) => (
  <Box as={'tr'} {...props} sx={{ ...sx }}>
    {children}
  </Box>
);

export const TableName: FC<BoxProps> = ({ sx, children }) => (
  <Box
    {...tables.table}
    textStyle="table"
    sx={{ ...sx }}
    background="gray.100"
    borderRadius="lg lg 0px 0px"
  >
    {children}
  </Box>
);

export type SortDirection = 'asc' | 'desc';

export type TableColHeadProps = TableCellProps & {
  sortDirection?: SortDirection;
};

export const TableColHead: FC<TableCellProps> = ({
  children,
  onClick,
  sortDirection,
  stickyPositionTopOffset = '64px',
  sx,
  ...props
}) => {
  const pointerStyle = onClick ? { cursor: 'pointer' } : {};
  return (
    <Box
      as={'th'}
      onClick={onClick}
      top={stickyPositionTopOffset}
      sx={{ ...tables.tableHeader, ...pointerStyle, ...sx }}
      {...props}
    >
      <Flex>
        {children}
        <Flex direction="column" ml={1} justifyContent="center">
          {sortDirection && (
            <Box sx={{ opacity: sortDirection === 'asc' ? 1 : 0.3 }} mb="1px">
              <ArrowUp />
            </Box>
          )}
          {sortDirection && (
            <Box sx={{ opacity: sortDirection === 'desc' ? 1 : 0.3 }} mt="1px">
              <ArrowDown />
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export const TableCell: FC<TableCellProps> = ({
  children,
  onClick,
  sx,
  ...props
}) => (
  <Box
    as={'td'}
    sx={{ ...tables.tableCell, ...sx }}
    onClick={onClick}
    {...props}
  >
    {children}
  </Box>
);

export interface TableProps extends BoxProps {}
export const Table: FC<TableProps> = ({ sx, ...props }) => (
  <Box
    as={'table'}
    sx={{ ...tables.table, ...sx }}
    textStyle="table"
    {...props}
  >
    {props.children}
  </Box>
);
