import { EligibilityDecision } from 'typesToMigrate/EligibilityDecision';
import { useAssessmentsArchiveEligibilityContext } from 'contexts';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import partition from 'lodash.partition';
import useSWR from 'swr';

interface EligibilityDecisionsUpdater {
  readonly activeDecisions: EligibilityDecision[];
  readonly inactiveDecisions: EligibilityDecision[];
  readonly error: boolean;
  readonly loaded: boolean;
  toggleArchivedDecisions(): void;
}

const mockEligibilityDecisions: EligibilityDecision[] = [
  {
    id: 'fakeId1',
    isArchived: false,
    isDeterminedEligible: true,
    decisionDate: new Date('8/18/2021'),
    teamMembers: [],
    diagnoses: [],
    evaluationsDomains: {
      adaptive: [],
      cognitive: [],
      communicationExpressive: [],
      communicationReceptive: [],
      hearing: [],
      physicalMotorFine: [],
      physicalMotorGross: [],
      socialEmotional: [],
      vision: [],
    },
    icoText: 'Lorem ipsum',
    icoCreated: new Date('8/15/2021'),
  },
  {
    id: 'fakeId2',
    isArchived: true,
    isDeterminedEligible: true,
    decisionDate: new Date('8/14/2021'),
    teamMembers: [],
    diagnoses: [],
    evaluationsDomains: {
      adaptive: [],
      cognitive: [],
      communicationExpressive: [],
      communicationReceptive: [],
      hearing: [],
      physicalMotorFine: [],
      physicalMotorGross: [],
      socialEmotional: [],
      vision: [],
    },
    icoText: 'Lorem ipsum',
    icoCreated: new Date('8/10/2021'),
  },
];

// these are used for testing different data states
const triggerDataResponse = async () => mockEligibilityDecisions;
// const triggerLoadingResponse = undefined;
// const triggerEmptyResponse = async () => [];
// const triggerErrorResponse = async () => {
//   throw new Error('Eligibility  data error');
// };

const useEligibilityDecisions = (): EligibilityDecisionsUpdater => {
  const { id: childId } = useParams<{ id: string }>();
  const [showArchived, setShowArchived] =
    useAssessmentsArchiveEligibilityContext();

  const recordKey = `children-eligibility-decisions-${childId}`;
  const {
    data: eligibilityDecisions,
    error,
    // mutate,
  } = useSWR<EligibilityDecision[]>(recordKey, triggerDataResponse);

  const [activeDecisions, inactiveDecisions] = useMemo(
    () => partition(eligibilityDecisions, ['isArchived', false]),
    [eligibilityDecisions]
  );

  const loaded = !!eligibilityDecisions;

  const toggleArchivedDecisions = useCallback(async () => {
    setShowArchived(!showArchived);
    // await mutate();
  }, [setShowArchived, showArchived]);

  return {
    error,
    loaded,
    activeDecisions,
    inactiveDecisions,
    toggleArchivedDecisions,
  };
};

export default useEligibilityDecisions;
