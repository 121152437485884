import { DirtyFormsActions, DirtyFormsState } from 'contexts';
import { useEffect, useReducer, useState } from 'react';
import isEmpty from 'lodash.isempty';
import some from 'lodash.some';

const defaultInitialState = {};

export const useDirtyFormsContext = (
  providedInitialState?: DirtyFormsState
) => {
  const dirtyFormsReducer = (
    state: DirtyFormsState,
    action: DirtyFormsActions
  ) => {
    switch (action.type) {
      case 'formIsDirty':
        return { ...state, [action.payload.humanReadableFormName]: true };
      case 'formIsClean':
        return { ...state, [action.payload.humanReadableFormName]: false };
      case 'reset':
        return reset(action.payload);
    }
  };

  const reset = (payload?: DirtyFormsState) =>
    payload ? payload : defaultInitialState;

  const [maybeDirtyFormsCollection, dispatch] = useReducer(
    dirtyFormsReducer,
    providedInitialState ? providedInitialState : defaultInitialState,
    reset
  );
  const [hasDirtyForms, setHasDirtyForms] = useState<boolean>(false);

  useEffect(() => {
    if (
      !isEmpty(maybeDirtyFormsCollection) &&
      some(
        Object.values(maybeDirtyFormsCollection),
        isDirty => isDirty === true
      )
    ) {
      setHasDirtyForms(true);
    } else {
      setHasDirtyForms(false);
    }
  }, [maybeDirtyFormsCollection]);

  return {
    hasDirtyForms,
    maybeDirtyFormsCollection,
    dispatch,
  };
};
