import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { Footer, SIDEBAR_COLLAPSED_WIDTH, SideBar, TopBar } from 'molecules';
import { PageContainer } from '../../components/styled/layout';
import { PageHeaderContextProvider, TopBarContextProvider } from 'contexts';

const PageLayout: FC = ({ children }) => (
  <Flex height="100%">
    <TopBarContextProvider>
      <PageHeaderContextProvider>
        <TopBar />
        <Flex width="100%" flexDirection="column">
          <Flex flex="1 0 auto">
            <SideBar />
            <Flex as="main" flexGrow={1}>
              <Flex
                ml={SIDEBAR_COLLAPSED_WIDTH}
                mt="68px"
                width="100%"
                justifyContent={['start', 'start', 'center']}
              >
                <PageContainer>{children}</PageContainer>
              </Flex>
            </Flex>
          </Flex>
          <Footer />
        </Flex>
      </PageHeaderContextProvider>
    </TopBarContextProvider>
  </Flex>
);

export default PageLayout;
