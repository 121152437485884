import {
  ChildDetailsContextProvider,
  ChildListTableContextProvider,
  OpenFormContextProvider,
} from 'contexts';
import { ChildList } from 'pages/ChildList';
import { ChildRecord } from 'pages/ChildRecord';
import { CreateReferral } from 'pages/CreateReferral';
import { FC } from 'react';
import { PageLayout } from 'molecules';
import { PreviousReferralContextProvider } from 'contexts/PreviousReferralContext';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Services } from 'pages/Services';
import { TabIndexContextProvider } from 'contexts';
import { TrackSession } from 'pages/Services/TrackSession';

const AuthenticatedRoutes: FC = () => {
  return (
    <Switch>
      <Route exact path="/referral/create">
        <ChildDetailsContextProvider>
          <PreviousReferralContextProvider>
            <CreateReferral />
          </PreviousReferralContextProvider>
        </ChildDetailsContextProvider>
      </Route>
      <Route exact path="/services">
        <PageLayout>
          <Services />
        </PageLayout>
      </Route>
      <Route path="/services/track">
        <TrackSession />
      </Route>
      <Route path="/children/:id/">
        <PageLayout>
          <OpenFormContextProvider>
            <TabIndexContextProvider>
              <ChildRecord />
            </TabIndexContextProvider>
          </OpenFormContextProvider>
        </PageLayout>
      </Route>
      <Route path="/children">
        <PageLayout>
          <ChildListTableContextProvider>
            <ChildList />
          </ChildListTableContextProvider>
        </PageLayout>
      </Route>
      <Route path="*">
        <Redirect to="/children" />
      </Route>
    </Switch>
  );
};

export default AuthenticatedRoutes;
