import { FC, createContext, useContext, useMemo, useState } from 'react';

export enum Tabs {
  Overview = 'Overview',
  Evaluations = 'Evaluations',
  COS = 'COS',
  Exit = 'Exit',
  Services = 'Services',
}

interface OpenFormState {
  readonly tab: Tabs;
  readonly primaryJumpLink: string;
  readonly secondaryJumplink?: string;
}

type OpenFormContextState = [
  OpenFormState | undefined,
  (value?: OpenFormState) => void
];

const OpenFormContext = createContext<OpenFormContextState>([
  undefined,
  (_value?: OpenFormState) => {},
]);

const useOpenFormContext = () => useContext(OpenFormContext);

const OpenFormContextProvider: FC = ({ children }) => {
  const [openForm, setOpenForm] = useState<OpenFormState | undefined>(
    undefined
  );

  const value: OpenFormContextState = useMemo(
    () => [openForm, (value?: OpenFormState) => setOpenForm(value)],
    [openForm]
  );

  return (
    <OpenFormContext.Provider value={value}>
      {children}
    </OpenFormContext.Provider>
  );
};

export { OpenFormContextProvider, useOpenFormContext };
