import { Box, Divider, Heading, Stack, Text } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { Form, useFormikContext } from 'formik';
import { startOfToday, startOfYesterday, subYears } from 'date-fns';

import { ChildSearch } from '../ChildMatchSection/useChildMatch';
import { DateField, TextField } from 'components/formik';
import { EndOfFormSpacing } from 'components/styled/forms/EndOfFormSpacing';
import { FormFields } from './validation';
import { SexOfChildFields } from 'molecules';
import { formStyles } from '../styles';
import { space } from 'theme';
import ChildMatchSection from '../ChildMatchSection/ChildMatchSection';
import useHandleChildMatchSearch from './useHandleChildMatchSearch';

const disabledDays = {
  after: startOfYesterday(),
  before: subYears(startOfToday(), 3),
};

interface ChildDetailsFormProps {
  readonly childMatch: ChildSearch;
}

const ChildDetailsForm: FC<ChildDetailsFormProps> = ({ childMatch }) => {
  const { setFieldValue } = useFormikContext<FormFields>();
  const { state } = childMatch;

  useEffect(() => {
    setFieldValue('childMatch', state.childMatchValue);
  }, [setFieldValue, state.childMatchValue]);

  useHandleChildMatchSearch(childMatch);

  return (
    <Form style={formStyles}>
      <Box data-testid="child-section">
        <Heading as="h2" variant="tertiary" mb={4}>
          Child Details
        </Heading>
        <Text variant="bodyText" mb={12}>
          The fields below are all required and will be used to determine if
          this is a child whose information may already be in ACORN.
        </Text>

        <Stack spacing={5} mb={space[5]}>
          <TextField name="childFirstName" label="First name" required />
          <TextField name="childLastName" label="Last name" required />
          <DateField
            name="childDateOfBirth"
            label="Date of birth"
            disabledDays={disabledDays}
            required
          />
        </Stack>

        <Box>
          <SexOfChildFields name="sexOfChild" noteFieldName="otherSexOfChild" />
        </Box>
        <Divider orientation="horizontal" mt={space[12]} />
        <Box mt={space[12]}>
          <ChildMatchSection childMatch={childMatch} />
        </Box>
      </Box>
      <EndOfFormSpacing />
    </Form>
  );
};

export default ChildDetailsForm;
