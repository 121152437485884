import { FC, createContext, useContext, useMemo, useState } from 'react';

type ModalContentContextState = [
  HTMLDivElement | undefined,
  (value?: HTMLDivElement) => void
];

const ModalContentContext = createContext<ModalContentContextState>([
  undefined,
  () => {},
]);

const useModalContentContext = () => useContext(ModalContentContext);

const ModalContentContextProvider: FC = ({ children }) => {
  const [node, setNode] = useState<HTMLDivElement | undefined>(undefined);

  const value: ModalContentContextState = useMemo(
    () => [node, (value?: HTMLDivElement) => setNode(value)],
    [node]
  );

  return (
    <ModalContentContext.Provider value={value}>
      {children}
    </ModalContentContext.Provider>
  );
};

export { ModalContentContextProvider, useModalContentContext };
