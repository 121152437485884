import { useParams } from 'react-router-dom';
import useDiagnoses from 'pages/ChildRecord/EvaluationsTab/useDiagnoses';
import useEvaluationsAndAssessments from '../EvaluationsSection/useEvaluations';
import useTeamMembers from '../TeamMembersSection/useTeamMembers';

const useCanAddEligibility = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: diagnosesApiData } = useDiagnoses(childId);
  const { data: teamMemberApiData } = useTeamMembers(childId);
  const { data: evalPayload } = useEvaluationsAndAssessments(childId);

  const evaluations = evalPayload?.activeEvals || [];

  const hasTeamMembers = teamMemberApiData && teamMemberApiData?.length > 0;
  const hasDiagnosis = diagnosesApiData && diagnosesApiData?.length > 0;

  const domainsTested = evaluations.reduce((acc, curr) => {
    Object.values(curr.domainsTested).forEach(value => {
      if (value) {
        return (acc += 1);
      }
    });
    return acc;
  }, 0);

  const hasTwoOrMoreDomains = domainsTested >= 2;

  return hasTeamMembers && hasDiagnosis && hasTwoOrMoreDomains;
};

export default useCanAddEligibility;
