import {
  DataCard,
  FormIconButton,
  JumpLinkRefIds,
  SectionHeader,
  SectionWrapper,
} from 'molecules';
import { ReactComponent as EditIcon } from 'icons/pencil.svg';
import { FC, useCallback, useMemo, useState } from 'react';
import { Tabs, useOpenFormContext } from 'contexts';
import { iconButtonStyles } from '../../styles';
import { useLatestReferral } from 'hooks';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import ChildDetailsCard from './ChildDetailsCard';
import ChildDetailsForm from './ChildDetailsForm';
import InlineAlert from 'components/alerts/InlineAlert';

const ChildDetailsSection: FC = () => {
  const [openFormState, setOpenFormState] = useOpenFormContext();
  const { id: childId } = useParams<{ id: string }>();
  const referral = useLatestReferral(childId);
  const { search } = useLocation();
  const isActive = referral?.isActive;

  const openEditMode = useCallback(() => {
    if (openFormState) {
      return;
    }

    setOpenFormState({
      tab: Tabs.Overview,
      primaryJumpLink: JumpLinkRefIds.ChildDetails,
    });
  }, [openFormState, setOpenFormState]);

  const isFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.ChildDetails;

  const isNewReferral = () => {
    const queryParams = new URLSearchParams(search);
    return !!queryParams.get('newReferral');
  };

  const headerProps = useMemo(
    () => ({
      buttons: (
        <FormIconButton
          Icon={EditIcon}
          refId={JumpLinkRefIds.ChildDetails}
          aria-label="Edit"
          onClick={openEditMode}
          sx={iconButtonStyles}
          tooltipProps={{ label: 'Edit' }}
          disabled={!isActive}
        />
      ),
    }),
    [isActive, openEditMode]
  );

  const [showNewReferralAlert, setShowNewReferralAlert] = useState(true);

  return (
    <SectionWrapper id={JumpLinkRefIds.ChildDetails}>
      {referral && showNewReferralAlert && isActive && isNewReferral() && (
        <InlineAlert
          marginBottom={8}
          status="warning"
          title="Child has a new referral."
          description="Please review and update their record as necessary."
          onClose={() => {
            setShowNewReferralAlert(false);
          }}
        />
      )}
      {referral && !isActive && (
        <InlineAlert
          marginBottom={8}
          status="info"
          title="This record cannot be edited because the child has been exited."
          description="To reactivate the record, enter a new referral."
        />
      )}
      <SectionHeader headerText="Child details" isFormOpen={isFormOpen} />
      <DataCard headerProps={headerProps} hideHeader={isFormOpen}>
        {isFormOpen ? <ChildDetailsForm /> : <ChildDetailsCard />}
      </DataCard>
    </SectionWrapper>
  );
};

export default ChildDetailsSection;
