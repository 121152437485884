import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { Formik } from 'formik';
import { ModalFormLayout } from 'molecules';
import {
  ReferralSchema,
  constructInitialValues,
} from './ReferralForm/validation';
import { constructChildDetails } from './helpers';
import { formMaxWidthStyles } from '../styles';
import { useChildDetailsContext } from 'contexts/ChildDetailsContext';
import { usePreviousReferralContext } from 'contexts/PreviousReferralContext';
import InlineAlert from 'components/alerts/InlineAlert';
import ReferralForm from './ReferralForm/ReferralForm';
import ReferralHeaderButtons from './ReferralHeaderButtons';
import ReferralShortForm from './ReferralForm/ReferralShortForm';
import useSubmitReferral from './useSubmitReferral';

interface ReferralProps {
  readonly organizationId: string;
}

const Referral: FC<ReferralProps> = ({ organizationId }) => {
  const { onSubmit, showSubmitError, hasSubmitted } = useSubmitReferral();
  const [childDetails] = useChildDetailsContext();
  const [previousReferral] = usePreviousReferralContext();

  if (!childDetails) return null;

  const { childFirstName, childLastName } = constructChildDetails(
    childDetails,
    previousReferral
  );

  return (
    <Formik
      validationSchema={ReferralSchema}
      initialValues={constructInitialValues(
        organizationId,
        childDetails,
        previousReferral
      )}
      onSubmit={onSubmit}
    >
      {formik => {
        const showShortForm = formik.values.showShortForm;

        return (
          <ModalFormLayout
            formButtons={<ReferralHeaderButtons hasSubmitted={hasSubmitted} />}
            title={`Create new referral for ${childFirstName} ${childLastName}`}
          >
            {showSubmitError && (
              <InlineAlert
                marginBottom={4}
                data-testid="createReferralErrorToast"
                status="error"
                title="Sorry, we can’t create this referral right now."
                description="Please check your connection and try again."
              />
            )}
            <Box maxWidth={formMaxWidthStyles}>
              {showShortForm ? <ReferralShortForm /> : <ReferralForm />}
            </Box>
          </ModalFormLayout>
        );
      }}
    </Formik>
  );
};

export default Referral;
