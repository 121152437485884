import { Box, Flex } from '@chakra-ui/react';
import { ReactComponent as ChildrenIcon } from 'icons/children.svg';
import { FC, KeyboardEvent, useState } from 'react';
import { ReactComponent as ReferralsIcon } from 'icons/referrals.svg';
import { ReactComponent as ServicesIcon } from 'icons/services.svg';
import { transition, zIndexMap } from 'theme';
import NavItem from './NavItem';

const EXPANDED_WIDTH = '160px';
export const SIDEBAR_COLLAPSED_WIDTH = '68px';

const SideBar: FC<{ isStaticallyExpanded?: boolean }> = ({
  isStaticallyExpanded = false,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(isStaticallyExpanded);

  return (
    <Flex
      as="nav"
      backgroundColor="gray.50"
      height="100%"
      px={6}
      flexShrink={0}
      onMouseEnter={() => {
        setIsExpanded(true);
      }}
      onMouseLeave={() => {
        if (isStaticallyExpanded) return;
        setIsExpanded(false);
      }}
      tabIndex={0}
      {...(isExpanded ? { 'aria-expanded': true } : {})}
      onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === ' ') {
          e.preventDefault();
          if (isStaticallyExpanded) return;
          setIsExpanded(value => !value);
        }
      }}
      sx={{
        boxShadow: isExpanded ? 'base' : 'none',
        position: 'fixed',
        top: 0,
        zIndex: zIndexMap.sidebarLayer,
        overflowX: 'hidden',
        transition: transition.sideBar,
        width: isExpanded ? EXPANDED_WIDTH : SIDEBAR_COLLAPSED_WIDTH,
        '&:focus': {
          boxShadow: 'outline',
          outline: 'none',
        },
      }}
    >
      <Box
        as="ul"
        mt={20}
        p={0}
        sx={{
          listStyleType: 'none',
          li: {
            mb: '3.5px',
          },
        }}
      >
        <NavItem
          to={'/referral/create'}
          text="New Referral"
          isTextShown={isExpanded}
          icon={ReferralsIcon}
        />
        <NavItem
          to={'/children'}
          text="Children"
          isTextShown={isExpanded}
          icon={ChildrenIcon}
        />
        <NavItem
          to={'/services'}
          text="Services"
          isTextShown={isExpanded}
          icon={ServicesIcon}
        />
      </Box>
    </Flex>
  );
};

export default SideBar;
