import { FC, createContext, useContext, useState } from 'react';

type ChildOutcomeIdContextType = [
  string | undefined,
  (id: string | undefined) => void
];

const ChildOutcomeIdContext = createContext<ChildOutcomeIdContextType>([
  undefined,
  () => {},
]);

export const useChildOutcomeIdContext = () => useContext(ChildOutcomeIdContext);

export const ChildOutcomeIdContextProvider: FC = ({ children }) => {
  const value = useState<string | undefined>(undefined);

  return (
    <ChildOutcomeIdContext.Provider value={value}>
      {children}
    </ChildOutcomeIdContext.Provider>
  );
};
