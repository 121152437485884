import { Checkbox, TextareaField } from 'components/formik';
import { FC, useCallback } from 'react';
import { Heading, Stack } from '@chakra-ui/react';
import { concernsFormSchema, createEmptyFamilyConcerns } from '../validation';
import { useParams } from 'react-router-dom';
import ChildSectionFormikWrapper from 'pages/ChildRecord/ChildSectionFormikWrapper/ChildSectionFormikWrapper';
import useConcerns, { useMutateConcerns } from './useConcerns';

const FamilyConcernsForm: FC = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: concerns } = useConcerns(childId);
  const updateConcerns = useMutateConcerns(childId);

  const onSubmit = useCallback(
    async formValues => {
      await updateConcerns(formValues);
    },
    [updateConcerns]
  );

  return (
    <ChildSectionFormikWrapper
      humanReadableFormName="Child Assessments - Family evaluations concerns"
      inlineAlertMessage="There was a problem updating the family concerns."
      formikProps={{
        validationSchema: concernsFormSchema,
        initialValues: concerns ?? createEmptyFamilyConcerns(),
      }}
      successToastProps={{ title: `Family concerns updated` }}
      errorToastProps={{
        title: 'Sorry, we can’t update family concerns right now.',
        description: 'Please check your connection and try again.',
      }}
      apiRequest={onSubmit}
    >
      <Stack flexDir="column" spacing={4}>
        <Heading as="h4" variant="tertiary">
          Family concerns
        </Heading>
        <TextareaField
          rows={1}
          name="summary"
          label="Provide a summary of the family’s concerns based on challenges in everyday routines and activities."
        />
        <TextareaField
          rows={1}
          name="priorities"
          label="What are the priorities of the family based on the concerns identified above?"
        />
        <TextareaField
          rows={1}
          name="resources"
          label="What are some strengths and resources the family has in order to meet their child’s current needs? This can include family, friends, community groups, financial support or anything else the family feels is helpful."
        />
        <TextareaField
          rows={1}
          name="additionalConcerns"
          label="Are there any additional concerns that others have shared about the child?"
        />
        <Checkbox
          value="true"
          name="familyDeclinesToShare"
          label="The family does not wish to share concerns, priorities, or resources and/or have this information included
        in the IFSP. The family understands that if the child is eligible, the child can still receive appropriate services
        as determined by the IFSP team even if the parent chooses not to complete this section."
        />
      </Stack>
    </ChildSectionFormikWrapper>
  );
};

export default FamilyConcernsForm;
