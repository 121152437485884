import { useMemo, useState } from 'react';
import Fuse from 'fuse.js';

export interface UseFuse<T> {
  list: ReadonlyArray<T>;
  fuseOptions?: Fuse.IFuseOptions<T>;
}

interface UseFuseReturn<T> {
  searchResults: Fuse.FuseResult<T>[];
  search: (
    searchPattern: string | Fuse.Expression,
    searchOptions?: Fuse.FuseSearchOptions
  ) => void;
  isSearchResultsAllResults: boolean;
}

export const useFuse = <T>({
  list,
  fuseOptions,
}: UseFuse<T>): UseFuseReturn<T> => {
  const fuse = useMemo(() => new Fuse(list, fuseOptions), [list, fuseOptions]);
  const [searchPattern, setSearchPattern] = useState<string | Fuse.Expression>(
    ''
  );
  const [searchOptions, setSearchOptions] = useState<Fuse.FuseSearchOptions>();

  const search = (
    searchPattern: string | Fuse.Expression,
    searchOptions?: Fuse.FuseSearchOptions
  ) => {
    setSearchPattern(searchPattern);
    searchOptions && setSearchOptions(searchOptions);
  };

  const searchResults = useMemo(
    () => fuse.search(searchPattern, searchOptions),
    [fuse, searchOptions, searchPattern]
  );

  const allResults = useMemo(
    () =>
      list.map((item, index) => {
        return {
          item,
          refIndex: index,
        };
      }),
    [list]
  );

  const results = searchResults.length > 0 ? searchResults : allResults;

  const isSearchResultsAllResults = useMemo(
    () => results.length === allResults.length,
    [allResults.length, results.length]
  );

  return {
    searchResults: results,
    search,
    isSearchResultsAllResults,
  };
};
