import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import {
  DropDownField,
  DropDownOption,
  RadioGroupField,
} from 'components/formik';
import { PlanDetails } from '../PlanDetails';
import { RadioPill } from 'components/formik/RadioPill';
import { ServicePlan } from 'pages/Services/types';
import { fakeKids } from 'lib';
import { gridColumns } from 'theme';
import { useFormikContext } from 'formik';
import type { FormFields } from './validation';

const kidsAsOptions = fakeKids.map(child => ({
  label: child.name,
  value: child.id,
}));
const selectChildOptions = [{ label: '', value: '' }, ...kidsAsOptions];

const findChildWithServicePlans = (selectedChildId: string) => {
  return fakeKids.find(kid => kid.id === selectedChildId);
};

const findService = (selectedChildId: string, selectedServiceId: string) => {
  const childWithServicePlans = findChildWithServicePlans(selectedChildId);

  if (!childWithServicePlans?.servicePlans) return undefined;

  return childWithServicePlans.servicePlans.find(
    service => service.id === selectedServiceId
  );
};

const selectServiceOptions = (selectedChildId: string) => {
  if (selectedChildId === '') return [];

  const childWithServicePlans = findChildWithServicePlans(selectedChildId);

  if (!childWithServicePlans?.servicePlans) return [];

  return childWithServicePlans.servicePlans.map(service => {
    return {
      label: service.name,
      value: service.id,
    };
  });
};

export const Section01ChildAndService: FC<{
  service: ServicePlan | undefined;
  setService: Dispatch<SetStateAction<ServicePlan | undefined>>;
}> = ({ service, setService }) => {
  const { values, setFieldValue } = useFormikContext<FormFields>();
  const standardWidth = gridColumns(4);

  const serviceOptions: DropDownOption[] = useMemo<DropDownOption[]>(() => {
    return selectServiceOptions(values.child);
  }, [values.child]);

  useEffect(() => {
    if (serviceOptions.length === 1) {
      setFieldValue('service', serviceOptions[0].value);
    }
    if (values.child !== '' && values.service !== '') {
      setService(findService(values.child, values.service));
    }
  }, [serviceOptions, values.child, values.service, setFieldValue, setService]);

  return (
    <>
      <DropDownField
        name="child"
        label="Child's name"
        options={selectChildOptions}
        required
        width={standardWidth}
        onChange={() => {
          if (values.child !== '') {
            setFieldValue('service', '');
            setService(undefined);
          }
        }}
      />

      {values.child !== '' ? (
        <RadioGroupField
          name="service"
          label="Service"
          direction="horizontal"
          defaultValue={
            serviceOptions.length === 1 ? serviceOptions[0].value : undefined
          }
          required
        >
          {serviceOptions &&
            serviceOptions.map(({ label, value }) => (
              <RadioPill
                variant={'radioPill'}
                key={`radio-${label}`}
                value={value ? value : label}
              >
                {label}
              </RadioPill>
            ))}
        </RadioGroupField>
      ) : null}
      {values.service !== '' && service ? (
        <PlanDetails service={service} />
      ) : null}
    </>
  );
};
