import { FC, createContext, useContext, useMemo, useState } from 'react';

type PageHeaderContextState = [
  HTMLDivElement | undefined,
  (value?: HTMLDivElement) => void
];

const PageHeaderContext = createContext<PageHeaderContextState>([
  undefined,
  () => {},
]);

const usePageHeaderContext = () => useContext(PageHeaderContext);

const PageHeaderContextProvider: FC = ({ children }) => {
  const [node, setNode] = useState<HTMLDivElement | undefined>(undefined);

  const value: PageHeaderContextState = useMemo(
    () => [node, (value?: HTMLDivElement) => setNode(value)],
    [node]
  );

  return (
    <PageHeaderContext.Provider value={value}>
      {children}
    </PageHeaderContext.Provider>
  );
};

export { PageHeaderContextProvider, usePageHeaderContext };
