import { ChildContact } from '@cssat/acorn-api-shared';
import { FormFields } from './validation';
import { isPreferredMethodValid } from 'lib';

type PreferredContactMethod = ChildContact['preferredContactMethod'];
type ContactConfidentiality = ChildContact['confidentiality'];

const preferredContactUnspecified = 'unspecified';

export default (formFields: FormFields, id: string): ChildContact => {
  if (!formFields) {
    throw new Error('No form data');
  }

  const { isAddressSame, ...request } = formFields;
  if (isAddressSame) {
    request.mailingAddress1 = request.physicalAddress1;
    request.mailingAddress2 = request.physicalAddress2;
    request.mailingCity = request.physicalCity;
    request.mailingState = request.physicalState;
    request.mailingZip = request.physicalZip;
  }

  if (!isPreferredMethodValid(formFields, formFields.preferredContactMethod)) {
    throw new Error('Preferred contact method must have valid information');
  }

  const preferredContactMethod =
    request.preferredContactMethod === preferredContactUnspecified
      ? undefined
      : (request.preferredContactMethod as PreferredContactMethod);

  return {
    ...request,
    id,
    preferredContactMethod,
    confidentiality: request.confidentiality as ContactConfidentiality,
    isArchived: false,
  };
};
