import { ChildContact } from '@cssat/acorn-api-shared';
import { ReactComponent as EditIcon } from 'icons/pencil.svg';
import { FormIconButton, JumpLinkRefIds } from 'molecules';
import { Tabs, useOpenFormContext } from 'contexts';
import { useCallback, useMemo } from 'react';
import { useLatestReferral } from 'hooks';
import { useParams } from 'react-router-dom';
import useArchiveIconProps from './useArchiveIconProps';

const getHeaderTags = ({
  isPrimaryContact,
  childLivesHere,
  isArchived,
  id,
}: ChildContact) => {
  const headerTags = [];

  if (isArchived) return [{ text: 'Inactive Contact', key: `${id}-archived` }];

  if (isPrimaryContact)
    headerTags.push({ text: 'Primary Contact', key: `${id}-primary-contact` });
  if (childLivesHere)
    headerTags.push({
      text: 'Child lives here',
      key: `${id}-child-lives-here`,
    });

  return headerTags;
};

const useCardHeaderProps = (contact: ChildContact) => {
  const [openFormState, setOpenFormState] = useOpenFormContext();
  const { id: childId } = useParams<{ id: string }>();
  const referral = useLatestReferral(childId);
  const isActive = referral?.isActive;
  const archiveIconProps = useArchiveIconProps(contact, !isActive);

  const onClick = useCallback(() => {
    if (openFormState) {
      return;
    }

    setOpenFormState({
      tab: Tabs.Overview,
      primaryJumpLink: JumpLinkRefIds.ChildContacts,
      secondaryJumplink: contact.id,
    });
  }, [contact.id, openFormState, setOpenFormState]);

  return useMemo(() => {
    const showEditButton = !contact.isArchived;

    return {
      headerTags: getHeaderTags(contact),
      isArchived: contact.isArchived,
      buttons: (
        <>
          {showEditButton && (
            <FormIconButton
              onClick={onClick}
              aria-label={'Edit Contact'}
              Icon={EditIcon}
              refId={contact.id}
              tooltipProps={{ label: 'Edit' }}
              disabled={!isActive}
            />
          )}
          <FormIconButton {...archiveIconProps} />
        </>
      ),
    };
  }, [archiveIconProps, contact, isActive, onClick]);
};

export default useCardHeaderProps;
