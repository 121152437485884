import { ChakraProps, Flex } from '@chakra-ui/react';
import { DetailSectionContent } from 'components/styled/forms/DetailSection';
import { FC } from 'react';
import { JumpLinkRefIds } from 'molecules';
import { useScrollRefIntoViewById } from 'hooks';
import DataCardHeader, { Props as HeaderProps } from './DataCardHeader';

export interface Props extends ChakraProps {
  readonly hideHeader?: boolean;
  readonly headerProps?: HeaderProps;
  readonly id?: JumpLinkRefIds | string;
}

const DataCard: FC<Props> = ({
  children,
  headerProps,
  id,
  hideHeader = false,
  ...props
}) => {
  const cardRef = useScrollRefIntoViewById(id);

  return (
    <Flex id={id} ref={cardRef} flexDirection="column">
      <DetailSectionContent p="0" {...props}>
        {!hideHeader && <DataCardHeader {...headerProps} />}
        <Flex p="6" pt={hideHeader ? 6 : 0}>
          {children}
        </Flex>
      </DetailSectionContent>
    </Flex>
  );
};

export default DataCard;
