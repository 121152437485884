import { ESITService } from '@cssat/acorn-api-shared';
import { authenticatedFetch, authenticatedPost, paths } from 'lib';
import useSWR from 'swr';

const useESITServices = (childId: string) => {
  return useSWR<ESITService[]>(
    paths.services.getESITServices({ childId }),
    authenticatedFetch
  );
};

export const useMutateESITServices = (childId: string) => {
  const { mutate } = useESITServices(childId);
  return async (outcome: ESITService) => {
    await authenticatedPost(paths.services.saveESITService({ childId }), {
      body: outcome,
    });
    await mutate();
  };
};

export default useESITServices;
