import { AcornFormField } from 'components/formik/AcornFormField';
import { FC } from 'react';
import { FormLabel, Select, SelectProps } from '@chakra-ui/react';
import { useField } from 'formik';
import { v4 as uuid } from 'uuid';

export interface DropDownOption {
  label: string;
  value?: string | number | undefined;
  disabled?: boolean;
}

interface DropDownOptionWithSubGroup extends DropDownOption {
  options?: DropDownOption[];
}
export interface DropDownFieldProps extends SelectProps {
  name: string;
  label: string;
  options: DropDownOptionWithSubGroup[];
  placeholder?: string;
  required?: boolean;
}
export const DropDownField: FC<DropDownFieldProps> = props => {
  const { name, label, options, placeholder, required, maxWidth } = props;
  const [field] = useField(name);

  return (
    <AcornFormField name={name} required={required}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select
        textStyle="bodyText"
        {...field}
        name={name}
        data-testid={name}
        placeholder={placeholder}
        value={field.value}
        {...(maxWidth ? { maxWidth } : {})}
      >
        {options &&
          options.map(option => {
            if (!option.options) {
              return (
                <option key={option.value ?? uuid()} {...option}>
                  {option.label}
                </option>
              );
            }

            return (
              <optgroup key={option.value ?? uuid()} {...option}>
                {option.options.map(subOpt => (
                  <option key={subOpt.value ?? uuid()} {...subOpt}>
                    {subOpt.label}
                  </option>
                ))}
              </optgroup>
            );
          })}
      </Select>
    </AcornFormField>
  );
};
