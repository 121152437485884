import { Box } from '@chakra-ui/react';
import { CardHeader, InfoCard } from 'components/styled/cards';
import { FC } from 'react';
import { ListItem } from 'components/styled/text';
import { ServicePlan } from 'pages/Services/types';
import { enumToDisplay, numberToRepetition, toSentenceCase } from 'lib';
import { space } from 'theme';

const timingFromService = (service: ServicePlan) =>
  toSentenceCase(
    `${numberToRepetition(service.frequency)} ${service.timescale}`
  );

export const PlanDetails: FC<{ service: ServicePlan }> = ({ service }) => (
  <Box data-testid="planDetails">
    <InfoCard>
      <CardHeader data-testid="planDetailsHeader" mb={space.half}>
        Service Plan
      </CardHeader>
      <ul>
        <ListItem>{timingFromService(service)}</ListItem>
        <ListItem>{`${service.duration} minute sessions`}</ListItem>
        <ListItem>{enumToDisplay(service.intensity)}</ListItem>
        <ListItem>{enumToDisplay(service.setting)}</ListItem>
      </ul>
    </InfoCard>
  </Box>
);
