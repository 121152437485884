import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Text,
} from '@chakra-ui/react';
import {
  Checkbox,
  DateField,
  DropDownField,
  RadioGroupField,
  RadioPill,
  TextField,
  TextareaField,
} from 'components/formik';
import { DetailSectionDivider } from 'components/styled/forms/DetailSection';
import {
  ESITService,
  ServiceIntervalList,
  ServiceLengthList,
  ServiceNameList,
  ServiceSettingList,
  ServiceTypeList,
} from '@cssat/acorn-api-shared';
import { FC } from 'react';
import { FormSection } from 'components/styled/forms/FormSection';
import { initialValues, validateFormSchema } from './validation';
import { useESITServiceIdContext, useOpenFormContext } from 'contexts';
import { useMutateESITServices } from '../useESITServices';
import { useParams } from 'react-router-dom';
import ChildSectionFormikWrapper from 'pages/ChildRecord/ChildSectionFormikWrapper/ChildSectionFormikWrapper';
import useOutcomes from '../useOutcomes';

interface ESITServicesFormProps {
  readonly service?: ESITService;
}

const ESITServicesForm: FC<ESITServicesFormProps> = ({
  service,
}: ESITServicesFormProps) => {
  const [, setOpenFormState] = useOpenFormContext();
  const [, setEsitServiceFormId] = useESITServiceIdContext();
  const { id: childId } = useParams<{ id: string }>();
  const { data: outcomes } = useOutcomes(childId);
  const createOrUpdateESITService = useMutateESITServices(childId);

  const serviceOptions = ServiceNameList.map(svc => {
    return {
      value: svc,
      label: svc,
    };
  });

  return (
    <ChildSectionFormikWrapper
      formikProps={{
        validationSchema: validateFormSchema(outcomes),
        initialValues: initialValues(service),
      }}
      successToastProps={{
        title: `ESIT service saved successfully`,
      }}
      errorToastProps={{
        title: `Sorry, we can’t save the ESIT service right now.`,
        description: 'Please check your connection and try again.',
      }}
      humanReadableFormName="Services - ESIT services"
      inlineAlertMessage="There was a problem completing this ESIT service."
      apiRequest={async values => {
        await createOrUpdateESITService(values as ESITService);
      }}
      onCancel={() => {
        setOpenFormState(undefined);
        setEsitServiceFormId(undefined);
      }}
      formProps={{ noValidate: true }}
    >
      {formik => {
        const isOtherServiceSetting = formik.values.serviceSetting === 'Other';
        return (
          <>
            <FormSection>
              <DropDownField
                name="serviceName"
                label="Service"
                options={serviceOptions}
                placeholder=" "
                required
              />
              <TextField name="provider" label="Provider" required />
              <DateField
                name="plannedStartDate"
                label="Planned start date"
                required
              />
              <DateField
                name="plannedEndDate"
                label="Planned end date"
                required
              />
              <TextareaField
                name="methods"
                label="Methods"
                height={200}
                required
              />
            </FormSection>
            <DetailSectionDivider orientation="horizontal" />
            <FormSection>
              <Heading as="h4" variant="secondary">
                Child and family outcomes supported
              </Heading>
              <Text textStyle="bodyText">
                Select the child and family outcomes this service will support.
                You must select at least one
                <Text as="span" color="teal.700" pr={2}>
                  *
                </Text>
              </Text>
              <FormControl
                name="outcomesSupported"
                isInvalid={Boolean(
                  formik.touched && formik.errors.outcomesSupported
                )}
              >
                {outcomes?.map(outcome => (
                  <Checkbox
                    key={outcome.id.toString()}
                    label={`${outcome.title}`}
                    value={outcome.id.toString()}
                    name={`outcomesSupported`}
                    mb={0}
                  />
                ))}
                <FormErrorMessage>
                  {formik.touched && formik.errors.outcomesSupported}
                </FormErrorMessage>
              </FormControl>
            </FormSection>
            <DetailSectionDivider orientation="horizontal" />
            <FormSection>
              <Heading as="h4" variant="secondary">
                Intensity of service
              </Heading>
              <RadioGroupField
                name="serviceType"
                label="What is the service type?"
                direction="horizontal"
              >
                {ServiceTypeList.map(item => (
                  <RadioPill
                    key={`service-interval-${item}`}
                    value={item}
                    label={item}
                  />
                ))}
              </RadioGroupField>
              <RadioGroupField
                name="serviceLength"
                label="How long is each session of this service?"
                direction="horizontal"
              >
                {ServiceLengthList.map(item => (
                  <RadioPill
                    key={`service-length-${item}`}
                    value={item}
                    label={item}
                  />
                ))}
              </RadioGroupField>
              <Text textStyle="bodyText">
                How often will this service occur?
              </Text>
              <Flex>
                <Box mr={2}>
                  <TextField name="serviceFrequency" width={100} />
                </Box>
                <Text textStyle="bodyText">time(s) per</Text>
                <RadioGroupField
                  name="serviceInterval"
                  direction="horizontal"
                  ml={6}
                >
                  {ServiceIntervalList.map(item => (
                    <RadioPill
                      key={`service-interval-${item}`}
                      value={item}
                      label={item}
                    />
                  ))}
                </RadioGroupField>
              </Flex>
              <RadioGroupField
                name="serviceSetting"
                label="In what setting will this service happen?"
                direction="horizontal"
              >
                {ServiceSettingList.map(item => (
                  <RadioPill
                    key={`service-setting-${item}`}
                    value={item}
                    label={item}
                  />
                ))}
              </RadioGroupField>
            </FormSection>
            {isOtherServiceSetting && (
              <>
                <DetailSectionDivider orientation="horizontal" />
                <FormSection>
                  <Heading as="h4" variant="secondary">
                    Other setting
                  </Heading>
                  <Text textStyle="bodyText">
                    Since you noted this setting is not in a natural
                    environment, please answer these additional questions.
                  </Text>
                  <TextField name="settingName" label="Setting name" required />
                  <TextField
                    name="settingExplain"
                    label="Why can this outcome not be achieved in a natural environment?"
                    required
                  />
                  <TextField
                    name="settingPlan"
                    label="What is the plan for moving this service and/or support for this service into a natural environment?"
                    required
                  />
                </FormSection>
              </>
            )}
          </>
        );
      }}
    </ChildSectionFormikWrapper>
  );
};

export default ESITServicesForm;
