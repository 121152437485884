import { ChildMatchQuery } from 'lib';
import {
  ChildMatchState,
  childMatchReducer,
  createClearAction,
  createResultsAction,
  createSearchAction,
  createSearchExceptionAction,
  createSelectChildAction,
} from './reducer';
import { searchForMatchingChild } from './search';
import { useCallback, useEffect, useMemo, useReducer } from 'react';

export interface ChildSearch {
  state: ChildMatchState;
  search: (query: ChildMatchQuery) => void;
  clearResults: () => void;
  selectChild: (selectedChildId: string) => void;
}

export const useChildMatch = (): ChildSearch => {
  const [childMatchState, dispatch] = useReducer(childMatchReducer, {
    isSearching: false,
    results: undefined,
    searchException: undefined,
    query: undefined,
    childMatchValue: undefined,
  });

  const search = useCallback((query: ChildMatchQuery) => {
    const searchAction = createSearchAction(query);
    return dispatch(searchAction);
  }, []);

  const clearResults = useCallback(() => {
    dispatch(createClearAction());
  }, []);

  const selectChild = useCallback((selectedChildId: string) => {
    dispatch(createSelectChildAction(selectedChildId));
  }, []);

  const { query } = childMatchState;
  useEffect(() => {
    (async () => {
      if (!query) return;
      try {
        const results = await searchForMatchingChild(query);
        if (results && Array.isArray(results)) {
          const resultsAction = createResultsAction(results);
          return dispatch(resultsAction);
        }
      } catch (error) {
        const e = error as Error;
        return dispatch(createSearchExceptionAction(e));
      }
    })();
  }, [query, dispatch]);

  return useMemo(
    () => ({
      state: childMatchState,
      search,
      clearResults,
      selectChild,
    }),
    [childMatchState, clearResults, search, selectChild]
  );
};
