import { Box, Flex, Text } from '@chakra-ui/react';
import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionExclamation,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { FC } from 'react';
import { TruncatableTextBlock } from 'molecules';
import { useParams } from 'react-router-dom';
import useConcerns from './useConcerns';

const FamilyConcernsView: FC = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: concerns } = useConcerns(childId);

  return (
    <Flex flexGrow={1} direction="column">
      {concerns?.familyDeclinesToShare && (
        <Box>
          <DetailSectionExclamation text="The family does not wish to have this information included in the IFSP." />
          <DetailSectionDivider orientation="horizontal" />
        </Box>
      )}

      <DetailSectionRow>
        <DetailSectionColumn flexBasis={'25%'}>
          <Text textStyle="bodyText" color="gray.600">
            Summary of family concerns based on challenges in everyday routines
            and activities.
          </Text>
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn flexBasis={'75%'}>
          <TruncatableTextBlock text={concerns?.summary} />
        </DetailSectionColumn>
      </DetailSectionRow>

      <DetailSectionDivider orientation="horizontal" />

      <DetailSectionRow>
        <DetailSectionColumn flexBasis={'25%'}>
          <Text textStyle="bodyText" color="gray.600">
            Priorities of the family based on the concerns identified above
          </Text>
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn flexBasis={'75%'}>
          <TruncatableTextBlock text={concerns?.priorities} />
        </DetailSectionColumn>
      </DetailSectionRow>

      <DetailSectionDivider orientation="horizontal" />

      <DetailSectionRow>
        <DetailSectionColumn flexBasis={'25%'}>
          <Text textStyle="bodyText" color="gray.600">
            Strengths and resources the family has in order to meet their
            child’s current needs. This can include family, friends, community
            groups, financial support or anything else the family feels is
            helpful
          </Text>
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn flexBasis={'75%'}>
          <TruncatableTextBlock text={concerns?.resources} />
        </DetailSectionColumn>
      </DetailSectionRow>

      <DetailSectionDivider orientation="horizontal" />

      <DetailSectionRow>
        <DetailSectionColumn flexBasis={'25%'}>
          <Text textStyle="bodyText" color="gray.600">
            Additional concerns that others have shared about the child
          </Text>
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn flexBasis={'75%'}>
          <TruncatableTextBlock text={concerns?.additionalConcerns} />
        </DetailSectionColumn>
      </DetailSectionRow>
    </Flex>
  );
};

export default FamilyConcernsView;
