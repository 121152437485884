import { Box, Grid, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { COS } from '@cssat/acorn-api-shared';
import { FC } from 'react';
import { familyParticipationIsValid, skillsAreValid } from './validation';

export const CosUnfilledRequirements: FC<{ cos: COS }> = ({ cos }) => {
  const missingCosInfo = [];
  if (!cos.dateStarted) {
    missingCosInfo.push('Date COS started is required');
  }
  if (!familyParticipationIsValid(cos)) {
    missingCosInfo.push('Family participation questions are required');
  }
  if (cos.sources.length < 2) {
    missingCosInfo.push('At least two sources are required');
  }
  return (
    <Grid gap={3}>
      {missingCosInfo.length > 0 && (
        <>
          <Text textStyle="sectionFieldViewLabel">COS Information</Text>
          <Box pl={2}>
            <UnorderedList textStyle="bodyText" spacing={0}>
              {missingCosInfo.map(item => (
                <ListItem key={item}>{item}</ListItem>
              ))}
            </UnorderedList>
          </Box>
        </>
      )}

      {!skillsAreValid(cos.positiveSkills) && (
        <>
          <Text textStyle="sectionFieldViewLabel">
            Positive social-emotional skills
          </Text>
          <Box pl={2}>
            <UnorderedList textStyle="bodyText" spacing={0}>
              <ListItem>Descriptor Statement</ListItem>
              <ListItem>Summary of functional performance</ListItem>
            </UnorderedList>
          </Box>
        </>
      )}
      {!skillsAreValid(cos.acquiringSkills) && (
        <>
          <Text textStyle="sectionFieldViewLabel">
            Acquiring and using knowledge and skills
          </Text>
          <Box pl={2}>
            <UnorderedList textStyle="bodyText" spacing={0}>
              <ListItem>Descriptor Statement</ListItem>
              <ListItem>Summary of functional performance</ListItem>
            </UnorderedList>
          </Box>
        </>
      )}
      {!skillsAreValid(cos.actionsSkills) && (
        <>
          <Text textStyle="sectionFieldViewLabel">
            Taking appropriate actions to meet needs
          </Text>
          <Box pl={2}>
            <UnorderedList textStyle="bodyText" spacing={0}>
              <ListItem>Descriptor Statement</ListItem>
              <ListItem>Summary of functional performance</ListItem>
            </UnorderedList>
          </Box>
        </>
      )}
    </Grid>
  );
};
