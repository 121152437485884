import * as customTheme from 'theme';
import { ChildListResult } from '@cssat/acorn-api-shared';
import { DispatchMaybeDirtyForm } from 'components/WarnUnsavedChanges/DispatchMaybeDirtyForm';
import { DropDownField } from 'components/formik';
import { FC, useCallback, useState } from 'react';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import { FormFields, formSchema } from './validation';
import { FormSection } from 'components/styled/forms/FormSection';
import { Formik } from 'formik';
import { SaveAndCancel } from 'components/styled/forms/SaveAndCancel';
import { useChildTableContext } from 'contexts';
import { usePopupToast } from 'hooks';
import InlineAlert from 'components/alerts/InlineAlert';
import useChildList, { useAssignFRCToChild } from '../useChildList';
import useOrganizationUsers from '../useOrganizationUsers';

const constructFRCCounts = (childList: ChildListResult[]) => {
  const frcCounts = {} as Record<string, number>;

  childList.forEach(child => {
    if (!child.assignedUser?.id) {
      return;
    }

    if (!frcCounts[child.assignedUser?.id]) {
      frcCounts[child.assignedUser?.id] = 1;
    } else {
      frcCounts[child.assignedUser?.id]++;
    }
  });

  return frcCounts;
};

interface Props {
  readonly assignedAgency?: string;
  readonly assignedUserId?: string;
  onCancel(): void;
}

const FRCForm: FC<Props> = ({ onCancel, assignedAgency, assignedUserId }) => {
  const [submitError, setSubmitError] = useState(false);
  const [config] = useChildTableContext();
  const [successToast] = usePopupToast({
    status: 'success',
    title: 'FRC assignment updated',
  });
  const { space } = customTheme;

  const { mutate, data } = useChildList();
  const frcCounts = constructFRCCounts(data ?? []);
  const updateFRCAssignment = useAssignFRCToChild();
  const { data: users } = useOrganizationUsers(assignedAgency as string);

  const assignmentOptions = users
    ? users.map(user => ({
        label: `${user.fullNameByFamilyName} ${
          frcCounts[user.id] ? `(${frcCounts[user.id]} assigned)` : ''
        }`,
        value: user.id,
      }))
    : [];

  const onSubmit = useCallback(
    async (values: FormFields) => {
      setSubmitError(false);
      try {
        await updateFRCAssignment(
          config.currentChildId as string,
          values.assignedUserId as string
        );
        await mutate();
        successToast();
        onCancel();
      } catch (err) {
        setSubmitError(true);
      }
    },
    [updateFRCAssignment, config.currentChildId, mutate, successToast, onCancel]
  );

  const loaded = !!users;
  if (!loaded) {
    return (
      <Flex flexGrow={1} alignItems="center" justifyContent="center">
        <Spinner
          label="Loading..."
          thickness={space.quarter}
          speed="1.25s"
          emptyColor="gray.100"
          color="teal.700"
          size="xl"
        />
      </Flex>
    );
  }

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={formSchema}
      initialValues={{ assignedUserId: assignedUserId ?? '' }}
    >
      {formik => {
        return (
          <form
            onSubmit={e => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <DispatchMaybeDirtyForm
              isDirty={formik.dirty}
              humanReadableFormName="Child List - FRC Assignment"
            />
            {submitError && (
              <InlineAlert
                marginBottom={4}
                status="error"
                title="There was a problem updating the FRC assignment."
              />
            )}
            <Flex flexGrow={1} flexDir="column" justifyContent="space-between">
              <FormSection>
                <DropDownField
                  name="assignedUserId"
                  label="Family resource coordinator"
                  options={[{ label: '-', value: '' }, ...assignmentOptions]}
                />
                <Text textStyle="bodyText">
                  NOTE: Contact support if you need to transfer the child to a
                  different agency.
                </Text>
              </FormSection>

              <Flex alignSelf="flex-end">
                <SaveAndCancel
                  onCancel={onCancel}
                  disabled={formik.values.assignedUserId === ''}
                />
              </Flex>
            </Flex>
          </form>
        );
      }}
    </Formik>
  );
};

export default FRCForm;
