import { ChildContact } from '@cssat/acorn-api-shared';
import { useMemo } from 'react';

export interface ContactMethod {
  key: string;
  label: string;
  value?: string | null;
  preferred?: boolean;
}

type UseOrderedContactMethods = (childContact: ChildContact) => ContactMethod[];
export const useOrderedContactMethods: UseOrderedContactMethods = ({
  phoneCell,
  email,
  phoneHome,
  phoneWork,
  preferredContactMethod,
}) => {
  return useMemo(() => {
    const labeledContactMethods: ContactMethod[] = [
      { key: 'phone-cell', label: 'Cell phone', value: phoneCell },
      { key: 'email', label: 'Email address', value: email },
      { key: 'phone-home', label: 'Home phone', value: phoneHome },
      { key: 'phone-work', label: 'Work phone', value: phoneWork },
    ];

    return labeledContactMethods
      .map(method =>
        method.key === preferredContactMethod
          ? {
              ...method,
              preferred: true,
            }
          : method
      )
      .sort((a, b) => (b.preferred ? 1 : -1));
  }, [preferredContactMethod, phoneCell, email, phoneHome, phoneWork]);
};
