import { FC } from 'react';
import { FieldConfig, useField } from 'formik';
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  SystemStyleObject,
} from '@chakra-ui/react';

export type AcornFormFieldProps = FormControlProps & {
  label?: string;
  required?: boolean;
  name: FieldConfig['name'];
  errorStyle?: SystemStyleObject;
};

export const AcornFormField: FC<AcornFormFieldProps> = ({
  name,
  required = false,
  errorStyle,
  children,
}) => {
  const [field, meta] = useField(name);
  const isInvalid = meta.touched && Boolean(meta.error);

  return (
    <FormControl
      id={field.name}
      isRequired={required}
      isInvalid={isInvalid}
      sx={errorStyle && isInvalid ? errorStyle : {}}
    >
      {children}
      {meta.touched && Boolean(meta.error) && (
        <FormErrorMessage data-testid={`error-${field.name}`}>
          {meta.error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
