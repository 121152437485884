import { DirtyFormsContext } from 'contexts';
import { FC, useContext, useEffect } from 'react';

export const DispatchMaybeDirtyForm: FC<{
  isDirty: boolean;
  humanReadableFormName: string;
}> = ({ isDirty, humanReadableFormName }) => {
  const { dispatch } = useContext(DirtyFormsContext);

  useEffect(() => {
    isDirty
      ? dispatch({
          type: 'formIsDirty',
          payload: { humanReadableFormName },
        })
      : dispatch({
          type: 'formIsClean',
          payload: { humanReadableFormName },
        });

    return () => {
      dispatch({
        type: 'formIsClean',
        payload: { humanReadableFormName },
      });
    };
  }, [dispatch, humanReadableFormName, isDirty]);

  return null;
};
