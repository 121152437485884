import { JumpLinkRefIds, SectionHeader, SectionWrapper } from 'molecules';
import TransitionConferenceCard from '../TransitionConferenceCard/TransitionConferenceCard';

const TransitionConferenceSection = () => {
  return (
    <SectionWrapper id={JumpLinkRefIds.TransitionConference} mb={12}>
      <SectionHeader headerText="Transition conference" isFormOpen={false} />
      <TransitionConferenceCard />
    </SectionWrapper>
  );
};

export default TransitionConferenceSection;
