import { Button } from '@chakra-ui/react';
import { ButtonGroup } from 'components/ButtonGroup';
import { FC, useCallback } from 'react';
import { FormFields } from './validation';
import { hideOnDesktop, hideOnMobile } from '../styles';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';

const ChildDetailsHeaderButtons: FC = () => {
  const history = useHistory();
  const { handleSubmit, values } = useFormikContext<FormFields>();
  const navigateToHome = useCallback(() => history.replace('/'), [history]);

  const isDisabled =
    Boolean(values.childMatch) && values.childMatch !== 'continueWithoutMatch';

  return (
    <ButtonGroup>
      <Button display={hideOnMobile} variant="ghost" onClick={navigateToHome}>
        Cancel
      </Button>

      <Button
        type="submit"
        onClick={() => handleSubmit()}
        isDisabled={isDisabled}
      >
        Continue
      </Button>

      <Button display={hideOnDesktop} variant="ghost" onClick={navigateToHome}>
        Cancel
      </Button>
    </ButtonGroup>
  );
};

export default ChildDetailsHeaderButtons;
