import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';
import { ChildListResult } from '@cssat/acorn-api-shared';
import { HorizontalDivider } from 'components/styled/presentation';
import { InternalLinkNewTab } from 'components/ExternalLink';
import { schoolDistricts } from '@cssat/acorn-api-shared';
import { useCallback } from 'react';
import { useChildTableContext, useUserContext } from 'contexts';
import FRCForm from './FRCForm';
import useChildList from '../useChildList';

const formatLanguages = (currentChild?: ChildListResult) => {
  if (currentChild?.primaryLanguage) {
    return `${currentChild.primaryLanguage}${
      currentChild.otherLanguages ? `, ${currentChild.otherLanguages}` : ''
    }`;
  }

  return;
};

const ChildListDrawer = () => {
  const { data } = useChildList();
  const childList = data ?? [];
  const [config, setConfig] = useChildTableContext();
  const onClose = useCallback(
    () => setConfig({ ...config, currentChildId: undefined }),
    [config, setConfig]
  );
  const { user } = useUserContext();

  const currentChild = childList.find(
    child => child.id === config.currentChildId
  );

  const schoolDistrict = schoolDistricts.find(
    district => district.id === currentChild?.schoolDistrictId
  );

  return (
    <Drawer
      size="sm"
      placement="right"
      onClose={onClose}
      isOpen={!!config.currentChildId}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader pb={0}>
          <Heading as="h2">{`${currentChild?.firstName} ${currentChild?.lastName}`}</Heading>
        </DrawerHeader>
        <DrawerBody>
          <Flex flexDir="column" height="100%">
            <Flex justifyContent="space-between" pb={4}>
              <Text textStyle="bodyText">
                Languages: {formatLanguages(currentChild) ?? '-'}
              </Text>
              <Text textStyle="bodyText">
                School district: {schoolDistrict?.name ?? '-'}
              </Text>
            </Flex>
            <InternalLinkNewTab to={`/children/${currentChild?.id}`}>
              Open child record
            </InternalLinkNewTab>
            <HorizontalDivider my={4} />
            <Heading as="h3" size="md" pb={2}>
              FRC Assignment
            </Heading>
            <Box pb={4}>
              <Text textStyle="bodyText">
                The child can only be assigned to an FRC who is within{' '}
                <Text
                  as="span"
                  textStyle="bodyText"
                  display="inline"
                  fontWeight="bold"
                >
                  {currentChild?.assignedAgency
                    ? `${
                        user?.organizations.find(
                          org => org.id === currentChild?.assignedAgency
                        )?.name
                      }`
                    : `the child's assigned agency`}
                  .
                </Text>
              </Text>
            </Box>
            <Flex flexGrow={1}>
              <FRCForm
                onCancel={onClose}
                assignedUserId={currentChild?.assignedUser?.id}
                assignedAgency={currentChild?.assignedAgency}
              />
            </Flex>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ChildListDrawer;
