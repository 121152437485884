class ApiGetError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ApiGetError';
  }
}

const unauthenticatedFetch = (resource: RequestInfo, init?: RequestInit) => {
  const withDefaults: RequestInit = {
    headers: {
      'Content-Type': 'application/json',
    },
    ...(init ?? {}),
  };
  return fetch(resource, withDefaults).then(res => {
    if (!res.ok) {
      throw new ApiGetError(res.statusText);
    }

    return res.json();
  });
};

export default unauthenticatedFetch;
