import useSWR from 'swr';

import {
  KnownDiagnosisType,
  KnownDiagnosisTypesResult,
} from '@cssat/acorn-api-shared';
import { authenticatedFetch, paths } from 'lib';

export const useQualifyingDiagnosisTypes = () => {
  const { data } = useKnownMedicalDiagnosisTypes();
  return data?.qualifying.map(({ id, name }: KnownDiagnosisType) => {
    return { id, label: name };
  });
};

export const useKnownMedicalDiagnosisTypes = () => {
  return useSWR<KnownDiagnosisTypesResult>(
    paths.knownDiagnosisTypes.get,
    authenticatedFetch
  );
};

export default useKnownMedicalDiagnosisTypes;
