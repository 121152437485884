import * as yup from 'yup';
import { AssessmentMethod, Evaluation } from '@cssat/acorn-api-shared';
import {
  dateFormatErrorString,
  yupOptionalDate,
  yupOptionalLongString,
  yupOptionalString,
  yupRequiredString,
  yupRequiredUuid,
} from 'lib';
import { startOfToday } from 'date-fns';
import { v4 as uuid } from 'uuid';

export type DomainKey = keyof Evaluation['domainsTested'];

const domain = yup.object({
  standardDeviation: yup
    .number()
    .label('Standard deviation')
    /* eslint-disable-next-line */
    .typeError('${label} must be a ${type}')
    .min(-5.0, 'Standard deviation must be -5.0 or greater')
    .max(5.0, 'Standard deviation must be 5.0 or less')
    .optional(),
  percentageDelay: yup
    .number()
    .label('Percentage delay')
    /* eslint-disable-next-line */
    .typeError('${label} must be a ${type}')
    .min(0, 'Percentage delay cannot be less than 0')
    .max(100, 'Percentage delay cannot be greater than 100')
    .optional(),
  ageEquivalence: yup
    .number()
    .label('Age equivalence')
    /* eslint-disable-next-line */
    .typeError('${label} must be a ${type}')
    .min(0, 'Age equivalence cannot be less than 0')
    .optional(),
  presentLevelOfDevelopment: yupOptionalLongString(),
  results: yupOptionalLongString(),
});

export const formSchema = yup
  .object({
    id: yupRequiredUuid(),
    methodOfAssessment: yup
      .mixed<AssessmentMethod>()
      .oneOf(Object.values(AssessmentMethod)),
    evaluationToolId: yupOptionalString(),
    evaluationToolName: yupRequiredString(
      'Test, tool or assessment name is required'
    ),
    evaluationToolVersion: yupOptionalString(),
    dateAdministered: yupOptionalDate()
      .typeError(dateFormatErrorString)
      .max(startOfToday(), 'Date cannot be in the future'),
    administeredBy: yupOptionalString(),
    location: yupOptionalString(),
    notes: yupOptionalLongString(),
    domainsSelected: yup.array().of(yup.string()),
    domainsTested: yup.object({
      adaptive: domain.optional(),
      cognitive: domain.optional(),
      communicationExpressive: domain.optional(),
      communicationReceptive: domain.optional(),
      hearing: domain.optional(),
      physicalMotorFine: domain.optional(),
      physicalMotorGross: domain.optional(),
      socialEmotional: domain.optional(),
      vision: domain.optional(),
    }),
  })
  .defined();

export type FormFields = yup.InferType<typeof formSchema>;

const emptyDomain = {
  standardDeviation: '' as unknown as number,
  percentageDelay: '' as unknown as number,
  ageEquivalence: '' as unknown as number,
  presentLevelOfDevelopment: undefined,
  results: undefined,
};

export const createEmptyEvaluation = () => ({
  id: uuid(),
  methodOfAssessment: AssessmentMethod.NORMALIZED_TOOLS,
  evaluationToolId: '',
  evaluationToolName: '',
  evaluationToolVersion: '',
  dateAdministered: undefined,
  administeredBy: '',
  location: '',
  domainsSelected: [] as DomainKey[],
  domainsTested: {
    adaptive: emptyDomain,
    cognitive: emptyDomain,
    communicationExpressive: emptyDomain,
    communicationReceptive: emptyDomain,
    hearing: emptyDomain,
    physicalMotorFine: emptyDomain,
    physicalMotorGross: emptyDomain,
    socialEmotional: emptyDomain,
    vision: emptyDomain,
  },
  notes: '',
});
