import { Box, ChakraProps } from '@chakra-ui/react';
import { FC } from 'react';
import { colors, space } from 'theme';

const EditDot: FC<ChakraProps & { 'aria-label'?: string }> = ({
  'aria-label': ariaLabel,
  ...props
}) => {
  return (
    <Box
      aria-label={ariaLabel}
      sx={{
        borderRadius: space.half,
        width: space.half,
        height: space.half,
        backgroundColor: colors.teal[500],
      }}
      {...props}
    ></Box>
  );
};
export default EditDot;
