import {
  DataCard,
  JumpLinkRefIds,
  SectionHeader,
  SectionWrapper,
} from 'molecules';
import { useOpenFormContext } from 'contexts';
import { useParams } from 'react-router-dom';
import CardSkeleton from 'pages/ChildRecord/CardSkeleton/CardSkeleton';
import EvaluationForm from './EvaluationForm/EvaluationForm';
import EvaluationsCardList from './EvaluationsCardList';
import InlineAlert from 'components/alerts/InlineAlert';
import useEvaluationsAndAssessments from './useEvaluations';
import useIconButton from './useIconButton';
import useSecondaryButton from './useSecondaryButton';

const EvaluationsSection = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { error, data } = useEvaluationsAndAssessments(childId);
  const [openFormState] = useOpenFormContext();
  const iconButton = useIconButton(childId);
  const secondaryButton = useSecondaryButton(childId);

  const loaded = !!data;
  const isFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.Evaluations;

  const shouldShowAddForm =
    openFormState?.primaryJumpLink === JumpLinkRefIds.Evaluations &&
    openFormState.secondaryJumplink === undefined;

  return (
    <SectionWrapper id={JumpLinkRefIds.Evaluations} mb={12}>
      <SectionHeader
        headerText="Evaluations and assessments"
        isFormOpen={isFormOpen}
        iconButton={iconButton}
        secondaryButton={secondaryButton}
      />
      {error && (
        <InlineAlert
          marginTop={4}
          status="warning"
          title="Hang on! We can't load this section right now."
          description="We'll keep trying."
        />
      )}
      {shouldShowAddForm && (
        <DataCard>
          <EvaluationForm evaluation={undefined} />
        </DataCard>
      )}
      {loaded ? (
        <EvaluationsCardList isFormOpen={isFormOpen} />
      ) : (
        <CardSkeleton />
      )}
    </SectionWrapper>
  );
};

export default EvaluationsSection;
