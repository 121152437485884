import { ButtonProps, ChakraProps, TooltipProps } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { inactiveTooltipMsg } from './constants';
import { useHealthCheck } from 'hooks';
import { useOpenFormContext } from 'contexts';
import IconButton, { Props as IconButtonProps } from 'components/IconButton';

interface ChildRecordFormIconProps extends ChakraProps {
  readonly disabled?: boolean;
  readonly Icon: IconButtonProps['Icon'];
  onClick: ButtonProps['onClick'];
  'aria-label': string;
  readonly tooltipProps?: Partial<TooltipProps>;
  readonly refId?: string;
}

const FormIconButton: FC<ChildRecordFormIconProps> = ({
  'aria-label': ariaLabel,
  disabled,
  Icon,
  onClick,
  refId,
  tooltipProps,
  ...props
}) => {
  const [openFormState] = useOpenFormContext();
  const { error } = useHealthCheck();

  const isFormOpen = useMemo(() => {
    if (!refId || !openFormState) return false;

    return (
      openFormState.primaryJumpLink === refId ||
      openFormState.secondaryJumplink === refId
    );
  }, [openFormState, refId]);

  const mergedTooltipProps = useMemo(() => {
    const tooltipPropsWithDefaults: Partial<TooltipProps> = {
      placement: 'auto',
      openDelay: 250,
      ...tooltipProps,
    };

    return {
      'aria-label': inactiveTooltipMsg,
      label: inactiveTooltipMsg,
      ...tooltipPropsWithDefaults,
      ...props,
    };
  }, [props, tooltipProps]);

  const iconProps = useMemo(
    () => ({
      opacity: !!openFormState || disabled || error ? 0.3 : 1,
    }),
    [disabled, error, openFormState]
  );

  const buttonProps = useMemo(
    () => ({
      onClick,
      disabled: (disabled || error) ?? !!openFormState,
      'aria-label': ariaLabel,
      ...props,
    }),
    [ariaLabel, disabled, error, onClick, openFormState, props]
  );

  if (isFormOpen) {
    return null;
  }

  return (
    <IconButton
      Icon={Icon}
      hasTooltip={true}
      tooltipProps={mergedTooltipProps}
      iconProps={iconProps}
      buttonProps={buttonProps}
    />
  );
};

export default FormIconButton;
