import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { ReactComponent as CaretIcon } from 'icons/caret-right.svg';
import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { JumpLinkRefIds, SectionHeader, SectionWrapper } from 'molecules';
import { useTabIndexContext } from 'contexts';

const DocumentsTabIndex = 8;

type FormLinkProps = {
  href: string;
};

const FormLink = ({ href }: FormLinkProps) => {
  return (
    <Link isExternal href={href}>
      this form
    </Link>
  );
};

const EvaluationConsentFormsSection = () => {
  const { setTabIndex } = useTabIndexContext();

  return (
    <SectionWrapper id={JumpLinkRefIds.EvaluationConsentForms} mb={12}>
      <SectionHeader headerText="Evaluation consent forms" isFormOpen={false} />
      <Flex mx={6} my={6}>
        <DetailSectionRow>
          <DetailSectionColumn>
            <Text textStyle="sectionFieldViewValue">
              Initial evaluation consent
            </Text>
            <Text textStyle="bodyText">
              Before an initial evaluation or assessment can be performed, the
              guardian must first sign{' '}
              <FormLink href="https://www.dcyf.wa.gov/sites/default/files/pdf/esit/InitialConsentForEval-Assess.pdf" />
              .
            </Text>
          </DetailSectionColumn>
          <DetailSectionDivider />
          <DetailSectionColumn>
            <Text textStyle="sectionFieldViewValue">
              Additional evaluation consent
            </Text>
            <Text textStyle="bodyText">
              Before an additional evaluation or assessment can be performed,
              the guardian must first sign{' '}
              <FormLink href="https://www.dcyf.wa.gov/sites/default/files/forms/15-054.pdf" />
              .
            </Text>
          </DetailSectionColumn>
        </DetailSectionRow>
      </Flex>
      <Link
        width="fit-content"
        my={6}
        mx={6}
        onClick={() => setTabIndex(DocumentsTabIndex)}
      >
        Go to Documents Tab to upload completed Evaluation Consent Forms
        <Box ml={3} height="10px" display="inline-block">
          <CaretIcon />
        </Box>
      </Link>
    </SectionWrapper>
  );
};

export default EvaluationConsentFormsSection;
