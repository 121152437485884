import { AlertStatus as ChakraAlertStatus } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as DefaultCheckIcon } from 'icons/default-check.svg';
import { ReactComponent as ExclamationIcon } from 'icons/circle-exclamation.svg';

export type AlertStatus = ChakraAlertStatus | 'save no-op';

interface AlertStatusStyles {
  backgroundColor: string;
  accentColor: string;
  statusIcon: ReactNode;
  closeIcon: ReactNode;
}

export const getAlertStatusStyles = (
  status: AlertStatus
): AlertStatusStyles => {
  switch (status) {
    case 'success':
      return {
        backgroundColor: 'green.50',
        accentColor: 'green.500',
        statusIcon: <DefaultCheckIcon />,
        closeIcon: <CloseIcon />,
      };
    case 'error': {
      return {
        backgroundColor: 'red.50',
        accentColor: 'red.500',
        statusIcon: <ExclamationIcon />,
        closeIcon: <CloseIcon />,
      };
    }
    case 'warning': {
      return {
        backgroundColor: 'orange.50',
        accentColor: 'orange.500',
        statusIcon: <ExclamationIcon />,
        closeIcon: <CloseIcon />,
      };
    }
    case 'info': {
      return {
        backgroundColor: 'gray.50',
        accentColor: 'gray.500',
        statusIcon: <ExclamationIcon />,
        closeIcon: <CloseIcon />,
      };
    }
    case 'save no-op': {
      return {
        backgroundColor: 'purple.50',
        accentColor: 'purple.700',
        statusIcon: <ExclamationIcon />,
        closeIcon: <CloseIcon />,
      };
    }
    default:
      throw new Error(`Unhandled status: ${status}`);
  }
};
