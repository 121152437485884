import { DataCard } from 'molecules';
import { Diagnosis } from '@cssat/acorn-api-shared';
import { FC } from 'react';
import { useOpenFormContext } from 'contexts';
import MedicalDiagnosisForm from '../MedicalDiagnosisForm/MedicalDiagnosisForm';
import MedicalDiagnosisView from './MedicalDiagnosisView';
import useCardHeaderProps from './useCardHeaderProps';

interface MedicalDiagnosisCardProps {
  readonly diagnosis: Diagnosis;
}

const MedicalDiagnosisCard: FC<MedicalDiagnosisCardProps> = ({ diagnosis }) => {
  const [openFormState] = useOpenFormContext();
  const headerProps = useCardHeaderProps(diagnosis);

  const isFormOpen = openFormState?.secondaryJumplink === diagnosis.id;

  return (
    <DataCard
      id={diagnosis.id}
      headerProps={headerProps}
      hideHeader={isFormOpen}
    >
      {isFormOpen ? (
        <MedicalDiagnosisForm diagnosis={diagnosis} />
      ) : (
        <MedicalDiagnosisView diagnosis={diagnosis} />
      )}
    </DataCard>
  );
};

export default MedicalDiagnosisCard;
