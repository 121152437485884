import { ChildSearch } from './useChildMatch';
import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/layout';
import { RadioOption } from 'components/formik';
import { space } from 'theme';

const ContinueWithoutMatch: FC<{
  childMatch: ChildSearch;
}> = ({ childMatch }) => {
  const checked = childMatch.state.childMatchValue === 'continueWithoutMatch';
  return (
    <Flex
      flexDirection="row"
      mt={space.one}
      p={space.one}
      sx={{
        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: checked ? '2px' : '1px',
        borderRadius: 'md',
        borderColor: checked ? 'teal.600' : 'gray.400',
      }}
      onClick={() => {
        childMatch.selectChild('continueWithoutMatch');
      }}
    >
      <Text
        as="label"
        data-testid="continueWithoutMatchOption"
        htmlFor="continueWithoutMatch"
        sx={{ cursor: 'pointer', width: 'fit-content', display: 'block' }}
      >
        <RadioOption
          name="childMatch"
          value="continueWithoutMatch"
          id="continueWithoutMatch"
          isChecked={checked}
          onChange={() => {
            childMatch.selectChild('continueWithoutMatch');
          }}
          mr={space.one}
        >
          <Text as="span" sx={{ position: 'absolute', marginTop: '-4px' }}>
            Continue without match
          </Text>
        </RadioOption>
        <Flex
          marginTop={2}
          marginLeft={6}
          as="p"
          flexDirection="column"
          width="fit-content"
        >
          <Text as="span" variant="bodyText">
            This will create a new child record along with your referral
          </Text>
        </Flex>
      </Text>
    </Flex>
  );
};

export default ContinueWithoutMatch;
