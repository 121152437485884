import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { Evaluation } from '@cssat/acorn-api-shared';
import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { TruncatableTextBlock } from 'molecules';
import { dateFormatDisplayShort, formatISODate } from 'lib';
import DomainView from './DomainView';

interface Props {
  readonly evaluation: Evaluation;
}

const EvaluationView: FC<Props> = ({
  evaluation: {
    evaluationToolName,
    dateAdministered,
    administeredBy,
    notes,
    domainsTested,
  },
}) => {
  return (
    <Flex flexGrow={1} direction="column">
      <DetailSectionRow>
        <DetailSectionColumn flexBasis="33%">
          <DetailSectionField
            label="Name of tool"
            value={evaluationToolName}
            showWhenEmpty
          />
        </DetailSectionColumn>

        <DetailSectionDivider />

        <DetailSectionColumn flexBasis="33%">
          <DetailSectionField
            label="Date Administered"
            showWhenEmpty
            value={formatISODate(dateAdministered, dateFormatDisplayShort)}
          />
        </DetailSectionColumn>

        <DetailSectionDivider />

        <DetailSectionColumn flexBasis="33%">
          <DetailSectionField
            label="Administered By"
            showWhenEmpty
            value={administeredBy}
          ></DetailSectionField>
        </DetailSectionColumn>
      </DetailSectionRow>
      {domainsTested.adaptive && (
        <DomainView domainName="Adaptive" domain={domainsTested.adaptive} />
      )}
      {domainsTested.cognitive && (
        <DomainView domainName="Cognitive" domain={domainsTested.cognitive} />
      )}
      {domainsTested.communicationExpressive && (
        <DomainView
          domainName="Communication expressive"
          domain={domainsTested.communicationExpressive}
        />
      )}
      {domainsTested.communicationReceptive && (
        <DomainView
          domainName="Communication receptive"
          domain={domainsTested.communicationReceptive}
        />
      )}
      {domainsTested.hearing && (
        <DomainView domainName="Hearing" domain={domainsTested.hearing} />
      )}
      {domainsTested.physicalMotorFine && (
        <DomainView
          domainName="Physical motor fine"
          domain={domainsTested.physicalMotorFine}
        />
      )}
      {domainsTested.physicalMotorGross && (
        <DomainView
          domainName="Physical motor gross"
          domain={domainsTested.physicalMotorGross}
        />
      )}
      {domainsTested.socialEmotional && (
        <DomainView
          domainName="Social emotional"
          domain={domainsTested.socialEmotional}
        />
      )}
      {domainsTested.vision && (
        <DomainView domainName="Vision" domain={domainsTested.vision} />
      )}
      {notes && (
        <>
          <DetailSectionDivider orientation="horizontal" />
          <DetailSectionRow>
            <DetailSectionColumn>
              <DetailSectionField label="Evaluation Notes">
                <TruncatableTextBlock text={notes} />
              </DetailSectionField>
            </DetailSectionColumn>
          </DetailSectionRow>
        </>
      )}
    </Flex>
  );
};

export default EvaluationView;
