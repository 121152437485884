import * as customTheme from 'theme';
import {
  ChakraProvider,
  theme as chakraTheme,
  extendTheme,
} from '@chakra-ui/react';
import { DirtyFormsProvider } from 'components/WarnUnsavedChanges/DirtyFormsProvider';
import { FC } from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import {
  SWRConfigType,
  SWRConfigWithFetcher,
} from 'components/SWRConfigWithFetcher';
import { TrainingUserContextProvider, UserContextProvider } from 'contexts';
import { useTrainingUserSession, useUserSession } from 'hooks';
import AppSpinner from './AppSpinner';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import ScrollToTop from 'components/ScrollToTop';
import SignIn from 'pages/SignIn/SignIn';
import WarnUnsavedChanges from 'components/WarnUnsavedChanges/WarnUnsavedChanges';

const combinedTheme = extendTheme(customTheme, chakraTheme);

const App: FC<{ swrConfig?: SWRConfigType }> = ({ swrConfig }) => {
  const {
    user: trainingUser,
    setUser: setTrainingUser,
    logout,
  } = useTrainingUserSession();

  const { user, setUser, isSessionLoaded } = useUserSession();

  return (
    <SWRConfigWithFetcher config={swrConfig}>
      <Router>
        <ScrollToTop />
        <ChakraProvider theme={combinedTheme}>
          <TrainingUserContextProvider
            value={{ user: trainingUser, setUser: setTrainingUser, logout }}
          >
            <UserContextProvider
              value={{
                user,
                setUser,
              }}
            >
              <DirtyFormsProvider>
                <WarnUnsavedChanges />
                <Switch>
                  {isSessionLoaded ? (
                    user ? (
                      <AuthenticatedRoutes />
                    ) : (
                      <>
                        <Route path="/sign-in">
                          <SignIn />
                        </Route>
                        <Route path="*">
                          <Redirect to="/sign-in" />
                        </Route>
                      </>
                    )
                  ) : (
                    <AppSpinner />
                  )}
                </Switch>
              </DirtyFormsProvider>
            </UserContextProvider>
          </TrainingUserContextProvider>
        </ChakraProvider>
      </Router>
    </SWRConfigWithFetcher>
  );
};

export default App;
