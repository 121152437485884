import { Box, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { ReactComponent as QualifyingIcon } from 'icons/qualifying.svg';

interface Props {
  label: string;
}
const QualifyingIconLabel: FC<Props> = ({ label }) => {
  return (
    <Flex>
      <Box paddingRight={1}>
        <QualifyingIcon />
      </Box>
      <Box>{label}</Box>
    </Flex>
  );
};

export default QualifyingIconLabel;
