import { DomainKey } from './EvaluationForm/validation';
import { Evaluation } from '@cssat/acorn-api-shared';

export const QUALIFYING_PERCENTAGE_DELAY = 25;
export const QUALIFYING_STANDARD_DEVIATION = -1.5;

export const isQualifyingStandardDeviation = (
  standardDeviation?: number
): boolean => {
  if (typeof standardDeviation !== 'number') return false;

  return standardDeviation <= QUALIFYING_STANDARD_DEVIATION;
};

export const isQualifyingPercentageDelay = (percentageDelay?: number) => {
  if (typeof percentageDelay !== 'number') return false;

  return percentageDelay >= QUALIFYING_PERCENTAGE_DELAY;
};

export const isEvaluationQualifyingForDomain = (
  domainKey: DomainKey,
  evaluation?: Evaluation
): boolean => {
  if (!evaluation || !evaluation.domainsTested[domainKey]) return false;

  const domain = evaluation?.domainsTested[domainKey];
  return (
    isQualifyingPercentageDelay(domain?.percentageDelay) ||
    isQualifyingStandardDeviation(domain?.standardDeviation)
  );
};
