import { Box, ChakraProps, Flex, Stack, Text } from '@chakra-ui/react';
import { Checkbox, TextareaField } from 'components/formik';
import { FC } from 'react';
import { FlexedTextField } from 'components/formik';
import { ReactComponent as QualifyingIcon } from 'icons/qualifying.svg';
import {
  isQualifyingPercentageDelay,
  isQualifyingStandardDeviation,
} from '../helpers';
import { useDebounceOnChange } from 'hooks';
import { useField } from 'formik';

interface DomainFormProps {
  label: string;
  domainKey: string;
}

const QualifyingScore: FC = () => (
  <Flex alignItems="center" py={1} textStyle="iconText">
    <QualifyingIcon />
    <Text px={1}>Qualifying score</Text>
  </Flex>
);

interface DomainFieldsProps extends ChakraProps {
  formSchemaKey: string;
}

const DomainFields: FC<DomainFieldsProps> = ({ formSchemaKey, ...props }) => {
  const [field] = useField(formSchemaKey);
  const [value] = useDebounceOnChange(
    field.value,
    field.onChange,
    '',
    val => val
  );

  if (formSchemaKey.includes('vision') || formSchemaKey.includes('hearing')) {
    return (
      <Box {...props}>
        <TextareaField name={`${formSchemaKey}.results`} label="Results" />
      </Box>
    );
  }

  return (
    <Box {...props}>
      <Stack direction="row" spacing={1} mb={4}>
        <FlexedTextField
          label="Standard deviation"
          name={`${formSchemaKey}.standardDeviation`}
          type="number"
          subLabel="(-5.0 to 5.0)"
          conditional={
            value &&
            !!value.standardDeviation &&
            isQualifyingStandardDeviation(Number(value.standardDeviation)) && (
              <QualifyingScore />
            )
          }
        />
        <FlexedTextField
          label="Percentage delay"
          name={`${formSchemaKey}.percentageDelay`}
          type="number"
          subLabel="%"
          conditional={
            value &&
            !!value.percentageDelay &&
            isQualifyingPercentageDelay(Number(value.percentageDelay)) && (
              <QualifyingScore />
            )
          }
        />
        <FlexedTextField
          sx={{ mr: 0 }}
          label="Age equivalence"
          subLabel="months"
          name={`${formSchemaKey}.ageEquivalence`}
          type="number"
        />
      </Stack>
      <TextareaField
        name={`${formSchemaKey}.presentLevelOfDevelopment`}
        label="Present level of development"
      ></TextareaField>
    </Box>
  );
};

const DomainForm: FC<DomainFormProps> = ({ domainKey, label }) => {
  const [domainsSelected] = useField('domainsSelected');
  const formSchemaKey = `domainsTested.${domainKey}`;

  return (
    <>
      <Checkbox my={4} value={domainKey} label={label} name="domainsSelected" />
      {domainsSelected.value.includes(domainKey) && (
        <DomainFields formSchemaKey={formSchemaKey} mb={6} />
      )}
    </>
  );
};

export default DomainForm;
