import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { ESITService } from '@cssat/acorn-api-shared';
import { FC } from 'react';
import { Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { TruncatableTextBlock } from 'molecules';
import { useParams } from 'react-router';
import useOutcomes from '../useOutcomes';

interface ESITServicesViewProps {
  readonly service: ESITService;
}

const ESITServicesView: FC<ESITServicesViewProps> = ({
  service,
}: ESITServicesViewProps) => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: outcomes } = useOutcomes(childId);

  const outcomesFields = service.outcomesSupported.length > 0 && (
    <UnorderedList textStyle="sectionFieldViewValue" spacing={0} ml={6}>
      {outcomes &&
        outcomes
          .filter(it => service.outcomesSupported.includes(it.id))
          .map(outcome => (
            <ListItem key={`outcome-${service.id}-${outcome.id}`}>
              {outcome.title}
            </ListItem>
          ))}
    </UnorderedList>
  );

  return (
    <>
      <Flex direction="column" flexGrow={1}>
        <DetailSectionRow>
          <DetailSectionColumn flexBasis="32%">
            <DetailSectionField
              label="Service"
              showWhenEmpty
              value={service.serviceName}
            />
            <DetailSectionField
              label="Provider"
              showWhenEmpty
              value={service.provider}
            />
            <DetailSectionField
              label="Intensity"
              showWhenEmpty
              value={serviceIntensityDisplayHelper(service)}
            />
            <DetailSectionField
              label="Setting"
              showWhenEmpty
              value={service.serviceSetting}
            />
          </DetailSectionColumn>

          <DetailSectionDivider />

          <DetailSectionColumn flexBasis="32%">
            <DetailSectionField
              label="Planned start date"
              showWhenEmpty
              value={service.plannedStartDate}
            />
            <DetailSectionField
              label="Planned end date"
              showWhenEmpty
              value={service.plannedEndDate}
            />
          </DetailSectionColumn>

          <DetailSectionDivider />

          <DetailSectionColumn flexBasis="36%">
            <DetailSectionField label="Outcomes supported" showWhenEmpty>
              {outcomesFields}
            </DetailSectionField>
          </DetailSectionColumn>
        </DetailSectionRow>

        <DetailSectionDivider orientation="horizontal" />

        <Flex direction="row" flexGrow={1}>
          <DetailSectionColumn>
            <Text textStyle="sectionFieldViewLabel">Methods</Text>
            <TruncatableTextBlock text={service.methods} />
          </DetailSectionColumn>
        </Flex>
      </Flex>
    </>
  );
};

const serviceIntensityDisplayHelper = (service: ESITService) => {
  return `${service.serviceType}, for ${service.serviceLength.replace(
    'min',
    'minutes'
  )}, ${
    service.serviceFrequency
  } times a ${service.serviceInterval.toLowerCase()}`;
};

export default ESITServicesView;
