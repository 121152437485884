import { routes } from '@cssat/acorn-api-shared';

const baseUrl = `//${process.env.REACT_APP_API_HOST}`;

interface ChildUrlParams {
  readonly childId: string;
}

export type SortParameter = { sortBy: string; sortDirection?: string };

export type ChildMatchQuery = {
  childFirstName: string;
  childLastName: string;
  dateOfBirth: string;
};

const paths = {
  healthcheck: () => `${baseUrl}${routes.healthcheck}`,
  auth: {
    login: () => `${baseUrl}${routes.auth.login}`,
    logout: () => `${baseUrl}${routes.auth.logout}`,
    session: () => `${baseUrl}${routes.auth.session}`,
  },
  child: {
    list: ({ agency, sort }: { agency: string; sort?: SortParameter[] }) => {
      const params = new URLSearchParams();
      params.append('agency', agency);

      if (sort) {
        sort.forEach(sortParameter => {
          params.append('sortBy', sortParameter.sortBy);
          params.append('sortDirection', sortParameter.sortDirection ?? 'desc');
        });
      }
      return `${baseUrl}${routes.child.list}?${params.toString()}`;
    },
    assignFRC: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.child.assign}?childId=${childId}`,
    show: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.child.show}?childId=${childId}`,
    updateDetails: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.child.updateDetails}?childId=${childId}`,
    updatePrimaryCareProvider: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.child.updatePrimaryCareProvider}?childId=${childId}`,
    getContacts: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.child.getContacts}?childId=${childId}`,
    addContact: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.child.addContact}?childId=${childId}`,
    updateContact: ({ contactId }: { contactId: string }) =>
      `${baseUrl}${routes.child.updateContact}?contactId=${contactId}`,
    getFamilyInfo: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.child.getFamilyInfo}?childId=${childId}`,
    updateFamilyInfo: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.child.updateFamilyInfo}?childId=${childId}`,
    getFamilyEvalRoutines: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.child.getFamilyEvalRoutines}?childId=${childId}`,
    updateFamilyEvalRoutines: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.child.updateFamilyEvalRoutines}?childId=${childId}`,
    getFamilyEvalConcerns: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.child.getFamilyEvalConcerns}?childId=${childId}`,
    updateFamilyEvalConcerns: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.child.updateFamilyEvalConcerns}?childId=${childId}`,
    getParticipants: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.child.getParticipants}?childId=${childId}`,
    addParticipant: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.child.addParticipant}?childId=${childId}`,
    updateParticipant: ({ participantId }: { participantId: string }) =>
      `${baseUrl}${routes.child.updateParticipant}?participantId=${participantId}`,
    upsertDiagnosis: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.child.upsertDiagnosis}?childId=${childId}`,
    getDiagnoses: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.child.getDiagnoses}?childId=${childId}`,
  },
  docs: {
    list: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.docs.list}?childId=${childId}`,
    archive: ({ childId, fileId }: ChildUrlParams & { fileId: string }) =>
      `${baseUrl}${routes.docs.archive}?childId=${childId}&fileId=${fileId}`,
    upload: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.docs.upload}?childId=${childId}`,
    download: ({ childId, fileId }: ChildUrlParams & { fileId: string }) =>
      `${baseUrl}${routes.docs.download}?childId=${childId}&fileId=${fileId}`,
  },
  evaluationsAndAssessments: {
    get: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.evaluationsAndAssessments.get}?childId=${childId}`,
    upsert: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.evaluationsAndAssessments.upsert}?childId=${childId}`,
  },
  transition: {
    get: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.transitions.getTransitions}?childId=${childId}`,
    upsert: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.transitions.addTransition}?childId=${childId}`,
  },
  organization: {
    findUsers: ({ organizationId }: { organizationId: string }) =>
      `${baseUrl}${routes.organization.findUsersByOrganizationId}?organization_id=${organizationId}`,
  },
  referrals: {
    listByChildId: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.referral.listByChildId}?childId=${childId}`,
    create: () => `${baseUrl}${routes.referral.create}`,
  },
  serviceDelivery: {
    list: ({ providerId }: { providerId: string }) =>
      `${baseUrl}${routes.serviceDelivery.list}?providerId=${providerId}`,
    track: () => `${baseUrl}${routes.serviceDelivery.track}`,
  },
  controls: {
    loginAsNewFrc: () => `${baseUrl}${routes.controls.loginAsNewFrc}`,
    assumeLogin: ({ userId }: { userId: string }) =>
      `${baseUrl}${routes.controls.assumeLogin}?userId=${userId}`,
    createChild: () => `${baseUrl}${routes.controls.child.createChild}`,
    addContact: ({
      childId,
      isArchived,
    }: ChildUrlParams & { isArchived: boolean }) =>
      `${baseUrl}${routes.controls.child.addContact}?childId=${childId}&isArchived=${isArchived}`,
  },
  childMatch: {
    search: ({ childFirstName, childLastName, dateOfBirth }: ChildMatchQuery) =>
      `${baseUrl}${routes.child.search}?firstName=${childFirstName}&lastName=${childLastName}&dateOfBirth=${dateOfBirth}`,
  },
  knownDiagnosisTypes: {
    get: () => `${baseUrl}${routes.knownDiagnosisTypes}`,
  },
  services: {
    getChildOutcomes: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.childOutcomes.get}?childId=${childId}`,
    saveChildOutcomes: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.childOutcomes.save}?childId=${childId}`,
    getESITServices: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.esitServices.get}?childId=${childId}`,
    saveESITService: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.esitServices.save}?childId=${childId}`,
  },
  cos: {
    upsert: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.cos.upsert}?childId=${childId}`,
    listByChildId: ({ childId }: ChildUrlParams) =>
      `${baseUrl}${routes.cos.list}?childId=${childId}`,
  },
};

export default paths;
