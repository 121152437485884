import * as yup from 'yup';
import {
  FormFields as ChildDetails,
  ChildDetailsSchemaFields,
} from '../../ChildDetails/validation';
import { ReferralData } from '@cssat/acorn-api-shared';
import {
  UnspecifiedPreferredContact,
  dateFormatErrorString,
  validateMailingAndPhysicalAddress,
  yupOptionalDate,
  yupOptionalString,
  yupPhoneTestValidator,
  yupRequireAddressWhenPreferredContact,
  yupRequiredDate,
  yupRequiredString,
  yupRequiredUuid,
  yupRequiredWhenPreferredContact,
  yupZipTestValidator,
} from 'lib';
import { startOfToday } from 'date-fns';
import { v4 as uuid } from 'uuid';

export const preferredContactMethods: Array<string> = [
  'mailing-address',
  'phone-generic',
  'email',
  UnspecifiedPreferredContact,
];

const ContactSchema = yup
  .object()
  .shape({
    physicalAddress1: yupRequireAddressWhenPreferredContact('Address1'),
    physicalAddress2: yupRequireAddressWhenPreferredContact('Address2'),
    physicalCity: yupRequireAddressWhenPreferredContact('City'),
    physicalState: yupRequireAddressWhenPreferredContact('State'),
    physicalZip:
      yupRequireAddressWhenPreferredContact('Zip').test(yupZipTestValidator),

    preferredContactMethod: yup
      .string()
      .oneOf(
        preferredContactMethods,
        'Preferred contact method must be phone, email, or mailing address'
      )
      .default(UnspecifiedPreferredContact)
      .notRequired(),

    phoneGeneric: yupRequiredWhenPreferredContact('phone', 'phone').test(
      yupPhoneTestValidator
    ),

    email: yupRequiredWhenPreferredContact('email', 'email').email(
      'Email address must be valid'
    ),

    childLivesHere: yup.boolean().default(false).required(),
    isAddressSame: yup.boolean().default(true).required(),
    mailingAddress1: yupRequireAddressWhenPreferredContact('Address1'),
    mailingAddress2: yupRequireAddressWhenPreferredContact('Address2'),
    mailingCity: yupRequireAddressWhenPreferredContact('City'),
    mailingState: yupRequireAddressWhenPreferredContact('State'),
    mailingZip:
      yupRequireAddressWhenPreferredContact('Zip').test(yupZipTestValidator),
  })
  .required();

const ReferralSchemaFields = {
  childId: yupRequiredUuid(),
  referralId: yupRequiredUuid(),

  dateOfInitialReferral: yupRequiredDate('Referral date is required')
    .typeError(dateFormatErrorString)
    .when('childDateOfBirth', {
      is: value => value !== undefined,
      then: yup
        .date()
        .min(
          yup.ref('childDateOfBirth'),
          'Referral date cannot be before child date of birth'
        ),
    })
    .max(startOfToday(), 'Referral date cannot be in the future'),

  referringParty: ContactSchema.shape({
    name: yupRequiredString('Referral source name is required'),
    role: yupRequiredString('Referral source role is required'),
  }),

  referringPartyIsChildContact: yup.boolean().default(false).required(),

  referringPartyIsPrimaryCareProvider: yup.boolean().default(false).required(),

  familyContactDate: yupOptionalDate()
    .when('dateOfInitialReferral', {
      is: dateOfInitialReferral => dateOfInitialReferral !== undefined,
      then: yup
        .date()
        .min(
          yup.ref('dateOfInitialReferral'),
          'Family contact date cannot be before referral date'
        ),
      otherwise: yup
        .date()
        .min(
          yup.ref('childDateOfBirth'),
          'Family contact date cannot be before child date of birth'
        ),
    })
    .max(startOfToday(), 'Family contact date cannot be in the future'),

  referralReason: yupRequiredString('Reason for referral is required'),

  assignedAgency: yupRequiredString(),

  referralNotes: yupOptionalString(),
};

const ReferralFormFields = {
  showShortForm: yup.boolean().default(false).required(),
};

export const ReferralSchema = yup
  .object({
    ...ChildDetailsSchemaFields,
    ...ReferralSchemaFields,
    ...ReferralFormFields,
  })
  .defined();

export type FormFields = ReturnType<typeof ReferralSchema.validateSync>;

export const testReferralFormFields: FormFields = {
  childFirstName: 'Child first name',
  childLastName: 'Child last name',
  childDateOfBirth: new Date('1/1/2019'),
  sexOfChild: 'female',
  otherSexOfChild: '',
  childMatch: 'continueWithoutMatch',
  childId: 'child-id',
  referralId: 'referral-id',

  dateOfInitialReferral: new Date('6/6/2019'),

  referringParty: {
    name: 'Referrer name',
    role: 'Referring party role',
    preferredContactMethod: 'email',
    email: 'referring-party@example.com',
    phoneGeneric: '111-111-1111',
    physicalAddress1: 'Referring party physical address 1',
    physicalAddress2: 'Referring party physical address 2',
    physicalCity: 'Referring party physical city',
    physicalState: 'Referring party physical state',
    physicalZip: 'RefPhysZip',
    isAddressSame: false,
    childLivesHere: false,
    mailingAddress1: 'Referring party mailing address 1',
    mailingAddress2: 'Referring party mailing address 2',
    mailingCity: 'Referring party mailing city',
    mailingState: 'Referring party mailing state',
    mailingZip: 'RefMailZip',
  },

  referringPartyIsChildContact: true,
  referringPartyIsPrimaryCareProvider: true,

  familyContactDate: new Date('7/7/2019'),
  referralReason: 'development-concerns',
  assignedAgency: 'assigned-agency',
  referralNotes: 'Short notes',
  showShortForm: false,
};

const isReferralFormAutoFilledInDev = (isAutoFilled: boolean) =>
  process.env.NODE_ENV === 'development' && isAutoFilled
    ? testReferralFormFields
    : {};

export const constructInitialValues = (
  organizationId: string,
  childDetails?: ChildDetails,
  previousReferral?: ReferralData
): FormFields => {
  const referringParty = previousReferral?.referringParty;

  const { isAddressTheSame } = validateMailingAndPhysicalAddress(
    previousReferral?.referringParty
  );

  const previousReferralValues = {
    childFirstName: previousReferral?.child.name.first,
    childLastName: previousReferral?.child.name.last,
    childDateOfBirth: previousReferral?.child
      ? new Date(previousReferral?.child.childDateOfBirth)
      : undefined,
    sexOfChild: previousReferral?.child.sexOfChild,
    referringParty: {
      isAddressSame: isAddressTheSame,
      name: referringParty?.name || '',
      role: referringParty?.role || '',
      physicalAddress1: referringParty?.physicalAddress1,
      physicalAddress2: referringParty?.physicalAddress2,
      physicalCity: referringParty?.physicalCity,
      physicalState: referringParty?.physicalState,
      physicalZip: referringParty?.physicalZip,
      mailingAddress1: referringParty?.mailingAddress1,
      mailingAddress2: referringParty?.mailingAddress2,
      mailingCity: referringParty?.mailingCity,
      mailingState: referringParty?.mailingState,
      mailingZip: referringParty?.mailingZip,
      phoneGeneric: referringParty?.phoneGeneric,
      email: referringParty?.email,
      preferredContactMethod: referringParty?.preferredContactMethod || '',
      childLivesHere: referringParty?.childLivesHere || false,
    },
    referralReason: undefined,
    familyContactDate: undefined,
    referralNotes: undefined,
  };

  const initialValues = {
    ...ReferralSchema.default(),
    ...isReferralFormAutoFilledInDev(false), // NOTE: DO NOT COMMIT "true"
    childId: previousReferral?.child?.id || uuid(),
    referralId: uuid(),
    assignedAgency: organizationId,
    ...childDetails,
    ...(previousReferral && previousReferralValues),
  };

  return {
    ...initialValues,
  };
};
