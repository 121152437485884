import * as yup from 'yup';
import { Diagnosis, Evaluation } from '@cssat/acorn-api-shared';
import { dateFormatErrorString, yupRequiredDate } from 'lib';
import { isEvaluationQualifyingForDomain } from '../../EvaluationsSection/helpers';
import { startOfToday } from 'date-fns';
import { useKnownMedicalDiagnosisTypes } from '../../MedicalDiagnosesSection/useKnownMedicalDiagnosisTypes';
import useEligibilityCriteria from '../useEligibilityCriteria';
import type { DomainKey } from '../../EvaluationsSection/EvaluationForm/validation';

const errors = {
  QUALIFYING_ITEM:
    'At least one qualifying diagnosis, qualifying evaluation, or an informed clinical opinion must be used to determine eligibility',
  ELIGIBILITY_DECISION:
    'At least one qualifying diagnosis, qualifying evaluation, or an informed clinical opinion must be used to determine eligibility, and each evaluation domain requires at least one selection',
  DIAGNOSIS: 'At least one diagnosis must be selected',
  DOMAIN: 'At least one evaluation must be selected per domain',
  ICO_TEXT: 'Please enter an informed clinical opinion',
  ICO_DATE: 'Please enter the date of the informed clinical opinion',
  ICO_REQUIRED:
    'If there are no qualifying diagnoses or evaluations, you must check this box to indicate that the eligibility decision is based solely on an informed clinical opinion',
  DETERMINATION: 'Please select whether this child is eligible for services',
  DETERMINATION_DATE: 'Please enter the date that eligibility was determined',
};

export const useValidation = () => {
  // Have to provide validation as a hook to be able to see source of diagnoses & evaluations
  // for determining if any are qualified
  const { diagnoses: diagnosesSource, domains } = useEligibilityCriteria();
  const diagnosisTypes = useKnownMedicalDiagnosisTypes();
  const otherDiagnosisTypeId = diagnosisTypes.data?.other.id;
  const noDiagnosisTypeId = diagnosisTypes.data?.noDiagnosis.id;

  const isQualifyingDiagnosisSelected = (
    selectedIds: Diagnosis['id'][]
  ): boolean => {
    let hasQualifiedDiagnosis = false;
    selectedIds.forEach(id => {
      const diagnosis = diagnosesSource?.find(
        (diagnosis: Diagnosis) => diagnosis.id === id
      );

      // Diagnosis is qualified if not "Other" or "No diagnosis exists"
      hasQualifiedDiagnosis = Boolean(
        diagnosis!.knownDiagnosisTypeId !== otherDiagnosisTypeId &&
          diagnosis!.knownDiagnosisTypeId !== noDiagnosisTypeId
      );
    });
    return hasQualifiedDiagnosis;
  };

  const isAnyQualifyingEvaluationSelected = (
    adaptive: string[],
    cognitive: string[],
    commExpressive: string[],
    commReceptive: string[],
    hearing: string[],
    motorFine: string[],
    motorGross: string[],
    social: string[],
    vision: string[]
  ): boolean => {
    const fn = isQualifyingEvaluationForDomainSelected;

    let hasQualified = fn('adaptive', adaptive);
    hasQualified = hasQualified || fn('cognitive', cognitive);
    hasQualified =
      hasQualified || fn('communicationExpressive', commExpressive);
    hasQualified = hasQualified || fn('communicationReceptive', commReceptive);
    hasQualified = hasQualified || fn('hearing', hearing);
    hasQualified = hasQualified || fn('physicalMotorFine', motorFine);
    hasQualified = hasQualified || fn('physicalMotorGross', motorGross);
    hasQualified = hasQualified || fn('socialEmotional', social);
    hasQualified = hasQualified || fn('vision', vision);

    return hasQualified;
  };

  const isQualifyingEvaluationForDomainSelected = (
    domainString: string,
    values: string[]
  ): boolean => {
    // Skip domain validation if no evaluations are selected
    if (values.length === 0) return false;

    const key = domainString as DomainKey;
    let hasQualifyingEvaluation = false;
    values.forEach((id: Evaluation['id']) => {
      // Skip validating other domain evaluations if a qualifying evaluation has already been found
      if (!domains || hasQualifyingEvaluation === true) return;
      const evaluation = domains[key].find(
        (evaluation: Evaluation) => evaluation.id === id
      );

      hasQualifyingEvaluation = isEvaluationQualifyingForDomain(
        key,
        evaluation
      );
    });
    return hasQualifyingEvaluation;
  };

  const determineIfQualifierExists = (
    diagnosesValue: string[],
    adaptiveValue: string[],
    cognitiveValue: string[],
    communicationExpressiveValue: string[],
    communicationReceptiveValue: string[],
    hearingValue: string[],
    physicalMotorFineValue: string[],
    physicalMotorGrossValue: string[],
    socialEmotionalValue: string[],
    visionValue: string[]
  ) => {
    let hasQualifiedDiagnosis: boolean = false;
    let hasQualifiedEvaluation: boolean = false;

    if (diagnosesValue.length > 0) {
      hasQualifiedDiagnosis = isQualifyingDiagnosisSelected(diagnosesValue);
    }

    hasQualifiedEvaluation = isAnyQualifyingEvaluationSelected(
      adaptiveValue,
      cognitiveValue,
      communicationExpressiveValue,
      communicationReceptiveValue,
      hearingValue,
      physicalMotorFineValue,
      physicalMotorGrossValue,
      socialEmotionalValue,
      visionValue
    );

    return Boolean(hasQualifiedDiagnosis || hasQualifiedEvaluation);
  };

  const hasAtLeastOneQualifyingItem = () => {
    const fieldKeys = [
      'diagnoses',
      'adaptive',
      'cognitive',
      'communicationExpressive',
      'communicationReceptive',
      'hearing',
      'physicalMotorFine',
      'physicalMotorGross',
      'socialEmotional',
      'vision',
    ];

    return yup.boolean().when(fieldKeys, {
      is: determineIfQualifierExists,
      then: yup.boolean().default(true),
      otherwise: yup.boolean().required('No qualifying item selected'),
    });
  };

  const isSelectionMadeForEachEvaluationDomain = (
    evaluationDomains: string[][]
  ): boolean => {
    const doesDomainHaveSelection = (values: string[]): boolean => {
      return values.length > 0;
    };

    return evaluationDomains.every(domain => doesDomainHaveSelection(domain));
  };

  const allEligibilityDecisionPrerequisitesMet = () => {
    const fieldKeys = [
      'adaptive',
      'cognitive',
      'communicationExpressive',
      'communicationReceptive',
      'hearing',
      'physicalMotorFine',
      'physicalMotorGross',
      'socialEmotional',
      'vision',
      'AT_LEAST_ONE_QUALIFYING_ITEM_OR_ICO',
    ];

    return yup.boolean().when(fieldKeys, {
      is: (
        adaptiveValue,
        cognitiveValue,
        communicationExpressiveValue,
        communicationReceptiveValue,
        hearingValue,
        physicalMotorFineValue,
        physicalMotorGrossValue,
        socialEmotionalValue,
        visionValue,
        AT_LEAST_ONE_QUALIFYING_ITEM_OR_ICO
      ) => {
        let hasSelectionForEachEvaluationDomain: boolean = false;

        hasSelectionForEachEvaluationDomain =
          isSelectionMadeForEachEvaluationDomain([
            adaptiveValue,
            cognitiveValue,
            communicationExpressiveValue,
            communicationReceptiveValue,
            hearingValue,
            physicalMotorFineValue,
            physicalMotorGrossValue,
            socialEmotionalValue,
            visionValue,
          ]);

        return Boolean(
          hasSelectionForEachEvaluationDomain &&
            !AT_LEAST_ONE_QUALIFYING_ITEM_OR_ICO
        );
      },
      then: yup.boolean().default(true),
      otherwise: yup.boolean().required(errors.ELIGIBILITY_DECISION),
    });
  };

  const hasQualifyingItemOrIcoSoleDetermination = (errMsg: string) => {
    const fieldKeys = [
      'determinedEligible',
      'icoIsSoleFactor',
      'AT_LEAST_ONE_QUALIFYING_ITEM',
    ];

    return yup.boolean().when(fieldKeys, {
      is: (
        determinedEligible,
        icoIsSoleFactor,
        AT_LEAST_ONE_QUALIFYING_ITEM
      ) => {
        // Skip if not determined eligble, or if ICO is sole factor in eligibility determination
        if (determinedEligible !== true || icoIsSoleFactor === true) {
          return true;
        }

        return AT_LEAST_ONE_QUALIFYING_ITEM;
      },
      then: yup.boolean().optional(),
      otherwise: yup.boolean().required(errMsg),
    });
  };

  const getDefaultDiagnosis = () => {
    const returnArr = [];
    if (diagnosesSource && diagnosesSource.length === 1) {
      const diagnosis = diagnosesSource[0];
      if (diagnosis.knownDiagnosisTypeId === noDiagnosisTypeId) {
        returnArr.push(diagnosis.id);
      }
    }
    return returnArr;
  };

  const getDefaultEvaluation = (domain: string) => {
    const returnArr = [];
    const key = domain as DomainKey;
    if (domains) {
      const evaluations = domains[key];
      if (evaluations.length === 1) {
        returnArr.push(evaluations[0].id);
      }
    }

    return returnArr;
  };

  const requireOneWithoutIcoSoleFactor = (errorMsg: string) => {
    return yup
      .array()
      .of(yup.string())
      .when('icoIsSoleFactor', {
        is: true,
        then: yup.array().of(yup.string()).default([]),
        otherwise: yup.array().of(yup.string()).min(1, errorMsg).default([]),
      });
  };

  const domainValidation = yup
    .array()
    .of(yup.string())
    .min(1, errors.DOMAIN)
    .default([]);

  const formSchema = yup.object({
    AT_LEAST_ONE_QUALIFYING_ITEM: hasAtLeastOneQualifyingItem(),
    AT_LEAST_ONE_QUALIFYING_ITEM_OR_ICO:
      hasQualifyingItemOrIcoSoleDetermination(errors.QUALIFYING_ITEM),
    CAN_DETERMINE_ELIGIBLITY: allEligibilityDecisionPrerequisitesMet(),
    ICO_REQUIRED: hasQualifyingItemOrIcoSoleDetermination(errors.ICO_REQUIRED),
    diagnoses: requireOneWithoutIcoSoleFactor(errors.DIAGNOSIS),
    adaptive: domainValidation,
    cognitive: domainValidation,
    communicationExpressive: domainValidation,
    communicationReceptive: domainValidation,
    hearing: domainValidation,
    physicalMotorFine: domainValidation,
    physicalMotorGross: domainValidation,
    socialEmotional: domainValidation,
    vision: domainValidation,
    icoText: yup.string().required(errors.ICO_TEXT),
    icoIsSoleFactor: yup.boolean().default(false),
    icoDate: yupRequiredDate(errors.ICO_DATE)
      .typeError(dateFormatErrorString)
      .max(startOfToday(), 'Date cannot be in the future'),
    determinedEligible: yup.boolean().required(errors.DETERMINATION),
    eligibilityDate: yupRequiredDate(errors.DETERMINATION_DATE)
      .typeError(dateFormatErrorString)
      .max(startOfToday(), 'Date cannot be in the future'),
  });

  const initialValues = formSchema.default();
  if (initialValues) {
    initialValues.diagnoses = getDefaultDiagnosis();
    initialValues.adaptive = getDefaultEvaluation('adaptive');
    initialValues.cognitive = getDefaultEvaluation('cognitive');
    initialValues.communicationExpressive = getDefaultEvaluation(
      'communicationExpressive'
    );
    initialValues.communicationReceptive = getDefaultEvaluation(
      'communicationReceptive'
    );
    initialValues.hearing = getDefaultEvaluation('hearing');
    initialValues.physicalMotorFine = getDefaultEvaluation('physicalMotorFine');
    initialValues.physicalMotorGross =
      getDefaultEvaluation('physicalMotorGross');
    initialValues.socialEmotional = getDefaultEvaluation('socialEmotional');
    initialValues.vision = getDefaultEvaluation('vision');
  }

  const eligibilityDecisionPrerequisitesMet = (formValues?: any) => {
    if (!formValues) return false;

    const {
      diagnoses,
      icoIsSoleFactor,
      adaptive,
      cognitive,
      communicationExpressive,
      communicationReceptive,
      hearing,
      physicalMotorFine,
      physicalMotorGross,
      socialEmotional,
      vision,
    } = formValues;

    const selectionMadeForEachEvaluationDomain =
      isSelectionMadeForEachEvaluationDomain([
        adaptive,
        cognitive,
        communicationExpressive,
        communicationReceptive,
        hearing,
        physicalMotorFine,
        physicalMotorGross,
        socialEmotional,
        vision,
      ]);

    const hasQualifier = determineIfQualifierExists(
      diagnoses,
      adaptive,
      cognitive,
      communicationExpressive,
      communicationReceptive,
      hearing,
      physicalMotorFine,
      physicalMotorGross,
      socialEmotional,
      vision
    );

    return (
      selectionMadeForEachEvaluationDomain && (hasQualifier || icoIsSoleFactor)
    );
  };

  return {
    initialValues,
    formSchema,
    eligibilityDecisionPrerequisitesMet,
  };
};
