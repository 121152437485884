import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { DirtyFormsContext } from 'contexts';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Location } from 'history';
import { Prompt } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const WarnUnsavedChanges: FC = () => {
  const history = useHistory();
  const cancelButtonRef = useRef(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [lastLocation, setLastLocation] = useState<Location>();
  const [isDiscarded, setIsDiscarded] = useState<boolean>(false);
  const { hasDirtyForms, maybeDirtyFormsCollection, dispatch } =
    useContext(DirtyFormsContext);

  const isSamePathname = (nextLocation: Location): boolean => {
    return history.location.pathname === nextLocation.pathname;
  };

  const shouldWarn = (nextLocation: Location): boolean => {
    if (hasDirtyForms && !isDiscarded && !isSamePathname(nextLocation)) {
      setShowAlert(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (isDiscarded && lastLocation) {
      history.push(lastLocation.pathname);
      setIsDiscarded(false);
    }
  }, [isDiscarded, history, lastLocation]);

  return (
    <>
      <Prompt when={hasDirtyForms} message={shouldWarn} />
      <AlertDialog
        isCentered
        isOpen={showAlert}
        leastDestructiveRef={cancelButtonRef}
        onClose={() => {
          setShowAlert(false);
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <Heading as={AlertDialogHeader} variant="secondary">
              Unsaved Changes
            </Heading>

            <AlertDialogBody py={0}>
              <Text textStyle="bodyText" fontWeight="medium" fontSize="md">
                There are unsaved form changes in:
              </Text>
              <UnorderedList
                textStyle="bodyText"
                mt={4}
                mb={8}
                ml={8}
                spacing={1}
              >
                {Object.entries(maybeDirtyFormsCollection).map(
                  ([formName, isDirty]) => {
                    return isDirty ? (
                      <ListItem
                        key={formName}
                        fontFamily="secondary"
                        letterSpacing="wide"
                      >
                        {formName}
                      </ListItem>
                    ) : null;
                  }
                )}
              </UnorderedList>
              <Text textStyle="bodyText" fontWeight="medium" fontSize="md">
                Do you want to go back to save these changes?
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <ButtonGroup spacing={4}>
                <Button
                  variant="ghost"
                  onClick={() => {
                    dispatch({ type: 'reset' });
                    setIsDiscarded(true);
                    setShowAlert(false);
                  }}
                >
                  Discard
                </Button>
                <Button
                  ref={cancelButtonRef}
                  onClick={() => {
                    setShowAlert(false);
                  }}
                >
                  Go back
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default WarnUnsavedChanges;
