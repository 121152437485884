import { FC, createContext, useContext } from 'react';
import { SessionInfo } from '@cssat/acorn-api-shared';

export type SessionUser = SessionInfo['user'];
export type Organizations = SessionInfo['organizations'];

type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;
export type Organization = ArrayElement<Organizations>;

export type User = SessionUser & { organizations: Organizations };

export type UserState = {
  user?: User;
  setUser: (user?: User) => void;
};

const UserContext = createContext<UserState>({
  user: undefined,
  setUser: () => {},
});

export const useUserContext = () => {
  const { user, setUser } = useContext(UserContext);
  const defaultOrganization = user?.organizations[0];
  return { user, defaultOrganization, setUser };
};

interface Props {
  readonly value: UserState;
}

export const UserContextProvider: FC<Props> = ({ children, value }) => {
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
