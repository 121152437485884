import { Box, Flex, UseToastOptions } from '@chakra-ui/react';
import { ComponentProps, FC, ReactNode, useState } from 'react';
import { DispatchMaybeDirtyForm } from 'components/WarnUnsavedChanges/DispatchMaybeDirtyForm';
import { Form, Formik, FormikConfig, FormikProps, FormikValues } from 'formik';
import { SaveAndCancel } from 'components/styled/forms/SaveAndCancel';
import { useOpenFormContext } from 'contexts';
import { usePopupToast } from 'hooks';
import InlineAlert from 'components/alerts/InlineAlert';

/**
 * This abstraction is purpose built for forms which are
 * built in the cards on the Child Record. (ex. the forms
 * underneath the colored bars on the Child Record)
 *
 * This is not built to be generic enough for the "wizard" forms
 * like Referrals, or forms in drawers, such as document upload.
 *
 * Before updating this interface, attempt to use the lifecycle
 * methods, such as onBeforeRequest, onAfterSuccessfulSubmit, or override
 * props such as formikProps and formProps.
 *
 * If updating this interface is necessary, opt for overrides to existing
 * elements, such as Flex or Box, vs adding conditional logic. Should
 * conditional logic be necessary, it is likely better to copy and paste
 * this as a base to preserve this components usefulness.
 */
interface Props {
  readonly formikProps: Omit<FormikConfig<FormikValues>, 'onSubmit'>;
  readonly successToastProps: UseToastOptions & { title: ReactNode };
  readonly errorToastProps: UseToastOptions & { title: ReactNode };
  readonly inlineAlertMessage: string;
  readonly humanReadableFormName: string;
  readonly children:
    | ReactNode
    | ((formik: FormikProps<FormikValues>) => ReactNode);
  apiRequest(values: FormikValues): void;

  readonly disableSubmit?: boolean;
  readonly formProps?: ComponentProps<typeof Form>;
  onBeforeRequest?(): void;
  onAfterSuccessfulSubmit?(): void;
  onAfterFailedSubmit?(e: Error): void;
  onCancel?(): void;
}

const FormikWrapper: FC<Props> = ({
  formikProps,
  successToastProps,
  errorToastProps,
  inlineAlertMessage,
  humanReadableFormName,
  disableSubmit = false,
  children,
  formProps,
  apiRequest,
  onBeforeRequest,
  onAfterSuccessfulSubmit,
  onAfterFailedSubmit,
  onCancel,
}) => {
  const [successToast] = usePopupToast({
    ...successToastProps,
    status: 'success',
  });
  const [errorToast] = usePopupToast({
    ...errorToastProps,
    status: 'error',
  });
  const [, setOpenFormState] = useOpenFormContext();
  const [showSubmitError, setShowSubmitError] = useState<boolean>(false);

  return (
    <Formik
      {...formikProps}
      onSubmit={async values => {
        setShowSubmitError(false);
        try {
          if (onBeforeRequest) {
            onBeforeRequest();
          }
          await apiRequest(values);
          successToast();
          setOpenFormState(undefined);

          if (onAfterSuccessfulSubmit) {
            onAfterSuccessfulSubmit();
          }
        } catch (e: any) {
          setShowSubmitError(true);
          errorToast();

          if (onAfterFailedSubmit) {
            onAfterFailedSubmit(e);
          }
        }
      }}
    >
      {formik => (
        <Form
          {...formProps}
          onSubmit={e => {
            if (formProps?.onSubmit) {
              return formProps.onSubmit(e);
            }
            e.preventDefault();
            formik.handleSubmit(e);
          }}
          style={formProps?.style ?? { width: '100%' }}
        >
          <DispatchMaybeDirtyForm
            isDirty={formik.dirty}
            humanReadableFormName={humanReadableFormName}
          />
          {showSubmitError && (
            <InlineAlert
              marginBottom={4}
              status="error"
              title={inlineAlertMessage}
            />
          )}
          <Flex maxWidth="1000px" justifyContent="space-between">
            <Box width="100%">
              {typeof children === 'function' ? children(formik) : children}
            </Box>
            <Box>
              <SaveAndCancel
                onCancel={() => {
                  if (onCancel) {
                    return onCancel();
                  }

                  setOpenFormState(undefined);
                }}
                disabled={disableSubmit}
              />
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default FormikWrapper;
