const swrFetcher = async <JSON>(
  resource: RequestInfo,
  init?: RequestInit
): Promise<JSON> => {
  const response = await fetch(resource, {
    headers: {
      'Content-Type': 'application/json',
    },
    ...(init ? init : {}),
  });
  return response.json();
};

export { swrFetcher };
