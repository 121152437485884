import { Box } from '@chakra-ui/react';
import { JumpLinkRefIds, SectionHeader, SectionWrapper } from 'molecules';
import { useOpenFormContext } from 'contexts';
import FamilyConcernsCard from './FamilyConcernsCard/FamilyConcernsCard';
import RoutinesAndActivitiesCard from './RoutinesAndActivitiesCard/RoutinesAndActivitiesCard';

const FamilyDirectedAssessmentsSection = () => {
  const [openFormState] = useOpenFormContext();
  const isFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.FamilyDirectedAssessments;

  return (
    <SectionWrapper id={JumpLinkRefIds.FamilyDirectedAssessments} mb={12}>
      <SectionHeader
        headerText="Family directed assessments"
        isFormOpen={isFormOpen}
      />
      <Box>
        <RoutinesAndActivitiesCard />
        <FamilyConcernsCard />
      </Box>
    </SectionWrapper>
  );
};

export default FamilyDirectedAssessmentsSection;
