import { Box, Flex, Heading } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';
import { useScreenWidth } from 'hooks';
import { zIndexMap } from 'theme';

interface Props {
  title: string;
  formButtons: ReactElement;
}

const ModalFormHeader: FC<Props> = ({ formButtons, title }) => {
  const { isWideScreenWidth } = useScreenWidth();

  return isWideScreenWidth ? (
    <Flex
      as="header"
      backgroundColor="white"
      justifyContent="flex-end"
      position="fixed"
      top={0}
      right={0}
      width="full"
      alignItems="center"
      padding={8}
      boxShadow="base"
      zIndex={zIndexMap.formLayer}
    >
      <Heading marginLeft={72} as="h2" variant="secondary">
        {title}
      </Heading>
      {formButtons}
    </Flex>
  ) : (
    <Box boxShadow="base" zIndex={zIndexMap.formLayer} padding={4}>
      <Heading as="h2" variant="secondary">
        {title}
      </Heading>
    </Box>
  );
};

export default ModalFormHeader;
