import { FC } from 'react';
import { JumpLinkRefIds, SectionHeader } from 'molecules';
import { useOpenFormContext } from 'contexts';
import useIconButton from './useIconButton';
import useSecondaryButton from './useSecondaryButton';

const ChildContactsSectionHeader: FC = () => {
  const [openFormState] = useOpenFormContext();
  const iconButton = useIconButton();
  const secondaryButton = useSecondaryButton();

  const isContactsFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.ChildContacts;

  return (
    <SectionHeader
      isFormOpen={isContactsFormOpen}
      headerText="Child contact(s)"
      secondaryButton={secondaryButton}
      iconButton={iconButton}
    />
  );
};

export default ChildContactsSectionHeader;
