import { Button } from '@chakra-ui/react';
import { ButtonGroup } from 'components/ButtonGroup';
import { FC, useCallback } from 'react';
import { FormFields } from './ReferralForm/validation';
import { hideOnDesktop, hideOnMobile } from '../styles';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';

interface ReferralHeaderButtonsProps {
  readonly hasSubmitted: boolean;
}

const ReferralHeaderButtons: FC<ReferralHeaderButtonsProps> = ({
  hasSubmitted,
}) => {
  const history = useHistory();
  const navigateToHome = useCallback(() => history.replace('/'), [history]);
  const { handleSubmit } = useFormikContext<FormFields>();

  return (
    <ButtonGroup>
      <Button display={hideOnMobile} variant="ghost" onClick={navigateToHome}>
        Cancel
      </Button>

      <Button
        type="submit"
        isDisabled={hasSubmitted}
        onClick={() => handleSubmit()}
      >
        Create new referral
      </Button>

      <Button display={hideOnDesktop} variant="ghost" onClick={navigateToHome}>
        Cancel
      </Button>
    </ButtonGroup>
  );
};

export default ReferralHeaderButtons;
