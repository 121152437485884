import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { TruncatableTextBlock } from 'molecules';
import { useParams } from 'react-router-dom';
import useRoutines from './useRoutines';

const RoutinesAndActivitiesView: FC = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: routines } = useRoutines(childId);

  return (
    <Flex flexGrow={1} direction="column">
      <DetailSectionRow>
        <DetailSectionColumn flexBasis={'25%'}>
          <Text textStyle="bodyText" color="gray.600">
            Where does your child spend the day and who is involved? How would
            you describe your child’s relationship with you and the people they
            spend the most time with in different settings?
          </Text>
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn flexBasis={'75%'}>
          <TruncatableTextBlock text={routines?.setting} />
        </DetailSectionColumn>
      </DetailSectionRow>

      <DetailSectionDivider orientation="horizontal" />

      <DetailSectionRow>
        <DetailSectionColumn flexBasis={'25%'}>
          <Text textStyle="bodyText" color="gray.600">
            What are the things your child enjoys most? (toys, people, places,
            activities, etc)
          </Text>
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn flexBasis={'75%'}>
          <TruncatableTextBlock text={routines?.enjoyedByChild} />
        </DetailSectionColumn>
      </DetailSectionRow>

      <DetailSectionDivider orientation="horizontal" />

      <DetailSectionRow>
        <DetailSectionColumn flexBasis={'25%'}>
          <Text textStyle="bodyText" color="gray.600">
            What does your family enjoy and why? Who is involved? When does this
            occur?
          </Text>
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn flexBasis={'75%'}>
          <TruncatableTextBlock text={routines?.enjoyedByFamily} />
        </DetailSectionColumn>
      </DetailSectionRow>

      <DetailSectionDivider orientation="horizontal" />

      <DetailSectionRow>
        <DetailSectionColumn flexBasis={'25%'}>
          <Text textStyle="bodyText" color="gray.600">
            What activities and relationships are going well?
          </Text>
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn flexBasis={'75%'}>
          <TruncatableTextBlock text={routines?.activitiesSucceeding} />
        </DetailSectionColumn>
      </DetailSectionRow>

      <DetailSectionDivider orientation="horizontal" />

      <DetailSectionRow>
        <DetailSectionColumn flexBasis={'25%'}>
          <Text textStyle="bodyText" color="gray.600">
            What, if any, routines and activities do you find to be difficult or
            frustrating for you and your child?
          </Text>
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn flexBasis={'75%'}>
          <TruncatableTextBlock text={routines?.activitiesChallenging} />
        </DetailSectionColumn>
      </DetailSectionRow>

      <DetailSectionDivider orientation="horizontal" />

      <DetailSectionRow>
        <DetailSectionColumn flexBasis={'25%'}>
          <Text textStyle="bodyText" color="gray.600">
            What activities and routines does your family currently not do
            because of your child’s needs but is interested in doing now or in
            the near future?
          </Text>
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn flexBasis={'75%'}>
          <TruncatableTextBlock text={routines?.activitiesInterest} />
        </DetailSectionColumn>
      </DetailSectionRow>
    </Flex>
  );
};

export default RoutinesAndActivitiesView;
