import { COS } from '@cssat/acorn-api-shared';
import { FormFields } from './validation';
import { formatISO } from 'date-fns';

type booleanString = 'true' | 'false';

const confirmationToBoolean = (confirmation: booleanString): boolean => {
  if (confirmation.toLowerCase() === 'true') return true;
  return false;
};

const adaptFormToApi = (values: FormFields) => {
  const sources = values.sources;
  const body: Partial<COS> = {
    id: values.id,
    dateStarted: values?.dateStarted
      ? formatISO(values.dateStarted)
      : undefined,
    sources,
  };

  if (values.usedDecisionTreeForPrep) {
    body.usedDecisionTreeForPrep = {
      confirmationAnswer: confirmationToBoolean(
        values.usedDecisionTreeForPrep as booleanString
      ),
      notes: values.usedDecisionTreeForPrepNotes,
    };
  }

  if (values.didFamilyParticipateDiscussion) {
    body.didFamilyParticipateDiscussion = {
      confirmationAnswer: confirmationToBoolean(
        values.didFamilyParticipateDiscussion as booleanString
      ),
      notes: values.didFamilyParticipateDiscussionNotes,
    };
  }

  if (
    values.didFamilyParticipateSelection &&
    values.didFamilyParticipateDiscussion === 'true'
  ) {
    body.didFamilyParticipateSelection = {
      confirmationAnswer: confirmationToBoolean(
        values.didFamilyParticipateSelection as booleanString
      ),
      notes: values.didFamilyParticipateSelectionNotes,
    };
  }

  if (
    values.usedESITDecisionTree &&
    values.didFamilyParticipateSelection === 'true' &&
    values.didFamilyParticipateDiscussion === 'true'
  ) {
    body.usedESITDecisionTree = {
      confirmationAnswer: confirmationToBoolean(
        values.usedESITDecisionTree as booleanString
      ),
      notes: values.usedESITDecisionTreeNotes,
    };
  }

  return body;
};

export default adaptFormToApi;
