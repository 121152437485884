import { DocumentMetadata } from '@cssat/acorn-api-shared';
import { SortConfig } from './DocumentsTable';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useDocuments from './useDocuments';

const useDocumentsTableConfig = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: documents } = useDocuments(childId);
  const [tableData, setTableData] = useState<DocumentMetadata[] | undefined>(
    documents
  );
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    sortColumn: 'createdAt',
    sortDirection: 'desc',
  });

  const getSortDirection = useCallback(
    (parameter: string) =>
      sortConfig.sortColumn === parameter
        ? sortConfig.sortDirection
        : undefined,
    [sortConfig.sortColumn, sortConfig.sortDirection]
  );

  const onClick = useCallback(
    (column: keyof DocumentMetadata) => {
      const direction =
        sortConfig.sortColumn === column && sortConfig.sortDirection === 'desc'
          ? 'asc'
          : 'desc';

      setSortConfig({
        ...sortConfig,
        sortColumn: column,
        sortDirection: direction,
      });
    },
    [sortConfig]
  );

  const sortComparator = useCallback(
    (a: DocumentMetadata, b: DocumentMetadata) => {
      const retVal = sortConfig.sortDirection === 'asc' ? -1 : 1;

      if (sortConfig.sortColumn === 'user') {
        return (a.user.givenName || '') < (b.user.givenName || '')
          ? retVal
          : -1 * retVal;
      }

      const sortCol = sortConfig.sortColumn || 'createdAt';
      return (a[sortCol] || '') < (b[sortCol] || '') ? retVal : -1 * retVal;
    },
    [sortConfig]
  );

  // Ensure data from api that is incoming gets updated
  useEffect(() => {
    const newDocs = documents ? [...documents] : [];
    setTableData(newDocs?.sort(sortComparator));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  // We need to ensure a synchronous update of sortConfig to then sort data
  useEffect(() => {
    if (!tableData) {
      return;
    }

    const newTableData = [...tableData];
    newTableData.sort(sortComparator);

    setTableData(newTableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortConfig.sortColumn, sortConfig.sortDirection]);

  return {
    tableData,
    onClick,
    getSortDirection,
  };
};

export default useDocumentsTableConfig;
