import {
  Box,
  UseToastOptions as ChakraUseToastOptions,
  Flex,
  FlexProps,
  Text,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { AlertStatus } from './status';
import { getAlertStatusStyles } from './status';

type InlineAlertProps = Pick<ChakraUseToastOptions, 'description'> & {
  status: AlertStatus;
  title: ReactNode;
  onClose?: () => void;
} & FlexProps;

const InlineAlert: FC<InlineAlertProps> = ({
  status,
  description,
  title,
  onClose,
  ...props
}) => {
  const { backgroundColor, accentColor, statusIcon, closeIcon } =
    getAlertStatusStyles(status);

  return (
    <Flex
      padding={4}
      backgroundColor={backgroundColor}
      borderRadius="md"
      border="1px solid"
      borderColor={accentColor}
      justifyContent="space-between"
      {...props}
    >
      <Flex>
        <Box marginRight={2} sx={{ 'svg path': { fill: accentColor } }}>
          {statusIcon}
        </Box>

        <Text
          as="span"
          textStyle="bodyText"
          lineHeight="20px"
          {...(description && { marginRight: 2 })}
        >
          <Text as="strong" fontWeight="bold">
            {title}
          </Text>
          {description && <Text as="span"> {description}</Text>}
        </Text>
      </Flex>
      {onClose && (
        <Box sx={{ 'svg path': { fill: accentColor } }} onClick={onClose}>
          {closeIcon}
        </Box>
      )}
    </Flex>
  );
};

export default InlineAlert;
