import { COS, SkillsSections } from '@cssat/acorn-api-shared';
import { DataCard } from 'molecules';
import { FC } from 'react';
import { skillsFormContentProps } from '../SkillsForm/helpers';
import { useOpenFormContext } from 'contexts';
import SkillsForm from '../SkillsForm/SkillsForm';
import SkillsView from './SkillsView';
import useCardHeaderProps from './useCardHeaderProps';

interface SkillsTypeProps {
  skillType: SkillsSections;
  cos: COS;
}

const SkillsCard: FC<SkillsTypeProps> = ({ cos, skillType }) => {
  const cardContent = skillsFormContentProps[skillType];
  const cosIsCompleted = !!cos.dateCompleted;
  const headerProps = useCardHeaderProps(cardContent, cosIsCompleted);
  const [openFormState] = useOpenFormContext();

  const isFormOpen =
    !cosIsCompleted &&
    openFormState?.secondaryJumplink === cardContent.jumplinkRef;

  return (
    <DataCard
      id={`${cos.id}${cardContent.jumplinkRef}`}
      headerProps={headerProps}
      hideHeader={isFormOpen}
    >
      {isFormOpen ? (
        <SkillsForm cos={cos} skillType={skillType} />
      ) : (
        <SkillsView cosSkills={cos[skillType]} />
      )}
    </DataCard>
  );
};

export default SkillsCard;
