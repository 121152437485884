interface ReferralReasons {
  [id: string]: string;
  'development-concerns': string;
  'development-concerns.chet-screening': string;
  'doh-ehddi': string;
}

export const referralReasons: ReferralReasons = {
  'development-concerns': "Concerns about the child's development",
  'development-concerns.chet-screening':
    "Concerns about the child's development identified during the CHET screening process",
  'doh-ehddi': 'Currently served and referred to ESIT by the DOH/EHDDI program',
};
