import { DateUtils } from 'react-day-picker';
import { format, parse, parseISO } from 'date-fns';

const dateDisplayLong = 'MMMM d, yyyy';
export const dateFormatDisplayShort = 'M/d/yyyy';
export const dateFormatData = 'yyyy-MM-dd';

export const formatDateObject = (
  date: Date,
  formatString: string = dateDisplayLong
) => {
  return format(date, formatString);
};

export const formatDateString = (
  dateString: string,
  formatString: string = dateDisplayLong
) => {
  const date = new Date(dateString);
  return formatDateObject(date, formatString);
};

export const ensureFourDigitYear = (dateString: string) => {
  const chunks = dateString.split('/');
  let year = chunks[2];
  if (year) {
    if (year.length === 2) {
      year = `20${year}`;
    }
  } else {
    year = new Date().getFullYear().toString();
  }
  dateString = `${chunks[0]}/${chunks[1]}/${year}`;
  return dateString;
};

export const parseDateFromString = (
  dateString: string,
  formatString: string
) => {
  return parse(dateString, formatString, new Date());
};

export const formatISODate = (
  date?: string,
  formatString: string = dateDisplayLong
) => {
  if (date) {
    const parsed = parseISO(date);
    return format(parsed, formatString);
  }
  return date;
};

export const apiDateToClientDate = (apiDate: string) =>
  parse(apiDate, dateFormatData, new Date());

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const getHumanDateFromISOString = (dateString: string) => {
  if (!dateString || dateString === 'undefined') return '';
  const date = parseISO(dateString);
  return `${
    monthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;
};

export const getTabularDateFromISOString = (dateString: string) => {
  if (!dateString || dateString === 'undefined') return '';
  const date = parseISO(dateString);
  return format(date, dateFormatDisplayShort);
};

const dateFieldInputRegex = /^\d{1,2}\/\d{1,2}(\/\d{2,4})?$/;
export const parseDate_MDYYYY = (dateString: string) => {
  if (!dateFieldInputRegex.test(dateString)) return;

  dateString = ensureFourDigitYear(dateString);
  const parsedDate = parseDateFromString(dateString, dateFormatDisplayShort);
  if (DateUtils.isDate(parsedDate)) {
    return parsedDate;
  }
  return;
};
