import { Button, Tooltip } from '@chakra-ui/react';
import { COS } from '@cssat/acorn-api-shared';
import { Flex } from '@chakra-ui/layout';
import { authenticatedPost, paths } from 'lib';
import { spaceUnitless } from 'theme';
import { useCosByChildId } from '../useCosByChildId';
import { useLatestReferral, useStickyItemsOffset } from 'hooks';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import CosSection from '../CosSection/CosSection';
import JumpLinksSection from '../JumpLinksSection/JumpLinksSection';

const emptyCos = (): COS => ({
  id: v4(),
  isLatestCompletedCos: false,
  sources: [],
  usedDecisionTreeForPrep: {},
  didFamilyParticipateDiscussion: {},
  acquiringSkills: {},
  positiveSkills: {},
  actionsSkills: {},
});

const TabContent = () => {
  const jumpLinksOffset = useStickyItemsOffset();
  const { id: childId } = useParams<{ id: string }>();
  const referral = useLatestReferral(childId);
  const { data, mutate } = useCosByChildId(childId);

  const cosList: COS[] = useMemo(() => {
    // show an empty COS when none have yet been saved
    if (!data || data?.length === 0) {
      return [emptyCos()];
    }
    return data;
  }, [data]);

  const allowNewCos =
    referral?.isActive && cosList.every(cos => cos.dateCompleted);

  const tooltip = allowNewCos
    ? undefined
    : 'Complete in-progress COS before adding a new COS';

  return (
    <Flex width="100%" alignItems="flex-start">
      {data && (
        <JumpLinksSection
          ml="-4"
          mt="-2"
          position="sticky"
          width="220px"
          maxWidth="220px"
          top={jumpLinksOffset + spaceUnitless.one}
          coss={cosList}
        />
      )}
      <Flex flexDir="column" width="100%">
        <Flex justifyContent="flex-end">
          <Tooltip
            label={tooltip}
            aria-label={tooltip}
            // NOTE: shouldWrapChildren lets the tooltip show when button is disabled
            // https://github.com/chakra-ui/chakra-ui/issues/500
            shouldWrapChildren
            placement="left"
          >
            <Button
              data-testid="add-new-cos-button"
              disabled={!allowNewCos}
              marginBottom="4"
              onClick={async () => {
                await authenticatedPost(paths.cos.upsert({ childId }), {
                  body: emptyCos(),
                });
                await mutate();
              }}
            >
              Add new COS
            </Button>
          </Tooltip>
        </Flex>

        {cosList.map(cos => (
          <CosSection cos={cos} key={cos.id} />
        ))}
      </Flex>
    </Flex>
  );
};

export default TabContent;
