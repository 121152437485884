import { ChildOutcome } from '@cssat/acorn-api-shared';
import { authenticatedFetch, authenticatedPost, paths } from 'lib';
import useSWR from 'swr';

const useOutcomes = (childId: string) => {
  return useSWR<ChildOutcome[]>(
    paths.services.getChildOutcomes({ childId }),
    authenticatedFetch
  );
};

export const useMutateChildOutcome = (childId: string) => {
  const { mutate } = useOutcomes(childId);
  return async (outcome: ChildOutcome) => {
    await authenticatedPost(paths.services.saveChildOutcomes({ childId }), {
      body: outcome,
    });
    await mutate();
  };
};

export default useOutcomes;
