import * as yup from 'yup';
import { FC, Fragment, useCallback } from 'react';
import {
  Flex,
  FormControl,
  Heading,
  Input,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { FormSection } from 'components/styled/forms/FormSection';
import { HorizontalDivider } from 'components/styled/presentation';
import { ListItem } from 'components/styled/text';
import {
  ageExpectedSkillsNumberAndDescription,
  ageExpectedSkillsReverseIndices,
} from '../CosSection/helpers';
import useChildRecord from 'pages/ChildRecord/OverviewTab/useChildRecord';

import { COS } from '@cssat/acorn-api-shared';
import {
  RadioGroupField,
  RadioOption,
  RadioPill,
  TextareaField,
} from 'components/formik';
import {
  SkillsSections,
  skillHeaderTitleBuilder,
  skillsFormContentProps,
} from './helpers';
import { adaptSkillsFields, formSchema } from './validation';
import { authenticatedPost, paths } from 'lib';
import { useCosByChildId } from '../useCosByChildId';
import { useParams } from 'react-router-dom';
import ChildSectionFormikWrapper from 'pages/ChildRecord/ChildSectionFormikWrapper/ChildSectionFormikWrapper';
import _ from 'lodash';

interface SkillsFormProps {
  cos: COS;
  skillType: SkillsSections;
}

const SkillsForm: FC<SkillsFormProps> = ({ cos, skillType }) => {
  const { id: childId } = useParams<{ id: string }>();
  const { data } = useChildRecord(childId);
  const childDetails = data?.childDetails;
  const formContent = skillsFormContentProps[skillType];
  const { mutate } = useCosByChildId(childId);

  type FormFields = yup.InferType<typeof formSchema>;

  const adaptApiToForm = (cos: COS, skillType: SkillsSections): FormFields => {
    const skillData = cos[skillType];
    return {
      ...skillData,
      level: skillData.level?.toString() || '',
      summary: skillData.summary || '',
    };
  };

  const formValues = adaptApiToForm(cos, skillType);

  const onSubmit = useCallback(
    async (values: FormFields) => {
      const body = { id: cos.id, [skillType]: adaptSkillsFields(values) };
      await authenticatedPost(paths.cos.upsert({ childId }), { body });
      await mutate();
    },
    [childId, cos.id, mutate, skillType]
  );

  return (
    <ChildSectionFormikWrapper
      formikProps={{
        initialValues: formValues,
        validationSchema: formSchema,
      }}
      apiRequest={onSubmit}
      humanReadableFormName={`Child COS - ${formContent.title}`}
      inlineAlertMessage="There was a problem."
      successToastProps={{
        title: `${_.startCase(formContent.title)} Updated.`,
      }}
      errorToastProps={{
        title: `Sorry, we can't update "${formContent.title}" right now.`,
        description: 'Please check your connection and try again.',
      }}
    >
      {formik => {
        const lowerLevelEntered =
          parseInt(formik.values.level || '') <
          (cos[skillType].prevLevel || NaN);
        return (
          <Flex
            direction="column"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <FormSection>
              <Heading as="h4" variant="tertiary">
                {skillHeaderTitleBuilder(formContent)}
              </Heading>
              <Text textStyle="bodyText" color="gray.600">
                To what extent does this child show age-appropriate functioning,
                across a variety of settings and situations
                {formContent.withOrWhen}
                <Text as="strong">{formContent.title.toLowerCase()}</Text>? This
                includes:
              </Text>
              <UnorderedList my={1} pl={2}>
                {formContent.skillExampleBullets.map(textContent => (
                  <ListItem
                    key={`${formContent.sectionOrderNumber}-example-${textContent}`}
                  >
                    {textContent}
                  </ListItem>
                ))}
              </UnorderedList>
            </FormSection>
            <HorizontalDivider my={4} />
            <FormSection>
              <Heading as="h4" variant="tertiary">
                Descriptor Statements
              </Heading>
              <Text textStyle="bodyText">
                Choose the option that best describes{' '}
                <Text as="strong">
                  how the child functions during everyday routines and
                  activities related to {formContent.title.toLowerCase()}
                </Text>
                {''} as indicated by assessments and based on observations from
                individuals in close contact with the child.{' '}
                <Text color="gray.400" as="span">
                  (choose only one)
                </Text>
              </Text>
              <RadioGroupField name="level" label="Age expected skills">
                {ageExpectedSkillsReverseIndices.map(level => (
                  <Fragment
                    key={`${
                      formContent.sectionOrderNumber
                    }-agelevel-${level.toString()}`}
                  >
                    <RadioOption
                      value={level.toString()}
                      label={ageExpectedSkillsNumberAndDescription(
                        childDetails!.firstName,
                        level
                      )}
                    />
                    {level === 6 && (
                      <Text textStyle="bodyText" mb={3}>
                        Primarily immediate foundational skills with increasing
                        degree of age expected skills
                      </Text>
                    )}
                    {level === 4 && (
                      <Text textStyle="bodyText" mb={3}>
                        Primarily foundational skills with increasing immediate
                        foundational skills
                      </Text>
                    )}
                  </Fragment>
                ))}
              </RadioGroupField>
            </FormSection>
            {lowerLevelEntered && (
              <>
                <HorizontalDivider my={4} />
                <FormSection>
                  <Heading as="h4" variant="tertiary">
                    Improvement within lower skill level
                  </Heading>
                  <Text textStyle="bodyText" mb="20px">
                    The {formContent.title.toLowerCase()} level you just entered
                    is lower than the {formContent.title.toLowerCase()} level
                    you entered for the most recent, previous COS.
                  </Text>
                  <RadioGroupField
                    required
                    name="hasShownImprovement"
                    label={`Has ${
                      childDetails!.firstName
                    } shown any new skills or behaviors related to
                        ${formContent.title.toLowerCase()}
                        since the last outcomes summary?`}
                    direction="horizontal"
                  >
                    <RadioPill value="true" label="Yes" />
                    <RadioPill value="false" label="No" />
                    {formik.values.hasShownImprovement === 'true' && (
                      <TextareaField
                        required
                        name="hasShownImprovementNotes"
                        label="Please specify new skills or behaviors related to positive social-emotional skills since the last outcomes summary"
                      />
                    )}
                  </RadioGroupField>
                </FormSection>
              </>
            )}
            <HorizontalDivider my={4} />
            <FormSection>
              <Heading as="h4" variant="tertiary">
                Summary of functional performance
              </Heading>
              <Text textStyle="bodyText">
                Summarize how the child functions with{' '}
                <Text as="strong">{formContent.title.toLowerCase()}</Text> as
                indicated by assessments and based on observations from
                individuals in close contact with the child.
              </Text>
              <Text textStyle="bodyText" as="p">
                This summary must support the Descriptor Statement selected and
                include:
              </Text>
              <UnorderedList my={1} pl={2}>
                {formContent.summaryBullets.map(textContent => (
                  <ListItem
                    key={`${formContent.sectionOrderNumber}-summary-${textContent}`}
                  >
                    {textContent}
                  </ListItem>
                ))}
              </UnorderedList>

              <TextareaField
                height={200}
                borderRadius="6px"
                name="summary"
                label="Summary of functional performance"
                data-testid="cos-skills-summary-textarea"
              />
              <FormControl id="id">
                <Input hidden={true} value={cos.id} readOnly />
              </FormControl>
            </FormSection>
          </Flex>
        );
      }}
    </ChildSectionFormikWrapper>
  );
};

export default SkillsForm;
