import * as yup from 'yup';
import {
  Intensity,
  Setting,
  Status,
} from '@cssat/acorn-api-shared/dist/service-delivery';
import { dateFormatErrorString, parseDate_MDYYYY } from 'lib';
import { startOfToday, subYears } from 'date-fns';

/*
 * Missed Delivery Reason values supported by UI
 */
export enum MissedDeliveryReason {
  ExtenuatingFamilyCircumstances = 'extenuatingFamilyCircumstances',
  ProviderSick = 'providerSick',
  ProviderNotAvailable = 'providerNotAvailable',
  AgencyDelay = 'agencyDelay',
  Other = 'other',
}

/*
 * EFC values used in form UI
 */

export enum ExtenuatingFamilyCircumstance {
  LossOfContact = 'lossOfContact',
  FamilyNoShow = 'familyNoShow',
  FamilyCancelled = 'familyCancelled',
}

export const TrackSessionSchema = yup
  .object({
    child: yup.string().default('').required("Child's name is required"),

    service: yup.string().default('').required('Service is required'),

    duration: yup
      .number()
      .when('serviceStatus', {
        is: Status.AsPlanned,
        then: yup.number(),
      })
      .when('serviceStatus', {
        is: Status.WithChanges,
        then: yup.number().when('whatChanged', {
          is: whenChanged => whenChanged.includes('duration'),
          then: yup.number().required('Select a length'),
          otherwise: yup.number().notRequired().default(undefined),
        }),
      }),

    intensity: yup
      .string()
      .oneOf(Object.values(Intensity))
      .when('serviceStatus', {
        is: Status.AsPlanned,
        then: yup.string(),
      })
      .when('serviceStatus', {
        is: Status.WithChanges,
        then: yup.string().when('whatChanged', {
          is: whenChanged => whenChanged.includes('intensity'),
          then: yup.string().required('Select a type'),
          otherwise: yup.string().notRequired().default(undefined),
        }),
      }),

    setting: yup
      .string()
      .oneOf(Object.values(Setting))
      .when('serviceStatus', {
        is: Status.AsPlanned,
        then: yup.string(),
      })
      .when('serviceStatus', {
        is: Status.WithChanges,
        then: yup.string().when('whatChanged', {
          is: whenChanged => whenChanged.includes('setting'),
          then: yup.string().required('Select a setting'),
          otherwise: yup.string().notRequired().default(undefined),
        }),
      }),

    whatChanged: yup
      .array()
      .of(yup.string())
      .default([])
      .when('serviceStatus', {
        is: Status.WithChanges,
        then: yup.array().required('Please select at least one'),
      })
      .when('serviceStatus', {
        is: Status.NotDelivered,
        then: yup.array().default(undefined),
      })
      .when('serviceStatus', {
        is: Status.AsPlanned,
        then: yup.array().default(undefined),
      }),

    serviceStatus: yup
      .string()
      .oneOf(Object.values(Status))
      .default(undefined)
      .required('Service status is required'),

    dateOfService: yup.date().when('serviceStatus', {
      is: serviceStatus =>
        [Status.AsPlanned, Status.WithChanges].includes(serviceStatus),
      then: yup
        .date()
        .transform((value, originalValue) => {
          return parseDate_MDYYYY(originalValue) || originalValue;
        })
        .max(startOfToday(), 'Date of service cannot be in the future')
        .min(
          subYears(startOfToday(), 3),
          'Date of service cannot be more than 3 years ago'
        )
        .typeError(dateFormatErrorString)
        .required('Date of service is required'),
    }),

    scheduledAppointmentDate: yup.date().when('serviceStatus', {
      is: Status.NotDelivered,
      then: yup
        .date()
        .transform((value, originalValue) => {
          return parseDate_MDYYYY(originalValue) || originalValue;
        })
        .max(startOfToday(), 'Scheduled service date cannot be in the future')
        .min(
          subYears(startOfToday(), 3),
          'Scheduled service date cannot be more than 3 years ago'
        )
        .typeError(dateFormatErrorString)
        .required('Scheduled service date is required'),
    }),

    reasonForMissedDelivery: yup
      .string()
      .oneOf(Object.values(MissedDeliveryReason))
      .when('serviceStatus', {
        is: Status.NotDelivered,
        then: yup
          .string()
          .default(undefined)
          .required('Reason for Missed Delivery is required'),
      }),

    reasonForMissedDeliveryNote: yup
      .string()
      .default('')
      .when('reasonForMissedDelivery', {
        is: MissedDeliveryReason.Other,
        then: yup
          .string()
          .required(
            'Note is required when reason for missed delivery is Other'
          ),
      }),

    extenuatingFamilyCircumstance: yup
      .string()
      .oneOf(Object.values(ExtenuatingFamilyCircumstance))
      .when('reasonForMissedDelivery', {
        is: MissedDeliveryReason.ExtenuatingFamilyCircumstances,
        then: yup
          .string()
          .default(undefined)
          .required('Exceptional family circumstances is required'),
      }),

    note: yup.string().default('').trim(),
  })
  .defined();

export type FormFields = yup.InferType<typeof TrackSessionSchema>;

export const initialValues: FormFields = {
  ...TrackSessionSchema.default(),
};
