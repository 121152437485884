import * as yup from 'yup';
import { parseDate_MDYYYY, toSentenceCase } from 'lib';
import some from 'lodash.some';

export const yupZipTestValidator = {
  name: 'zip code length when exists',
  test: (zip: any) =>
    zip?.length && !zip.match(/^(\d{5}|\d{5}-\d{4})$/) ? false : true,
  message:
    'ZIP code should be a valid five digit ZIP or hyphenated nine digit ZIP+4',
};

export const yupPhoneTestValidator = {
  name: 'phone length when exists',
  test: (phone: any) => (phone?.length && phone.length < 10 ? false : true),
  message: 'Phone number should be at least 10 characters',
};

export const yupRequiredBoolean = (defaultValue = false) =>
  yup.boolean().default(defaultValue).required();

export const yupRequiredUuid = () => yup.string().uuid().required();

export const yupNullableString = (max = 500) =>
  yup.string().max(max).nullable().trim().notRequired();

export const yupOptionalString = (max = 500) =>
  yup.string().max(max).trim().notRequired();

export const yupNullableLongString = () =>
  yup.string().nullable().trim().notRequired();

export const yupOptionalLongString = () => yup.string().trim().notRequired();

export const yupRequiredString = (
  errorMsg = 'This field is required',
  defaultValue?: string
) => yup.string().trim().default(defaultValue).required(errorMsg);

export const yupRequiredDate = (errorMsg = '') =>
  yup
    .date()
    .transform((value, originalValue) => {
      return parseDate_MDYYYY(originalValue) || originalValue;
    })
    .required(errorMsg);

export const yupOptionalDate = () =>
  yup.date().transform((value, originalValue) => {
    return parseDate_MDYYYY(originalValue) || originalValue;
  });

export const yupRequiredWhenPreferredContact = (
  method: string,
  label: string
) =>
  yup
    .string()
    .trim()
    .when('preferredContactMethod', {
      is: method,
      then: yup
        .string()
        .required(
          `${toSentenceCase(
            label
          )} is required when it is the preferred contact method`
        ),
      otherwise: yupNullableString(),
    });

export const yupRequireAddressWhenPreferredContact = (
  currentFieldAddressType = ''
) => {
  return yup
    .string()
    .trim()
    .when('preferredContactMethod', {
      is: 'mailing-address',
      then: yup.string().when('isAddressSame', {
        is: true,
        then: yup.string().when(
          [
            'physicalAddress1',
            'physicalAddress2',
            'physicalCity',
            'physicalState',
            'physicalZip',
          ].filter(field => field !== `physical${currentFieldAddressType}`),
          {
            is: (...args) => some(args, value => Boolean(value)),
            then: yupOptionalString(),
            otherwise: yup
              .string()
              .required(
                'At least one address field is required when mailing address is selected as preferred contact method'
              ),
          }
        ),
        otherwise: yup.string().when(
          [
            'mailingAddress1',
            'mailingAddress2',
            'mailingCity',
            'mailingState',
            'mailingZip',
          ].filter(field => field !== `mailing${currentFieldAddressType}`),
          {
            is: (...args) => some(args, value => Boolean(value)),
            then: yupOptionalString(),
            otherwise: yup
              .string()
              .required(
                'At least one address field is required when mailing address is selected as preferred contact method'
              ),
          }
        ),
      }),
      otherwise: yupOptionalString(),
    });
};
