import { ChildListResult } from '@cssat/acorn-api-shared';
import {
  SortParameter,
  authenticatedFetch,
  authenticatedPost,
  paths,
} from 'lib';
import { useChildTableContext, useUserContext } from 'contexts';
import useSWR from 'swr';

const mapSortToRequest = (
  column: string,
  direction: string
): SortParameter[] => {
  if (column === 'name') {
    return [
      { sortBy: 'lastName', sortDirection: direction },
      { sortBy: 'firstName', sortDirection: direction },
    ];
  }
  return [{ sortBy: column, sortDirection: direction }];
};

const useChildList = () => {
  const [config] = useChildTableContext();
  const { user } = useUserContext();
  const agency = user?.organizations[0].id ?? '';

  return useSWR<ChildListResult[]>(
    paths.child.list({
      agency,
      sort: mapSortToRequest(config.sortColumn, config.sortDirection),
    }),
    authenticatedFetch,
    {
      refreshInterval: 10000,
    }
  );
};

export const useAssignFRCToChild =
  () => async (childId: string, assignedUserId: string) => {
    await authenticatedPost(paths.child.assignFRC({ childId }), {
      body: { assignedUser: assignedUserId },
    });
  };

export default useChildList;
