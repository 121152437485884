import { FC, createContext, useCallback, useContext, useState } from 'react';
import { JumpLinkTabIndexes } from 'molecules/JumpLinks';
import { scrollToTop } from 'lib';
import { useHistory, useLocation } from 'react-router-dom';

type TabIndexContextState = {
  tabIndex: number;
  setTabIndex: (index: number) => void;
  handleTabIndexChange: (index: number) => void;
};

type JumpLinkKeys = keyof typeof JumpLinkTabIndexes;

const TabIndexContext = createContext<TabIndexContextState>({
  tabIndex: 0,
  setTabIndex: () => {},
  handleTabIndexChange: () => {},
});

const useTabIndexContext = () => useContext(TabIndexContext);

const useTabIndexFromHash = () => {
  const { hash } = useLocation();
  const myHash: JumpLinkKeys = hash.replace('#', '') as JumpLinkKeys;

  return JumpLinkTabIndexes[myHash] || 0;
};

const TabIndexContextProvider: FC = ({ children }) => {
  const [tabIndex, setTabIndex] = useState(useTabIndexFromHash());
  const { push, location } = useHistory();

  const handleTabIndexChange = useCallback(
    (index: number) => {
      setTabIndex(index);
      push(location.pathname);
      scrollToTop();
    },
    [push, location.pathname]
  );

  return (
    <TabIndexContext.Provider
      value={{ tabIndex, handleTabIndexChange, setTabIndex }}
    >
      {children}
    </TabIndexContext.Provider>
  );
};

export { TabIndexContextProvider, useTabIndexContext };
