import { ChildRecord } from '@cssat/acorn-api-shared';
import { authenticatedFetch, authenticatedPost, paths } from 'lib';
import useSWR from 'swr';

export type UpdateChildDetailsRequest = Omit<
  ChildRecord['childDetails'],
  'id' | 'createdAt' | 'updatedAt' | 'assignedAgency' | 'assignedUser'
>;

export type UpdatePrimaryCareProviderRequest = Omit<
  ChildRecord['primaryCareProvider'],
  'id' | 'createdAt' | 'updatedAt'
>;

const useChildRecord = (childId: string) => {
  return useSWR<ChildRecord>(paths.child.show({ childId }), authenticatedFetch);
};

export const useMutateChildDetails = (childId: string) => {
  const { mutate } = useChildRecord(childId);

  return async (childDetails: UpdateChildDetailsRequest) => {
    await authenticatedPost(paths.child.updateDetails({ childId }), {
      body: childDetails,
    });
    await mutate();
  };
};

export const useMutatePrimaryCareProvider = (childId: string) => {
  const { mutate } = useChildRecord(childId);

  return async (newPrimaryCareProvider: UpdatePrimaryCareProviderRequest) => {
    await authenticatedPost(
      paths.child.updatePrimaryCareProvider({ childId }),
      {
        body: newPrimaryCareProvider,
      }
    );
    await mutate();
  };
};

export default useChildRecord;
