import * as customTheme from 'theme';
import { Center, Spinner } from '@chakra-ui/react';
import { FC } from 'react';

const AppSpinner: FC = () => {
  const { space } = customTheme;

  return (
    <Center width="100%" height="100vh">
      <Spinner
        label="Loading..."
        thickness={space.quarter}
        speed="1.25s"
        emptyColor="gray.100"
        color="teal.700"
        size="xl"
      />
    </Center>
  );
};

export default AppSpinner;
