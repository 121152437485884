import { ReactComponent as AddIcon } from 'icons/new.svg';
import {
  DataCard,
  FormIconButton,
  JumpLinkRefIds,
  SectionHeader,
  SectionWrapper,
} from 'molecules';

import { Tabs, useChildOutcomeIdContext, useOpenFormContext } from 'contexts';
import { useLatestReferral } from 'hooks';
import { useParams } from 'react-router-dom';
import ChildOutcomesCardList from './ChildOutcomesCardList';
import ChildOutcomesForm from '../ChildOutcomesForm/ChildOutcomesForm';

const ADD_FORM_ID = 'ADD_FORM_ID';

const ChildOutcomesSection = () => {
  const { id: childId } = useParams<{ id: string }>();
  const [openFormState, setOpenFormState] = useOpenFormContext();
  const [childOutcomeFormId, setChildOutcomeFormId] =
    useChildOutcomeIdContext();
  const referral = useLatestReferral(childId);
  const isActive = referral?.isActive;

  const isFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.ChildOutcomes;

  return (
    <SectionWrapper id={JumpLinkRefIds.ChildOutcomes} mb={12}>
      <SectionHeader
        isFormOpen={isFormOpen}
        headerText="Child and family outcomes"
        iconButton={
          <FormIconButton
            onClick={() => {
              if (openFormState) {
                return;
              }

              setOpenFormState({
                tab: Tabs.Services,
                primaryJumpLink: JumpLinkRefIds.ChildOutcomes,
              });

              setChildOutcomeFormId(ADD_FORM_ID);
            }}
            refId={JumpLinkRefIds.ChildOutcomes}
            aria-label={'Add'}
            Icon={AddIcon}
            tooltipProps={{ label: 'Add outcome' }}
            disabled={!isActive}
          />
        }
      />
      {isFormOpen && childOutcomeFormId === ADD_FORM_ID && (
        <DataCard>
          <ChildOutcomesForm />
        </DataCard>
      )}
      <ChildOutcomesCardList isFormOpen={isFormOpen} />
    </SectionWrapper>
  );
};

export default ChildOutcomesSection;
