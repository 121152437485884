export const numberToRepetition = (num: number): string => {
  switch (num) {
    case 1:
      return 'once';
    case 2:
      return 'twice';
    default:
      return `${num} times`;
  }
};
