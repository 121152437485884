import { Collapse } from '@chakra-ui/react';
import { useAssessmentsArchiveEligibilityContext } from 'contexts';
import EligibilityCard from './EligibilityCard';
import useEligibilityDecisions from './useEligibilityDecisions';

const EvaluationsCardList = () => {
  const { activeDecisions, inactiveDecisions } = useEligibilityDecisions();
  const [showArchived] = useAssessmentsArchiveEligibilityContext();

  return (
    <>
      <Collapse in={showArchived}>
        {inactiveDecisions.map(eligibilityDecision => (
          <EligibilityCard
            key={eligibilityDecision.id}
            eligibilityDecision={eligibilityDecision}
          />
        ))}{' '}
      </Collapse>
      {activeDecisions.map(eligibilityDecision => (
        <EligibilityCard
          key={eligibilityDecision.id}
          eligibilityDecision={eligibilityDecision}
        />
      ))}
    </>
  );
};

export default EvaluationsCardList;
