import { JumpLinkRefIds, SectionHeader, SectionWrapper } from 'molecules';
import CardSkeleton from 'pages/ChildRecord/CardSkeleton/CardSkeleton';

import { useOpenFormContext } from 'contexts';
import { useParams } from 'react-router-dom';
import AddNewDiagnosisFormContainer from './AddNewDiagnosisFormContainer';
import MedicalDiagnosesList from './MedicalDiagnosesList';
import useDiagnoses from '../useDiagnoses';
import useIconButton from './useIconButton';

const MedicalDiagnosesSection = () => {
  const [openFormState] = useOpenFormContext();
  const { id: childId } = useParams<{ id: string }>();
  const { data: diagnoses } = useDiagnoses(childId);
  const loaded = !!diagnoses;

  const isFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.MedicalDiagnoses;

  return (
    <SectionWrapper id={JumpLinkRefIds.MedicalDiagnoses} mb={12}>
      <SectionHeader
        headerText="Medical diagnoses"
        isFormOpen={isFormOpen}
        iconButton={useIconButton()}
      />
      <AddNewDiagnosisFormContainer />
      {loaded ? <MedicalDiagnosesList /> : <CardSkeleton />}
    </SectionWrapper>
  );
};

export default MedicalDiagnosesSection;
