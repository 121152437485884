import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  LinkBox,
  LinkOverlay,
  Text,
} from '@chakra-ui/react';
import { ReactComponent as DCYFLogo } from 'icons/dcyf-logo-splash-login.svg';
import { DevControlSignInByUserId } from './DevControlSignInByUserId';
import { FC } from 'react';
import { ReactComponent as SignInIcon } from 'icons/sign-in.svg';
import { paths } from 'lib';
import { space } from 'theme';
import DevControl from 'components/DevControl';

const desktopBrandHeight = '52px';
const mobileBrandHeight = '40px';

const SignIn: FC = () => {
  return (
    <Flex flexDirection={['column', 'column', 'row']} height="100vh">
      <Flex as="header" backgroundColor="teal.700" flexGrow={[0, 0, 1]}>
        <Flex
          alignItems="center"
          justifyContent={['center', 'center', 'flex-end']}
          flexGrow={1}
          px={[space.one, space.one, 0]}
          py={[space.two, space.two, 0]}
          ml={[0, 0, space.two]}
          mr={[0, 0, space.three]}
        >
          <Flex flexDirection={['column', 'column', 'row']}>
            <Heading
              as="h1"
              textAlign={['center', 'center', 'start']}
              fontSize={[
                mobileBrandHeight,
                mobileBrandHeight,
                desktopBrandHeight,
              ]}
              lineHeight={[
                mobileBrandHeight,
                mobileBrandHeight,
                desktopBrandHeight,
              ]}
              sx={{
                color: 'white',
                whiteSpace: 'nowrap',
              }}
            >
              ACORN
            </Heading>
            <Box
              display={['none', 'none', 'block']}
              width="1px"
              mx={space.two}
              height={desktopBrandHeight}
              backgroundColor="white"
            />
            <Box
              as={DCYFLogo}
              width={['100%', '100%', 'auto']}
              height={['auto', 'auto', desktopBrandHeight]}
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex
        as="main"
        flexGrow={1}
        justifyContent={['center', 'center', 'flex-start']}
        alignItems={['flex-start', 'flex-start', 'center']}
        display={['block', 'block', 'flex']}
        backgroundColor={['white', 'white', 'teal.700']}
      >
        <Box
          px={[space.one, space.one, space.two]}
          py={[space.one, space.one, space.six]}
          mr={[space.one, space.one, space.two]}
          ml={[space.one, space.one, space.three]}
          my={[space.two, space.two, 0]}
          sx={{
            borderRadius: 'lg',
            borderWidth: 1,
            borderColor: ['gray.400', 'gray.400', 'white'],
            borderStyle: 'solid',
          }}
          backgroundColor="white"
        >
          <Heading as="h2" variant="tertiary">
            Welcome to Acorn!
          </Heading>

          <Text as="span" mt={space.one} variant="bodyText">
            Click the Sign In link below to be redirected to an external login
            provider.
          </Text>
          <LinkBox marginTop={8}>
            <LinkOverlay href={paths.auth.login()}>
              <Button
                width={['full', 'full', 'auto']}
                leftIcon={<SignInIcon />}
              >
                Sign in
              </Button>
            </LinkOverlay>
          </LinkBox>
          <DevControl>
            <LinkBox marginTop={8}>
              <LinkOverlay href={paths.controls.loginAsNewFrc()}>
                <Button
                  size="sm"
                  borderRadius="6px"
                  colorScheme="blackAlpha"
                  width={['full', 'full', 'auto']}
                >
                  Create new FRC and Sign In
                </Button>
              </LinkOverlay>
            </LinkBox>
            <Container
              marginTop={8}
              borderRadius="6px"
              border="1px solid"
              borderColor="gray.400"
              padding={[8, 4]}
            >
              <DevControlSignInByUserId />
            </Container>
          </DevControl>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SignIn;
