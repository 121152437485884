import { Diagnosis } from '@cssat/acorn-api-shared';
import { authenticatedFetch, authenticatedPost, paths } from 'lib';
import useSWR from 'swr';

const useDiagnoses = (childId: string) => {
  return useSWR<Diagnosis[]>(
    paths.child.getDiagnoses({ childId }),
    authenticatedFetch
  );
};

export const useMutateDiagnosis = (childId: string) => {
  const { mutate } = useDiagnoses(childId);

  return async (diagnosis: Diagnosis) => {
    await authenticatedPost(paths.child.upsertDiagnosis({ childId }), {
      body: diagnosis,
    });
    await mutate();
  };
};

export default useDiagnoses;
