import { Collapse } from '@chakra-ui/react';
import { FC } from 'react';
import { useChildArchiveContactsContext } from 'contexts';
import { useParams } from 'react-router-dom';
import ChildContactCard from '../ChildContactCard/ChildContactCard';
import useChildContacts from '../useChildContacts';

const ChildContactCardList: FC = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { activeContacts, inactiveContacts } = useChildContacts(childId);
  const [showArchived] = useChildArchiveContactsContext();

  return (
    <>
      <Collapse in={showArchived}>
        {inactiveContacts.map(contact => (
          <ChildContactCard key={contact.id} contact={contact} />
        ))}
      </Collapse>
      {activeContacts.map(contact => (
        <ChildContactCard key={contact.id} contact={contact} />
      ))}
    </>
  );
};

export default ChildContactCardList;
