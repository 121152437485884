import { DataCard, JumpLinkRefIds } from 'molecules';
import { useOpenFormContext } from 'contexts';
import { useParams } from 'react-router-dom';
import CardSkeleton from 'pages/ChildRecord/CardSkeleton/CardSkeleton';
import InlineAlert from 'components/alerts/InlineAlert';
import TransitionConferenceForm from '../TransitionConferenceForm/TransitionConferenceForm';
import TransitionConferenceView from './TransitionConferenceView';
import useCardHeaderProps from './useCardHeaderProps';
import useTransition from '../useTransition';

const TransitionConferenceCard = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { error, data } = useTransition(childId);
  const [openFormState] = useOpenFormContext();
  const headerProps = useCardHeaderProps();

  const isFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.TransitionConference;

  return (
    <>
      {error && (
        <InlineAlert
          marginTop={4}
          status="warning"
          title="Hang on! We can't load this section right now."
          description="We'll keep trying."
        />
      )}
      {data ? (
        <DataCard headerProps={headerProps}>
          {isFormOpen ? (
            <TransitionConferenceForm />
          ) : (
            <TransitionConferenceView />
          )}
        </DataCard>
      ) : (
        <CardSkeleton />
      )}
    </>
  );
};

export default TransitionConferenceCard;
