import { COS } from '@cssat/acorn-api-shared';
import { dateFormatData, parseDateFromString } from 'lib';

const adaptApiToForm = (cos: COS) => {
  return {
    ...cos,
    dateStarted: cos.dateStarted
      ? parseDateFromString(cos.dateStarted, dateFormatData)
      : undefined,
    dateCompleted: cos.dateCompleted
      ? parseDateFromString(cos.dateCompleted, dateFormatData)
      : undefined,
    usedDecisionTreeForPrep:
      cos.usedDecisionTreeForPrep?.confirmationAnswer?.toString(),
    usedDecisionTreeForPrepNotes: cos.usedDecisionTreeForPrep?.notes,

    didFamilyParticipateDiscussion:
      cos.didFamilyParticipateDiscussion?.confirmationAnswer?.toString(),
    didFamilyParticipateDiscussionNotes:
      cos.didFamilyParticipateDiscussion?.notes,

    didFamilyParticipateSelection:
      cos.didFamilyParticipateSelection?.confirmationAnswer?.toString(),
    didFamilyParticipateSelectionNotes:
      cos.didFamilyParticipateSelection?.notes,
    usedESITDecisionTree:
      cos.usedESITDecisionTree?.confirmationAnswer?.toString(),
    usedESITDecisionTreeNotes: cos.usedESITDecisionTree?.notes,
    sources: cos.sources,
  };
};

export default adaptApiToForm;
