import { FC } from 'react';
import { useParams } from 'react-router-dom';
import NoneYetPlaceholder from 'components/NoneYetPlaceholder';
import TeamMemberCard from './TeamMemberCard/TeamMemberCard';
import useTeamMembers from './useTeamMembers';

const TeamMembersCardList: FC<{ isFormOpen: boolean }> = ({ isFormOpen }) => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: teamMembers } = useTeamMembers(childId);

  if (!teamMembers) return null;

  const noTeamMembersPlaceholder = isFormOpen ? null : (
    <NoneYetPlaceholder message="No team members added yet" />
  );

  return (
    <>
      {teamMembers.length > 0
        ? teamMembers.map(teamMember => (
            <TeamMemberCard key={teamMember.id} teamMember={teamMember} />
          ))
        : noTeamMembersPlaceholder}
    </>
  );
};

export default TeamMembersCardList;
