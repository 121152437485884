import {
  AssessmentsArchiveEligibilityContextProvider,
  EvaluationsArchiveContextProvider,
} from 'contexts';
import { Flex } from '@chakra-ui/react';
import { TeamMemberIdContextProvider } from 'contexts';
import { spaceUnitless } from 'theme';
import { useStickyItemsOffset } from 'hooks';
import EligibilitySection from '../EligibilitySection/EligibilitySection';
import EvaluationConsentFormsSection from '../EvaluationConsentFormsSection/EvaluationConsentFormsSection';
import EvaluationsSection from '../EvaluationsSection/EvaluationsSection';
import FamilyDirectedAssessmentsSection from '../FamilyDirectedAssessmentsSection/FamilyDirectedAssessmentsSection';
import JumpLinksSection from '../JumpLinksSection/JumpLinksSection';
import MedicalDiagnosesSection from '../MedicalDiagnosesSection/MedicalDiagnosesSection';
import TeamMembersSection from '../TeamMembersSection/TeamMembersSection';

const TabContent = () => {
  const jumpLinksOffset = useStickyItemsOffset();

  return (
    <AssessmentsArchiveEligibilityContextProvider>
      <Flex width="100%" alignItems="flex-start">
        <JumpLinksSection
          ml="-4"
          mt="-2"
          position="sticky"
          width="220px"
          maxWidth="220px"
          top={jumpLinksOffset + spaceUnitless.one}
        />
        <Flex flexDir="column" width="100%">
          <EvaluationConsentFormsSection />
          <TeamMemberIdContextProvider>
            <TeamMembersSection />
          </TeamMemberIdContextProvider>
          <FamilyDirectedAssessmentsSection />
          <MedicalDiagnosesSection />
          <EvaluationsArchiveContextProvider>
            <EvaluationsSection />
          </EvaluationsArchiveContextProvider>
          <EligibilitySection />
        </Flex>
      </Flex>
    </AssessmentsArchiveEligibilityContextProvider>
  );
};

export default TabContent;
