import {
  Box,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  Text,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { forms } from 'theme';
import { useField } from 'formik';

type CheckboxProps = ChakraCheckboxProps & {
  label: string | ReactNode;
  name: string;
  value: string;
};

export const Checkbox: FC<CheckboxProps> = ({
  label,
  name,
  value,
  ...props
}) => {
  const [field, , helper] = useField(name);

  const isSelected = () => {
    if (field.value === undefined || field.value === null) {
      return false;
    }
    if (typeof field.value === 'string') {
      return field.value === value;
    }
    if (typeof field.value === 'boolean') {
      return field.value.toString() === value;
    }
    if (field.value.includes) {
      return !!field.value?.includes(value);
    }
    return false;
  };

  const labelVariant = isSelected()
    ? forms.checkbox.checkedLabel
    : forms.checkbox.uncheckedLabel;

  return (
    <Box variant="checkbox" {...props}>
      <ChakraCheckbox
        {...field}
        onChange={e => {
          field.onChange(e);
          helper.setTouched(true, false);
        }}
        colorScheme="teal"
        sx={{
          polyline: { color: 'white' },
          // this aligns all checkbox inputs inline with
          // first line of label text
          alignItems: 'flex-start',
          'span:first-of-type': {
            marginTop: 1,
          },
        }}
        name={name}
        data-testid={name}
        value={value}
        isChecked={isSelected()}
      >
        <Text as="span" textStyle="bodyText" sx={{ ...labelVariant }}>
          {label}
        </Text>
      </ChakraCheckbox>
    </Box>
  );
};
