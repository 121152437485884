import {
  AddressControlMap,
  PartialAddressControlMap,
  defaultPhysicalAddressControls,
} from './constants';
import { FC } from 'react';
import { FormSection } from 'components/styled/forms/FormSection';
import { TextField } from 'components/formik';

interface AddressSectionProps {
  readonly fieldOverrides?: PartialAddressControlMap;
}

const mergeFieldOverrides = (fieldOverrides: PartialAddressControlMap) => {
  const mergedOverrides: PartialAddressControlMap = {};

  for (const key in defaultPhysicalAddressControls) {
    let castedKey = key as keyof AddressControlMap;
    mergedOverrides[castedKey] = {
      ...defaultPhysicalAddressControls[castedKey],
      ...fieldOverrides[castedKey],
    };
  }

  return mergedOverrides as AddressControlMap;
};

const AddressFormSection: FC<AddressSectionProps> = ({ fieldOverrides }) => {
  const { address1, address2, city, state, zip } = mergeFieldOverrides(
    fieldOverrides ?? {}
  );

  return (
    <FormSection>
      {address1.isVisible && (
        <TextField
          name={address1.name}
          label={address1.label}
          required={address1.required}
        />
      )}
      {address2.isVisible && (
        <TextField
          name={address2.name}
          label={address2.label}
          required={address2.required}
        />
      )}
      {city.isVisible && (
        <TextField
          name={city.name}
          label={city.label}
          required={city.required}
        />
      )}
      {state.isVisible && (
        <TextField
          name={state.name}
          label={state.label}
          required={state.required}
        />
      )}
      {zip.isVisible && (
        <TextField name={zip.name} label={zip.label} required={zip.required} />
      )}
    </FormSection>
  );
};

export default AddressFormSection;
