import { ReactComponent as AddIcon } from 'icons/add.svg';
import { Box, Button } from '@chakra-ui/react';
import { DateField, TextareaField } from 'components/formik';
import { FC, useState } from 'react';
import { space } from 'theme';
import { startOfToday, subYears } from 'date-fns';

export const SectionOption03AsPlanned: FC = () => {
  const [noteAdded, setNoteAdded] = useState<boolean>(false);

  return (
    <>
      <DateField
        name="dateOfService"
        label="Date delivered"
        required
        disabledDays={{
          after: startOfToday(),
          before: subYears(startOfToday(), 3),
        }}
      />

      {noteAdded ? (
        <Box mt={space.two}>
          <TextareaField
            autoFocus={true}
            name="note"
            label="Note"
            data-testid="track-session-notes"
            onBlur={e => {
              if (e.target.value.trim() === '') {
                setNoteAdded(false);
              }
            }}
          />
        </Box>
      ) : (
        <Button
          marginTop={4}
          variant="ghost"
          leftIcon={<AddIcon />}
          onClick={() => setNoteAdded(true)}
        >
          Add note (optional)
        </Button>
      )}
    </>
  );
};
