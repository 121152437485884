import { DirtyFormsContext, DirtyFormsState } from 'contexts';
import { FC } from 'react';
import { useDirtyFormsContext } from 'hooks';

export const DirtyFormsProvider: FC<{
  initialState?: DirtyFormsState;
}> = ({ initialState, children }) => {
  const { hasDirtyForms, maybeDirtyFormsCollection, dispatch } =
    useDirtyFormsContext(initialState);

  return (
    <DirtyFormsContext.Provider
      value={{ hasDirtyForms, maybeDirtyFormsCollection, dispatch }}
    >
      {children}
    </DirtyFormsContext.Provider>
  );
};
