import {
  ButtonGroup as ChakraButtonGroup,
  ButtonProps as ChakraButtonProps,
  Stack,
} from '@chakra-ui/react';
import { FC } from 'react';

export const ButtonGroup: FC<ChakraButtonProps> = ({ children }) => (
  <ChakraButtonGroup display="flex" flexGrow={1} spacing={0}>
    <Stack
      display="flex"
      flexGrow={1}
      direction={['column', 'column', 'row']}
      justifyContent={['flex-start', 'flex-start', 'flex-end']}
      spacing={4}
    >
      {children}
    </Stack>
  </ChakraButtonGroup>
);
