import * as yup from 'yup';
import { Alert } from 'molecules';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  Checkbox,
  DateField,
  RadioGroupField,
  RadioPill,
  TextareaField,
} from 'components/formik';
import { DetailSectionDivider } from 'components/styled/forms/DetailSection';
import { DispatchMaybeDirtyForm } from 'components/WarnUnsavedChanges/DispatchMaybeDirtyForm';
import { FormSection } from 'components/styled/forms/FormSection';
import { Formik } from 'formik';
import { SaveAndCancel } from 'components/styled/forms/SaveAndCancel';
import { startOfToday } from 'date-fns';
import { useCallback, useState } from 'react';
import { useOpenFormContext } from 'contexts';
import { useParams } from 'react-router-dom';
import { usePopupToast } from 'hooks';
import { useQualifyingDiagnosisTypes } from '../../MedicalDiagnosesSection/useKnownMedicalDiagnosisTypes';
import { useValidation } from './useValidation';
import EvaluatedDomainsForm from './EvaluatedDomainsForm';
import InlineAlert from 'components/alerts/InlineAlert';
import QualifyingIconLabel from '../../qualifyingIconLabel';
import useDiagnoses from 'pages/ChildRecord/EvaluationsTab/useDiagnoses';

const mockSave = async (values: any) => await Promise.resolve(values);

const EligibilityForm = () => {
  const { formSchema, initialValues, eligibilityDecisionPrerequisitesMet } =
    useValidation();
  type FormFields = yup.InferType<typeof formSchema>;

  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [, setOpenFormState] = useOpenFormContext();

  const [successToast] = usePopupToast({
    status: 'success',
    title: `Eligibility decision added`,
  });
  const [errorToast] = usePopupToast({
    status: 'error',
    title: `Sorry, we can’t add the eligibility decision right now.`,
    description: 'Please check your connection and try again.',
  });
  const { id: childId } = useParams<{ id: string }>();
  const { data: diagnoses } = useDiagnoses(childId);
  const qualifyingDiagnosisTypes = useQualifyingDiagnosisTypes();
  const setOfQualifyingDiagnosisNames: Set<string> = new Set(
    qualifyingDiagnosisTypes?.map(d => d.label)
  );
  const onSubmit = useCallback(
    async (values: FormFields) => {
      setIsAlertOpen(true);
      setSubmitError(false);

      try {
        await mockSave(values);
        setOpenFormState(undefined);
        successToast();
      } catch (error) {
        errorToast();
        setSubmitError(true);
      }
    },
    [errorToast, setOpenFormState, successToast]
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues!}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      {formik => (
        <>
          <form
            noValidate
            onSubmit={e => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
            style={{ width: '100%', display: 'flex' }}
          >
            <DispatchMaybeDirtyForm
              isDirty={formik.dirty}
              humanReadableFormName="Child Assessments - Eligibility decision"
            />
            {submitError && (
              <InlineAlert
                marginBottom={4}
                status="error"
                title="There was a problem adding this eligibility decision."
              />
            )}
            <Flex flexDir="column" justifyContent="space-between">
              <FormSection>
                <Heading as="h4" variant="tertiary">
                  Diagnoses
                </Heading>
                <Text textStyle="bodyText">
                  Select all diagnoses the eligibility team used to help
                  determine the eligibility decision
                </Text>
                <FormControl
                  name="diagnoses"
                  isInvalid={Boolean(formik.touched && formik.errors.diagnoses)}
                >
                  <Stack spacing={4}>
                    {diagnoses &&
                      diagnoses.map(diagnosis => (
                        <Checkbox
                          name="diagnoses"
                          key={diagnosis.id}
                          value={diagnosis.id}
                          label={
                            setOfQualifyingDiagnosisNames.has(
                              diagnosis.diagnosisName
                            ) ? (
                              <QualifyingIconLabel
                                label={diagnosis.diagnosisName}
                              />
                            ) : (
                              diagnosis.diagnosisName
                            )
                          }
                        />
                      ))}
                  </Stack>
                  <FormErrorMessage>
                    {formik.touched && formik.errors.diagnoses}
                  </FormErrorMessage>
                </FormControl>
              </FormSection>
              <DetailSectionDivider orientation="horizontal" />
              <FormSection>
                <EvaluatedDomainsForm />
              </FormSection>
              <DetailSectionDivider orientation="horizontal" />
              <FormSection>
                <Heading as="h4" variant="tertiary">
                  Informed clinical opinion
                </Heading>
                <Text textStyle="bodyText">
                  The evaluation and assessment of each child and the
                  determination of the child’s initial eligibility for Part C
                  early intervention services must include the use of informed
                  clinical opinion. Please mark the checkbox if there are no
                  qualifying diagnosises or assessments but the child is still
                  eligible for services.
                </Text>
                <TextareaField
                  name="icoText"
                  label="Informed clinical opinion"
                  required
                />
                {formik.errors.AT_LEAST_ONE_QUALIFYING_ITEM && (
                  <>
                    <FormControl
                      name="ICO_REQUIRED"
                      mt={0}
                      isInvalid={Boolean(
                        formik.touched && formik.errors.ICO_REQUIRED
                      )}
                    >
                      <Checkbox
                        value="true"
                        label="An informed clinical opinion is the sole method being used to determine eligibility."
                        name="icoIsSoleFactor"
                      />
                      <FormErrorMessage>
                        {formik.errors.ICO_REQUIRED}
                      </FormErrorMessage>
                    </FormControl>
                  </>
                )}
                <DateField
                  name="icoDate"
                  label="Informed clinical opinion date"
                  disabledDays={{ after: startOfToday() }}
                  required
                />
              </FormSection>
              <DetailSectionDivider orientation="horizontal" />
              <FormSection>
                <Heading as="h4" variant="tertiary">
                  Eligibility decision
                </Heading>
                <Text textStyle="bodyText">
                  Remember that eligibility is a team decision.
                </Text>
                <RadioGroupField
                  name="determinedEligible"
                  label="In your professional opinion, is this child eligible for services?"
                  direction="horizontal"
                  required
                >
                  <RadioPill
                    value="true"
                    label="Yes"
                    isDisabled={
                      !eligibilityDecisionPrerequisitesMet(formik.values)
                    }
                  />
                  <RadioPill
                    value="false"
                    label="No"
                    isDisabled={
                      !eligibilityDecisionPrerequisitesMet(formik.values)
                    }
                  />
                  <FormControl
                    name="CAN_DETERMINE_ELIGIBLITY"
                    isInvalid={Boolean(
                      formik.touched && formik.errors.CAN_DETERMINE_ELIGIBLITY
                    )}
                  >
                    <FormErrorMessage>
                      {formik.errors.CAN_DETERMINE_ELIGIBLITY}
                    </FormErrorMessage>
                  </FormControl>
                </RadioGroupField>
                <DateField
                  name="eligibilityDate"
                  label="Eligibility decision date"
                  disabledDays={{ after: startOfToday() }}
                  required
                  inputProps={{
                    disabled: !eligibilityDecisionPrerequisitesMet(
                      formik.values
                    ),
                  }}
                />
              </FormSection>
            </Flex>
            <Box>
              <SaveAndCancel
                onCancel={() => setOpenFormState(undefined)}
                disabled={false}
              />
            </Box>
          </form>
          <Alert
            headerText="Save eligibility"
            confirmText="Save"
            cancelText="Go back"
            isOpen={isAlertOpen}
            setIsOpen={setIsAlertOpen}
            onConfirm={async () => {
              await onSubmit(formik.values);
              setIsAlertOpen(false);
            }}
          >
            <Text textStyle="bodyText" fontWeight="medium" fontSize="md">
              Are you sure you want to make an eligibility decision now? Doing
              this will remove the previous eligibility decision.
            </Text>
          </Alert>
        </>
      )}
    </Formik>
  );
};

export default EligibilityForm;
