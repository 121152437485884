import * as yup from 'yup';
import { ChildOutcome, ESITService } from '@cssat/acorn-api-shared';
import {
  dateFormatData,
  yupOptionalString,
  yupRequiredDate,
  yupRequiredString,
} from 'lib';
import { isBefore, parse } from 'date-fns';
import { isNumber } from 'lodash';
import { v4 as uuid } from 'uuid';

export const validateFormSchema = (outcomes?: ChildOutcome[]) =>
  yup.object({
    serviceName: yupRequiredString('Service name is required'),
    provider: yupRequiredString('Provider is required'),
    plannedStartDate: yupRequiredDate('Planned start date is required').test(
      'plannedStartDate',
      'Planned start date cannot be before outcome start date',
      function () {
        const { outcomesSupported, plannedStartDate } = this.parent;

        const latestOutcomeDate = outcomes
          ?.filter(outcome => {
            return outcomesSupported?.includes(outcome.id);
          })
          .map(outcome => {
            return new Date(outcome.startDate!);
          })
          .sort(function (a, b) {
            return b.getTime() - a.getTime();
          })
          .at(0);

        return latestOutcomeDate === undefined
          ? true
          : isBefore(latestOutcomeDate, plannedStartDate);
      }
    ),
    plannedEndDate: yupRequiredDate('Planned end date is required').when(
      'plannedStartDate',
      {
        is: value => value !== undefined,
        then: yup
          .date()
          .min(
            yup.ref('plannedStartDate'),
            'Planned end date cannot be before planned start date'
          ),
      }
    ),
    methods: yupRequiredString('Methods is required'),
    outcomesSupported: yup
      .array()
      .of(yup.string())
      .test('outcomesSupported', 'Please select at least one', vals => {
        return !!vals && vals.length > 0;
      }),
    serviceType: yupRequiredString('Please choose service type'),
    serviceLength: yupRequiredString('Please choose service length'),
    serviceFrequency: yupRequiredString().test(
      'serviceFrequency-validate',
      'Please enter a number',
      val => {
        return (
          !!val &&
          isNumber(parseInt(val, 10)) &&
          parseInt(val, 10) > 0 &&
          Number(val) === parseInt(val, 10)
        );
      }
    ),
    serviceInterval: yupRequiredString(
      'Please choose how often this service will occur'
    ),
    serviceSetting: yupRequiredString(
      'Please choose in what setting this service will happen'
    ),
    settingName: yup.string().when('serviceSetting', {
      is: val => val === 'Other',
      then: yupRequiredString('Please enter setting name'),
      otherwise: yupOptionalString(),
    }),
    settingExplain: yup.string().when('serviceSetting', {
      is: val => val === 'Other',
      then: yupRequiredString('Please enter an explanation'),
      otherwise: yupOptionalString(),
    }),
    settingPlan: yup.string().when('serviceSetting', {
      is: val => val === 'Other',
      then: yupRequiredString('Please enter an explanation'),
      otherwise: yupOptionalString(),
    }),
  });

export type FormFields = yup.InferType<typeof validateFormSchema>;

export const initialValues = (service?: ESITService) => {
  return {
    id: service ? service.id : uuid(),
    serviceName: '',
    provider: '',

    methods: '',
    outcomesSupported: [],
    serviceType: '',
    serviceLength: '',
    serviceFrequency: 0,
    serviceInterval: '',
    serviceSetting: '',
    ...service,
    plannedStartDate: service?.plannedStartDate
      ? parse(service.plannedStartDate, dateFormatData, new Date())
      : undefined,
    plannedEndDate: service?.plannedEndDate
      ? parse(service.plannedEndDate, dateFormatData, new Date())
      : undefined,
  };
};
