import {
  Box,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Popover,
  PopoverTrigger,
  ResponsiveValue,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { useCombobox } from 'downshift';
import { useEffect } from 'react';
import { useField } from 'formik';
import Fuse from 'fuse.js';

import { AcornFormField } from 'components/formik/AcornFormField';
import { ReactComponent as CaretDown } from 'icons/caret-down.svg';
import { ReactComponent as CaretUp } from 'icons/caret-up.svg';
import { UseFuse, useFuse } from './useFuse';
import { useDebounceOnChange } from 'hooks';
import BaseButton from 'components/BaseButton';
import Menu from './Menu';

export interface Item {
  id: string;
  label: string;
}

const defaultFuseConfig: Fuse.IFuseOptions<Item> = {
  keys: ['label'],
  ignoreLocation: true,
};

const emptyInputValue = '';

interface SearchableTextFieldProps extends InputProps {
  name: string;
  required?: boolean;
  label?: string;
  textStyle?: ResponsiveValue<string & {}>;
}

interface SearchableDropdownProps extends SearchableTextFieldProps {
  menuItems?: ReadonlyArray<Item>;
  searchConfig?: UseFuse<Item>['fuseOptions'];
  searchOptions?: Fuse.FuseSearchOptions;
  onSelected?(newItem: Item): void;
  selectedItemIndicator?: ReactNode;
  idName?: string;
  idRequired?: boolean;
  resetIdValue?: string;
}

const SearchableDropDownField: FC<SearchableDropdownProps> = ({
  menuItems,
  searchConfig = defaultFuseConfig,
  searchOptions,
  name: textFieldName,
  label,
  textStyle,
  required,
  idRequired,
  onSelected,
  resetIdValue,
  selectedItemIndicator,
  idName,
  ...customInputProps
}) => {
  const idFieldName = idName ? idName : `${textFieldName}Id`;
  const [textField, , formikTextFieldHelper] = useField(textFieldName);
  const [idField, , formikIdFieldHelper] = useField(idFieldName);

  const [textFieldValue, textFieldOnChange] = useDebounceOnChange(
    textField.value,
    textField.onChange,
    '',
    val => val
  );

  const { searchResults, search, isSearchResultsAllResults } = useFuse<Item>({
    list: menuItems ? menuItems : [],
    fuseOptions: searchConfig,
  });

  const initialItem: Fuse.FuseResult<Item> | null =
    searchResults.find(result => result.item.id === idField.value) ?? null;

  useEffect(() => {
    if (resetIdValue && (!idField.value || idField.value === emptyInputValue)) {
      formikIdFieldHelper.setValue(resetIdValue);
    }
  }, [formikIdFieldHelper, idField.value, resetIdValue]);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    selectedItem,
    selectItem,
    getItemProps,
    getInputProps,
    getComboboxProps,
  } = useCombobox<Fuse.FuseResult<Item> | null>({
    initialSelectedItem: initialItem,
    initialInputValue: initialItem?.item.label ?? textFieldValue,
    items: searchResults,
    id: `${textField.name}`,
    itemToString: result => (result ? result.item.label : ''),
    onInputValueChange: ({ inputValue, selectedItem, isOpen }) => {
      if (!isOpen) {
        search('');
        return;
      }
      search(inputValue ? inputValue : emptyInputValue, searchOptions);
      if (selectedItem && inputValue !== selectedItem.item.label) {
        selectItem(null);
      }
    },
    onSelectedItemChange: ({ selectedItem: newItem }) => {
      const newId = newItem?.item.id ?? emptyInputValue;
      const newLabel = newItem?.item.label ?? emptyInputValue;
      if (onSelected) {
        onSelected({ id: newId, label: newLabel });
      }

      if (newItem) {
        formikTextFieldHelper.setValue(newLabel);
        formikIdFieldHelper.setValue(newId);
      } else {
        formikIdFieldHelper.setValue(
          resetIdValue ? resetIdValue : emptyInputValue
        );
      }
    },
    onStateChange: changes => {
      if (!onSelected) {
        return;
      }

      if (
        changes.type === '__input_keydown_escape__' ||
        changes.type === '__input_blur__'
      ) {
        onSelected({
          id: idField.value,
          label: textFieldValue !== '' ? textFieldValue : '-',
        });
      }
    },
  });

  return (
    <Box width="100%">
      <Popover
        returnFocusOnClose={false}
        isOpen={true} // controlled usage
        onClose={() => {}} // controlled usage
        placement="bottom"
        closeOnBlur={false}
        matchWidth
      >
        <Flex {...getComboboxProps()}>
          <AcornFormField name={textField.name} required={required}>
            {label && (
              <FormLabel textStyle={textStyle} {...getLabelProps()}>
                {label}
              </FormLabel>
            )}
            <PopoverTrigger>
              <InputGroup>
                {selectedItemIndicator && selectedItem && selectedItemIndicator}
                <Input
                  textStyle="bodyText"
                  placeholder={!menuItems ? 'Loading options...' : undefined}
                  // @ts-ignore: Types of property 'as' are incompatible
                  {...getInputProps({
                    ...textField,
                    value: textFieldValue,
                    // @ts-ignore: Type 'FormEvent<HTMLInputElement>' is not assignable to type 'ChangeEvent<HTMLInputElement | HTMLTextAreaElement>'.
                    onChange: textFieldOnChange,
                    ...customInputProps,
                  })}
                />
                <InputRightElement>
                  {menuItems && (
                    <BaseButton
                      type="button"
                      padding={2.5}
                      {...getToggleButtonProps()}
                    >
                      {isOpen ? (
                        <CaretUp />
                      ) : (
                        <CaretDown data-testid="drop-down-icon" />
                      )}
                    </BaseButton>
                  )}
                </InputRightElement>
              </InputGroup>
            </PopoverTrigger>
          </AcornFormField>

          <Box display="none" aria-hidden>
            <AcornFormField name={idField.name} required={idRequired}>
              <Input
                type="hidden"
                {...idField}
                value={
                  idField.value ?? resetIdValue ? resetIdValue : emptyInputValue
                }
              />
            </AcornFormField>
          </Box>
        </Flex>

        <Menu
          getMenuProps={getMenuProps}
          getItemProps={getItemProps}
          highlightedIndex={highlightedIndex}
          selectedItem={selectedItem}
          isOpen={isOpen}
          searchResults={searchResults}
          isSearchResultsAllResults={isSearchResultsAllResults}
        />
      </Popover>
    </Box>
  );
};

export default SearchableDropDownField;
