import { Box, Flex } from '@chakra-ui/react';
import { ReactComponent as DCYFLogo } from 'icons/dcyf-logo-header.svg';
import { FC, useEffect, useRef } from 'react';
import { Heading } from '@chakra-ui/react';
import { space, zIndexMap } from 'theme';
import { useTopBarContext } from 'contexts';
import UserProfile from './UserProfile';

const logoHeight = '24px';

const TopBar: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [, setTopBarContext] = useTopBarContext();

  useEffect(() => {
    if (ref.current !== null) {
      setTopBarContext(ref.current);
    }
  }, [ref, setTopBarContext]);

  return (
    <Flex
      ref={ref}
      as="header"
      backgroundColor="teal.700"
      justifyContent="space-between"
      position="fixed"
      top={0}
      right={0}
      width="100%"
      zIndex={zIndexMap.navigationLayer}
      boxShadow="base"
      alignItems="center"
      pl={6}
      pr={[space.one, space.one, 6]}
    >
      <Flex alignItems="center" py={space.one}>
        <Heading as="h1" variant="appTitle" color="white">
          ACORN
        </Heading>
        <Box
          width="1px"
          mx={[space.half, space.half, space.one]}
          height={logoHeight}
          backgroundColor="white"
        />
        <DCYFLogo height={logoHeight} />
      </Flex>
      <UserProfile />
    </Flex>
  );
};

export default TopBar;
