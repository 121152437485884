import { FC, useCallback } from 'react';
import { Heading, Stack } from '@chakra-ui/react';
import {
  RoutinesFormFields,
  createEmptyFamilyRoutines,
  routinesFormSchema,
} from '../validation';
import { TextareaField } from 'components/formik';
import { useParams } from 'react-router-dom';
import ChildSectionFormikWrapper from 'pages/ChildRecord/ChildSectionFormikWrapper/ChildSectionFormikWrapper';
import useRoutines, { useMutateRoutines } from './useRoutines';

const RoutinesAndActivitiesForm: FC = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: routines } = useRoutines(childId);
  const updateRoutines = useMutateRoutines(childId);

  const onSubmit = useCallback(
    async (values: RoutinesFormFields) => {
      await updateRoutines(values);
    },
    [updateRoutines]
  );

  return (
    <ChildSectionFormikWrapper
      formikProps={{
        validationSchema: routinesFormSchema,
        initialValues: routines ?? createEmptyFamilyRoutines(),
      }}
      successToastProps={{ title: 'Routines and activities updated' }}
      errorToastProps={{
        title: 'Sorry, we can’t update routines and activities right now.',
        description: 'Please check your connection and try again.',
      }}
      apiRequest={onSubmit}
      humanReadableFormName="Child Assessments - Family Directed Assessments - Routines and Activities"
      inlineAlertMessage="There was a problem updating routines and activities."
    >
      <Stack flexDir="column" spacing={4}>
        <Heading as="h4" variant="tertiary">
          Routines and activities
        </Heading>
        <TextareaField
          rows={1}
          name="setting"
          label="Where does your child spend the day and who is involved? How would you describe your child’s relationship with you and the people they spend the most time with in different settings?"
        />
        <TextareaField
          rows={1}
          name="enjoyedByChild"
          label="What are the things your child enjoys most?  Examples: toys, people, places, activities, etc."
        />
        <TextareaField
          rows={1}
          name="enjoyedByFamily"
          label="What does your family enjoy and why? Who is involved? When does this occur?"
        />
        <TextareaField
          rows={1}
          name="activitiesSucceeding"
          label="What activities and relationships are going well?"
        />
        <TextareaField
          rows={1}
          name="activitiesChallenging"
          label="What, if any, routines and activities do you find to be difficult or frustrating for you and your child?"
        />
        <TextareaField
          rows={1}
          name="activitiesInterest"
          label="What are the activities and routines your family currently does not do because of your child’s needs but is interested in doing now or in the near future?"
        />
      </Stack>
    </ChildSectionFormikWrapper>
  );
};

export default RoutinesAndActivitiesForm;
