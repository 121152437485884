import { FormFields } from './validation';
import { PreferredContactMethod, ReferralData } from '@cssat/acorn-api-shared';
import { formatISO } from 'date-fns';

const adaptPreferredContactMethod = (
  contactMethod: FormFields['referringParty']['preferredContactMethod']
) => {
  switch (contactMethod) {
    case 'phone':
      return 'phone-generic';
    case 'unspecified':
      return undefined;
    default:
      return contactMethod as PreferredContactMethod;
  }
};

const addFamilyContactDate = (
  familyContactDate: FormFields['familyContactDate']
) => {
  if (familyContactDate) {
    return {
      familyContactDate: formatISO(familyContactDate!, {
        representation: 'date',
      }),
    };
  }
  return {};
};

const hydrateMailingAddress = (
  referringParty: FormFields['referringParty']
) => {
  const { isAddressSame, ...contactInfo } = referringParty;
  if (isAddressSame) {
    contactInfo.mailingAddress1 = contactInfo.physicalAddress1;
    contactInfo.mailingAddress2 = contactInfo.physicalAddress2;
    contactInfo.mailingCity = contactInfo.physicalCity;
    contactInfo.mailingState = contactInfo.physicalState;
    contactInfo.mailingZip = contactInfo.physicalZip;
  }

  return contactInfo;
};

const addReferringParty = (
  formFields: FormFields
): ReferralData['referringParty'] => {
  const { referringParty } = formFields;
  return {
    ...hydrateMailingAddress(referringParty),
    preferredContactMethod: adaptPreferredContactMethod(
      referringParty.preferredContactMethod
    ),
  };
};

export const adaptFormToApi = (
  formFields: FormFields
): Omit<ReferralData, 'isActive'> => ({
  referralId: formFields.referralId,
  child: {
    id: formFields.childId,
    name: {
      first: formFields.childFirstName,
      last: formFields.childLastName,
    },
    childDateOfBirth: formatISO(formFields.childDateOfBirth, {
      representation: 'date',
    }),
    sexOfChild:
      formFields.sexOfChild === 'other'
        ? !formFields.otherSexOfChild
          ? 'other'
          : formFields.otherSexOfChild
        : formFields.sexOfChild!,
  },
  referringParty: addReferringParty(formFields),
  dateOfInitialReferral: formatISO(formFields.dateOfInitialReferral, {
    representation: 'date',
  }),
  assignedAgency: formFields.assignedAgency,
  reason: formFields.referralReason,
  createContactFromReferringParty: formFields.referringPartyIsChildContact,
  createPrimaryCareProviderFromReferringParty:
    formFields.referringPartyIsPrimaryCareProvider,
  ...addFamilyContactDate(formFields.familyContactDate),
  ...(formFields.referralNotes ? { notes: formFields.referralNotes } : {}),
});
