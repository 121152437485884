import { FC } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

export const LegendaryLabel: FC<FlexProps> = ({
  children,
  textStyle,
  ...props
}) => (
  <Flex as="legend" textStyle={textStyle ? textStyle : 'bigLabel'} {...props}>
    {children}
  </Flex>
);
