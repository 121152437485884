import { Transition } from '@cssat/acorn-api-shared';

const adaptApiToForm = (transition: Transition) => {
  return {
    ...transition,
    partBPotential: transition.partBPotential?.toString(),
  };
};

export default adaptApiToForm;
