import { Alert } from 'molecules';
import { ChakraProps, Text } from '@chakra-ui/react';
import { FC, ReactElement, useState } from 'react';
import { FormFields } from 'pages/ChildRecord/ExitTab/CompleteTransitionForm/validation';
import { SaveAndCancel } from './SaveAndCancel';
import { useFormikContext } from 'formik';

export const ModalAndCancel: FC<{
  onCancel: () => void;
  disabled: boolean;
  confirmText: string;
  saveText?: string | ReactElement;
  cancelText?: string;
  sx?: ChakraProps['sx'];
  text?: string;
  subText?: string;
  headerText?: string;
}> = ({
  onCancel,
  disabled,
  saveText = 'Save',
  cancelText = 'Cancel',
  text = '',
  subText = '',
  headerText = '',
  confirmText = '',
}) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { submitForm, validateForm } = useFormikContext<FormFields>();

  return (
    <>
      <SaveAndCancel
        onSubmit={async () => {
          const errors = await validateForm();
          if (errors && Object.keys(errors).length === 0) {
            setIsAlertOpen(true);
          } else {
            // this will show validation errors but not actually submit
            submitForm();
          }
        }}
        onCancel={onCancel}
        disabled={disabled}
        cancelText={cancelText}
        saveText={saveText}
      />
      <Alert
        headerText={headerText}
        confirmText={confirmText}
        cancelText="Go back"
        isOpen={isAlertOpen}
        setIsOpen={setIsAlertOpen}
        onConfirm={submitForm}
        hasCloseButton={true}
      >
        <Text textStyle="bodyText">{text}</Text>
        <Text textStyle="bodyText" mt={2} mb={2}>
          {subText}
        </Text>
      </Alert>
    </>
  );
};
