import { FormFields } from './validation';
import { formatISO } from 'date-fns';
import type { UpdateChildDetailsRequest } from 'pages/ChildRecord/OverviewTab/useChildRecord';

export const adaptFormToApi = (
  formFields: FormFields
): UpdateChildDetailsRequest => {
  const {
    dateOfBirth,
    hispanicOrLatino,
    otherSexOfChild,
    notes,
    race,
    ...rest
  } = formFields;

  const isOtherSex = !['male', 'female'].includes(formFields.sex);
  const toNullableBoolean = (value: string) => {
    if (value === 'false') return false;
    if (value === 'true') return true;
    return null;
  };
  return {
    ...rest,
    sex: isOtherSex ? otherSexOfChild || 'other' : formFields.sex,
    dateOfBirth: formatISO(dateOfBirth),
    notes: notes ?? '',
    hispanicOrLatino: toNullableBoolean(hispanicOrLatino),
    race: race.map(raceStr => parseInt(raceStr)),
  };
};
