import { Flex, SkeletonText } from '@chakra-ui/react';

import {
  DetailSectionColumn,
  DetailSectionContent,
  DetailSectionDivider,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';

const CardSkeleton = () => (
  <Flex flexDirection="column">
    <DetailSectionContent p="6">
      <DetailSectionRow>
        <DetailSectionColumn>
          <SkeletonText noOfLines={2} />
          <SkeletonText pt="6" noOfLines={2} />
          <SkeletonText pt="6" noOfLines={2} />
        </DetailSectionColumn>

        <DetailSectionDivider />

        <DetailSectionColumn>
          <SkeletonText noOfLines={2} />
          <SkeletonText pt="6" noOfLines={3} />
        </DetailSectionColumn>

        <DetailSectionDivider />

        <DetailSectionColumn>
          <SkeletonText noOfLines={2} />
          <SkeletonText pt="6" noOfLines={4} />
        </DetailSectionColumn>
      </DetailSectionRow>
    </DetailSectionContent>
  </Flex>
);

export default CardSkeleton;
