import { FC, createContext, useContext, useState } from 'react';

type TeamMemberIdContextType = [
  string | undefined,
  (id: string | undefined) => void
];

const TeamMemberIdContext = createContext<TeamMemberIdContextType>([
  undefined,
  () => {},
]);

export const useTeamMemberIdContext = () => useContext(TeamMemberIdContext);

export const TeamMemberIdContextProvider: FC = ({ children }) => {
  const value = useState<string | undefined>(undefined);

  return (
    <TeamMemberIdContext.Provider value={value}>
      {children}
    </TeamMemberIdContext.Provider>
  );
};
