import { AccessibilityStatement } from 'molecules';
import { FC } from 'react';
import { Flex, Stack } from '@chakra-ui/react';
import { useScreenWidth } from 'hooks';
import { zIndexMap } from 'theme';
import Copyright from 'components/Copyright';

const Footer: FC = () => {
  const { isWideScreenWidth, isNarrowScreenWidth } = useScreenWidth();

  return (
    <Flex
      flexShrink={0}
      py={6}
      px={24}
      backgroundColor="teal.900"
      as="footer"
      justifyContent={['center', 'center', 'space-between']}
      alignItems="center"
      zIndex={zIndexMap.navigationLayer}
    >
      {isWideScreenWidth && <Copyright />}

      <Stack spacing={4}>
        {/* <FeedbackButton /> */}
        <AccessibilityStatement />
        {isNarrowScreenWidth && <Copyright />}
      </Stack>
    </Flex>
  );
};

export default Footer;
