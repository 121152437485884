import { ChildListResult, SessionUser } from '@cssat/acorn-api-shared';
import {
  ChildListTableConfig,
  useChildTableContext,
  useUserContext,
} from 'contexts';
import { Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableColHead,
  TableHeader,
  TableRow,
} from 'components/styled/tables';
import {
  calculateDueDateDaysFromToday,
  calculateIFSPDueDateFromReferralDate,
  relativeDueDateCopy,
} from 'lib/calculateDueDate';
import { dateFormatDisplayShort, getTabularDateFromISOString } from 'lib';
import { format } from 'date-fns';
import { schoolDistricts } from '@cssat/acorn-api-shared';
import { useCallback } from 'react';
import useChildList from '../useChildList';

const getSortDirection = (config: ChildListTableConfig, paramter: string) =>
  config.sortColumn === paramter ? config.sortDirection : undefined;

const concatName = (assignedUser: SessionUser) =>
  `${assignedUser.givenName} ${assignedUser.familyName}`;

const getSchoolDistrictFromChild = (child: ChildListResult) => {
  const schoolDistrict = schoolDistricts.find(
    district => district.id === child.schoolDistrictId
  );

  return schoolDistrict;
};

const initialIFSPDueDateInfo = (
  latestReferralDateString: string
): { copy: string; overdue: boolean } => {
  if (!latestReferralDateString) return { copy: '-', overdue: false };

  const IFSPDueDate = calculateIFSPDueDateFromReferralDate(
    latestReferralDateString
  );
  const formattedIFSPDueDate = format(IFSPDueDate, dateFormatDisplayShort);
  const numberOfDays = calculateDueDateDaysFromToday(IFSPDueDate);
  const relativeDaysCopy = relativeDueDateCopy(numberOfDays);

  return {
    copy: `${formattedIFSPDueDate} (${relativeDaysCopy})`,
    overdue: numberOfDays < 0,
  };
};

const ChildListTable = () => {
  const { user } = useUserContext();
  const { data } = useChildList();
  const childList = data ?? [];
  const [config, setConfig] = useChildTableContext();

  const handleSetSort = useCallback(
    (column: string) => {
      const direction =
        config.sortColumn === column && config.sortDirection === 'desc'
          ? 'asc'
          : 'desc';
      setConfig({ ...config, sortColumn: column, sortDirection: direction });
    },
    [setConfig, config]
  );
  const openSidebar = useCallback(
    (currentChildId: string) => setConfig({ ...config, currentChildId }),
    [config, setConfig]
  );

  return (
    <Table data-testid="child-table">
      <TableHeader>
        <TableRow>
          <TableColHead
            onClick={() => handleSetSort('name')}
            sortDirection={getSortDirection(config, 'name')}
          >
            Child Name
          </TableColHead>
          <TableColHead
            onClick={() => handleSetSort('dateOfBirth')}
            sortDirection={getSortDirection(config, 'dateOfBirth')}
          >
            Date of Birth
          </TableColHead>
          <TableColHead
          // TODO: Uncomment me when the api is fixed
          // onClick={() => handleSetSort('schoolDistrictId')}
          // sortDirection={getSortDirection(config, 'schoolDistrictId')}
          >
            School District
          </TableColHead>
          <TableColHead
            onClick={() => handleSetSort('assignedUser')}
            sortDirection={getSortDirection(config, 'assignedUser')}
          >
            Assigned To
          </TableColHead>
          <TableColHead
            onClick={() => handleSetSort('assignedAgency')}
            sortDirection={getSortDirection(config, 'assignedAgency')}
          >
            Agency
          </TableColHead>
          <TableColHead>Current Status</TableColHead>
          <TableColHead>Next Due Date</TableColHead>
        </TableRow>
      </TableHeader>

      <TableBody>
        {childList.map((child: ChildListResult) => {
          const exitedChild = child.status === 'Exited';

          return (
            <TableRow key={`child-${child.id}`}>
              <TableCell>
                <Link to={`children/${child.id}`} as={RouterLink}>
                  {`${child.lastName}, ${child.firstName}`}
                </Link>
              </TableCell>

              <TableCell>
                {getTabularDateFromISOString(child.dateOfBirth)}
              </TableCell>
              <TableCell>
                {getSchoolDistrictFromChild(child)?.name ?? '-'}
              </TableCell>
              <TableCell>
                {exitedChild ? (
                  '-'
                ) : (
                  <Link
                    as="button"
                    ml={1}
                    sx={
                      !child.assignedUser
                        ? {
                            color: 'red.600',
                            ':hover': {
                              color: 'red.700',
                            },
                          }
                        : {}
                    }
                    textDecoration="underline"
                    onClick={() => openSidebar(child.id)}
                  >
                    {child.assignedUser
                      ? concatName(child.assignedUser)
                      : 'Assign to FRC'}
                  </Link>
                )}
              </TableCell>
              <TableCell>
                {child.assignedAgency
                  ? user?.organizations.find(
                      org => org.id === child.assignedAgency
                    )?.name
                  : '-'}
              </TableCell>
              <TableCell>{child.status}</TableCell>
              <TableCell
                sx={
                  initialIFSPDueDateInfo(child.latestReferralDate).overdue &&
                  !exitedChild
                    ? { color: 'red.600' }
                    : {}
                }
              >
                {exitedChild
                  ? '-'
                  : initialIFSPDueDateInfo(child.latestReferralDate).copy}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ChildListTable;
