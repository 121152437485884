import { ChildContact as ChildContactData } from '@cssat/acorn-api-shared';
import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Link } from '@chakra-ui/react';

import { Address } from 'components/styled/Address';
import { ContactMethod, useOrderedContactMethods } from 'hooks';
import {
  DetailSectionCheckMark,
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionNotes,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { validateMailingAndPhysicalAddress } from 'lib';

const areTruthy = (el: any) => !!el;

interface ChildContactViewProps {
  readonly childContact: ChildContactData;
}

const ChildContactView: FC<ChildContactViewProps> = ({
  childContact,
  childContact: {
    name,
    relationship,
    physicalAddress1,
    physicalAddress2,
    physicalCity,
    physicalState,
    physicalZip,
    mailingAddress1,
    mailingAddress2,
    mailingCity,
    mailingState,
    mailingZip,
    childLivesHere,
    primaryLanguage,
    otherLanguages,
    interpreterNeeded,
    isSurrogateParent,
    transportationNeeded,
  },
}) => {
  const { isAddressTheSame, doesMailingAddressExist } =
    validateMailingAndPhysicalAddress(childContact);

  const orderedContactMethods = useOrderedContactMethods(childContact);

  const otherInformationFields = [isSurrogateParent, transportationNeeded];

  return (
    <Flex flexGrow={1} direction="column">
      <DetailSectionRow>
        <DetailSectionColumn>
          <DetailSectionField label="Name">
            <Text textStyle="sectionFieldViewValue">
              {name}
              {relationship ? ',' : ''}
            </Text>
            <Text textStyle="sectionFieldViewValue">{relationship}</Text>
          </DetailSectionField>

          <DetailSectionField label="Physical Address" showWhenEmpty>
            <Address
              line1={physicalAddress1 || '-'}
              line2={physicalAddress2}
              city={physicalCity}
              state={physicalState}
              zip={physicalZip}
            />
            {childLivesHere && (
              <DetailSectionCheckMark text="Child lives here" />
            )}
          </DetailSectionField>
          {!isAddressTheSame && doesMailingAddressExist && (
            <DetailSectionField label="Mailing address">
              <Address
                line1={mailingAddress1 || '-'}
                line2={mailingAddress2}
                city={mailingCity}
                state={mailingState}
                zip={mailingZip}
              />
              {childContact.preferredContactMethod === 'mailing-address' && (
                <DetailSectionCheckMark text="Preferred contact method" />
              )}
            </DetailSectionField>
          )}
          {isAddressTheSame && (
            <DetailSectionCheckMark text="Same as mailing address" />
          )}
        </DetailSectionColumn>

        <DetailSectionDivider />

        <DetailSectionColumn>
          {orderedContactMethods.map(
            (contactMethod: ContactMethod) =>
              contactMethod.value && (
                <DetailSectionField
                  key={contactMethod.key}
                  label={contactMethod.label}
                  showWhenEmpty
                >
                  {contactMethod.label === 'Email address' ? (
                    <Link
                      variant="detailSectionFieldViewValue"
                      width="fit-content"
                      href={`mailto:${contactMethod.value}`}
                      isExternal
                    >
                      {contactMethod.value}
                    </Link>
                  ) : (
                    <Text textStyle="sectionFieldViewValue">
                      {contactMethod.value}
                    </Text>
                  )}
                  {contactMethod.preferred && (
                    <DetailSectionCheckMark text="Preferred contact method" />
                  )}
                </DetailSectionField>
              )
          )}
        </DetailSectionColumn>

        <DetailSectionDivider />

        <DetailSectionColumn>
          <DetailSectionField label="Primary language" value={primaryLanguage}>
            {interpreterNeeded && (
              <DetailSectionCheckMark text="Interpreter needed" />
            )}
          </DetailSectionField>
          <DetailSectionField label="Other languages" value={otherLanguages} />

          <Flex flexDirection="column">
            {otherInformationFields.some(areTruthy) && (
              <>
                <Text textStyle="sectionFieldViewLabel">Other information</Text>

                {isSurrogateParent && (
                  <DetailSectionCheckMark text="Surrogate parent" />
                )}
                {transportationNeeded && (
                  <DetailSectionCheckMark text="Transportation needed" />
                )}
              </>
            )}
          </Flex>
        </DetailSectionColumn>
      </DetailSectionRow>
      <DetailSectionNotes notes={childContact.notes} />
    </Flex>
  );
};

export default ChildContactView;
