import { Box, Tab, TabList } from '@chakra-ui/react';
import { Tabs, useOpenFormContext } from 'contexts';
import EditDot from 'components/EditDot';

const TabListSection = () => {
  const [openFormState] = useOpenFormContext();
  return (
    <TabList mb={0}>
      <Tab position="relative">
        {openFormState?.tab === Tabs.Overview && (
          <EditDot
            aria-label="Form is open in tab"
            position="absolute"
            left={0}
          />
        )}
        Overview
      </Tab>
      <Tab position="relative">
        {openFormState?.tab === Tabs.Evaluations && (
          <EditDot
            aria-label="Form is open in tab"
            position="absolute"
            left={0}
          />
        )}
        Evaluations
      </Tab>
      <Tab position="relative">
        {openFormState?.tab === Tabs.COS && (
          <EditDot
            aria-label="Form is open in tab"
            position="absolute"
            left={0}
          />
        )}
        COS
      </Tab>
      <Tab position="relative">
        {openFormState?.tab === Tabs.Services && (
          <EditDot
            aria-label="Form is open in tab"
            position="absolute"
            left={0}
          />
        )}
        Services
      </Tab>
      <Tab>IFSPs</Tab>
      <Tab position="relative">
        {openFormState?.tab === Tabs.Exit && (
          <EditDot
            aria-label="Form is open in tab"
            position="absolute"
            left={0}
          />
        )}
        Exit
      </Tab>
      <Box
        bg="gray.400"
        height="auto"
        minWidth="1px"
        mx={4}
        display="flex"
        my={3}
        aria-hidden
      />
      <Tab>Team members</Tab>
      <Tab>Notes</Tab>
      <Tab>Documents</Tab>
    </TabList>
  );
};

export default TabListSection;
