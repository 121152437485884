import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { EndOfFormSpacing } from 'components/styled/forms/EndOfFormSpacing';
import { Form, useFormikContext } from 'formik';
import { FormFields } from './validation';
import { Section01ChildAndService } from './Section01ChildAndService';
import { Section02ServiceStatus } from './Section02ServiceStatus';
import { SectionOption03AsPlanned } from './SectionOption03AsPlanned';
import { SectionOption03NotDelivered } from './SectionOption03NotDelivered';
import { SectionOption03WithChanges } from './SectionOption03WithChanges';
import { ServicePlan } from 'pages/Services/types';
import { SpacedFormLayout } from 'components/styled/forms';
import { Status } from '@cssat/acorn-api-shared/dist/service-delivery';
import { scrollToTop } from 'lib';

const TrackSessionForm: FC<{
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  service: ServicePlan | undefined;
  setService: Dispatch<SetStateAction<ServicePlan | undefined>>;
}> = ({ step, setStep, service, setService }) => {
  const { values } = useFormikContext<FormFields>();

  useEffect(() => {
    scrollToTop();
  }, [step]);

  return (
    <Form>
      <SpacedFormLayout>
        {step === 1 && (
          <Section01ChildAndService service={service} setService={setService} />
        )}
        {(step === 2 || step === 3) && (
          <Section02ServiceStatus service={service} setStep={setStep} />
        )}
        <span data-testid="track-session-step-3">
          {step === 3 && values.serviceStatus === Status.AsPlanned && (
            <SectionOption03AsPlanned />
          )}
          {step === 3 && values.serviceStatus === Status.WithChanges && (
            <SectionOption03WithChanges service={service} />
          )}
          {step === 3 && values.serviceStatus === Status.NotDelivered && (
            <SectionOption03NotDelivered />
          )}
        </span>
      </SpacedFormLayout>
      <EndOfFormSpacing />
    </Form>
  );
};

export default TrackSessionForm;
