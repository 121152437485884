import { FC } from 'react';
import { Text } from '@chakra-ui/react';

const ChildMatchCriteria: FC = () => (
  <Text as="span" variant="bodyText">
    <Text as="strong" fontWeight={500}>
      A child must have the exact last name and date of birth
    </Text>{' '}
    in order for a possible match to be displayed. Please ensure you’ve entered
    both those details correctly.
  </Text>
);

export default ChildMatchCriteria;
