import {
  CreateTrackedSessionWithChanges,
  Status,
} from '@cssat/acorn-api-shared/dist/service-delivery';
import { FormFields } from 'pages/Services/TrackSessionForm/validation';
import { ServicePlan } from 'pages/Services/types';
import { TrainingUser } from 'contexts';
import { v4 as uuid } from 'uuid';

export const adaptWithChangesForm = (
  values: FormFields,
  user: TrainingUser,
  service: ServicePlan
): CreateTrackedSessionWithChanges => {
  return {
    childId: values.child,

    duration: values.whatChanged?.includes('duration')
      ? values.duration
      : undefined,
    intensity: values.whatChanged?.includes('intensity')
      ? values.intensity
      : undefined,
    setting: values.whatChanged?.includes('setting')
      ? values.setting
      : undefined,

    servicePlanId: values.service,
    serviceTypeId: service.serviceTypeId,
    dateOfService: values.dateOfService!.toISOString(),
    providerId: user.email,
    providerName: user.name,
    status: Status.WithChanges,
    trackedSessionId: uuid() as string,
    ...(values.note ? { note: values.note } : {}),
  } as CreateTrackedSessionWithChanges;
};
