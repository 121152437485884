export const toSentenceCase = (sentence: string): string => {
  if (!sentence) return '';
  const [first, ...rest] = sentence;
  return `${first.toUpperCase()}${rest.join('')}`;
};

export const snakeCaseToWhiteSpace = (snakeCased: string): string => {
  if (!snakeCased) return '';
  return snakeCased.replace(/_/g, ' ');
};

export const enumToDisplay = (string: string) => {
  return toSentenceCase(snakeCaseToWhiteSpace(string));
};

export const kebabToWhiteSpace = (kebabStr: string): string => {
  if (!kebabStr) return '';
  return kebabStr.replace(/-/g, ' ');
};

export const kebabToSentenceCase = (kebabStr: string): string => {
  return toSentenceCase(kebabToWhiteSpace(kebabStr));
};

export const toUpperCaseSubstring = (
  baseStr: string,
  subStr: string
): string => {
  return baseStr.replace(subStr, subStr.toUpperCase());
};

export const kebabToCamelCase = (kebabStr: string): string => {
  if (!kebabStr) return '';

  const kebabList = kebabStr.split('-').map((str, i) => {
    if (i === 0) return str;
    return toSentenceCase(str);
  });
  return kebabList.join('');
};

export const camelCaseToKebab = (camelStr: string): string => {
  if (!camelStr) return '';

  return camelStr
    .split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
        : letter;
    })
    .join('');
};
