import {
  CreateTrackedSessionNotDelivered,
  MissedDeliveryReason,
  Status,
} from '@cssat/acorn-api-shared/dist/service-delivery';
import {
  ExtenuatingFamilyCircumstance,
  FormFields,
  MissedDeliveryReason as FormMissedDeliveryReason,
} from 'pages/Services/TrackSessionForm/validation';
import { ServicePlan } from 'pages/Services/types';
import { TrainingUser } from 'contexts';
import { v4 as uuid } from 'uuid';
const reasonForMissedDelivery = (values: FormFields): MissedDeliveryReason => {
  const uiReason = FormMissedDeliveryReason;
  const apiReason = MissedDeliveryReason;

  const reason = values.reasonForMissedDelivery as FormMissedDeliveryReason;
  switch (reason) {
    case uiReason.AgencyDelay:
      return apiReason.AgencyDelay;
    case uiReason.ExtenuatingFamilyCircumstances:
      return extenuatingFamilyCircumstanceMapper(values);
    case uiReason.Other:
      return apiReason.Other;
    case uiReason.ProviderNotAvailable:
      return apiReason.ProviderNotAvailable;
    case uiReason.ProviderSick:
      return apiReason.ProviderSick;
  }
};
type ReasonMapper = (fields: FormFields) => MissedDeliveryReason;

const extenuatingFamilyCircumstanceMapper: ReasonMapper = values => {
  switch (values.extenuatingFamilyCircumstance!) {
    case ExtenuatingFamilyCircumstance.FamilyCancelled:
      return MissedDeliveryReason.EFCFamilyCancelled;
    case ExtenuatingFamilyCircumstance.FamilyNoShow:
      return MissedDeliveryReason.EFCFamilyNoShow;
    case ExtenuatingFamilyCircumstance.LossOfContact:
      return MissedDeliveryReason.EFCLossOfContact;
  }
};

export const adaptNotDeliveredForm = (
  values: FormFields,
  user: TrainingUser,
  service: ServicePlan
): CreateTrackedSessionNotDelivered => {
  return {
    childId: values.child,
    servicePlanId: values.service,
    serviceTypeId: service.serviceTypeId,
    providerId: user.email,
    providerName: user.name,
    status: Status.NotDelivered,
    dateOfService: values.scheduledAppointmentDate!.toISOString(),
    trackedSessionId: uuid(),
    reasonForMissedDelivery: {
      reason: reasonForMissedDelivery(values),
      ...(values.reasonForMissedDelivery === FormMissedDeliveryReason.Other &&
      values.reasonForMissedDeliveryNote
        ? { note: values.reasonForMissedDeliveryNote }
        : {}),
    },
  } as CreateTrackedSessionNotDelivered;
};
