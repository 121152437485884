import { FC } from 'react';
import { useParams } from 'react-router-dom';
import ESITServicesCard from '../ESITServicesCard/ESITServicesCard';
import InlineAlert from 'components/alerts/InlineAlert';
import NoneYetPlaceholder from 'components/NoneYetPlaceholder';
import useESITServices from '../useESITServices';
import useOutcomes from '../useOutcomes';

const ESITServicesCardList: FC<{ isFormOpen: boolean }> = ({ isFormOpen }) => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: outcomes } = useOutcomes(childId);
  const { data: services, error } = useESITServices(childId);

  const noEsitServicesPlaceholder = isFormOpen ? null : (
    <NoneYetPlaceholder
      message={
        outcomes && outcomes.length > 0
          ? 'An ESIT service has not yet been added for this child.'
          : 'A child outcome must be added before a EIST service can be added. '
      }
    />
  );

  return (
    <>
      {error && (
        <InlineAlert
          marginTop={4}
          status="warning"
          title="Hang on! We can't load this section right now."
          description="We'll keep trying."
        />
      )}
      {services && services.length > 0
        ? services.map(svc => (
            <ESITServicesCard key={`service-${svc.id}`} service={svc} />
          ))
        : noEsitServicesPlaceholder}
    </>
  );
};

export default ESITServicesCardList;
