import { Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import TransitionConferenceDeclinedView from './TransitionConferenceDeclinedView';
import TransitionConferenceHeldView from './TransitionConferenceHeldView';
import useTransition from '../useTransition';

const TransitionConferenceView = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: transition } = useTransition(childId);

  return (
    <Flex direction="column" flexGrow={1}>
      {transition?.conferenceHappened === undefined ||
      transition?.conferenceHappened ? (
        <TransitionConferenceHeldView />
      ) : (
        <TransitionConferenceDeclinedView />
      )}
    </Flex>
  );
};

export default TransitionConferenceView;
