import { AddressFormSection, ContactInputSection } from 'molecules';
import { Box, Heading } from '@chakra-ui/layout';
import { ChakraProps } from '@chakra-ui/react';
import { Checkbox, TextField } from 'components/formik';
import { FC } from 'react';
import {
  FieldControlMap,
  PartialFieldControlMap,
  defaultFieldControls,
} from './constants';
import { FormSection } from 'components/styled/forms/FormSection';
import { useField } from 'formik';
import ContactRadioSection from 'molecules/ContactRadioSection';

interface ContactInfoFormProps extends ChakraProps {
  readonly fieldOverrides?: PartialFieldControlMap;
}

const mergeFieldOverrides = (fieldOverrides: PartialFieldControlMap) => {
  const mergedOverrides: PartialFieldControlMap = {};

  for (const key in defaultFieldControls) {
    let castedKey = key as keyof FieldControlMap;
    mergedOverrides[castedKey] = {
      ...defaultFieldControls[castedKey],
      ...fieldOverrides[castedKey],
    };
  }

  return mergedOverrides as FieldControlMap;
};

const ContactInfoForm: FC<ContactInfoFormProps> = ({
  fieldOverrides,
  ...rest
}) => {
  const {
    name,
    firstName,
    middleName,
    lastName,
    relationship,
    isPrimaryContact,
    physicalAddress1,
    physicalAddress2,
    physicalCity,
    physicalState,
    physicalZip,
    isAddressSame,
    childLivesHere,
    mailingAddress1,
    mailingAddress2,
    mailingCity,
    mailingState,
    mailingZip,
    phoneCell,
    phoneHome,
    phoneGeneric,
    phoneWork,
    fax,
    email,
    preferredContactMethod,
  } = mergeFieldOverrides(fieldOverrides ?? {});

  const [isAddressSameField] = useField(isAddressSame.name);

  return (
    <Box {...rest}>
      <FormSection>
        {name.isVisible && (
          <TextField
            name={name.name}
            label={name.label}
            required={name.required}
          />
        )}
        {firstName.isVisible && (
          <TextField
            name={firstName.name}
            label={firstName.label}
            required={firstName.required}
          />
        )}
        {middleName.isVisible && (
          <TextField
            name={middleName.name}
            label={middleName.label}
            required={middleName.required}
          />
        )}
        {lastName.isVisible && (
          <TextField
            name={lastName.name}
            label={lastName.label}
            required={lastName.required}
          />
        )}
        {relationship.isVisible && (
          <TextField
            name={relationship.name}
            label={relationship.label}
            required={relationship.required}
          />
        )}
        {isPrimaryContact.isVisible && (
          <Checkbox
            value="true"
            name={isPrimaryContact.name}
            label={isPrimaryContact.label}
          />
        )}
      </FormSection>
      <FormSection>
        <Heading as="h4" variant="tertiary">
          Contact methods
        </Heading>
        <ContactInputSection
          fieldOverrides={{
            phoneCell,
            phoneGeneric,
            phoneHome,
            phoneWork,
            fax,
            email,
          }}
        />
      </FormSection>
      <FormSection>
        <Heading as="h4" variant="tertiary">
          Physical address
        </Heading>
        <AddressFormSection
          fieldOverrides={{
            address1: physicalAddress1,
            address2: physicalAddress2,
            city: physicalCity,
            state: physicalState,
            zip: physicalZip,
          }}
        />
        {isAddressSame.isVisible && (
          <Checkbox
            value="true"
            name={isAddressSame.name}
            label={isAddressSame.label}
          />
        )}
        {childLivesHere.isVisible && (
          <Checkbox
            value="true"
            name={childLivesHere.name}
            label={childLivesHere.label}
          />
        )}
      </FormSection>
      {!isAddressSameField.value && (
        <FormSection>
          <Heading as="h4" variant="tertiary">
            Mailing address
          </Heading>
          <AddressFormSection
            fieldOverrides={{
              address1: mailingAddress1,
              address2: mailingAddress2,
              city: mailingCity,
              state: mailingState,
              zip: mailingZip,
            }}
          />
        </FormSection>
      )}
      <FormSection>
        <ContactRadioSection
          fieldOverrides={{
            contactRadio: preferredContactMethod,
            phoneCell,
            phoneGeneric,
            phoneHome,
            phoneWork,
            fax,
            email,
          }}
        />
      </FormSection>
    </Box>
  );
};

export default ContactInfoForm;
