import { Box } from '@chakra-ui/react';
import { ChildDetailsSchema, FormFields, initialValues } from './validation';
import { FC, useCallback } from 'react';
import { Formik } from 'formik';
import { ModalFormLayout } from 'molecules';
import { formMaxWidthStyles } from '../styles';
import { useChildDetailsContext } from 'contexts/ChildDetailsContext';
import { useChildMatch } from '../ChildMatchSection/useChildMatch';
import ChildDetailsForm from './ChildDetailsForm';
import ChildDetailsHeaderButtons from './ChildDetailsHeaderButtons';
import ChildMatchErrorBanner from '../ChildMatchErrorBanner';

const ChildDetails: FC = () => {
  const childMatch = useChildMatch();
  const [, setChildDetails] = useChildDetailsContext();

  const onSubmit = useCallback(
    (values: FormFields) => {
      setChildDetails(values);
    },
    [setChildDetails]
  );

  return (
    <Formik
      validationSchema={ChildDetailsSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {_formik => {
        return (
          <ModalFormLayout
            formButtons={<ChildDetailsHeaderButtons />}
            title="Create Referral"
          >
            {childMatch.state.searchException && <ChildMatchErrorBanner />}
            <Box maxWidth={formMaxWidthStyles}>
              <ChildDetailsForm childMatch={childMatch} />
            </Box>
          </ModalFormLayout>
        );
      }}
    </Formik>
  );
};

export default ChildDetails;
