import { Dispatch, FC, SetStateAction } from 'react';
import { SectionHeader } from 'molecules';
import { Text } from '@chakra-ui/react';
import { colors } from 'theme';
import { useParams } from 'react-router-dom';
import { usePastReferrals } from 'hooks';
import BaseButton from 'components/BaseButton';

interface Props {
  readonly showArchived: boolean;
  setShowArchived: Dispatch<SetStateAction<boolean>>;
}

const ReferralSectionHeader: FC<Props> = ({
  showArchived,
  setShowArchived,
}) => {
  const { id: childId } = useParams<{ id: string }>();
  const pastReferrals = usePastReferrals(childId);

  const textButtonColor = colors.teal[700];
  const textButtonHoverColor = colors.teal[600];
  const textButtonStyles = {
    color: textButtonColor,
    textDecoration: 'underline',
    textDecorationColor: textButtonColor,
    ':hover': {
      color: textButtonHoverColor,
      textDecorationColor: textButtonHoverColor,
    },
  };

  const iconButton =
    !pastReferrals || !pastReferrals.length ? null : (
      <BaseButton
        onClick={() => setShowArchived(prevArchived => !prevArchived)}
        sx={textButtonStyles}
      >
        <Text sx={textButtonStyles} textStyle="bodyText">
          {showArchived ? 'Hide' : 'View'} past referrals
        </Text>
      </BaseButton>
    );

  return (
    <SectionHeader
      iconButton={iconButton}
      headerText="Referrals"
      isFormOpen={false}
    />
  );
};

export default ReferralSectionHeader;
