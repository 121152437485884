import * as yup from 'yup';
import { yupOptionalLongString, yupRequiredString } from 'lib';

export enum DocumentAssociation {
  NONE = 'association-none',
  CONSENT = 'association-consent',
  EVALTOOLS = 'association-evaltools',
}

export const formSchema = yup
  .object({
    title: yupRequiredString('Document name is required'),
    documentAssociation: yupRequiredString().oneOf(
      Object.values(DocumentAssociation),
      err => `Bad value for association: ${err.value}`
    ),
    notes: yupOptionalLongString(),
    file: yup.mixed().required('Attached document is required'),
  })
  .defined();

export type FormFields = yup.InferType<typeof formSchema>;
