import { Box, HStack, Text } from '@chakra-ui/react';
import { ReactComponent as DCYFLogo } from 'icons/dcyf-logo-footer.svg';
import { FC } from 'react';

const Copyright: FC = () => (
  <HStack spacing={1}>
    <Box as={DCYFLogo} minWidth="fit-content" />
    <Text textStyle="bodyText" color="teal.200">
      {`©${new Date().getFullYear()} Washington State Department of Children, Youth & Families`}
    </Text>
  </HStack>
);

export default Copyright;
