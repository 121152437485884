import { theme as chakraTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { keyframes } from '@emotion/react';

/*
 * Returns a css calc for grid column widths
 *
 * Note: no outer gutters accounted for here... yet
 */
export const gridColumns = (num: number) => {
  return `calc((${space.gridColumn} * ${num}) + (${space.gridGutter} * ${
    num - 1
  }))`;
};

// @chakra-ui/react config
export const config = {
  initialColorMode: 'light' as const,
  useSystemColorMode: false,
};

export const breakpoints = createBreakpoints({
  base: '0px',
  sm: '400px',
  md: '1200px',
  lg: '1200px',
  xl: '1200px',
  '2xl': '1200px',
});

export const colors = {
  ...chakraTheme.colors,
  placeholder: '#6E777F',
  teal: {
    50: '#F5FAFC',
    100: '#E3F3F4',
    200: '#C7E7EC',
    300: '#A2D8E1',
    400: '#65C9DA',
    500: '#3EAFC2',
    600: '#1A8EA2',
    700: '#0A5A67',
    800: '#003D47',
    900: '#00161A',
  },
  gray: {
    50: '#FAFAFA',
    100: '#F0F0F0',
    200: '#E4E8EE',
    300: '#CDD5DF',
    400: '#A3AEBE',
    500: '#748094',
    600: '#4C5566',
    700: '#2F3747',
    800: '#003D47',
    900: '#00161A',
  },
};

export const zIndexMap = {
  formLayer: 20,
  bannerLayer: 30,
  sidebarLayer: 40,
  navigationLayer: 50,
  datePicker: 60,
};

export const shadows = {
  ...chakraTheme.shadows,
  outline: '0 0 0 3px rgba(194,124,207, 0.7)',
};

export const fonts = {
  ...chakraTheme.fonts,
  body: 'DM Sans',
  primary: 'DM Sans',
  heading: 'Assistant',
  secondary: 'Assistant',
};

export const fontSizes = {
  ...chakraTheme.fontSizes,
  xs: '.875rem',
  xxs: chakraTheme.fontSizes.xs,
};

export const letterSpacings = {
  ...chakraTheme.letterSpacings,
  small: '0.01em',
  moreWidest: '0.15em',
};

const spacingUnit = 16;
export const space = {
  ...chakraTheme.space,
  quarter: `${spacingUnit / 4}px`,
  half: `${spacingUnit / 2}px`,
  one: `${spacingUnit}px`,
  two: `${2 * spacingUnit}px`,
  three: `${3 * spacingUnit}px`,
  six: `${6 * spacingUnit}px`,
  eight: `${8 * spacingUnit}px`,
  ten: `${10 * spacingUnit}px`,
  gridColumn: `${4 * spacingUnit}px`,
  gridGutter: `${2 * spacingUnit}px`,
};

const numeric = (numberWithUnits: string): number =>
  parseInt(numberWithUnits.replace(/[a-z]/g, ''), 10);

export const spaceUnitless = Object.fromEntries(
  Object.entries(space).map(([k, v]) => [k, numeric(v)])
);

export const textStyles = {
  bodyText: {
    fontFamily: fonts.primary,
    fontSize: chakraTheme.fontSizes.sm,
    fontWeight: chakraTheme.fontWeights.normal,
    lineHeight: chakraTheme.lineHeights.base,
  },
  sidebarNavItem: {
    fontFamily: fonts.primary,
    fontWeight: chakraTheme.fontWeights.normal,
    fontSize: chakraTheme.fontSizes.sm,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    color: 'teal.600',
    textDecoration: 'underline',
    '& svg, & svg path': {
      fill: 'gray.600',
    },
    ':hover': {
      fontWeight: chakraTheme.fontWeights.medium,
      color: 'teal.700',
      textDecoration: 'underline',
      letterSpacing: letterSpacings.small,
      '& svg, & svg path': {
        fill: 'teal.600',
      },
    },
    ':active': {
      fontWeight: chakraTheme.fontWeights.bold,
      color: 'teal.600',
      letterSpacing: letterSpacings.small,
      '& svg, & svg path': {
        fill: 'teal.600',
      },
    },
  },
  label: {
    fontSize: chakraTheme.fontSizes.sm,
    lineHeight: chakraTheme.lineHeights.base,
    fontWeight: chakraTheme.fontWeights.normal,
    letterSpacing: chakraTheme.letterSpacings.wide,
  },
  lightLabel: {
    fontSize: chakraTheme.fontSizes.sm,
    lineHeight: chakraTheme.lineHeights.base,
    fontWeight: chakraTheme.fontWeights.normal,
    marginBottom: space.quarter,
    color: 'gray.700',
  },
  bigLabel: {
    fontFamily: fonts.primary,
    fontSize: chakraTheme.fontSizes.xl,
    fontWeight: chakraTheme.fontWeights.medium,
    lineHeight: chakraTheme.lineHeights.base,
    flexBasis: '100%',
    marginBottom: space[3],
  },
  table: {
    fontFamily: fonts.secondary,
    fontSize: chakraTheme.fontSizes.lg,
    fontWeight: chakraTheme.fontWeights.normal,
    lineHeight: chakraTheme.lineHeights.tall,
  },
  sectionFieldViewLabel: {
    fontFamily: fonts.primary,
    fontSize: chakraTheme.fontSizes.xs,
    fontWeight: chakraTheme.fontWeights.bold,
    letterSpacing: letterSpacings.wider,
    lineHeight: 'short',
    color: 'teal.700',
    textTransform: 'uppercase',
  },
  sectionFieldViewValue: {
    fontFamily: fonts.primary,
    fontSize: chakraTheme.fontSizes.sm,
    fontWeight: 'medium',
    lineHeight: 'base',
    color: 'gray.900',
  },
  headingLabel: {
    fontFamily: fonts.primary,
    fontSize: chakraTheme.fontSizes.lg,
    fontWeight: chakraTheme.fontWeights.normal,
    lineHeight: chakraTheme.lineHeights.base,
  },
  detailSectionViewHeader: {
    fontFamily: fonts.primary,
    fontSize: chakraTheme.fontSizes.xs,
    fontWeight: chakraTheme.fontWeights.bold,
    letterSpacing: letterSpacings.wider,
    lineHeight: 'short',
    color: 'teal.700',
    textTransform: 'uppercase',
  },
  iconText: {
    '&, & *': {
      fontFamily: fonts.primary,
      fontSize: fontSizes.sm,
      fontWeight: 400,
      color: 'teal.600',
      '& svg, & svg path': {
        fill: 'teal.600',
      },
    },
  },
  textFieldLabel: {
    marginLeft: 4,
  },
};

export const transition = {
  ...chakraTheme.transition,
  sideBar: `all 0.2s ease 0s`,
  quick: 'all .1s ease 0s',
};

const radioGroup = {
  border: 'none',
  marginInlineStart: 'unset',
  marginInlineEnd: 'unset',
  paddingInlineStart: 'unset',
  paddingInlineEnd: 'unset',
  paddingBlockStart: 'unset',
  paddingBlockEnd: 'unset',
  minInlineSize: 'unset',
  legend: {
    paddingInlineStart: 'unset',
    paddingInlineEnd: 'unset',
    border: 'none',
  },
};

const radioPill = {
  label: {
    ...textStyles.bodyText,
    cursor: 'pointer',
    minWidth: 'fit-content',
    lineHeight: '1.15em',
    whiteSpace: ['wrap', 'wrap', 'nowrap'],
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: '7px',
    paddingBottom: '7px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 'md',
    userSelect: 'none',
    width: 'auto',
    '&:focus-within': {
      boxShadow: 'outline',
    },
    '& input': {
      minWidth: 0,
      position: 'absolute',
      opacity: 0,
      zIndex: -1,
      width: '1px',
      height: '1px',
      overflow: 'hidden',
    },
  },
};

export const forms = {
  classicRadioGroup: {
    ...radioGroup,
    '& > div': {
      flexDirection: 'column',
      '& > div:not(:last-child)': {
        marginBottom: space.one,
      },
    },
  },
  classicRadio: {
    label: {
      ...textStyles.bodyText,
      fontSize: fontSizes.xs,
      cursor: 'pointer',
      alignItems: 'center',
      userSelect: 'none',
      '& > div': {
        flexBasis: '28px',
      },
    },
    unselectedLabel: {
      ...textStyles.bodyText,
      cursor: 'pointer',
      fontSize: fontSizes.xs,
      fontWeight: chakraTheme.fontWeights.medium,
      alignItems: 'center',
      userSelect: 'none',
      '& > div': {
        flexBasis: '28px',
      },
    },
    selectedLabel: {
      ...textStyles.bodyText,
      cursor: 'pointer',
      fontSize: fontSizes.xs,
      fontWeight: chakraTheme.fontWeights.bold,
      alignItems: 'center',
      userSelect: 'none',
      '& > div': {
        flexBasis: '28px',
      },
    },
    svg: {
      marginRight: space.half,
      fill: 'gray.700',
    },
  },
  radioPillGroup: {
    ...radioGroup,
    '& > div': {
      flexWrap: 'wrap',
      marginBottom: `-${space.one}`, // remove row wrapping space from bottom pill row
      '& > div': {
        marginBottom: space.one,
      },
    },
  },
  radioPill: {
    disabledLabel: {
      ...radioPill.label,
      // text
      '& div': {
        color: 'gray.500',
        fontWeight: 'medium',
      },
      backgroundColor: 'white',
      borderColor: 'gray.500',
      cursor: 'not-allowed',
      opacity: 0.3,
      '& svg': {
        marginRight: 2,
      },
    },
    selectedLabel: {
      ...radioPill.label,
      // text
      '& div': {
        color: 'teal.700',
        fontWeight: 'medium',
      },
      backgroundColor: 'teal.200',
      borderColor: 'teal.700',
      '& svg': {
        marginRight: 2,
      },
    },
    unselectedLabel: {
      ...radioPill.label,
      // text
      '& div': {
        color: 'gray.500',
      },
      backgroundColor: 'white',
      borderColor: 'gray.500',
    },
  },
  checkbox: {
    svg: {
      marginRight: space.half,
      fill: 'gray.700',
      width: 20,
      height: 20,
    },
    uncheckedLabel: {
      ...textStyles.bodyText,
      cursor: 'pointer',
      alignItems: 'center',
      fontWeight: chakraTheme.fontWeights.medium,
      userSelect: 'none',
    },
    checkedLabel: {
      ...textStyles.bodyText,
      cursor: 'pointer',
      alignItems: 'center',
      fontWeight: chakraTheme.fontWeights.bold,
      userSelect: 'none',
    },
  },
};

export const cards = {
  info: {
    border: '1px solid',
    backgroundColor: 'green.50',
    borderColor: 'green.500',
    borderRadius: chakraTheme.radii.md,
    padding: space.one,
  },
  secondaryBox: {
    marginBottom: space[4],
    marginLeft: space[2],
    paddingLeft: space[4],
    paddingBottom: space[4],
    marginTop: `-${space[2]}`,
  },
};

const ellipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const tableCell = {
  fontFamily: fonts.primary,
  fontSize: chakraTheme.fontSizes.sm,
  fontWeight: chakraTheme.fontWeights.normal,
  letterSpacing: letterSpacings.small,
  lineHeight: chakraTheme.lineHeights.short,
  color: 'gray.700',
  py: space.one,
  pr: space.two,
  pl: 0,
  borderColor: 'gray.400',
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  ...ellipsis,
};

export const dividers = {
  horizontal: {
    width: '100%',
    height: 0,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'gray.300',
  },
};

export const tables = {
  table: {
    borderCollapse: 'separate',
    borderRadius: 'lg',
    borderSpacing: 0,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    width: '100%',
    color: 'gray.700',
    tableLayout: 'fixed',
  },
  tableCell: {
    ...tableCell,
    '&:first-of-type': {
      paddingLeft: [null, null, space.two],
    },
    'tr:last-child &': {
      borderBottom: '0 none',
    },
  },
  tableHeader: {
    fontFamily: fonts.primary,
    fontSize: chakraTheme.fontSizes.xs,
    fontWeight: chakraTheme.fontWeights.bold,
    letterSpacing: letterSpacings.small,
    lineHeight: chakraTheme.lineHeights.short,
    textAlign: 'left',
    borderColor: 'gray.400',
    textTransform: 'uppercase',
    py: '2em',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    position: 'sticky',
    borderTopRadius: 'lg',
    backgroundColor: 'white',
    backgroundClip: 'padding-box',
    '&:first-of-type': {
      paddingLeft: space.two,
    },
    ...ellipsis,
  },
};

const banner = {
  width: ['100%', '100%', '87%'],
  position: 'fixed',
  top: 0,
  left: [0, 0, '13%'],
  right: 0,
  zIndex: zIndexMap.bannerLayer,
  borderBottomWidth: 2,
  borderBottomStyle: 'solid',
  padding: space.one,
  alignItems: 'center',
  justifyContent: 'center',
};

export const banners = {
  error: {
    ...banner,
    background: 'red.50',
    borderBottomColor: 'red.200',
    '& svg, & svg path': { fill: 'red.500' },
  },
};

const pendingDotsAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0.25;
  }
`;

export const animations = {
  pendingDots: {
    width: 'fit-content',
    '> *': {
      width: space.half,
      height: space.half,
      borderRadius: space.half,
      backgroundColor: 'teal.700',
      animation: `${pendingDotsAnimation} 0.5s infinite linear alternate`,
      '@media (prefers-reduced-motion)': {
        animation: `${pendingDotsAnimation} 1s infinite linear alternate`,
      },
      '&:not(:last-child)': {
        marginRight: space.quarter,
      },
      '&:nth-of-type(1)': {
        animationDelay: '0s',
      },
      '&:nth-of-type(2)': {
        animationDelay: '0.25s',
        '@media (prefers-reduced-motion)': {
          animationDelay: '0.5s',
        },
      },
      '&:nth-of-type(3)': {
        animationDelay: '0.5s',
        '@media (prefers-reduced-motion)': {
          animationDelay: '1s',
        },
      },
    },
  },
};

// @chakra-ui/react component overrides
export const components = {
  Avatar: {
    parts: ['label', 'container'],
    variants: {
      userProfile: {
        label: {
          color: 'teal.700',
        },
        container: {
          bg: 'white',
          svg: {
            path: {
              fill: 'teal.700',
            },
          },
        },
      },
    },
  },
  Button: {
    defaultProps: {
      colorScheme: 'teal',
    },
    sizes: {
      md: {
        h: 'auto',
        minW: 'fit-content',
        py: 4,
        px: '22px',
      },
    },
    variants: {
      solid({ colorScheme }: any) {
        return {
          borderRadius: 'full',
          color: 'white',
          bg: `${colorScheme}.700`,
          '& svg > *': {
            fill: 'white',
          },
          _disabled: {
            bg: `${colorScheme}.600`,
            opacity: 0.3,
          },
          _hover: {
            bg: `${colorScheme}.600`,
            boxShadow: 'sm',
            _disabled: { bg: 'gray.600' },
            _focus: { boxShadow: 'outline' },
          },
          _active: { bg: `${colorScheme}.600` },
        };
      },
      outline({ colorScheme }: any) {
        return {
          borderRadius: 'full',
          py: '15px',
          color: `${colorScheme}.700`,
          borderColor: `${colorScheme}.700`,
          '& svg > *': {
            fill: `${colorScheme}.700`,
          },
          _disabled: {
            color: `${colorScheme}.700`,
            borderColor: `${colorScheme}.700`,
            bg: 'transparent',
          },
          _hover: {
            color: `${colorScheme}.600`,
            borderColor: `${colorScheme}.600`,
            bg: 'transparent',
            boxShadow: 'sm',
            '& svg > *': {
              fill: `${colorScheme}.600`,
            },
            _disabled: {
              color: 'gray.600',
              borderColor: 'gray.600',
              '& svg > *': {
                fill: 'gray.600',
              },
            },
          },
          _active: {
            color: `${colorScheme}.500`,
            borderColor: `${colorScheme}.500`,
            bg: 'transparent',
            '& svg > *': {
              fill: `${colorScheme}.500`,
            },
          },
        };
      },
      ghost({ colorScheme }: any) {
        return {
          p: 3,
          borderRadius: 'md',
          color: `${colorScheme}.600`,
          '& svg > *': {
            fill: `${colorScheme}.600`,
          },
          _hover: {
            color: `${colorScheme}.700`,
            bg: `${colorScheme}.50`,
            boxShadow: 'sm',
            '& svg > *': {
              fill: `${colorScheme}.700`,
            },
          },
          _active: { bg: `${colorScheme}.100` },
        };
      },
      link({ colorScheme }: any) {
        return {
          ...textStyles.bodyText,
          textDecoration: 'underline',
          color: `${colorScheme}.600`,
          _hover: {
            color: `${colorScheme}.700`,
          },
        };
      },
    },
  },
  Checkbox: {
    parts: ['control', 'label'],
    baseStyle: {
      control: {
        borderColor: 'teal.700',
        borderRadius: '3px',
        _checked: {
          bg: 'teal.700',
          borderColor: 'teal.700',
          _hover: {
            bg: 'teal.600',
            borderColor: 'teal.600',
          },
        },
      },
    },
  },
  Divider: {
    baseStyle: {
      borderColor: colors.gray[300],
    },
  },
  Form: {
    parts: ['requiredIndicator'],
    baseStyle: {
      requiredIndicator: {
        color: 'teal.700',
      },
    },
  },
  FormLabel: {
    baseStyle: {
      ...textStyles.label,
    },
    variants: {
      lightLabel: {
        ...textStyles.lightLabel,
      },
      headingLabel: {
        ...textStyles.headingLabel,
      },
    },
  },
  Heading: {
    variants: {
      appTitle: {
        fontFamily: fonts.secondary,
        fontWeight: chakraTheme.fontWeights.bold,
        fontSize: chakraTheme.fontSizes['2xl'],
        lineHeight: chakraTheme.lineHeights.base,
        letterSpacing: letterSpacings.wide,
        color: 'teal.700',
      },
      detailSection: {
        fontFamily: fonts.secondary,
        fontWeight: chakraTheme.fontWeights.bold,
        fontSize: chakraTheme.fontSizes['xl'],
        lineHeight: chakraTheme.lineHeights.base,
        color: 'teal.700',
      },
      primary: {
        fontSize: chakraTheme.fontSizes['3xl'],
        lineHeight: chakraTheme.lineHeights.base,
        fontFamily: fonts.secondary,
        fontWeight: chakraTheme.fontWeights.bold,
      },
      secondary: {
        fontFamily: fonts.primary,
        fontSize: chakraTheme.fontSizes['2xl'],
        fontWeight: chakraTheme.fontWeights.medium,
        lineHeight: chakraTheme.lineHeights.base,
      },
      tertiary: {
        ...textStyles.headingLabel,
        fontWeight: chakraTheme.fontWeights.medium,
      },
    },
  },
  Input: {
    parts: ['field'],
    variants: {
      outline() {
        return {
          field: {
            border: '1px solid',
            borderColor: 'gray.300',
            boxShadow: 'none',
            _invalid: {
              boxShadow: 'none',
            },
            _focus: {
              borderColor: 'teal.600',
              boxShadow: 'outline',
            },
          },
        };
      },
    },
    defaultProps: {
      focusBorderColor: 'teal.600',
    },
  },
  Link: {
    baseStyle: {
      ...textStyles.bodyText,
      textDecoration: 'underline',
      'svg path': { fill: 'teal.600' },
      color: 'teal.600',
      ':hover': {
        'svg path': { fill: 'teal.700' },
        color: 'teal.700',
      },
    },
    variants: {
      section: {
        display: 'block',
        width: 'fit-content',
        p: 4,
        borderRadius: 'full',
        color: 'gray.600',
        textDecoration: 'none',
        _hover: {
          '&:focus': {
            boxShadow: 'outline',
          },
        },
        '&:hover': {
          color: `teal.700`,
          bg: `teal.50`,
          textDecoration: 'none',
          boxShadow: 'sm',
          letterSpacing: 'normal',
          fontWeight: 'normal',
        },

        _active: { bg: `teal.100` },
      },
      inlineBodyText: {
        ...textStyles.bodyText,
        whiteSpace: 'nowrap',
        color: 'teal.600',
        letterSpacing: 'normal',
        ':hover': {
          color: 'teal.700',
          fontWeight: 'normal',
          letterSpacing: 'normal',
        },
        ':active': {
          color: 'teal.600',
          fontWeight: 'normal',
          letterSpacing: 'normal',
        },
      },
      detailSectionFieldViewValue: {
        ...textStyles.sectionFieldViewValue,
        color: 'teal.600',
        letterSpacing: 'normal',
        ':hover': {
          color: 'teal.700',
          fontWeight: 'medium',
          letterSpacing: 'normal',
        },
        ':active': {
          color: 'teal.600',
          fontWeight: 'medium',
          letterSpacing: 'normal',
        },
      },
    },
  },
  Menu: {
    parts: ['list', 'item', 'groupTitle'],
    variants: {
      userProfile: {
        list: {
          py: 0,
          backgroundColor: 'gray.50',
          borderColor: 'gray.400',
          borderRadius: 'md',
        },
        groupTitle: {
          color: 'teal.700',
          textTransform: 'uppercase',
          letterSpacing: letterSpacings.moreWidest,
          fontSize: chakraTheme.fontSizes.xs,
        },
        item: {
          py: space.half,
          px: space.one,
          borderBottom: '1px solid',
          borderBottomColor: 'gray.400',
          '&:focus, &:active': {
            backgroundColor: 'teal.200',
          },
          '&:last-of-type': {
            borderBottomLeftRadius: 'md',
            borderBottomRightRadius: 'md',
            borderBottom: 'none',
          },
        },
      },
    },
  },
  Radio: {
    parts: ['container', 'control', 'label'],
    sizes: {
      lg: {
        control: {
          w: '18px',
          h: '18px',
        },
      },
    },
    defaultProps: {
      size: 'lg',
    },
    baseStyle: {
      control: {
        borderColor: 'teal.700',
        _checked: {
          bg: 'white',
          borderColor: 'teal.700',
          _hover: {
            bg: 'white',
            borderColor: 'teal.600',
            _before: {
              bg: 'teal.600',
            },
          },
          _before: {
            bg: 'teal.700',
            width: '12px',
            height: '12px',
          },
        },
      },
      container: {
        alignItems: 'start',
        fontSize: fontSizes.xs,
        ':not(:last-child)': {
          marginBottom: space[4],
        },
      },
    },
  },
  Select: {
    parts: ['field'],
    variants: {
      outline() {
        return {
          field: {
            _focus: {
              borderColor: 'teal.600',
              boxShadow: 'outline',
            },
          },
        };
      },
    },
  },
  Tabs: {
    parts: ['tabpanel', 'tablist', 'tab'],
    variants: {
      line() {
        return {
          tablist: {
            marginLeft: -4,
            borderBottom: '1px solid',
            borderColor: 'gray.400',
            marginBottom: 8,
          },
          tab: {
            fontSize: 'lg',
            color: 'gray.700',
            paddingBottom: '9px',
            _selected: {
              color: 'gray.900',
              borderBottom: '3px solid',
              paddingBottom: '8px',
            },
          },
          tabpanel: {
            padding: 0,
          },
        };
      },
    },
  },
  Text: {
    variants: {
      bodyText: {
        ...textStyles.bodyText,
      },
    },
  },
  Textarea: {
    variants: {
      outline() {
        return {
          _focus: {
            borderColor: 'teal.600',
            boxShadow: 'outline',
          },
        };
      },
    },
  },
  Tooltip: {
    baseStyle: {
      backgroundColor: 'teal.50',
      borderRadius: chakraTheme.radii.md,
      color: 'teal.700',
      fontSize: 'sm',
      fontWeight: '400',
      padding: 3,
    },
  },
};

export const globalStyles = {
  '*': {
    boxSizing: 'border-box',
    color: 'gray.700',
  },
  '*::placeholder': {
    color: 'placeholder',
  },
  'html, body, #root': {
    height: '100%',
  },
  ul: { margin: 0, padding: 0, listStyle: 'none' },
  // react-day-picker
  '.DayPickerInput-OverlayWrapper': {
    zIndex: zIndexMap.datePicker,
  },
  '.DayPickerInput': {
    '&, & > input': {
      width: 'full',
      boxSizing: 'border-box',
    },
  },
  '.DayPicker-Month': {
    margin: 2,
  },
  '.DayPicker-Day': {
    minWidth: 10,
    minHeight: 10,
  },
  '.DayPicker-Day--selected:not(.DayPicker-Day--disabled)': {
    '&:not(.DayPicker-Day--outside)': {
      '&, &:hover': {
        backgroundColor: `${colors.teal[200]} !important`,
        color: `${colors.gray[700]} !important`,
        fontWeight: `${chakraTheme.fontWeights.medium} !important`,
      },
    },
  },
};

export const styles = {
  global: globalStyles,
};

const statusBadge = {
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '3px',
  paddingTop: '3px',
  paddingBottom: '3px',
  paddingRight: '8px',
  paddingLeft: '8px',
  fontSize: '8px',
  lineHeight: '8px',
  letterSpacing: 'widest',
};

export const badges = {
  active: {
    ...statusBadge,
    backgroundColor: 'green.50',
    borderColor: 'green.500',
    color: 'green.500',
  },
  inactive: {
    ...statusBadge,
    backgroundColor: 'gray.50',
    borderColor: 'gray.500',
    color: 'gray.500',
  },
};
