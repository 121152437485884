import * as yup from 'yup';
import { Diagnosis } from '@cssat/acorn-api-shared';
import {
  dateFormatData,
  dateFormatErrorString,
  yupNullableLongString,
  yupOptionalDate,
  yupOptionalLongString,
  yupRequiredBoolean,
  yupRequiredString,
  yupRequiredUuid,
} from 'lib';
import { parse, startOfToday } from 'date-fns';
import { v4 as uuid } from 'uuid';

export const formSchema = yup
  .object({
    id: yupRequiredUuid(),
    diagnosisName: yupRequiredString('Diagnosis name is required'),
    determinesEligibility: yupRequiredBoolean(),
    knownDiagnosisTypeId: yupRequiredString(),
    dateOfDiagnosis: yupOptionalDate()
      .typeError(dateFormatErrorString)
      .max(startOfToday(), 'Date cannot be in the future'),
    diagnosedBy: yup.object({
      name: yupOptionalLongString(),
      title: yupOptionalLongString(),
      organization: yupOptionalLongString(),
    }),
    notes: yupNullableLongString(),
    dateReceived: yupOptionalDate()
      .when('dateOfDiagnosis', {
        is: dateOfDiagnosis => dateOfDiagnosis !== undefined,
        then: yup
          .date()
          .min(
            yup.ref('dateOfDiagnosis'),
            'Date received cannot be before date of diagnosis'
          ),
      })
      .typeError(dateFormatErrorString)
      .max(startOfToday(), 'Date cannot be in the future'),
  })
  .defined();

export type FormFields = yup.InferType<typeof formSchema>;

export const coerceInitialValues = (diagnosis?: Diagnosis) => {
  const initialValues = {
    ...diagnosis,
    id: diagnosis ? diagnosis.id : uuid(),
    dateOfDiagnosis: diagnosis?.dateOfDiagnosis
      ? parse(diagnosis.dateOfDiagnosis, dateFormatData, new Date())
      : undefined,
    dateReceived: diagnosis?.dateReceived
      ? parse(diagnosis.dateReceived, dateFormatData, new Date())
      : undefined,
  };
  return initialValues;
};
