const descriptions = [
  (name: string) =>
    `${name} uses important skills similar to a much younger child.`,
  (name: string) =>
    `${name} is mostly using important skills similar to a much younger child.`,
  (name: string) =>
    `${name} is using skills that come right before age-expected skills . These skills are similar to a slightly younger child.`,
  (name: string) =>
    `Mostly, ${name} is using some skills that are age-expected but mostly using skills similar to a slightly younger child.`,
  (name: string) =>
    `${name} is using some skills that are similar to a slightly younger child but mostly using age expected skills.`,
  (name: string) =>
    `${name} has all of the skills we would expect to see for a child their age and there are still some concerns.`,
  (name: string) => `${name} uses all age-expected skills.`,
];

const ageExpectedSkillsDescription = (name: string, level: number): string => {
  if (level < 1 || level > descriptions.length) {
    throw new Error('Age expected skills level is not in the supported range');
  }

  return descriptions[level - 1](name);
};

const ageExpectedSkillsNumberAndDescription = (
  name: string,
  level: number
): string => level + ' - ' + ageExpectedSkillsDescription(name, level);

const ageExpectedSkillsReverseIndices: Array<number> = Array.from(
  { length: descriptions.length },
  (_, idx) => idx + 1
).reverse();

export {
  ageExpectedSkillsNumberAndDescription,
  ageExpectedSkillsDescription,
  ageExpectedSkillsReverseIndices,
};
