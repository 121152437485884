import { FieldControl } from 'molecules/types';

const addressFields = ['address1', 'address2', 'city', 'state', 'zip'] as const;

export type AddressFields = typeof addressFields[number];
export type PartialAddressControlMap = Partial<
  Record<AddressFields, Partial<FieldControl>>
>;
export type AddressControlMap = Record<AddressFields, FieldControl>;

export const defaultPhysicalAddressControls: AddressControlMap = {
  address1: {
    name: 'physicalAddress1',
    label: 'Address line 1',
    isVisible: true,
  },
  address2: {
    name: 'physicalAddress2',
    label: 'Address line 2',
    isVisible: true,
  },
  city: {
    name: 'physicalCity',
    label: 'City',
    isVisible: true,
  },
  state: {
    name: 'physicalState',
    label: 'State',
    isVisible: true,
  },
  zip: {
    name: 'physicalZip',
    label: 'Zip code',
    isVisible: true,
  },
};

export const defaultMailingAddressControls: AddressControlMap = {
  address1: {
    name: 'mailingAddress1',
    label: 'Address line 1',
    isVisible: true,
  },
  address2: {
    name: 'mailingAddress2',
    label: 'Address line 2',
    isVisible: true,
  },
  city: {
    name: 'mailingCity',
    label: 'City',
    isVisible: true,
  },
  state: {
    name: 'mailingState',
    label: 'State',
    isVisible: true,
  },
  zip: {
    name: 'mailingZip',
    label: 'Zip code',
    isVisible: true,
  },
};
