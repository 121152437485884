import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import { COS } from '@cssat/acorn-api-shared';
import { FC } from 'react';
import { JumpLinkRefIds, SectionHeader, SectionWrapper } from 'molecules';
import { SkillsSections } from '../SkillsForm/helpers';
import { formattedCosDate } from '../TabContent/helpers';
import { useOpenFormContext } from 'contexts';
import CosCompleteCard from '../CosCompleteCard/CosCompleteCard';
import CosInfoCard from '../CosInfoCard';
import SkillsCard from '../SkillsCard/SkillsCard';

const getHeaderText = (cos: COS) => {
  if (!cos.dateCompleted) {
    return 'COS - In Progress';
  }
  if (cos.isEntryCos) {
    return 'Entry COS';
  }
  if (cos.isLatestCompletedCos) {
    return `Current COS - ${formattedCosDate(cos.dateCompleted)}`;
  }
  return `COS - ${formattedCosDate(cos.dateCompleted)}`;
};

const ExpandedCos: FC<{ cos: COS }> = ({ cos, children }) => {
  const [openFormState] = useOpenFormContext();

  const isFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.LatestCos;

  return (
    <>
      <SectionHeader headerText={getHeaderText(cos)} isFormOpen={isFormOpen} />
      {children}
    </>
  );
};

const CollapsibleWrapper: FC<{ cos: COS }> = ({ cos, children }) => {
  const collapsible = cos.dateCompleted && !cos.isLatestCompletedCos;

  if (!collapsible) {
    return <ExpandedCos cos={cos}>{children}</ExpandedCos>;
  }

  return (
    <Accordion allowToggle>
      <AccordionItem border={0}>
        <AccordionButton padding={0}>
          <Box flex="1" textAlign="left">
            <SectionHeader
              headerText={getHeaderText(cos)}
              bgColor="gray.100"
              textColor="gray.700"
              iconButton={<AccordionIcon />}
            />
          </Box>
        </AccordionButton>
        <AccordionPanel padding={0}>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

const CosSection: FC<{ cos: COS }> = ({ cos }) => {
  return (
    <SectionWrapper id={cos.id} mb={12}>
      <CollapsibleWrapper cos={cos}>
        <CosInfoCard cos={cos} />
        <SkillsCard cos={cos} skillType={SkillsSections.POSITIVE} />
        <SkillsCard cos={cos} skillType={SkillsSections.ACQUIRING} />
        <SkillsCard cos={cos} skillType={SkillsSections.ACTIONS} />
      </CollapsibleWrapper>
      {!cos.dateCompleted && <CosCompleteCard cos={cos} />}
    </SectionWrapper>
  );
};

export default CosSection;
