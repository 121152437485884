import { CosSkills } from '@cssat/acorn-api-shared';
import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { TruncatableTextBlock } from 'molecules';
import { ageExpectedSkillsDescription } from '../CosSection/helpers';
import { useParams } from 'react-router-dom';
import useChildRecord from 'pages/ChildRecord/OverviewTab/useChildRecord';

interface SkillsViewProps {
  cosSkills: CosSkills;
}

const SkillsView: FC<SkillsViewProps> = ({ cosSkills }) => {
  const { id: childId } = useParams<{ id: string }>();
  const { data } = useChildRecord(childId);
  const childDetails = data?.childDetails;

  if (!childDetails) return null;

  return (
    <Flex flexGrow={1} direction="column">
      <DetailSectionRow>
        <DetailSectionColumn flexBasis={'25%'}>
          <Text textStyle="bodyText" color="gray.600">
            Selected descriptor statement
          </Text>
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn flexBasis={'75%'}>
          <TruncatableTextBlock
            text={
              cosSkills?.level
                ? ageExpectedSkillsDescription(
                    childDetails.firstName,
                    cosSkills.level
                  )
                : undefined
            }
          />
        </DetailSectionColumn>
      </DetailSectionRow>

      <DetailSectionDivider orientation="horizontal" />

      {cosSkills.hasShownImprovement !== undefined && (
        <>
          <DetailSectionRow>
            <DetailSectionColumn flexBasis={'25%'}>
              <Text textStyle="bodyText" color="gray.600">
                Improvement within lower skill level
              </Text>
            </DetailSectionColumn>
            <DetailSectionDivider />
            <DetailSectionColumn flexBasis={'75%'}>
              <Text textStyle="bodyText">
                {childDetails.firstName}
                <Text as="strong">
                  {cosSkills.hasShownImprovement ? ' has ' : ' has not '}
                </Text>
                shown new skills or behaviors related to positive
                social-emotional skills since the last COS.
              </Text>
              <DetailSectionRow>
                <Text textStyle="bodyText">
                  {cosSkills.hasShownImprovementNotes}
                </Text>
              </DetailSectionRow>
            </DetailSectionColumn>
          </DetailSectionRow>

          <DetailSectionDivider orientation="horizontal" />
        </>
      )}

      <DetailSectionRow>
        <DetailSectionColumn flexBasis={'25%'}>
          <Text textStyle="bodyText" color="gray.600">
            Summary of functional performance
          </Text>
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn flexBasis={'75%'}>
          <TruncatableTextBlock text={cosSkills.summary} />
        </DetailSectionColumn>
      </DetailSectionRow>
    </Flex>
  );
};

export default SkillsView;
