import { FC, Fragment, ReactNode } from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface HeaderTag {
  readonly text: string;
  readonly key: string;
}

export interface Props {
  readonly headerText?: ReactNode;
  readonly headerTags?: HeaderTag[];
  readonly isArchived?: boolean;
  readonly buttons?: ReactNode;
}

const DataCardHeader: FC<Props> = ({
  headerText,
  headerTags,
  isArchived = false,
  buttons,
}) => {
  const hasHeaderTags = headerTags && headerTags?.length > 0;

  const backgroundColor = isArchived
    ? 'gray.100'
    : hasHeaderTags
    ? 'teal.50'
    : '';

  const headerTextContainerStyles = headerText && {
    borderBottom: '1px solid #CDD5DF',
    marginBottom: 6,
  };

  const headerTextStyles = headerText && {
    py: 4,
  };

  return (
    <Flex
      backgroundColor={backgroundColor}
      borderTopRadius="md"
      justifyContent="space-between"
      width="100%"
      mb={hasHeaderTags ? 3 : 0}
      py="2"
      pl="6"
      pr="3"
      minHeight="8"
      {...headerTextContainerStyles}
    >
      <Flex
        textStyle="sectionFieldViewLabel"
        fontSize="0.625rem"
        letterSpacing="widest"
        alignSelf="center"
        {...headerTextStyles}
      >
        {headerText && headerText}
        {hasHeaderTags &&
          headerTags?.map(({ text, key }, i, arr) => (
            <Fragment key={key}>
              <Text as="span" color="teal.700" pr={2}>
                {text}
              </Text>
              {i !== arr.length - 1 && (
                <Text as="span" color="teal.700" pr={2}>
                  |
                </Text>
              )}
            </Fragment>
          ))}
      </Flex>
      <Flex alignSelf="start">{buttons}</Flex>
    </Flex>
  );
};

export default DataCardHeader;
