import { DataCard, JumpLinkRefIds } from 'molecules';
import { FC } from 'react';
import { useOpenFormContext } from 'contexts';
import { useParams } from 'react-router-dom';
import MedicalDiagnosisForm from './MedicalDiagnosisForm/MedicalDiagnosisForm';
import useDiagnoses from '../useDiagnoses';

const AddNewDiagnosisFormContainer: FC = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data } = useDiagnoses(childId);
  const [openFormState] = useOpenFormContext();

  const loaded = !!data;

  const shouldShowAddForm =
    openFormState?.primaryJumpLink === JumpLinkRefIds.MedicalDiagnoses &&
    openFormState.secondaryJumplink === undefined;

  if (!loaded) {
    return null;
  }

  if (shouldShowAddForm) {
    return (
      <DataCard>
        <MedicalDiagnosisForm />
      </DataCard>
    );
  }

  return null;
};

export default AddNewDiagnosisFormContainer;
