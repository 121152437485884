import * as yup from 'yup';
import { cosSources } from '@cssat/acorn-api-shared';
import {
  dateFormatErrorString,
  yupOptionalDate,
  yupRequiredString,
  yupRequiredUuid,
} from 'lib';
import { startOfToday } from 'date-fns';

const booleanQuestionSchema = yup.string().test({
  name: 'Value is stringified boolean',
  message: 'Invalid value',
  test: val => val === undefined || val === 'true' || val === 'false',
});

export const formSchema = yup
  .object({
    id: yupRequiredUuid(),
    dateStarted: yupOptionalDate()
      .typeError(dateFormatErrorString)
      .max(startOfToday(), 'Date cannot be in the future'),

    usedDecisionTreeForPrep: booleanQuestionSchema,
    usedDecisionTreeForPrepNotes: yup.string().when('usedDecisionTreeForPrep', {
      is: 'false',
      then: yupRequiredString(),
    }),

    didFamilyParticipateDiscussion: booleanQuestionSchema,
    didFamilyParticipateDiscussionNotes: yup
      .string()
      .when('didFamilyParticipateDiscussion', {
        is: 'false',
        then: yupRequiredString(),
      }),

    didFamilyParticipateSelection: yup
      .string()
      .when('didFamilyParticipateDiscussion', {
        is: 'true',
        then: booleanQuestionSchema,
        otherwise: yup.string().optional(),
      }),
    didFamilyParticipateSelectionNotes: yup
      .string()
      .when('didFamilyParticipateSelection', {
        is: 'false',
        then: yupRequiredString(),
      }),

    usedESITDecisionTree: yup.string().when('didFamilyParticipateSelection', {
      is: 'true',
      then: booleanQuestionSchema,
      otherwise: yup.string().optional(),
    }),
    usedESITDecisionTreeNotes: yup.string().when('usedESITDecisionTree', {
      is: 'false',
      then: yupRequiredString(),
    }),

    sources: yup.array().of(yup.string().required().oneOf(cosSources)),
  })
  .defined();

export type FormFields = yup.InferType<typeof formSchema>;
