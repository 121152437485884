import { AcornFormField } from './AcornFormField';
import { FC } from 'react';
import {
  FormLabel,
  Input,
  InputProps,
  ResponsiveValue,
} from '@chakra-ui/react';
import { useDebounceOnChange } from 'hooks';
import { useField } from 'formik';

export interface TextFieldProps extends InputProps {
  name: string;
  required?: boolean;
  label?: string;
  textStyle?: ResponsiveValue<string & {}>;
}

export const TextField: FC<TextFieldProps> = ({
  name,
  label,
  required = false,
  textStyle,
  ...props
}) => {
  const [field] = useField(name);
  const [value, onChange] = useDebounceOnChange(
    field.value,
    field.onChange,
    '',
    val => val
  );

  return (
    <AcornFormField name={name} required={required}>
      {label && <FormLabel textStyle={textStyle}>{label}</FormLabel>}
      <Input
        textStyle="bodyText"
        data-testid={`input-${field.name}`}
        {...field}
        {...props}
        value={value}
        onChange={onChange}
      />
    </AcornFormField>
  );
};
