import {
  DOBToAge,
  dateFormatDisplayShort,
  formatISODate,
  races,
  toSentenceCase,
} from 'lib';
import {
  DetailSectionCheckMark,
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionFieldValue,
  DetailSectionNotes,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import useChildRecord from 'pages/ChildRecord/OverviewTab/useChildRecord';

const ChildDetailsCard: FC = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data } = useChildRecord(childId);
  const childDetails = data?.childDetails;

  if (!childDetails) {
    return null;
  }

  const formattedDOB = formatISODate(
    childDetails.dateOfBirth,
    dateFormatDisplayShort
  );
  const formattedEthnicity =
    childDetails.hispanicOrLatino === null
      ? '-'
      : `${childDetails.hispanicOrLatino ? '' : 'Not'} Hispanic or Latino`;

  const raceFields =
    childDetails.race.length > 0 &&
    childDetails.race.map(raceId => (
      <DetailSectionFieldValue
        key={raceId.toString()}
        textStyle="sectionFieldViewValue"
        value={races.find(race => race.id === raceId)?.race}
        mb={2}
      />
    ));

  const interpreterField = childDetails.interpreterNeeded && (
    <DetailSectionCheckMark text="Interpreter needed" />
  );

  const otherFields = (childDetails.inFosterCare ||
    childDetails.referredUnderCapta) && (
    <DetailSectionField label="Other information">
      {childDetails.inFosterCare && (
        <DetailSectionCheckMark text="Foster Care" />
      )}

      {childDetails.referredUnderCapta && (
        <DetailSectionCheckMark text="CAPTA referral" />
      )}
    </DetailSectionField>
  );

  return (
    <Flex direction="column" flexGrow={1}>
      <DetailSectionRow>
        <DetailSectionColumn>
          <DetailSectionField
            label="Name"
            value={`${childDetails.firstName} ${childDetails.lastName}`}
            showWhenEmpty
          />
          <DetailSectionField label="DOB" value={formattedDOB} showWhenEmpty />
          <DetailSectionField
            label="Age"
            value={DOBToAge(childDetails.dateOfBirth)}
            showWhenEmpty
          />
          <DetailSectionField
            label="Gender"
            value={toSentenceCase(childDetails.sex)}
            showWhenEmpty
          />
        </DetailSectionColumn>

        <DetailSectionDivider />

        <DetailSectionColumn>
          <DetailSectionField
            label="Ethnicity"
            value={formattedEthnicity}
            showWhenEmpty
          />
          <DetailSectionField label="Race" showWhenEmpty>
            {raceFields}
          </DetailSectionField>
        </DetailSectionColumn>

        <DetailSectionDivider />

        <DetailSectionColumn>
          <DetailSectionField label="Primary language">
            <DetailSectionFieldValue
              value={childDetails.primaryLanguage}
              showWhenEmpty
            />
            {interpreterField}
          </DetailSectionField>

          <DetailSectionField
            label="Other languages"
            value={childDetails.otherLanguages ?? ''}
          />
          {otherFields}
        </DetailSectionColumn>
      </DetailSectionRow>
      <DetailSectionNotes notes={childDetails.notes} />
    </Flex>
  );
};

export default ChildDetailsCard;
