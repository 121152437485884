import {
  Box,
  Button,
  Flex,
  Heading,
  ListItem,
  Spacer,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { JumpLinkRefIds, TruncatableTextBlock } from 'molecules';
import { Tabs, useOpenFormContext } from 'contexts';
import { useCallback } from 'react';
import { useHealthCheck, useLatestReferral } from 'hooks';
import { useParams } from 'react-router-dom';
import useTransition from '../useTransition';

const CompleteTransitionView = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: transition } = useTransition(childId);
  const [openFormState, setOpenFormState] = useOpenFormContext();
  const referral = useLatestReferral(childId);
  const isActive = referral?.isActive;
  const { error } = useHealthCheck();

  const onClick = useCallback(() => {
    if (openFormState) {
      return;
    }

    setOpenFormState({
      tab: Tabs.Exit,
      primaryJumpLink: JumpLinkRefIds.CompleteTransition,
    });
  }, [openFormState, setOpenFormState]);

  return (
    <Flex direction="column" flexGrow={1}>
      <Flex>
        <Flex direction="column">
          <Heading as="h4" variant="tertiary" mb={2}>
            Exit child from Part C services
          </Heading>
          {isActive && (
            <>
              <Text textStyle="bodyText" mb={2}>
                Before you exit a child from Part C services, please confirm the
                following are updated and accurate:
              </Text>
              <Box marginLeft={2}>
                <UnorderedList textStyle="bodyText" spacing={3}>
                  <ListItem>Transtion planning</ListItem>
                  <ListItem>Transition conference</ListItem>
                  <ListItem>Exit COS</ListItem>
                </UnorderedList>
              </Box>
            </>
          )}
        </Flex>
        <Spacer />
        {isActive && (
          <Box ml={4}>
            <Button
              onClick={onClick}
              disabled={!isActive || !!openFormState || error}
              borderColor="white"
              border="1px solid"
            >
              Begin Exit
            </Button>
          </Box>
        )}
      </Flex>

      <DetailSectionDivider orientation="horizontal" />

      <DetailSectionRow>
        <DetailSectionColumn flexBasis={'33%'}>
          <DetailSectionField
            label="Exit reason"
            value={transition?.transitionType}
            showWhenEmpty
          />
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn flexBasis={'67%'}>
          <DetailSectionField
            label="exit date"
            value={transition?.dateOfTransition}
            showWhenEmpty
          />
        </DetailSectionColumn>
      </DetailSectionRow>
      <DetailSectionDivider />
      <DetailSectionDivider orientation="horizontal" />
      <DetailSectionColumn>
        <Text textStyle="sectionFieldViewLabel">Notes</Text>
        <TruncatableTextBlock text={transition?.notes} />
      </DetailSectionColumn>
    </Flex>
  );
};

export default CompleteTransitionView;
