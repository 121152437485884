import { FC } from 'react';
import { JumpLinkRefIds, SectionWrapper } from 'molecules';
import { useParams } from 'react-router-dom';
import CardSkeleton from 'pages/ChildRecord/CardSkeleton/CardSkeleton';
import ChildContactCardList from './ChildContactsCardList';
import ChildContactsFormContainer from './ChildContactsFormContainer';
import ChildContactsSectionHeader from './ChildContactsSectionHeader';
import useChildContacts from '../useChildContacts';

const ChildContactsSection: FC = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data } = useChildContacts(childId);
  const loaded = !!data;

  return (
    <SectionWrapper id={JumpLinkRefIds.ChildContacts}>
      <ChildContactsSectionHeader />
      <ChildContactsFormContainer />
      {loaded ? <ChildContactCardList /> : <CardSkeleton />}
    </SectionWrapper>
  );
};

export default ChildContactsSection;
