import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { Flex, Text } from '@chakra-ui/react';
import { TruncatableTextBlock } from 'molecules';
import { displayUndefinedOrBoolean } from '../helpers';
import { useParams } from 'react-router-dom';
import useTransition from '../useTransition';

const TransitionPlanningView = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: transition } = useTransition(childId);

  return (
    <Flex direction="column" flexGrow={1}>
      <DetailSectionColumn>
        <Text textStyle="sectionFieldViewLabel">Priorities and Goals</Text>
        <TruncatableTextBlock text={transition?.prioritiesAndGoals} />
      </DetailSectionColumn>
      <DetailSectionDivider orientation="horizontal" />
      <DetailSectionColumn>
        <Text textStyle="sectionFieldViewLabel">Planning Steps</Text>
        <TruncatableTextBlock text={transition?.planningStepsTaken} />
      </DetailSectionColumn>
      <DetailSectionDivider orientation="horizontal" />

      <DetailSectionRow>
        <DetailSectionField
          label="Potential eligibility for part b"
          value={displayUndefinedOrBoolean(
            transition?.partBPotential,
            'The child is potentially eligible for Part B services.',
            'The child is not potentially eligible for Part B services.'
          )}
          showWhenEmpty
        />
      </DetailSectionRow>

      {transition?.partBPotential && (
        <>
          <DetailSectionDivider orientation="horizontal" />
          <DetailSectionRow>
            <DetailSectionColumn flexBasis={'33%'}>
              <DetailSectionField
                label="Special education liaison"
                value={transition?.specialEdContactInfoName}
                showWhenEmpty
              />
            </DetailSectionColumn>
            <DetailSectionDivider />
            <DetailSectionColumn flexBasis={'33%'}>
              <DetailSectionField
                label="phone number"
                value={transition?.specialEdContactInfoPhoneGeneric}
                showWhenEmpty
              />
            </DetailSectionColumn>
            <DetailSectionDivider />
            <DetailSectionColumn flexBasis={'33%'}>
              <DetailSectionField
                label="email"
                value={transition?.specialEdContactInfoEmail}
                showWhenEmpty
              />
            </DetailSectionColumn>
          </DetailSectionRow>
        </>
      )}
    </Flex>
  );
};

export default TransitionPlanningView;
