import { Button } from '@chakra-ui/react';
import { FC } from 'react';
import { paths } from 'lib';
import { useParams } from 'react-router-dom';

const isArchived = true;

export const DevControlAddArchivedContact: FC = (): JSX.Element => {
  const { id: childId } = useParams<{ id: string }>();
  const addChild = async () => {
    await fetch(paths.controls.addContact({ childId, isArchived }));
  };

  return (
    <Button colorScheme="blackAlpha" borderRadius="9px" onClick={addChild}>
      Add Control Archived Contact
    </Button>
  );
};
