import { FC, createContext, useContext, useState } from 'react';

type EvaluationsArchiveContextType = [boolean, (showArchived: boolean) => void];

const EvaluationsArchiveContext = createContext<EvaluationsArchiveContextType>([
  false,
  () => {},
]);

export const useEvaluationsArchiveContext = () =>
  useContext(EvaluationsArchiveContext);

export const EvaluationsArchiveContextProvider: FC = ({ children }) => {
  const value = useState<boolean>(false);

  return (
    <EvaluationsArchiveContext.Provider value={value}>
      {children}
    </EvaluationsArchiveContext.Provider>
  );
};
