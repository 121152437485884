import { ReactComponent as AddIcon } from 'icons/new.svg';
import {
  DataCard,
  FormIconButton,
  JumpLinkRefIds,
  SectionHeader,
  SectionWrapper,
} from 'molecules';

import { Tabs, useESITServiceIdContext, useOpenFormContext } from 'contexts';
import { useLatestReferral } from 'hooks';
import { useParams } from 'react-router-dom';
import ESITServicesCardList from './ESITServicesCardList';
import ESITServicesForm from '../ESITServicesForm/ESITServicesForm';
import useOutcomes from '../useOutcomes';

const ADD_FORM_ID = 'ADD_FORM_ID';

const ESITServicesSection = () => {
  const { id: childId } = useParams<{ id: string }>();
  const [openFormState, setOpenFormState] = useOpenFormContext();
  const [esitServiceFormId, setEsitServiceFormId] = useESITServiceIdContext();
  const { data: outcomes } = useOutcomes(childId);
  const referral = useLatestReferral(childId);
  const isActive = referral?.isActive;

  const isFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.ESITServices;

  return (
    <SectionWrapper id={JumpLinkRefIds.ESITServices} mb={12}>
      <SectionHeader
        isFormOpen={isFormOpen}
        headerText="ESIT Services"
        iconButton={
          <FormIconButton
            onClick={() => {
              if (openFormState) {
                return;
              }

              setOpenFormState({
                tab: Tabs.Services,
                primaryJumpLink: JumpLinkRefIds.ESITServices,
              });

              setEsitServiceFormId(ADD_FORM_ID);
            }}
            refId={JumpLinkRefIds.ESITServices}
            aria-label={'Add'}
            Icon={AddIcon}
            tooltipProps={{ label: 'Add ESIT service' }}
            disabled={!isActive || !outcomes || outcomes?.length === 0}
          />
        }
      />
      {isFormOpen && esitServiceFormId === ADD_FORM_ID && (
        <DataCard>
          <ESITServicesForm />
        </DataCard>
      )}
      <ESITServicesCardList isFormOpen={isFormOpen} />
    </SectionWrapper>
  );
};

export default ESITServicesSection;
