import { Box, Flex } from '@chakra-ui/react';
import { FC, ReactElement, useEffect, useRef } from 'react';
import { useModalContentContext } from 'contexts';
import { useScreenWidth } from 'hooks';
import { zIndexMap } from 'theme';
import Footer from '../Footer';

interface Props {
  sidebarChildren?: ReactElement;
  formButtons: ReactElement;
}

const ModalFormContent: FC<Props> = ({
  children,
  formButtons,
  sidebarChildren,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, setModalContentContext] = useModalContentContext();

  useEffect(() => {
    if (ref.current !== null) {
      setModalContentContext(ref.current);
    }
  }, [ref, setModalContentContext]);

  const { isWideScreenWidth } = useScreenWidth();

  const flexProps = isWideScreenWidth
    ? {
        backgroundColor: 'white',
        flexGrow: 1,
      }
    : {};

  const boxProps = isWideScreenWidth
    ? { as: 'main' as any, px: 32, marginTop: 44, width: '100%' }
    : { flex: '1 0 auto', padding: 4 };

  return (
    <Flex
      flexDirection="column"
      width="100%"
      data-testid="modalFormLayout"
      {...flexProps}
    >
      <Flex flex="1 0 auto" padding={isWideScreenWidth ? 0 : 4}>
        {isWideScreenWidth && (
          <Box
            as="aside"
            width={48}
            backgroundColor="teal.50"
            zIndex={zIndexMap.formLayer}
            flexShrink={0}
          >
            {sidebarChildren && sidebarChildren}
          </Box>
        )}
        <Box ref={ref} {...boxProps}>
          {children}
          {!isWideScreenWidth && <Box marginTop={8}>{formButtons}</Box>}
        </Box>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default ModalFormContent;
