import { ReactComponent as StethoscopeIcon } from 'icons/stethoscope.svg';
import { useParams } from 'react-router-dom';
import MedicalDiagnosisCard from './MedicalDiagnosisCard/MedicalDiagnosisCard';
import NoneYetPlaceholder from 'components/NoneYetPlaceholder';
import useDiagnoses from '../useDiagnoses';

const MedicalDiagnosesList = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: diagnoses } = useDiagnoses(childId);

  if (!diagnoses) return null;

  if (diagnoses.length < 1)
    return (
      <NoneYetPlaceholder
        Icon={StethoscopeIcon}
        message="A medical diagnosis has not yet been added for this child."
      />
    );

  return (
    <>
      {diagnoses.map(diagnosis => (
        <MedicalDiagnosisCard key={diagnosis.id} diagnosis={diagnosis} />
      ))}
    </>
  );
};

export default MedicalDiagnosesList;
