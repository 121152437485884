import * as yup from 'yup';
import {
  addDays,
  addYears,
  isBefore,
  startOfToday,
  startOfYesterday,
  subYears,
} from 'date-fns';
import {
  dateFormatErrorString,
  yupOptionalString,
  yupRequiredBoolean,
  yupRequiredDate,
  yupRequiredString,
} from 'lib';

export const ChildDetailsSchemaFields = {
  childFirstName: yupRequiredString('Child first name is required', ''),

  childLastName: yupRequiredString('Child last name is required', ''),

  childDateOfBirth: yupRequiredDate('Child date of birth is required')
    .typeError(dateFormatErrorString)
    .min(
      subYears(startOfToday(), 3),
      'Child date of birth cannot be more than 3 years ago'
    )
    .max(startOfYesterday(), 'Child date of birth must be in past')
    .test({
      name: 'ages out within 45 days',
      message:
        'Child will age out of Part C within 45 days; try Part B instead',
      test: value => {
        const childThirdBirthday = addYears(value, 3);
        const fortyFiveDaysFromNow = addDays(startOfToday(), 45);
        return isBefore(fortyFiveDaysFromNow, childThirdBirthday);
      },
    }),

  sexOfChild: yup.string().when('childMatch', {
    is: childMatch => !childMatch || 'continueWithoutMatch' === childMatch,
    then: yupRequiredString('Sex of child is required'),
    otherwise: yupOptionalString(),
  }),

  otherSexOfChild: yupOptionalString(),

  childMatch: yupRequiredString(
    'Resolving possible duplicate records is a required step'
  ),

  showShortForm: yupRequiredBoolean().default(false),
};

export const ChildDetailsSchema = yup
  .object(ChildDetailsSchemaFields)
  .defined();

export type FormFields = ReturnType<typeof ChildDetailsSchema.validateSync>;

export const testChildDetailFormFields: FormFields = {
  childFirstName: 'Child first name',
  childLastName: 'Child last name',
  childDateOfBirth: new Date('1/1/2019'),
  sexOfChild: 'female',
  otherSexOfChild: '',
  childMatch: 'continueWithoutMatch',
  showShortForm: false,
};

const isChildDetailFormAutoFilledInDev = (isAutoFilled: boolean) =>
  process.env.NODE_ENV === 'development' && isAutoFilled
    ? testChildDetailFormFields
    : ChildDetailsSchema.default();

export const initialValues = isChildDetailFormAutoFilledInDev(false); // NOTE: DO NOT COMMIT "true"
