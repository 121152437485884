import { AssessmentMethod, Domain, Evaluation } from '@cssat/acorn-api-shared';
import { FormFields } from './validation';
import { formatISO } from 'date-fns';
import {
  isQualifyingPercentageDelay,
  isQualifyingStandardDeviation,
} from '../helpers';
import { v4 as uuid } from 'uuid';

const removeEmptyStringsFromDomain = (domain: Domain) => {
  const cleanedDomain = Object.assign({}, domain);

  for (const key in cleanedDomain) {
    let domainKey = key as keyof Domain;

    if (cleanedDomain[domainKey] === '') {
      delete cleanedDomain[domainKey];
    }
  }

  return cleanedDomain;
};

const adaptDomain = (
  key: keyof Evaluation['domainsTested'],
  domain: Domain,
  evaluation: Evaluation
) => {
  const cleanedDomain = removeEmptyStringsFromDomain(domain);
  if (
    cleanedDomain.standardDeviation ||
    cleanedDomain.percentageDelay ||
    cleanedDomain.ageEquivalence ||
    cleanedDomain.presentLevelOfDevelopment ||
    cleanedDomain.results
  ) {
    evaluation.domainsTested[key] = cleanedDomain;
  }

  const { standardDeviation, percentageDelay } = cleanedDomain;
  if (
    isQualifyingStandardDeviation(standardDeviation) ||
    isQualifyingPercentageDelay(percentageDelay)
  ) {
    evaluation.determinesEligibility = true;
  }
};

const getToolIdIfNormedToolAssessment = (
  methodOfAssessment: AssessmentMethod,
  evaluationToolId?: string
) => {
  if (methodOfAssessment === AssessmentMethod.NORMALIZED_TOOLS)
    return evaluationToolId;
};

const adaptFormToApi = (values: FormFields): Evaluation => {
  const evaluation: Evaluation = {
    id: values.id || uuid(),
    isArchived: false,
    methodOfAssessment: values.methodOfAssessment,
    evaluationToolId: getToolIdIfNormedToolAssessment(
      values.methodOfAssessment,
      values.evaluationToolId
    ),
    evaluationToolName: values.evaluationToolName,
    determinesEligibility: false,
    domainsTested: {} as Evaluation['domainsTested'],
  };

  evaluation.evaluationToolVersion = values.evaluationToolVersion ?? undefined;
  evaluation.administeredBy = values.administeredBy ?? undefined;
  evaluation.location = values.location ?? undefined;
  evaluation.notes = values.notes ?? undefined;

  evaluation.dateAdministered = values.dateAdministered
    ? formatISO(values.dateAdministered)
    : undefined;

  values.domainsSelected!.forEach(domainKey => {
    let key = domainKey as keyof Evaluation['domainsTested'];

    const domainValues = values.domainsTested![key] as Domain;
    adaptDomain(key, domainValues, evaluation);
  });

  return evaluation;
};

export default adaptFormToApi;
