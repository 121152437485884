import { Diagnosis, Evaluation } from '@cssat/acorn-api-shared';
import { DomainKey } from '../EvaluationsSection/EvaluationForm/validation';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useDiagnoses from '../useDiagnoses';
import useEvaluationsAndAssessments from '../EvaluationsSection/useEvaluations';
import useSWR from 'swr';

interface EligibilityUpdater {
  readonly diagnoses?: Diagnosis[];
  readonly domains?: {
    adaptive: Evaluation[];
    cognitive: Evaluation[];
    communicationExpressive: Evaluation[];
    communicationReceptive: Evaluation[];
    hearing: Evaluation[];
    physicalMotorFine: Evaluation[];
    physicalMotorGross: Evaluation[];
    socialEmotional: Evaluation[];
    vision: Evaluation[];
  };
  readonly error: boolean;
  readonly loaded: boolean;
}

const useEligibilityCriteria = (): EligibilityUpdater => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: evalPayload } = useEvaluationsAndAssessments(childId);
  const evaluations = evalPayload?.activeEvals;
  const { data: diagnoses } = useDiagnoses(childId);
  const filterEvaluationsByDomain = useCallback(
    (
      domainKey: string,
      evaluations?: Evaluation[]
    ): Evaluation[] | undefined => {
      if (!evaluations) return;
      let filtered = evaluations.filter(
        evaluation => !!evaluation.domainsTested[domainKey as DomainKey]
      );
      return filtered;
    },
    []
  );

  const mockDomains = {
    adaptive: useMemo(
      () => filterEvaluationsByDomain('adaptive', evaluations) ?? [],
      [filterEvaluationsByDomain, evaluations]
    ),
    cognitive: useMemo(
      () => filterEvaluationsByDomain('cognitive', evaluations) ?? [],
      [filterEvaluationsByDomain, evaluations]
    ),
    communicationExpressive: useMemo(
      () =>
        filterEvaluationsByDomain('communicationExpressive', evaluations) ?? [],
      [filterEvaluationsByDomain, evaluations]
    ),
    communicationReceptive: useMemo(
      () =>
        filterEvaluationsByDomain('communicationReceptive', evaluations) ?? [],
      [filterEvaluationsByDomain, evaluations]
    ),
    hearing: useMemo(
      () => filterEvaluationsByDomain('hearing', evaluations) ?? [],
      [filterEvaluationsByDomain, evaluations]
    ),
    physicalMotorFine: useMemo(
      () => filterEvaluationsByDomain('physicalMotorFine', evaluations) ?? [],
      [filterEvaluationsByDomain, evaluations]
    ),
    physicalMotorGross: useMemo(
      () => filterEvaluationsByDomain('physicalMotorGross', evaluations) ?? [],
      [filterEvaluationsByDomain, evaluations]
    ),
    socialEmotional: useMemo(
      () => filterEvaluationsByDomain('socialEmotional', evaluations) ?? [],
      [filterEvaluationsByDomain, evaluations]
    ),
    vision: useMemo(
      () => filterEvaluationsByDomain('vision', evaluations) ?? [],
      [filterEvaluationsByDomain, evaluations]
    ),
  };

  const triggerDataResponse = async () => {
    return {
      domains: mockDomains,
      diagnoses,
    };
  };

  const recordKey = `children-eligibility-${childId}`;
  const {
    data,
    error,
    // mutate,
  } = useSWR<any>(recordKey, triggerDataResponse);

  const loaded = !!data && !!data.domains && !!data.diagnoses;

  return {
    error,
    loaded,
    diagnoses: data?.diagnoses,
    domains: data?.domains,
  };
};

export default useEligibilityCriteria;
