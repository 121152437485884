import { JumpLinkRefIds } from 'molecules/JumpLinks/constants';
import { useOpenFormContext } from 'contexts';
import InlineAlert from 'components/alerts/InlineAlert';

import { DataCard } from 'molecules';
import { useParams } from 'react-router-dom';
import CardSkeleton from 'pages/ChildRecord/CardSkeleton/CardSkeleton';
import TransitionPlanningForm from '../TransitionPlanningForm/TransitionPlanningForm';
import TransitionPlanningView from './TransitionPlanningView';
import useCardHeaderProps from './useCardHeaderProps';
import useTransition from '../useTransition';

const TransitionPlanningCard = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { error, data } = useTransition(childId);
  const [openFormState] = useOpenFormContext();
  const isFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.TransitionPlanning;
  const headerProps = useCardHeaderProps();

  return (
    <>
      {error && (
        <InlineAlert
          marginTop={4}
          status="warning"
          title="Hang on! We can't load this section right now."
          description="We'll keep trying."
        />
      )}
      {data ? (
        <DataCard headerProps={headerProps}>
          {isFormOpen ? <TransitionPlanningForm /> : <TransitionPlanningView />}
        </DataCard>
      ) : (
        <CardSkeleton />
      )}
    </>
  );
};

export default TransitionPlanningCard;
