import { Alert, DataCard, JumpLinkRefIds } from 'molecules';
import { Evaluation } from '@cssat/acorn-api-shared';
import { Text } from '@chakra-ui/react';
import { useMutateEvaluationAndAssessment } from '../useEvaluations';
import { useOpenFormContext } from 'contexts';
import { useParams } from 'react-router-dom';
import { usePopupToast } from 'hooks';
import { useState } from 'react';
import EvaluationForm from '../EvaluationForm/EvaluationForm';
import EvaluationView from './EvaluationView';
import useCardHeaderProps from './useCardHeaderProps';

interface EvaluationCardProps {
  readonly evaluation: Evaluation;
}

const EvaluationCard = ({ evaluation }: EvaluationCardProps) => {
  const [openFormState] = useOpenFormContext();
  const [archiveToggleSuccessToast] = usePopupToast({
    status: 'success',
    title: `Evaluation and assessment ${
      evaluation.isArchived ? 'unarchived' : 'archived'
    }`,
  });
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const headerProps = useCardHeaderProps({
    evaluation,
    onWarning: () => setIsAlertOpen(true),
  });
  const { id: childId } = useParams<{ id: string }>();
  const updateEval = useMutateEvaluationAndAssessment(childId);

  const isFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.Evaluations &&
    openFormState?.secondaryJumplink === evaluation.id;

  return (
    <>
      <DataCard
        id={evaluation.id}
        headerProps={headerProps}
        hideHeader={isFormOpen}
      >
        {isFormOpen ? (
          <EvaluationForm evaluation={evaluation} />
        ) : (
          <EvaluationView evaluation={evaluation} />
        )}
      </DataCard>
      <Alert
        headerText={`${
          evaluation.isArchived ? 'Unarchive' : 'Archive'
        } Evaluation and Assessment`}
        confirmText={evaluation.isArchived ? 'Unarchive' : 'Archive'}
        cancelText="Go back"
        isOpen={isAlertOpen}
        setIsOpen={setIsAlertOpen}
        onConfirm={async () => {
          await updateEval({
            ...evaluation,
            isArchived: !evaluation.isArchived,
          });
          setIsAlertOpen(false);
          archiveToggleSuccessToast();
        }}
      >
        <Text textStyle="bodyText" fontWeight="medium" fontSize="md">
          Are you sure you want to{' '}
          {evaluation.isArchived ? 'unarchive' : 'archive'} this evaluation and
          assessment?
        </Text>
      </Alert>
    </>
  );
};

export default EvaluationCard;
