import { Button } from '@chakra-ui/react';
import { DevControlCreateChild } from './DevControlCreateChild';
import { FC } from 'react';
import { PageHeader, SideBarPageErrorBanner } from 'molecules';
import { useHealthCheck } from 'hooks';
import { useHistory } from 'react-router-dom';
import ChildListDrawer from './ChildListDrawer/ChildListDrawer';
import ChildListTable from './ChildListTable/ChildListTable';
import DevControl from 'components/DevControl';
import useChildList from './useChildList';

export const ChildList: FC = () => {
  const { data, error, mutate } = useChildList();
  const { error: healthCheckError } = useHealthCheck();
  const loaded = !!data;
  const history = useHistory();

  return (
    <>
      {healthCheckError && <SideBarPageErrorBanner />}
      <PageHeader title="Child Records in Your Agency">
        <DevControl>
          <DevControlCreateChild onClick={async () => await mutate()} />
        </DevControl>
        <Button onClick={() => history.push('/referral/create')}>
          Refer a child
        </Button>
      </PageHeader>

      {!error && loaded && <ChildListTable />}
      <ChildListDrawer />
    </>
  );
};
