import * as yup from 'yup';
import { ChildRecord } from '@cssat/acorn-api-shared';
import { yupNullableString } from 'lib';

export type FormFields = Omit<
  NonNullable<ChildRecord['primaryCareProvider']>,
  'id' | 'createdAt' | 'updatedAt'
> & {
  isAddressSame: boolean;
};

export const formSchema = yup.object({
  name: yupNullableString(),
  clinicName: yupNullableString(),
  physicalAddress1: yupNullableString(),
  physicalAddress2: yupNullableString(),
  physicalCity: yupNullableString(),
  physicalState: yupNullableString(),
  physicalZip: yupNullableString(10),
  mailingAddress1: yupNullableString(),
  mailingAddress2: yupNullableString(),
  mailingCity: yupNullableString(),
  mailingState: yupNullableString(),
  mailingZip: yupNullableString(10),
  phone: yupNullableString(),
  fax: yupNullableString(),
  email: yupNullableString().email('Email address must be valid'),
  notes: yupNullableString(),
});
