import {
  Box,
  Flex,
  Heading,
  InputLeftAddon,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { DateField, TextField, TextareaField } from 'components/formik';
import { Diagnosis } from '@cssat/acorn-api-shared';
import { FC } from 'react';
import { FormSection } from 'components/styled/forms/FormSection';
import { ReactComponent as QualifyingIcon } from 'icons/qualifying.svg';
import { coerceInitialValues, formSchema } from './validation';
import { startOfToday } from 'date-fns';
import { useMutateDiagnosis } from '../../useDiagnoses';
import { useParams } from 'react-router-dom';
import ChildSectionFormikWrapper from 'pages/ChildRecord/ChildSectionFormikWrapper/ChildSectionFormikWrapper';
import SearchableDropDownField from 'components/formik/SearchableDropDownField/SearchableDropDownField';
import useKnownMedicalDiagnosisTypes, {
  useQualifyingDiagnosisTypes,
} from '../useKnownMedicalDiagnosisTypes';

interface MedicalDiagnosisFormProps {
  readonly diagnosis?: Diagnosis;
}

const MedicalDiagnosisForm: FC<MedicalDiagnosisFormProps> = ({ diagnosis }) => {
  const { id: childId } = useParams<{ id: string }>();
  const upsertDiagnosis = useMutateDiagnosis(childId);
  const diagnosisTypes = useKnownMedicalDiagnosisTypes();
  const otherDiagnosisTypeId = diagnosisTypes.data?.other.id;
  const qualifyingDiagnosisTypes = useQualifyingDiagnosisTypes();

  return (
    <ChildSectionFormikWrapper
      formikProps={{
        validationSchema: formSchema,
        initialValues: coerceInitialValues(diagnosis),
      }}
      successToastProps={{
        title: `Diagnosis saved successfully`,
      }}
      errorToastProps={{
        title: `Sorry, we can’t save the diagnosis right now.`,
        description: 'Please check your connection and try again.',
      }}
      humanReadableFormName="Evaluations - Medical diagnoses"
      inlineAlertMessage="There was a problem completing this diagnosis."
      apiRequest={async values => {
        await upsertDiagnosis(values as Diagnosis);
      }}
    >
      <FormSection maxWidth="1000px" width="100%">
        <Heading as="h4" variant="tertiary">
          Medical diagnosis
        </Heading>
        <Text textStyle="bodyText">
          The diagnosis list includes all possible diagnoses which qualify a
          child for Part C Services. Typing into the box searches the dropdown
          list of all qualifying diagnoses. Alternatively, you may enter a
          diagnosis that is not in this list directly into the box. This could
          be used for either general informational purposes or if a physician
          has stated that this diagnosis is likely to result in a developmental
          delay.
        </Text>
        <Flex>
          <Box paddingRight={1}>
            <QualifyingIcon />
          </Box>
          <Text textStyle="sectionFieldViewValue">
            This badge indicates the diagnosis is Automatically Qualifying
          </Text>
        </Flex>
        <SearchableDropDownField
          menuItems={qualifyingDiagnosisTypes}
          name="diagnosisName"
          data-testid="diagnosisName"
          label="Diagnosis"
          required
          idName="knownDiagnosisTypeId"
          idRequired
          resetIdValue={otherDiagnosisTypeId}
          selectedItemIndicator={
            <InputLeftAddon backgroundColor="teal.50" borderColor="gray.300">
              <Tooltip
                label="Qualifying diagnosis"
                aria-label="Qualifying diagnosis"
              >
                <QualifyingIcon />
              </Tooltip>
            </InputLeftAddon>
          }
        />
        <DateField
          name="dateOfDiagnosis"
          label="Date of diagnosis"
          disabledDays={{ after: startOfToday() }}
        />
        <TextField name="diagnosedBy.name" label="Diagnosed by" />
        <TextField name="diagnosedBy.title" label="Professional title" />
        <TextField
          name="diagnosedBy.organization"
          label="Clinic or organization"
        />
        <TextareaField
          name="notes"
          label="Other diagnosis information (optional)"
        />
        <DateField
          name="dateReceived"
          label="Date report was received"
          disabledDays={{ after: startOfToday() }}
        />
      </FormSection>
    </ChildSectionFormikWrapper>
  );
};

export default MedicalDiagnosisForm;
