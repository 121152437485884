import { AcornFormField } from './AcornFormField';
import { CSSProperties, FC, ReactNode, useCallback, useRef } from 'react';
import { useField } from 'formik';

interface Props {
  readonly name: string;
  readonly required?: boolean;
  readonly content: ReactNode;
  readonly style?: CSSProperties;
}

const FileUpload: FC<Props> = ({ name, required = false, content, style }) => {
  const [field, , helpers] = useField(name);
  const ref = useRef<HTMLInputElement>(null);

  const onClick = useCallback(() => ref.current?.click(), []);

  const attachFile = useCallback(
    e => {
      helpers.setValue(e.currentTarget.files[0]);
    },
    [helpers]
  );

  return (
    <AcornFormField name={name} required={required}>
      <label htmlFor={field.name} id={`${field.name}Label`}>
        <button
          onClick={onClick}
          aria-controls="filename"
          style={style}
          type="button"
        >
          {content}
        </button>
      </label>
      <input
        ref={ref}
        type="file"
        id={field.name}
        name={field.name}
        style={{ display: 'none' }}
        onChange={attachFile}
      />
    </AcornFormField>
  );
};

export default FileUpload;
