import { Address } from 'components/styled/Address';
import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { ReactComponent as DefaultCheckIcon } from 'icons/default-check.svg';
import {
  DetailSectionColumn,
  DetailSectionContent,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { ReactComponent as EditIcon } from 'icons/pencil.svg';
import { FC } from 'react';
import { ReferralData } from '@cssat/acorn-api-shared';
import { createRoleReadviewCopy } from 'lib/referringPartyRole/data';
import { referralReasons } from 'lib/referralReasonData';
import { space } from 'theme';
import { useFormikContext } from 'formik';
import { useLatestReferral } from 'hooks';
import { usePreviousReferralContext } from 'contexts/PreviousReferralContext';
import { validateMailingAndPhysicalAddress } from 'lib';

const InactiveMatchPreview: FC<{ currentReferral: ReferralData }> = ({
  currentReferral,
}) => {
  const referringPartyRoleCopy = createRoleReadviewCopy(
    currentReferral.referringParty.role
  );
  const { isAddressTheSame, doesMailingAddressExist } =
    validateMailingAndPhysicalAddress(currentReferral?.referringParty);

  const { setFieldValue, submitForm } = useFormikContext<any>();

  const [, setPreviousReferral] = usePreviousReferralContext();
  const latestReferral = useLatestReferral(currentReferral.child.id);

  return (
    <Box mt={space[14]}>
      <Heading as="h2" variant="tertiary" mb={space.one}>
        Matching an inactive child record
      </Heading>
      <Text as="span" variant="bodyText" mb={space[7]}>
        This child record already exists in ACORN. Please review the most recent
        referral for the child:
      </Text>
      <Flex flexDirection="column" flexGrow={1}>
        <DetailSectionContent>
          <DetailSectionColumn>
            <DetailSectionRow>
              <DetailSectionField
                label="Referral Source"
                value={currentReferral.referringParty.name}
                showWhenEmpty
              />
            </DetailSectionRow>
            <DetailSectionRow>
              <DetailSectionField
                label="Role"
                value={referringPartyRoleCopy}
                showWhenEmpty
              />
            </DetailSectionRow>
            <DetailSectionRow>
              <DetailSectionField label="Physical Address" showWhenEmpty>
                <Address
                  line1={currentReferral.referringParty.physicalAddress1 || '-'}
                  line2={currentReferral.referringParty.physicalAddress2}
                  city={currentReferral.referringParty.physicalCity}
                  state={currentReferral.referringParty.physicalState}
                  zip={currentReferral.referringParty.physicalZip}
                />
              </DetailSectionField>
            </DetailSectionRow>
            <DetailSectionRow>
              {!isAddressTheSame && doesMailingAddressExist && (
                <DetailSectionField label="Mailing Address">
                  <Address
                    line1={
                      currentReferral.referringParty.mailingAddress1 || '-'
                    }
                    line2={currentReferral.referringParty.mailingAddress2}
                    city={currentReferral.referringParty.mailingCity}
                    state={currentReferral.referringParty.mailingState}
                    zip={currentReferral.referringParty.mailingZip}
                  />
                </DetailSectionField>
              )}
            </DetailSectionRow>
            <DetailSectionRow>
              <DetailSectionField
                label="Phone number"
                value={currentReferral.referringParty.phoneGeneric}
                showWhenEmpty
              />
            </DetailSectionRow>
            <DetailSectionRow>
              <DetailSectionField
                label="Email address"
                value={currentReferral.referringParty.email}
                showWhenEmpty
              />
            </DetailSectionRow>
            <DetailSectionDivider orientation="horizontal" />
            <DetailSectionRow>
              <DetailSectionField
                label="Reason for referral"
                value={referralReasons[currentReferral.reason]}
                showWhenEmpty
              />
            </DetailSectionRow>
          </DetailSectionColumn>
        </DetailSectionContent>
      </Flex>
      <Flex mt={space[8]}>
        <Button
          colorScheme="teal"
          variant="outline"
          mr={space[4]}
          leftIcon={<EditIcon />}
          marginTop={4}
          isDisabled={!latestReferral}
          onClick={() => {
            if (!latestReferral) return;
            setPreviousReferral(latestReferral);
            submitForm();
          }}
        >
          Edit information
        </Button>
        <Button
          colorScheme="teal"
          variant="solid"
          leftIcon={<DefaultCheckIcon />}
          marginTop={4}
          isDisabled={!latestReferral}
          onClick={() => {
            if (!latestReferral) return;
            setPreviousReferral(latestReferral);
            setFieldValue('showShortForm', true);
            submitForm();
          }}
        >
          Continue with this information
        </Button>
      </Flex>
    </Box>
  );
};

export default InactiveMatchPreview;
