import { DropDownField, TextareaField } from 'components/formik';
import { ExternalLink } from 'components/ExternalLink';
import { Flex, Stack } from '@chakra-ui/react';
import { coerceInitialValues, formSchema } from './validation';
import { useParams } from 'react-router-dom';
import ChildSectionFormikWrapper from 'pages/ChildRecord/ChildSectionFormikWrapper/ChildSectionFormikWrapper';
import useFamilyDropDownOptions from './useFamilyDropdownOptions';
import useFamilyInfo, { useMutateFamilyInfo } from './useFamilyInfo';

const FamilyInfoForm = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: familyInfo } = useFamilyInfo(childId);
  const updateFamilyInfo = useMutateFamilyInfo(childId);

  const { counties, schoolDistricts, formLanguages } =
    useFamilyDropDownOptions();

  const initialValues = coerceInitialValues(familyInfo);

  return (
    <Flex padding={6} flexGrow={1}>
      <ChildSectionFormikWrapper
        formikProps={{ validationSchema: formSchema, initialValues }}
        successToastProps={{
          title: 'Family information updated',
        }}
        errorToastProps={{
          title: `Sorry, we can’t update the Family information right now.`,
          description: 'Please check your connection and try again.',
        }}
        humanReadableFormName="Child Overview - Family information"
        apiRequest={updateFamilyInfo}
        inlineAlertMessage="There was a problem updating the family information."
      >
        <Stack spacing={6} flexDir="column" flexGrow={1} alignItems="center">
          <DropDownField
            name="countyId"
            label="County"
            options={counties}
            placeholder=" "
          />
          <Flex flexDirection="column" width="100%">
            <DropDownField
              name="schoolDistrictId"
              label="School district"
              options={schoolDistricts}
              placeholder=" "
            />
            <ExternalLink
              href="https://k12wa.maps.arcgis.com/apps/webappviewer/index.html?id=7db7e443cd5c4f36a8355bc55cfb04c4"
              alignSelf="flex-end"
              mt={3}
            >
              Need help finding the school district?
            </ExternalLink>
          </Flex>
          <TextareaField
            rows={8}
            name="livesInHome"
            label="Who lives with the child?"
          />
          <DropDownField
            name="formLanguageId"
            label="Language for forms"
            options={formLanguages}
            placeholder=" "
          />
        </Stack>
      </ChildSectionFormikWrapper>
    </Flex>
  );
};

export default FamilyInfoForm;
