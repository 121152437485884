import { DataCard, JumpLinkRefIds } from 'molecules';
import { FC } from 'react';
import { useChildArchiveContactsContext, useOpenFormContext } from 'contexts';
import { useParams } from 'react-router-dom';
import ChildContactsForm from './Form/ChildContactsForm';
import NoneYetPlaceholder from 'components/NoneYetPlaceholder';
import useChildContacts from '../useChildContacts';

const useChildContactsAddFormState = () => {
  const { id: childId } = useParams<{ id: string }>();
  const [openFormState] = useOpenFormContext();
  const [showArchived] = useChildArchiveContactsContext();
  const { data, activeContacts, inactiveContacts } = useChildContacts(childId);

  const loaded = !!data;

  const shouldShowAddForm =
    openFormState?.primaryJumpLink === JumpLinkRefIds.ChildContacts &&
    openFormState.secondaryJumplink === undefined;

  const hasInactiveContacts = inactiveContacts.length > 0;
  const hasActiveContacts = activeContacts.length > 0;
  const hasVisibleContacts =
    hasActiveContacts || (hasInactiveContacts && showArchived);

  return {
    loaded,
    shouldShowAddForm,
    hasVisibleContacts,
  };
};

const ChildContactsFormContainer: FC = () => {
  const { loaded, shouldShowAddForm, hasVisibleContacts } =
    useChildContactsAddFormState();

  if (!loaded) {
    return null;
  }

  if (shouldShowAddForm) {
    return (
      <DataCard>
        <ChildContactsForm />
      </DataCard>
    );
  }

  return hasVisibleContacts ? null : (
    <NoneYetPlaceholder message="No Child contacts added yet" />
  );
};

export default ChildContactsFormContainer;
