import { FC, createContext, useContext } from 'react';
export interface TrainingUser {
  email: string;
  name: string;
}

export type NullishTrainingUser = TrainingUser | null;

interface UserContextState {
  user: NullishTrainingUser;
  setUser: (user: NullishTrainingUser) => void;
  logout: () => void;
}

export const TrainingUserContext = createContext<UserContextState>({
  user: null,
  setUser: () => {},
  logout: () => {},
});

interface Props {
  readonly value: UserContextState;
}

const useTrainingUserContext = () => useContext(TrainingUserContext);

const TrainingUserContextProvider: FC<Props> = ({ children, value }) => {
  TrainingUserContext.displayName = 'UserContext';

  return (
    <TrainingUserContext.Provider value={value}>
      {children}
    </TrainingUserContext.Provider>
  );
};

export { TrainingUserContextProvider, useTrainingUserContext };
