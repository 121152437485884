import { FamilyEvaluationRoutines } from '@cssat/acorn-api-shared';
import { authenticatedFetch, authenticatedPost, paths } from 'lib';
import useSWR from 'swr';

const useRoutines = (childId: string) => {
  return useSWR<FamilyEvaluationRoutines>(
    paths.child.getFamilyEvalRoutines({ childId }),
    authenticatedFetch
  );
};

export const useMutateRoutines = (childId: string) => {
  const { mutate } = useRoutines(childId);

  return async (routines: FamilyEvaluationRoutines) => {
    await authenticatedPost(paths.child.updateFamilyEvalRoutines({ childId }), {
      body: routines,
    });
    await mutate();
  };
};

export default useRoutines;
