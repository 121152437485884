import { DataCard, JumpLinkRefIds } from 'molecules';
import { FC } from 'react';
import { useOpenFormContext } from 'contexts';
import { useParams } from 'react-router-dom';
import CardSkeleton from 'pages/ChildRecord/CardSkeleton/CardSkeleton';
import InlineAlert from 'components/alerts/InlineAlert';
import RoutinesAndActivitiesForm from './RoutinesAndActivitiesForm';
import RoutinesAndActivitiesView from './RoutinesAndActivitiesView';
import useCardHeaderProps from './useCardHeaderProps';
import useRoutines from './useRoutines';

const RoutinesAndActivitiesCard: FC = () => {
  const [openFormState] = useOpenFormContext();
  const headerProps = useCardHeaderProps();

  const isFormOpen =
    openFormState?.secondaryJumplink === JumpLinkRefIds.RoutinesAndActivities;

  const { id: childId } = useParams<{ id: string }>();
  const { data, error } = useRoutines(childId);
  const loaded = !!data;

  return (
    <>
      {error && (
        <InlineAlert
          marginTop={4}
          status="warning"
          title="Hang on! We can't load this section right now."
          description="We'll keep trying."
        />
      )}
      {loaded ? (
        <DataCard
          id={JumpLinkRefIds.RoutinesAndActivities}
          headerProps={headerProps}
          hideHeader={isFormOpen}
        >
          {isFormOpen ? (
            <RoutinesAndActivitiesForm />
          ) : (
            <RoutinesAndActivitiesView />
          )}
        </DataCard>
      ) : (
        <CardSkeleton />
      )}
    </>
  );
};

export default RoutinesAndActivitiesCard;
