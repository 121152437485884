import { ChildMatchQuery, authenticatedFetch, paths } from 'lib';

export default class WrappedError extends Error {
  original: Error;

  constructor(message: string, original: Error) {
    super(message);
    this.name = 'WrappedError';
    this.original = original;
  }
}

export const searchForMatchingChild = async (query: ChildMatchQuery) => {
  try {
    const result = authenticatedFetch(paths.childMatch.search(query));
    return result;
  } catch (error) {
    const e = error as Error;
    throw new WrappedError(`Could not search children - ${e.message}`, e);
  }
};
