import { ReactComponent as AddIcon } from 'icons/new.svg';
import { FormIconButton, JumpLinkRefIds } from 'molecules';
import { Tabs, useOpenFormContext } from 'contexts';
import { useLatestReferral } from 'hooks';
import { useMemo } from 'react';

const useIconButton = (childId: string) => {
  const [openFormState, setOpenFormState] = useOpenFormContext();
  const referral = useLatestReferral(childId);

  return useMemo(
    () => (
      <FormIconButton
        onClick={() => {
          if (openFormState) {
            return;
          }

          setOpenFormState({
            tab: Tabs.Evaluations,
            primaryJumpLink: JumpLinkRefIds.Evaluations,
          });
        }}
        refId={JumpLinkRefIds.Evaluations}
        aria-label={'Add new evaluation'}
        Icon={AddIcon}
        tooltipProps={{ label: 'Add new evaluation' }}
        disabled={!referral?.isActive}
        data-testid="add-evaluation-icon"
      />
    ),
    [openFormState, referral?.isActive, setOpenFormState]
  );
};

export default useIconButton;
