import { FC, ReactElement } from 'react';
import { Flex } from '@chakra-ui/react';
import { ModalContentContextProvider } from 'contexts';
import { useScreenWidth } from 'hooks';
import ModalFormContent from './ModalFormContent';
import ModalFormHeader from './ModalFormHeader';

interface Props {
  sidebarChildren?: ReactElement;
  formButtons: ReactElement;
  title: string;
}

const ModalFormLayout: FC<Props> = ({
  children,
  title,
  sidebarChildren,
  formButtons,
}) => {
  const { isWideScreenWidth } = useScreenWidth();

  return (
    <ModalContentContextProvider>
      <Flex flexDirection={isWideScreenWidth ? 'row' : 'column'} height="100%">
        <ModalFormHeader title={title} formButtons={formButtons} />
        <ModalFormContent
          formButtons={formButtons}
          sidebarChildren={sidebarChildren}
        >
          {children}
        </ModalFormContent>
      </Flex>
    </ModalContentContextProvider>
  );
};

export default ModalFormLayout;
