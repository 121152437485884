import {
  DataCard,
  FormIconButton,
  JumpLinkRefIds,
  SectionHeader,
  SectionWrapper,
} from 'molecules';
import { ReactComponent as EditIcon } from 'icons/pencil.svg';
import { FC, useCallback, useMemo } from 'react';
import { PrimaryCareProviderForm } from './PrimaryCareProviderForm';
import { Tabs, useOpenFormContext } from 'contexts';
import { iconButtonStyles } from '../../styles';
import { useLatestReferral } from 'hooks';
import { useParams } from 'react-router-dom';
import PrimaryCareProviderCard from './PrimaryCareProviderCard';

const PrimaryCareProviderSection: FC = () => {
  const [openFormState, setOpenFormState] = useOpenFormContext();
  const { id: childId } = useParams<{ id: string }>();
  const referral = useLatestReferral(childId);
  const isActive = referral?.isActive;

  const openEditMode = useCallback(() => {
    if (openFormState) {
      return;
    }

    setOpenFormState({
      tab: Tabs.Overview,
      primaryJumpLink: JumpLinkRefIds.PrimaryCareProvider,
    });
  }, [openFormState, setOpenFormState]);

  const isFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.PrimaryCareProvider;

  const headerProps = useMemo(
    () => ({
      buttons: isFormOpen ? null : (
        <FormIconButton
          aria-label="Edit"
          onClick={openEditMode}
          Icon={EditIcon}
          refId={JumpLinkRefIds.PrimaryCareProvider}
          sx={iconButtonStyles}
          tooltipProps={{ label: 'Edit' }}
          disabled={!isActive}
        />
      ),
    }),
    [isActive, isFormOpen, openEditMode]
  );

  return (
    <SectionWrapper id={JumpLinkRefIds.PrimaryCareProvider}>
      <SectionHeader
        headerText="Primary care provider"
        isFormOpen={isFormOpen}
      />
      <DataCard headerProps={headerProps} hideHeader={isFormOpen}>
        {isFormOpen ? <PrimaryCareProviderForm /> : <PrimaryCareProviderCard />}
      </DataCard>
    </SectionWrapper>
  );
};

export default PrimaryCareProviderSection;
