import { Flex } from '@chakra-ui/layout';
import { spaceUnitless } from 'theme';
import { useStickyItemsOffset } from 'hooks';
import CompleteTransitionSection from '../CompleteTransitionSection/CompleteTransitionSection';
import JumpLinksSection from '../JumpLinksSection/JumpLinksSection';
import TransitionConferenceSection from '../TransitionConferenceSection/TransitionConferenceSection';
import TransitionPlanningSection from '../TransitionPlanningSection/TransitionPlanningSection';

const TabContent = () => {
  const jumpLinksOffset = useStickyItemsOffset();

  return (
    <Flex width="100%" alignItems="flex-start">
      <JumpLinksSection
        ml="-4"
        mt="-2"
        position="sticky"
        width="220px"
        maxWidth="220px"
        top={jumpLinksOffset + spaceUnitless.one}
      />
      <Flex flexDir="column" width="100%">
        <TransitionPlanningSection />
        <TransitionConferenceSection />
        <CompleteTransitionSection />
      </Flex>
    </Flex>
  );
};

export default TabContent;
