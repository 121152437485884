import { ESITService } from '@cssat/acorn-api-shared';
import { ReactComponent as EditIcon } from 'icons/pencil.svg';
import { FormIconButton, JumpLinkRefIds } from 'molecules';
import { Tabs, useESITServiceIdContext, useOpenFormContext } from 'contexts';
import { useCallback, useMemo } from 'react';
import { useLatestReferral } from 'hooks';
import { useParams } from 'react-router-dom';

const useCardHeaderProps = (service: ESITService) => {
  const [openFormState, setOpenFormState] = useOpenFormContext();
  const [_, setESITServiceFormId] = useESITServiceIdContext();
  const { id: childId } = useParams<{ id: string }>();
  const referral = useLatestReferral(childId);
  const isActive = referral?.isActive;

  const onClick = useCallback(() => {
    if (openFormState) {
      return;
    }

    setOpenFormState({
      tab: Tabs.Services,
      primaryJumpLink: JumpLinkRefIds.ESITServices,
    });
    setESITServiceFormId(service.id);
  }, [openFormState, service.id, setESITServiceFormId, setOpenFormState]);

  return useMemo(() => {
    return {
      buttons: (
        <FormIconButton
          onClick={onClick}
          aria-label={'Edit'}
          Icon={EditIcon}
          refId={JumpLinkRefIds.ESITServices}
          tooltipProps={{ label: 'Edit' }}
          disabled={!isActive}
        />
      ),
    };
  }, [isActive, onClick]);
};

export default useCardHeaderProps;
