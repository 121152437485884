import {
  Box,
  ChakraProps,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Checkbox } from 'components/formik';
import { DomainKey } from '../../EvaluationsSection/EvaluationForm/validation';
import { Evaluation } from '@cssat/acorn-api-shared';
import { FC, ReactNode } from 'react';
import { isEvaluationQualifyingForDomain } from '../../EvaluationsSection/helpers';
import { useField } from 'formik';
import QualifyingIconLabel from '../../qualifyingIconLabel';
import useEligibilityCriteria from '../useEligibilityCriteria';

interface DomainEvaluationToggleProps extends ChakraProps {
  readonly label: ReactNode;
  readonly domainName: DomainKey;
  readonly evaluations?: Evaluation[];
}

const DomainEvaluationToggle: FC<DomainEvaluationToggleProps> = ({
  label,
  domainName,
  evaluations,
  ...props
}) => {
  const [, meta] = useField(domainName);
  const errorMsg = meta.touched && meta.error;
  const isInvalid = () => Boolean(errorMsg);
  return (
    <FormControl name={domainName} isInvalid={isInvalid()} {...props}>
      <FormLabel textStyle="errorLabel">{label}</FormLabel>
      <Stack spacing={4}>
        {evaluations &&
          evaluations.map(evaluation => (
            <Checkbox
              key={`${domainName}-${evaluation.evaluationToolName}`}
              value={evaluation.id || 'true'}
              label={
                isEvaluationQualifyingForDomain(domainName, evaluation) ? (
                  <QualifyingIconLabel label={evaluation.evaluationToolName} />
                ) : (
                  evaluation.evaluationToolName
                )
              }
              name={domainName}
            />
          ))}
      </Stack>
      <FormErrorMessage>{errorMsg}</FormErrorMessage>
    </FormControl>
  );
};

const EvaluatedDomainsForm: FC = () => {
  const { domains } = useEligibilityCriteria();

  return (
    <>
      <Heading as="h4" variant="tertiary">
        Evaluation domains
      </Heading>
      <Text textStyle="bodyText">
        Select at least one evaluation to associate with each domain
      </Text>
      <Flex justifyContent="space-between">
        <Box flexBasis="45%">
          <DomainEvaluationToggle
            domainName="adaptive"
            mb={4}
            label={'Adaptive (select at least one)'}
            evaluations={domains?.adaptive}
          />
          <DomainEvaluationToggle
            domainName="cognitive"
            mb={4}
            label={'Cognitive (select at least one)'}
            evaluations={domains?.cognitive}
          />
          <DomainEvaluationToggle
            domainName="communicationExpressive"
            mb={4}
            label={'Expressive communication (select at least one)'}
            evaluations={domains?.communicationExpressive}
          />
          <DomainEvaluationToggle
            domainName="communicationReceptive"
            mb={4}
            label={'Receptive communication (select at least one)'}
            evaluations={domains?.communicationReceptive}
          />
        </Box>
        <Box flexBasis="45%">
          <DomainEvaluationToggle
            domainName="physicalMotorFine"
            mb={4}
            label={'Physical: Fine motor (select at least one)'}
            evaluations={domains?.physicalMotorFine}
          />
          <DomainEvaluationToggle
            domainName="physicalMotorGross"
            mb={4}
            label={'Physical: Gross motor (select at least one)'}
            evaluations={domains?.physicalMotorGross}
          />
          <DomainEvaluationToggle
            domainName="socialEmotional"
            mb={4}
            label={'Social/Emotional (select at least one)'}
            evaluations={domains?.socialEmotional}
          />
          <DomainEvaluationToggle
            domainName="vision"
            mb={4}
            label={'Vision (select at least one)'}
            evaluations={domains?.vision}
          />
          <DomainEvaluationToggle
            domainName="hearing"
            mb={4}
            label={'Hearing (select at least one)'}
            evaluations={domains?.hearing}
          />
        </Box>
      </Flex>
    </>
  );
};

export default EvaluatedDomainsForm;
