import { FC, useEffect } from 'react';
import { Flex, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { PageHeader, ReferralStatusBadge } from 'molecules';
import { useKeyPress, useLatestReferral } from 'hooks';
import { useOpenFormContext, useTopBarContext } from 'contexts';
import { useParams } from 'react-router-dom';
import { useTabIndexContext } from 'contexts';
import { zIndexMap } from 'theme';
import CosTabContent from '../CosTab';
import DocumentsTabContent from '../DocumentsTab';
import EvaluationsTabContent from '../EvaluationsTab';
import ExitTabContent from '../ExitTab';
import OverviewTabContent from '../OverviewTab';
import ServicesTabContent from '../ServicesTab';
import TabListSection from '../TabListSection/TabListSection';
import UnderConstruction from 'components/UnderConstruction/UnderConstruction';
import useChildRecord from '../OverviewTab/useChildRecord';
import useUpdatePageHeaderContext from './useUpdatePageHeaderContext';

const ChildNameHeader = () => {
  const { id: childId } = useParams<{ id: string }>();
  const referral = useLatestReferral(childId);
  const { data } = useChildRecord(childId);
  const childDetails = data?.childDetails;

  if (!childDetails) {
    return null;
  }

  return (
    <PageHeader
      width="100%"
      title={`${childDetails.firstName} ${childDetails.lastName}`}
      badge={referral && <ReferralStatusBadge active={referral.isActive} />}
    />
  );
};

const PageContent: FC = () => {
  const pageHeaderContainerRef = useUpdatePageHeaderContext();
  const [topBar] = useTopBarContext();
  const { tabIndex, handleTabIndexChange } = useTabIndexContext();
  const escPressed = useKeyPress('Escape');
  const [openFormState, setOpenFormState] = useOpenFormContext();

  useEffect(() => {
    if (escPressed && openFormState) {
      setOpenFormState(undefined);
    }
  }, [escPressed, openFormState, setOpenFormState]);

  return (
    <Tabs
      isLazy
      lazyBehavior="keepMounted"
      index={tabIndex}
      onChange={handleTabIndexChange}
    >
      <Flex
        ref={pageHeaderContainerRef}
        flexDirection="column"
        position="sticky"
        backgroundColor="white"
        width="100%"
        zIndex={zIndexMap.formLayer}
        sx={{
          top: topBar?.clientHeight,
        }}
      >
        <ChildNameHeader />
        <TabListSection />
      </Flex>
      <TabPanels mt={8}>
        <TabPanel>
          <OverviewTabContent />
        </TabPanel>
        <TabPanel>
          <EvaluationsTabContent />
        </TabPanel>
        <TabPanel>
          <CosTabContent />
        </TabPanel>
        <TabPanel>
          <ServicesTabContent />
        </TabPanel>
        <TabPanel>
          {/* IFSPs */}
          <UnderConstruction />
        </TabPanel>
        <TabPanel>
          <ExitTabContent />
        </TabPanel>
        <TabPanel>
          {/* Team members */}
          <UnderConstruction />
        </TabPanel>
        <TabPanel>
          {/* Notes */}
          <UnderConstruction />
        </TabPanel>
        <TabPanel>
          <DocumentsTabContent />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default PageContent;
