import { ChildListResult } from '@cssat/acorn-api-shared';
import { FC, createContext, useContext, useState } from 'react';

export interface ChildListTableConfig {
  readonly sortColumn: string;
  readonly sortDirection: 'asc' | 'desc';
  readonly currentChildId?: ChildListResult['id'] | undefined;
}

type ChildListTableContextType = [
  ChildListTableConfig,
  (tableConfig: ChildListTableConfig) => void
];

const ChildListTableContext = createContext<ChildListTableContextType>([
  {
    sortColumn: 'dateOfBirth',
    sortDirection: 'desc',
  },
  () => {},
]);

export const useChildTableContext = () => useContext(ChildListTableContext);

export const ChildListTableContextProvider: FC = ({ children }) => {
  const [config, setConfig] = useState<ChildListTableConfig>({
    sortColumn: 'dateOfBirth',
    sortDirection: 'desc',
  });

  return (
    <ChildListTableContext.Provider value={[config, setConfig]}>
      {children}
    </ChildListTableContext.Provider>
  );
};
