import { Box, Flex, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import {
  Checkbox,
  DateField,
  RadioGroupField,
  RadioOption,
  TextField,
  TextareaField,
} from 'components/formik';
import { FC, useCallback } from 'react';
import { FormSection } from 'components/styled/forms/FormSection';
import { SexOfChildFields } from 'molecules';
import { adaptApiToFormFields } from './adaptApiToFormFields';
import { adaptFormToApi } from './adaptFormToApi';
import { formSchema } from './validation';
import { races } from 'lib';
import { startOfYesterday } from 'date-fns';
import { useParams } from 'react-router-dom';
import ChildSectionFormikWrapper from 'pages/ChildRecord/ChildSectionFormikWrapper/ChildSectionFormikWrapper';
import useChildRecord, {
  useMutateChildDetails,
} from 'pages/ChildRecord/OverviewTab/useChildRecord';

const ChildDetailsForm: FC = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data } = useChildRecord(childId);
  const mutateChildDetails = useMutateChildDetails(childId);
  const childDetails = data?.childDetails;

  const onSubmit = useCallback(
    async values => {
      const formData = adaptFormToApi(values);
      await mutateChildDetails(formData);
    },
    [mutateChildDetails]
  );

  if (!childDetails) {
    return null;
  }

  const coercedInitialValues = adaptApiToFormFields(childDetails);

  return (
    <Flex padding={6} flexGrow={1}>
      <ChildSectionFormikWrapper
        formikProps={{
          initialValues: coercedInitialValues,
          validationSchema: formSchema,
        }}
        apiRequest={onSubmit}
        successToastProps={{
          title: 'Child details updated',
        }}
        errorToastProps={{
          title: `Sorry, we can’t update the Child Details right now.`,
          description: 'Please check your connection and try again.',
        }}
        humanReadableFormName="Child Overview - Child Details"
        inlineAlertMessage="There was a problem updating the child details."
      >
        <Box width="100%">
          <FormSection>
            <TextField name="firstName" label="First name" required />
            <TextField name="lastName" label="Last name" required />
            <DateField
              name="dateOfBirth"
              label="Date of birth"
              disabledDays={{
                after: startOfYesterday(),
              }}
              required
            />
            <SexOfChildFields name="sex" noteFieldName="otherSexOfChild" />
          </FormSection>
          <FormSection>
            <RadioGroupField
              name="hispanicOrLatino"
              label="Is the child Hispanic or Latino?"
            >
              <RadioOption value="null" label="Unspecified" />
              <RadioOption value="true" label="Yes" />
              <RadioOption value="false" label="No" />
            </RadioGroupField>
            <FormControl name="race">
              <FormLabel>Race of the child (check all that apply)</FormLabel>
              <Stack spacing={2}>
                {races.map(race => (
                  <Checkbox
                    key={race.id.toString()}
                    label={race.race}
                    value={race.id.toString()}
                    name="race"
                    mb={0}
                  />
                ))}
              </Stack>
            </FormControl>
          </FormSection>
          <FormSection>
            <TextField
              name="primaryLanguage"
              label="Child's primary language"
            />
            <Checkbox
              label="Interpreter needed"
              name="interpreterNeeded"
              value="true"
              marginBottom={0}
            />
            <TextField name="otherLanguages" label="Child's other languages" />
          </FormSection>
          <FormSection>
            <Checkbox
              label="Referral occurred under CAPTA"
              name="referredUnderCapta"
              value="true"
              marginBottom={0}
            />
            <Checkbox
              label="Child is in foster care"
              name="inFosterCare"
              value="true"
              marginBottom={0}
            />
          </FormSection>
          <TextareaField name="notes" label="Child detail notes" />
        </Box>
      </ChildSectionFormikWrapper>
    </Flex>
  );
};

export default ChildDetailsForm;
