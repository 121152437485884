import {
  Intensity,
  Setting,
} from '@cssat/acorn-api-shared/dist/service-delivery';
import type { ChildWithServicePlans, ServicePlan } from 'pages/Services/types';

const dosages = {
  xsmall: {
    duration: 15,
    frequency: 1,
    timescale: 'quarterly',
  },
  small: {
    duration: 30,
    frequency: 1,
    timescale: 'monthly',
  },
  medium: {
    duration: 45,
    frequency: 2,
    timescale: 'monthly',
  },
  normal: {
    duration: 60,
    frequency: 1,
    timescale: 'weekly',
  },
  large: {
    duration: 75,
    frequency: 2,
    timescale: 'weekly',
  },
  xlarge: {
    duration: 90,
    frequency: 5,
    timescale: 'weekly',
  },
};

const planDetails = {
  xlight: {
    ...dosages.xsmall,
    intensity: Intensity.Group,
    setting: Setting.CommunityGroup,
  },
  light: {
    ...dosages.small,
    intensity: Intensity.Group,
    setting: Setting.AgencySponsoredGroup,
  },
  semiRegular: {
    ...dosages.medium,
    intensity: Intensity.Individual,
    setting: Setting.ClinicVisit,
  },
  regular: {
    ...dosages.normal,
    intensity: Intensity.Individual,
    setting: Setting.HomeVisit,
  },
  heavy: {
    ...dosages.large,
    intensity: Intensity.Individual,
    setting: Setting.HomeVisit,
  },
  xheavy: {
    ...dosages.xlarge,
    intensity: Intensity.Individual,
    setting: Setting.HomeVisit,
  },
};

const therapies = {
  speechTherapy: {
    serviceTypeId: '1',
    name: 'Speech therapy',
  },
  occupationalTherapy: {
    serviceTypeId: '2',
    name: 'Occupational therapy',
  },
  physicalTherapy: {
    serviceTypeId: '3',
    name: 'Physical therapy',
  },
  specializedInstruction: {
    serviceTypeId: '4',
    name: 'Specialized instruction',
  },
};

export const fakeKids: ChildWithServicePlans[] = [
  {
    id: '1',
    name: 'Luz Gonzalez',
    servicePlans: [
      { ...therapies.speechTherapy, ...planDetails.heavy, id: '1-1' },
      { ...therapies.occupationalTherapy, ...planDetails.light, id: '1-2' },
    ],
  },
  {
    id: '2',
    name: 'Jamal Ahmed',
    servicePlans: [
      { ...therapies.specializedInstruction, ...planDetails.xlight, id: '2-1' },
    ],
  },
  {
    id: '3',
    name: 'Jacqueline Gray',
    servicePlans: [
      { ...therapies.occupationalTherapy, ...planDetails.light, id: '3-1' },
      { ...therapies.physicalTherapy, ...planDetails.semiRegular, id: '3-2' },
    ],
  },

  {
    id: '4',
    name: 'Victor Lee',
    servicePlans: [
      { ...therapies.physicalTherapy, ...planDetails.regular, id: '4-1' },
    ],
  },
  {
    id: '5',
    name: 'Sabrina Sparks',
    servicePlans: [
      { ...therapies.specializedInstruction, ...planDetails.heavy, id: '5-1' },
      { ...therapies.occupationalTherapy, ...planDetails.xheavy, id: '5-2' },
    ],
  },
  {
    id: '6',
    name: 'Clement Moyer',
    servicePlans: [
      { ...therapies.speechTherapy, ...planDetails.regular, id: '6-1' },
      { ...therapies.occupationalTherapy, ...planDetails.heavy, id: '6-2' },
    ],
  },
  {
    id: '7',
    name: 'Felicia Hamilton',
    servicePlans: [
      { ...therapies.speechTherapy, ...planDetails.semiRegular, id: '7-1' },
      { ...therapies.specializedInstruction, ...planDetails.xheavy, id: '7-2' },
    ],
  },
  {
    id: '8',
    name: 'Jeremy Russell',
    servicePlans: [
      { ...therapies.occupationalTherapy, ...planDetails.regular, id: '8-1' },
    ],
  },
];

export const fetchKid = (kidId: string): Promise<ChildWithServicePlans> => {
  return new Promise<ChildWithServicePlans>((resolve, reject) => {
    const kid = fakeKids.find(kid => kid.id === kidId.toString());
    if (!kid) {
      reject('No kid found');
      return;
    }
    resolve(kid);
  });
};

export const fetchKids = (
  kidIds: string[]
): Promise<ChildWithServicePlans[]> => {
  return new Promise((resolve, reject) => {
    Promise.all(kidIds.map(async k => await fetchKid(k))).then(kids => {
      if (!kids) {
        reject('kids not found');
      }
      resolve(kids);
    });
  });
};

const allServicePlans = fakeKids.flatMap(k => {
  if (!k.servicePlans) return [];
  return k.servicePlans;
});

export const fetchServicePlan = (
  servicePlanId: string
): Promise<ServicePlan> => {
  return new Promise<ServicePlan>((resolve, reject) => {
    const foundService = allServicePlans.find(
      service => service.id === servicePlanId
    );
    if (!foundService) {
      reject('No service plan found');
      return;
    }
    resolve(foundService);
  });
};

export const fetchServicePlans = (
  servicePlanIds: string[]
): Promise<ServicePlan[]> => {
  return new Promise((resolve, reject) => {
    Promise.all(
      servicePlanIds.map(
        async servicePlanId => await fetchServicePlan(servicePlanId)
      )
    ).then(services => {
      if (!services) {
        reject('services not found');
      }
      resolve(services);
    });
  });
};
