import {
  ContactInputControlMap,
  PartialContactInputControlMap,
  defaultContactInputControls,
} from 'molecules/ContactInputSection';
import { FieldControl } from 'molecules/types';

export interface PartialContactRadioControlMap
  extends PartialContactInputControlMap {
  contactRadio?: Partial<FieldControl>;
  unknown?: Partial<FieldControl>;
  mailingAddress?: Partial<FieldControl>;
}

export interface ContactRadioControlMap extends ContactInputControlMap {
  contactRadio: FieldControl;
  unknown: FieldControl;
  mailingAddress: FieldControl;
}

export const defaultContactRadioControls: ContactRadioControlMap = {
  ...defaultContactInputControls,
  contactRadio: {
    label: 'Preferred contact method',
    name: 'preferredContactMethod',
    isVisible: true,
  },
  unknown: {
    label: 'Unknown',
    name: 'unknown',
    isVisible: true,
  },
  mailingAddress: {
    label: 'Mailing address',
    name: 'mailingAddress',
    isVisible: true,
  },
};
