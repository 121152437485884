import { JumpLinkRefIds } from 'molecules';
import { SkillsSections } from '@cssat/acorn-api-shared';

const summaryBullets = [
  'Information from the family',
  'Concrete examples of age-anchored, functional skills (foundational, immediate foundational, age-expected)',
  'Where and how often functional skills are used',
  'How all developmental domains impact this functional outcome area',
  'Consideration for the child’s abilities relative to the family’s culture',
  'Use of assistive technology',
];

export interface SkillsCardContent {
  skillType: SkillsSections;
  title: string;
  headingTitleExtra?: string;
  sectionOrderNumber: number;
  withOrWhen: ' with ' | ' when ';
  skillExampleBullets: string[];
  summaryBullets: string[];
  jumplinkRef: JumpLinkRefIds;
}

const skillsFormContentProps: { [key in SkillsSections]: SkillsCardContent } = {
  [SkillsSections.POSITIVE]: {
    skillType: SkillsSections.POSITIVE,
    jumplinkRef: JumpLinkRefIds.PositiveSkills,
    title: 'Positive social-emotional skills',
    headingTitleExtra: '(including social relationships)',
    sectionOrderNumber: 1,
    withOrWhen: ' with ',
    skillExampleBullets: [
      'Relating with adults',
      'Relating with other children,',
      '(If older than 18 months) Following rules related to groups or interacting with others',
    ],
    summaryBullets: summaryBullets,
  },
  [SkillsSections.ACQUIRING]: {
    skillType: SkillsSections.ACQUIRING,
    jumplinkRef: JumpLinkRefIds.AcquiringSkills,
    title: 'Acquiring and using knowledge and skills',
    sectionOrderNumber: 2,
    withOrWhen: ' when ',
    skillExampleBullets: [
      'Thinking, reasoning, remembering, and problem solving',
      'Understanding symbols',
      'Understanding the physical and social worlds',
    ],
    summaryBullets: summaryBullets,
  },
  [SkillsSections.ACTIONS]: {
    skillType: SkillsSections.ACTIONS,
    jumplinkRef: JumpLinkRefIds.TakingActions,
    title: 'Taking appropriate actions to meet needs',
    sectionOrderNumber: 3,
    withOrWhen: ' with ',
    skillExampleBullets: [
      'Taking care of basic needs (ex: showing hunger, dressing, feeding, toileting, etc)',
      'If older than 24 months, contributing to own health and safety (ex: follows rules, assists with hand washing, avoids inedible objects)',
      'Getting from place to place (mobility) and using tools (ex: forks, strings attached to objects)',
    ],
    summaryBullets: summaryBullets,
  },
};

const skillHeaderTitleBuilder = (content: SkillsCardContent) =>
  `Outcome ${content.sectionOrderNumber} - ${content.title} ${
    content.headingTitleExtra || ''
  }`;

export { skillsFormContentProps, skillHeaderTitleBuilder, SkillsSections };
