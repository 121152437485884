import { Evaluation } from '@cssat/acorn-api-shared';
import { FormFields } from './validation';
import { dateFormatData, parseDateFromString } from 'lib';

const adaptApiToForm = (evaluation: Evaluation) => {
  const formFields: FormFields = {
    ...(evaluation as FormFields),
    domainsSelected: [],
    dateAdministered: evaluation.dateAdministered
      ? parseDateFromString(evaluation.dateAdministered, dateFormatData)
      : undefined,
  };

  for (let key in formFields.domainsTested) {
    formFields.domainsSelected?.push(key);
  }

  return formFields;
};

export default adaptApiToForm;
