import { ChildRecord } from '@cssat/acorn-api-shared';
import { dateFormatData } from 'lib';
import { parse } from 'date-fns';

export const adaptApiToFormFields = (
  childDetails: ChildRecord['childDetails']
) => {
  const {
    id,
    updatedAt,
    createdAt,
    assignedAgency,
    assignedUser,
    hispanicOrLatino,
    race,
    ...rest
  } = childDetails;
  const isOtherSex = !['male', 'female'].includes(childDetails.sex);

  return {
    ...rest,
    dateOfBirth: parse(childDetails.dateOfBirth, dateFormatData, new Date()),
    sex: isOtherSex ? 'other' : childDetails.sex,
    otherSexOfChild:
      isOtherSex && childDetails.sex !== 'other' ? childDetails.sex : '',
    hispanicOrLatino: hispanicOrLatino?.toString() ?? 'null',
    race: race.map(raceNum => raceNum.toString()),
  };
};
