import * as yup from 'yup';
import { yupNullableString } from 'lib';

export const formSchema = yup
  .object({
    assignedUserId: yupNullableString(),
  })
  .defined();

export type FormFields = yup.InferType<typeof formSchema>;
