import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { yupRequiredBoolean, yupRequiredString, yupRequiredUuid } from 'lib';

export const formSchema = yup
  .object({
    id: yupRequiredUuid(),
    name: yupRequiredString('Participant name is required'),
    role: yupRequiredString('Participant role is required'),
    isArchived: yupRequiredBoolean(),
    presentLevelsActivity: yupRequiredBoolean(),
    eligibilityServicesActivity: yupRequiredBoolean(),
    contributedInformationActivity: yupRequiredBoolean(),
    participatedOutcomeActivity: yupRequiredBoolean(),
  })
  .defined();

export type FormFields = yup.InferType<typeof formSchema>;

export const createEmptyTeamMember = () => ({
  id: uuid(),
  name: '',
  role: '',
  isArchived: false,
  presentLevelsActivity: false,
  eligibilityServicesActivity: false,
  contributedInformationActivity: false,
  participatedOutcomeActivity: false,
});
