import { Box, Text } from '@chakra-ui/react';
import { ChildWithServicePlans, ServicePlan } from 'pages/Services/types';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { PlanDetails } from '../PlanDetails';
import { RadioGroupField, RadioPill } from 'components/formik';
import { Status } from '@cssat/acorn-api-shared/dist/service-delivery';
import { fetchKid, fetchServicePlan } from 'lib';
import { space } from 'theme';
import { useFormikContext } from 'formik';
import type { FormFields } from './validation';

type SelectedChildAndService = {
  child: ChildWithServicePlans;
  servicePlan: ServicePlan;
};

const SelectedChildAndServiceSummary: FC = () => {
  const { values } = useFormikContext<FormFields>();
  const [selectedChildAndService, setSelectedChildAndService] =
    useState<SelectedChildAndService>();

  useEffect(() => {
    const resolveData = async () => {
      const child = await fetchKid(values.child);
      const servicePlan = await fetchServicePlan(values.service);
      setSelectedChildAndService({
        child,
        servicePlan,
      });
    };

    resolveData();
  }, [values.child, values.service, setSelectedChildAndService]);

  const selectionDetailsText = selectedChildAndService
    ? `${selectedChildAndService.child?.name}, ${selectedChildAndService.servicePlan?.name}`
    : '';

  return (
    <Text as="span" variant="bodyText" fontWeight="medium">
      {selectionDetailsText}
    </Text>
  );
};

export const Section02ServiceStatus: FC<{
  service: ServicePlan | undefined;
  setStep: Dispatch<SetStateAction<number>>;
}> = ({ service, setStep }) => {
  const { values, setFieldValue } = useFormikContext<FormFields>();

  useEffect(() => {
    if (!values.serviceStatus) {
      return;
    }

    setStep(() => 3);
  }, [values.serviceStatus, setStep, service, setFieldValue]);

  return (
    <>
      <SelectedChildAndServiceSummary />
      <Box mt={space.one}>{service && <PlanDetails service={service} />}</Box>
      <RadioGroupField
        name="serviceStatus"
        label="Was the service delivered?"
        labelStyle="bigLabel"
        direction="horizontal"
        required
      >
        <RadioPill value={Status.AsPlanned}>Yes, as planned</RadioPill>
        <RadioPill value={Status.WithChanges}>Yes, with changes</RadioPill>
        <RadioPill value={Status.NotDelivered}>No</RadioPill>
      </RadioGroupField>
    </>
  );
};
