import { FieldControl } from 'molecules/types';

export const contactInputFields = [
  'phoneHome',
  'phoneCell',
  'phoneWork',
  'phoneGeneric',
  'fax',
  'email',
] as const;

export type ContactInputFields = typeof contactInputFields[number];
export type PartialContactInputControlMap = Partial<
  Record<ContactInputFields, Partial<FieldControl>>
>;
export type ContactInputControlMap = Record<ContactInputFields, FieldControl>;

export const defaultContactInputControls: ContactInputControlMap = {
  phoneHome: {
    name: 'phoneHome',
    label: 'Home phone',
    isVisible: true,
  },
  phoneCell: {
    name: 'phoneCell',
    label: 'Cell phone',
    isVisible: true,
  },
  phoneWork: {
    name: 'phoneWork',
    label: 'Work phone',
    isVisible: true,
  },
  phoneGeneric: {
    name: 'phoneGeneric',
    label: 'Generic phone',
    isVisible: true,
  },
  fax: {
    name: 'fax',
    label: 'Fax number',
    isVisible: true,
  },
  email: {
    name: 'email',
    label: 'Email address',
    isVisible: true,
  },
};
