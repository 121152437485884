import { NullishTrainingUser } from 'contexts';
import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from './useLocalStorage';

export interface UserSession {
  user?: NullishTrainingUser;
  createdAt: string;
  ttl: number;
}
export interface TrainingUserSessionHook {
  user: NullishTrainingUser;
  setUser: (user: NullishTrainingUser) => void;
  logout: () => void;
  validate: () => void;
}

const nullSession = {
  user: null,
  createdAt: '',
  ttl: -1,
};

// Session duration in seconds
const SESSION_DURATION = 3600;

// Session polling interval in milliseconds
const VALIDATE_SESSION_INTERVAL = 5000;

export const useTrainingUserSession = (): TrainingUserSessionHook => {
  const [
    trainingUserSession,
    setTrainingUserSession,
    removeTrainingUserSession,
    getTrainingUserSession,
  ] = useLocalStorage<UserSession>('trainingUserSession', nullSession);

  const [trainingUser, setTrainingUser] = useState<NullishTrainingUser>(
    trainingUserSession.user
  );

  const logout = useCallback(() => {
    setTrainingUser(null);
    removeTrainingUserSession();
  }, [setTrainingUser, removeTrainingUserSession]);

  const isSessionValid = useCallback(
    (userSession = trainingUserSession): boolean => {
      const { ttl, createdAt } = userSession;
      const created = new Date(createdAt).valueOf() / 1000;
      const now = Date.now() / 1000;
      return now - created <= ttl;
    },
    [trainingUserSession]
  );

  const validateSession = useCallback(() => {
    const fromStorage = getTrainingUserSession();
    if (isSessionValid(fromStorage)) return;

    if (fromStorage !== nullSession) {
      logout();
    }
  }, [isSessionValid, getTrainingUserSession, logout]);

  useEffect(() => {
    validateSession();
    const validateIntervalID = setInterval(
      validateSession,
      VALIDATE_SESSION_INTERVAL
    );
    return () => {
      clearInterval(validateIntervalID);
    };
  }, [validateSession]);

  const setUser = (user: NullishTrainingUser) => {
    setTrainingUserSession({
      user: user,
      ttl: SESSION_DURATION,
      createdAt: new Date().toISOString(),
    });
    setTrainingUser(user);
  };

  return {
    user: trainingUser,
    setUser,
    logout,
    validate: validateSession,
  };
};
