import { Box, Flex, FormLabel, Stack } from '@chakra-ui/react';
import { Checkbox, TextField, TextareaField } from 'components/formik';
import { ChildContact } from '@cssat/acorn-api-shared';
import { ContactInfoForm } from 'molecules';
import { FC, useCallback } from 'react';
import { FormFields, formSchema } from './validation';
import { FormSection } from 'components/styled/forms/FormSection';
import {
  useMutateAddContact,
  useMutateUpdateContacts,
} from '../../useChildContacts';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import ChildSectionFormikWrapper from 'pages/ChildRecord/ChildSectionFormikWrapper/ChildSectionFormikWrapper';
import ConfidentialityRadio from './ConfidentialityRadio';
import adaptApiToForm from './adaptApiToForm';
import adaptFormToApi from './adaptFormToApi';
interface ChildContactsFormProps {
  readonly childContact?: ChildContact;
}

const ChildContactsForm: FC<ChildContactsFormProps> = ({ childContact }) => {
  const { id: childId } = useParams<{ id: string }>();
  const addContact = useMutateAddContact(childId);
  const updateContact = useMutateUpdateContacts(childId);

  const apiRequest = useCallback(
    async (values: FormFields) => {
      const newContactUuid = uuid();
      const apiPayload = childContact
        ? adaptFormToApi(values, childContact.id)
        : adaptFormToApi(values, newContactUuid);

      const sendRequest = childContact ? updateContact : addContact;

      await sendRequest(apiPayload);
    },
    [addContact, childContact, updateContact]
  );

  const initialValues: FormFields = adaptApiToForm(childContact);

  return (
    <Flex padding={6} flexGrow={1}>
      <ChildSectionFormikWrapper
        apiRequest={apiRequest}
        formikProps={{ validationSchema: formSchema, initialValues }}
        successToastProps={{
          title: `Child contact ${childContact ? 'updated' : 'created'}`,
        }}
        errorToastProps={{
          title: `Sorry, we can’t ${
            childContact ? 'update' : 'create'
          } the child contact right now.`,
          description: 'Please check your connection and try again.',
        }}
        humanReadableFormName="Child Overview - Child Contact(s)"
        inlineAlertMessage="There was a problem creating the child contact."
      >
        <ContactInfoForm
          fieldOverrides={{
            firstName: { isVisible: false },
            middleName: { isVisible: false },
            lastName: { isVisible: false },
            fax: { isVisible: false },
            phoneGeneric: { isVisible: false },
          }}
        />
        <FormSection>
          <TextField
            name="primaryLanguage"
            label="Contact's primary language"
          />
          <TextField name="otherLanguages" label="Contact's other languages" />
        </FormSection>
        <FormSection>
          <Box>
            <FormLabel>Other information</FormLabel>
            <Stack spacing={4}>
              <Checkbox
                value="true"
                name="interpreterNeeded"
                label="Interpreter needed"
              />
              <Checkbox
                value="true"
                name="isSurrogateParent"
                label="Surrogate parent"
              />
              <Checkbox
                value="true"
                name="transportationNeeded"
                label="Transportation needed"
              />
            </Stack>
          </Box>
        </FormSection>
        <FormSection>
          <ConfidentialityRadio />
        </FormSection>
        <TextareaField name="notes" label="Contact notes" />
      </ChildSectionFormikWrapper>
    </Flex>
  );
};

export default ChildContactsForm;
