import { FormFields } from './validation';
import type { UpdatePrimaryCareProviderRequest } from 'pages/ChildRecord/OverviewTab/useChildRecord';

export const adaptFormToApi = (
  formFields: FormFields
): UpdatePrimaryCareProviderRequest => {
  const { isAddressSame, ...request } = formFields;
  if (formFields.isAddressSame) {
    request.mailingAddress1 = request.physicalAddress1;
    request.mailingAddress2 = request.physicalAddress2;
    request.mailingCity = request.physicalCity;
    request.mailingState = request.physicalState;
    request.mailingZip = request.physicalZip;
  }
  return request;
};
