import { ReactComponent as ArchiveIcon } from 'icons/archive.svg';
import { ChildContact } from '@cssat/acorn-api-shared';
import { Placement } from '@chakra-ui/react';
import { ReactComponent as UnarchiveIcon } from 'icons/unarchive.svg';
import { useMemo } from 'react';
import { useMutateUpdateContacts } from '../useChildContacts';
import { useParams } from 'react-router-dom';
import { usePopupToast } from 'hooks';

const useArchiveIconProps = (contact: ChildContact, disabled: boolean) => {
  const { id: childId } = useParams<{ id: string }>();
  const updateContact = useMutateUpdateContacts(childId);
  const [reactivatedSuccessToast] = usePopupToast({
    status: 'success',
    title: 'Contact reactivated',
  });
  const [deactivatedSuccessToast] = usePopupToast({
    status: 'success',
    title: 'Contact deactivated',
  });

  return useMemo(
    () =>
      contact.isArchived
        ? {
            Icon: UnarchiveIcon,
            'aria-label': 'Reactivate Contact',
            onClick: async () => {
              await updateContact({ ...contact, isArchived: false });
              reactivatedSuccessToast();
            },
            tooltipProps: {
              placement: 'auto' as Placement,
              openDelay: 250,
              label: 'Reactivate contact',
              'aria-label': 'Reactivate contact',
            },
            disabled: disabled,
          }
        : {
            Icon: ArchiveIcon,
            'aria-label': 'Archive Contact',
            onClick: async () => {
              await updateContact({ ...contact, isArchived: true });
              deactivatedSuccessToast();
            },
            tooltipProps: {
              placement: 'auto' as Placement,
              openDelay: 250,
              label: 'Deactivate contact',
              'aria-label': 'Deactivate contact',
            },
            disabled: disabled,
          },
    [
      contact,
      deactivatedSuccessToast,
      disabled,
      reactivatedSuccessToast,
      updateContact,
    ]
  );
};

export default useArchiveIconProps;
