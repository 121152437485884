import { Participant } from '@cssat/acorn-api-shared';
import { authenticatedFetch, authenticatedPost, paths } from 'lib';
import useSWR from 'swr';

const useTeamMembers = (childId: string) => {
  return useSWR<Participant[]>(
    paths.child.getParticipants({ childId }),
    authenticatedFetch
  );
};

export const useMutateUpdateTeamMember = (childId: string) => {
  const { mutate } = useTeamMembers(childId);

  return async (participant: Participant) => {
    await authenticatedPost(
      paths.child.updateParticipant({ participantId: participant.id }),
      {
        body: participant,
      }
    );
    await mutate();
  };
};

export const useMutateAddTeamMember = (childId: string) => {
  const { mutate } = useTeamMembers(childId);

  return async (participant: Participant) => {
    await authenticatedPost(paths.child.addParticipant({ childId }), {
      body: participant,
    });
    await mutate();
  };
};

export default useTeamMembers;
