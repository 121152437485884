import { FC, createContext, useContext, useState } from 'react';

type AssessmentsArchiveEligibilityContextType = [
  boolean,
  (showArchived: boolean) => void
];

const AssessmentsArchiveEligibilityContext =
  createContext<AssessmentsArchiveEligibilityContextType>([false, () => {}]);

export const useAssessmentsArchiveEligibilityContext = () =>
  useContext(AssessmentsArchiveEligibilityContext);

export const AssessmentsArchiveEligibilityContextProvider: FC = ({
  children,
}) => {
  const value = useState<boolean>(false);

  return (
    <AssessmentsArchiveEligibilityContext.Provider value={value}>
      {children}
    </AssessmentsArchiveEligibilityContext.Provider>
  );
};
