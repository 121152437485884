import { ChildOutcome } from '@cssat/acorn-api-shared';
import { DataCard } from 'molecules';
import { FC } from 'react';
import { JumpLinkRefIds } from 'molecules/JumpLinks/constants';
import { useChildOutcomeIdContext, useOpenFormContext } from 'contexts';
import CardSkeleton from 'pages/ChildRecord/CardSkeleton/CardSkeleton';
import ChildOutcomesForm from '../ChildOutcomesForm/ChildOutcomesForm';
import ChildOutcomesView from './ChildOutcomesView';
import useCardHeaderProps from './useCardHeaderProps';

interface OutcomeCardProps {
  readonly outcome: ChildOutcome;
}

const ChildOutcomesCard: FC<OutcomeCardProps> = ({
  outcome,
}: OutcomeCardProps) => {
  const [openFormState] = useOpenFormContext();
  const [childOutcomeFormId] = useChildOutcomeIdContext();

  const isFormOpen =
    childOutcomeFormId === outcome.id &&
    openFormState?.primaryJumpLink === JumpLinkRefIds.ChildOutcomes;
  const headerProps = useCardHeaderProps(outcome);

  return (
    <>
      {outcome ? (
        <DataCard headerProps={headerProps}>
          {isFormOpen ? (
            <ChildOutcomesForm outcome={outcome} />
          ) : (
            <ChildOutcomesView outcome={outcome} />
          )}
        </DataCard>
      ) : (
        <CardSkeleton />
      )}
    </>
  );
};

export default ChildOutcomesCard;
