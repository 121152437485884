import { Alert, DataCard } from 'molecules';
import { EligibilityDecision } from 'typesToMigrate/EligibilityDecision';
import { FC, useCallback, useState } from 'react';
import { Text } from '@chakra-ui/react';
import { usePopupToast } from 'hooks';
import EligibilityView from './EligibilityView';
import useCardHeaderProps from './useCardHeaderProps';

interface EligibilityCardProps {
  readonly eligibilityDecision: EligibilityDecision;
}

const EligibilityCard: FC<EligibilityCardProps> = ({ eligibilityDecision }) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [archivedSuccessToast] = usePopupToast({
    status: 'success',
    title: 'Eligibility decision archived',
  });

  const onArchiveClick = useCallback(
    () => setIsAlertOpen(true),
    [setIsAlertOpen]
  );

  const headerProps = useCardHeaderProps({
    eligibilityDecision,
    onArchiveClick,
  });

  return (
    <>
      <DataCard headerProps={headerProps}>
        <EligibilityView eligibilityDecision={eligibilityDecision} />
      </DataCard>
      <Alert
        headerText="Archive eligibility decision"
        confirmText="Archive"
        cancelText="Go back"
        isOpen={isAlertOpen}
        setIsOpen={setIsAlertOpen}
        onConfirm={() => {
          setIsAlertOpen(false);
          archivedSuccessToast();
        }}
      >
        <Text textStyle="bodyText" fontWeight="medium" fontSize="md">
          Are you sure you want to archive this eligibility decision?
        </Text>
        <Text textStyle="bodyText" fontWeight="medium" fontSize="md">
          Note: This action is irreversible.
        </Text>
      </Alert>
    </>
  );
};

export default EligibilityCard;
