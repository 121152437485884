import { Box, Button, Text } from '@chakra-ui/react';
import { FC, useCallback, useRef, useState } from 'react';
import { useStickyItemsOffset } from 'hooks';

const roughlySixLinesOfCharacters = 400;
const roughlySixLinesOfLineHeight = '126px';

const TruncatableTextBlock: FC<{ text?: string }> = ({ text }) => {
  const isTruncatable: boolean =
    !!text && text.length >= roughlySixLinesOfCharacters;
  const [isTruncated, setIsTruncated] = useState<boolean>(true);
  const ref = useRef<HTMLDivElement>(null);
  const offset = useStickyItemsOffset();

  const scrollIntoView = useCallback(() => {
    if (!ref.current) {
      return;
    }

    const refTop = ref.current.offsetTop;
    window.scrollTo({
      top: refTop - offset,
    });
  }, [offset]);

  const renderedText = () => {
    if (!text) return '-';

    if (isTruncated)
      return `${text.slice(0, 399)}${isTruncatable ? '...' : ''}`;

    return text;
  };

  return (
    <Box ref={ref}>
      <Text
        textStyle="bodyText"
        sx={
          isTruncated
            ? {
                overflow: 'hidden',
                maxHeight: roughlySixLinesOfLineHeight,
                whiteSpace: 'pre-line',
              }
            : {
                whiteSpace: 'pre-line',
              }
        }
      >
        {renderedText()}
      </Text>
      {isTruncatable && (
        <Button
          variant="link"
          width="fit-content"
          onClick={() => {
            const prevTruncation = isTruncated;
            setIsTruncated(!isTruncated);

            if (prevTruncation === false) {
              scrollIntoView();
            }
          }}
        >
          {isTruncated ? 'See more' : 'See less'}
        </Button>
      )}
    </Box>
  );
};

export default TruncatableTextBlock;
