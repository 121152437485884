import { User } from 'contexts';
import { authenticatedFetch, paths, stubAgency } from 'lib';
import { useEffect, useState } from 'react';

export const useUserSession = () => {
  const [user, setUser] = useState<User>();
  const [isSessionLoaded, setIsSessionLoaded] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const session = await authenticatedFetch(paths.auth.session());
        setUser({
          ...session.user,
          organizations: session.organizations.length
            ? session.organizations
            : [stubAgency],
        });
        setIsSessionLoaded(true);
      } catch (e) {
        setUser(undefined);
        setIsSessionLoaded(true);
      }
    })();
  }, [setUser, setIsSessionLoaded]);

  return { user, setUser, isSessionLoaded, setIsSessionLoaded };
};
