import { ChildOutcome } from '@cssat/acorn-api-shared';
import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { TruncatableTextBlock } from 'molecules';

interface ChildOutcomesViewProps {
  readonly outcome: ChildOutcome;
}

const ChildOutcomesView: FC<ChildOutcomesViewProps> = ({
  outcome,
}: ChildOutcomesViewProps) => {
  return (
    <>
      <Flex direction="column" flexGrow={1}>
        <DetailSectionRow>
          <DetailSectionColumn flexBasis="32%">
            <DetailSectionField label="Title" value={outcome.title} />
          </DetailSectionColumn>

          <DetailSectionDivider />

          <DetailSectionColumn flexBasis="32%">
            <DetailSectionField label="Start Date" value={outcome.startDate} />
          </DetailSectionColumn>

          <DetailSectionDivider />

          <DetailSectionColumn flexBasis="36%">
            <DetailSectionField
              label="Target Date"
              value={outcome.targetDate}
            />
          </DetailSectionColumn>
        </DetailSectionRow>

        <DetailSectionDivider orientation="horizontal" />

        <Flex direction="row" flexGrow={1}>
          <DetailSectionColumn>
            <Text textStyle="sectionFieldViewLabel">
              Child and family outcome
            </Text>
            <TruncatableTextBlock text={outcome.outcomeQuestions} />
          </DetailSectionColumn>
        </Flex>
      </Flex>
    </>
  );
};

export default ChildOutcomesView;
