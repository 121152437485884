import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Heading,
} from '@chakra-ui/react';
import { FC, useRef } from 'react';

interface Props {
  readonly headerText: string;
  readonly isOpen: boolean;
  readonly confirmText: string;
  readonly confirmStyle?: string;
  readonly cancelText: string;
  readonly cancelStyle?: string;
  onConfirm(): void;
  setIsOpen(isOpen: boolean): void;
  hasCloseButton?: boolean;
}

const Alert: FC<Props> = ({
  isOpen,
  setIsOpen,
  onConfirm,
  headerText,
  cancelText,
  cancelStyle,
  confirmText,
  confirmStyle = 'ghost',
  children,
  hasCloseButton = false,
}) => {
  const ref = useRef(null);

  return (
    <AlertDialog
      isCentered
      leastDestructiveRef={ref}
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <Heading as={AlertDialogHeader} variant="secondary">
            {headerText}
          </Heading>
          {hasCloseButton && <AlertDialogCloseButton />}

          <AlertDialogBody py={0}>{children}</AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup spacing={4}>
              <Button variant={confirmStyle} onClick={() => onConfirm()}>
                {confirmText}
              </Button>
              <Button
                variant={cancelStyle}
                ref={ref}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {cancelText}
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default Alert;
