import { ChildContact } from '@cssat/acorn-api-shared';
import { DataCard } from 'molecules';
import { FC } from 'react';
import { useOpenFormContext } from 'contexts';
import ChildContactView from './ChildContactView';
import ChildContactsForm from '../ChildContactsSection/Form/ChildContactsForm';
import useCardHeaderProps from './useCardHeaderProps';

interface ChildContactCardProps {
  readonly contact: ChildContact;
}

const ChildContactCard: FC<ChildContactCardProps> = ({ contact }) => {
  const [openFormState] = useOpenFormContext();
  const headerProps = useCardHeaderProps(contact);

  const isFormOpen = openFormState?.secondaryJumplink === contact.id;

  return (
    <DataCard id={contact.id} headerProps={headerProps} hideHeader={isFormOpen}>
      {isFormOpen ? (
        <ChildContactsForm childContact={contact} />
      ) : (
        <ChildContactView childContact={contact} />
      )}
    </DataCard>
  );
};

export default ChildContactCard;
