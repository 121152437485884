import { AcornFormField } from './AcornFormField';
import { FC } from 'react';
import { FormLabel, Textarea, TextareaProps } from '@chakra-ui/react';
import { useDebounceOnChange } from 'hooks';
import { useField } from 'formik';

export interface TextareaFieldProps extends TextareaProps {
  name: string;
  required?: boolean;
  label?: string;
}

export const TextareaField: FC<TextareaFieldProps> = ({
  name,
  label,
  required = false,
  ...props
}) => {
  const [field] = useField(name);
  const [value, onChange] = useDebounceOnChange(
    field.value,
    field.onChange,
    '',
    val => val
  );

  return (
    <AcornFormField name={name} required={required}>
      {label && <FormLabel>{label}</FormLabel>}
      <Textarea
        textStyle="bodyText"
        {...field}
        {...props}
        value={value}
        onChange={onChange}
      />
    </AcornFormField>
  );
};
