import { Alert, DataCard, JumpLinkRefIds } from 'molecules';
import { Participant } from '@cssat/acorn-api-shared';
import { Text } from '@chakra-ui/react';
import { useMutateUpdateTeamMember } from '../useTeamMembers';
import { useOpenFormContext, useTeamMemberIdContext } from 'contexts';
import { useParams } from 'react-router-dom';
import { usePopupToast } from 'hooks';
import { useState } from 'react';
import TeamMemberForm from '../TeamMemberForm/TeamMemberForm';
import TeamMemberView from './TeamMemberView';
import useCardHeaderProps from './useCardHeaderProps';

interface TeamMemberCardProps {
  readonly teamMember: Participant;
}

const TeamMemberCard = ({ teamMember }: TeamMemberCardProps) => {
  const [teamMemberFormId, setTeamMemberFormId] = useTeamMemberIdContext();
  const [openFormState] = useOpenFormContext();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const headerProps = useCardHeaderProps({
    teamMember,
    setTeamMemberFormId,
    openRemoveTeamMemberWarning: () => setIsAlertOpen(true),
  });
  const { id: childId } = useParams<{ id: string }>();
  const updateTeamMember = useMutateUpdateTeamMember(childId);
  const [removedSuccessToast] = usePopupToast({
    status: 'success',
    title: 'Team member removed',
  });

  const isFormOpen =
    teamMemberFormId === teamMember.id &&
    openFormState?.primaryJumpLink === JumpLinkRefIds.TeamMembers;

  return (
    <>
      <DataCard headerProps={headerProps} hideHeader={isFormOpen}>
        {isFormOpen ? (
          <TeamMemberForm teamMember={teamMember} />
        ) : (
          <TeamMemberView teamMember={teamMember} />
        )}
      </DataCard>
      <Alert
        headerText="Remove team member"
        confirmText="Remove"
        cancelText="Go back"
        isOpen={isAlertOpen}
        setIsOpen={setIsAlertOpen}
        onConfirm={async () => {
          await updateTeamMember({ ...teamMember, isArchived: true });
          setIsAlertOpen(false);
          removedSuccessToast();
        }}
      >
        <Text textStyle="bodyText" fontWeight="medium" fontSize="md">
          Are you sure you want to remove this team member?
        </Text>
      </Alert>
    </>
  );
};

export default TeamMemberCard;
