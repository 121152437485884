import { ChildMatchQuery } from 'lib';
import { ChildMatchResult } from '@cssat/acorn-api-shared';

export interface ChildMatchState {
  isSearching: boolean;
  searchException?: Error;
  results?: ChildMatchResult[];
  query?: ChildMatchQuery;
  childMatchValue?: string;
}

export interface ResultsAction {
  type: 'childmatch.results';
  payload: { results: ChildMatchResult[] };
}

export const createResultsAction = (
  results: ChildMatchResult[]
): ResultsAction => {
  return {
    type: 'childmatch.results',
    payload: { results },
  };
};

export type SearchAction = {
  type: 'childmatch.search';
  payload: {
    query: ChildMatchQuery;
  };
};

export const createSearchAction = (query: ChildMatchQuery): SearchAction => {
  return {
    type: 'childmatch.search',
    payload: { query },
  };
};

export type ClearAction = {
  type: 'childmatch.clear';
};

export const createClearAction = (): ClearAction => {
  return { type: 'childmatch.clear' };
};

export type SearchExceptionAction = {
  type: 'childmatch.searchexception';
  payload: {
    exception: Error;
  };
};

export const createSearchExceptionAction = (
  exception: Error
): SearchExceptionAction => {
  return {
    type: 'childmatch.searchexception',
    payload: { exception },
  };
};

export type SelectChildAction = {
  type: 'childmatch.selectchild';
  payload: {
    selectedChildId: string;
  };
};

export const createSelectChildAction = (
  selectedChildId: string
): SelectChildAction => {
  return {
    type: 'childmatch.selectchild',
    payload: { selectedChildId },
  };
};

export type ChildMatchAction =
  | ResultsAction
  | SearchAction
  | ClearAction
  | SearchExceptionAction
  | SelectChildAction;

export const childMatchReducer = (
  state: ChildMatchState,
  action: ChildMatchAction
): ChildMatchState => {
  let childMatchValue;
  switch (action.type) {
    case 'childmatch.selectchild':
      return {
        ...state,
        childMatchValue: action.payload.selectedChildId,
      };
    case 'childmatch.results':
      if (
        Array.isArray(action.payload.results) &&
        action.payload.results.length === 0
      ) {
        childMatchValue = 'continueWithoutMatch';
      }
      return {
        ...state,
        isSearching: false,
        childMatchValue,
        results: action.payload.results,
      };

    case 'childmatch.search':
      return handleSearch(state, action);
    case 'childmatch.searchexception':
      return {
        ...state,
        results: undefined,
        searchException: action.payload.exception,
      };

    case 'childmatch.clear':
      // don't unnecessarily trigger a state update
      if (!state.results && !state.searchException) return state;
      return {
        ...state,
        results: undefined,
        searchException: undefined,
      };
    default:
      throw new Error(`Unknown action type: ${action}`);
  }
};

const handleSearch = (state: ChildMatchState, action: SearchAction) => {
  const {
    payload: { query },
  } = action;

  return {
    ...state,
    isSearching: true,
    searchException: undefined,
    query: {
      childFirstName: query.childFirstName,
      childLastName: query.childLastName,
      dateOfBirth: query.dateOfBirth,
    },
  };
};
