import { FC } from 'react';

import { ErrorBanner } from 'components/styled/errorBanner';

const SideBarPageErrorBanner: FC = () => (
  <ErrorBanner left="68px" top="68px" width="100%">
    <strong>Sorry, we can't reach the server right now.</strong>
    &nbsp;Please wait a moment while we try again.
  </ErrorBanner>
);

export default SideBarPageErrorBanner;
