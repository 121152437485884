import { Collapse } from '@chakra-ui/react';
import { FC } from 'react';
import { useEvaluationsArchiveContext } from 'contexts';
import { useParams } from 'react-router-dom';
import EvaluationCard from './EvaluationCard/EvaluationCard';
import NoneYetPlaceholder from 'components/NoneYetPlaceholder';
import useEvaluationsAndAssessments from './useEvaluations';

const EvaluationsCardList: FC<{ isFormOpen: boolean }> = ({ isFormOpen }) => {
  const { id: childId } = useParams<{ id: string }>();
  const [includeArchived] = useEvaluationsArchiveContext();
  const { data: evalPayload } = useEvaluationsAndAssessments(childId);
  const activeEvals = evalPayload?.activeEvals;
  const archivedEvals = evalPayload?.archivedEvals;

  if (!activeEvals || !archivedEvals) return null;

  const hasVisibleEvaluations =
    activeEvals.length > 0 || (includeArchived && archivedEvals.length > 0);

  const noEvaluationsPlaceholder = isFormOpen ? null : (
    <NoneYetPlaceholder message="No evaluations added yet" />
  );

  return (
    <>
      <Collapse in={includeArchived}>
        {archivedEvals &&
          archivedEvals.map(evaluation => (
            <EvaluationCard key={evaluation.id} evaluation={evaluation} />
          ))}
      </Collapse>
      {activeEvals.map(evaluation => (
        <EvaluationCard key={evaluation.id} evaluation={evaluation} />
      ))}
      {!hasVisibleEvaluations && noEvaluationsPlaceholder}
    </>
  );
};

export default EvaluationsCardList;
