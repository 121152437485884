import { useEffect, useRef } from 'react';
import { usePageHeaderContext } from 'contexts';

const useUpdatePageHeaderContext = () => {
  const [, setPageHeader] = usePageHeaderContext();
  const pageHeaderContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (pageHeaderContainerRef.current) {
      setPageHeader(pageHeaderContainerRef.current);
    }
  }, [pageHeaderContainerRef, setPageHeader]);

  return pageHeaderContainerRef;
};

export default useUpdatePageHeaderContext;
