import { FC, createContext, useContext, useMemo, useState } from 'react';

type TopBarContextState = [
  HTMLDivElement | undefined,
  (value?: HTMLDivElement) => void
];

const TopBarContext = createContext<TopBarContextState>([undefined, () => {}]);

const useTopBarContext = () => useContext(TopBarContext);

const TopBarContextProvider: FC = ({ children }) => {
  const [node, setNode] = useState<HTMLDivElement | undefined>(undefined);

  const value: TopBarContextState = useMemo(
    () => [node, (value?: HTMLDivElement) => setNode(value)],
    [node]
  );

  return (
    <TopBarContext.Provider value={value}>{children}</TopBarContext.Provider>
  );
};

export { TopBarContextProvider, useTopBarContext };
