import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';
import { FC } from 'react';
import underConstructionImage from './under-construction.png';

const UnderConstruction: FC<FlexProps> = () => (
  <Flex flexDirection="column" alignItems="center" justifyContent="center">
    <Box
      as="img"
      alt="Under Construction"
      src={underConstructionImage}
      width="fit-content"
      mb={5}
    />

    <Text variant="bodyText" fontWeight="medium" fontSize="md" mb={1}>
      This section is incomplete.
    </Text>
    <Text variant="bodyText">
      Reference ACORN Handoff Documents and Figma files for additional
      information.
    </Text>
  </Flex>
);

export default UnderConstruction;
