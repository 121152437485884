import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { EligibilityDecision } from 'typesToMigrate/EligibilityDecision';
import { FC, useMemo } from 'react';
import { Flex, ListItem, UnorderedList } from '@chakra-ui/react';
import { TruncatableTextBlock } from 'molecules';
import { dateFormatDisplayShort, formatISODate } from 'lib';

const domainNameMap = {
  adaptive: 'Adaptive',
  cognitive: 'Cognitive',
  communicationExpressive: 'Expressive communication',
  communicationReceptive: 'Receptive communication',
  hearing: 'Hearing',
  physicalMotorFine: 'Physical: Fine motor',
  physicalMotorGross: 'Physical: Gross motor',
  socialEmotional: 'Social/Emotional',
  vision: 'Vision',
};

const decisionPresentationMap = new Map([
  [true, 'Yes'],
  [false, 'No'],
  [undefined, undefined],
]);

interface EligbilityViewProps {
  eligibilityDecision: EligibilityDecision;
}

const EligbilityView: FC<EligbilityViewProps> = ({ eligibilityDecision }) => {
  let presentedDecision = decisionPresentationMap.get(
    eligibilityDecision.isDeterminedEligible
  );

  const selectedDiagnoses = useMemo(
    () => eligibilityDecision.diagnoses.filter(d => d.selected),
    [eligibilityDecision.diagnoses]
  );

  const presentedEvaluationDomains = useMemo(
    () =>
      (
        Object.keys(
          eligibilityDecision.evaluationsDomains
        ) as (keyof EligibilityDecision['evaluationsDomains'])[]
      )
        .filter(d => eligibilityDecision.evaluationsDomains[d].length > 0)
        .map(d => {
          const domainName = domainNameMap[d];
          const domainTools = eligibilityDecision.evaluationsDomains[d]
            .map(qualifier => qualifier.label)
            .join(', ');
          const domainWithTools = `${domainName} — ${domainTools}`;

          return { name: domainName, presentedValue: domainWithTools };
        }),
    [eligibilityDecision.evaluationsDomains]
  );

  return (
    <Flex flexGrow={1} direction="column">
      <DetailSectionRow>
        <DetailSectionColumn flexBasis="33%">
          <DetailSectionField
            label="Eligibility decision"
            showWhenEmpty
            value={presentedDecision}
          />
          <DetailSectionField
            label="Eligibility Decision Date"
            showWhenEmpty
            value={formatISODate(
              eligibilityDecision.decisionDate.toISOString(),
              dateFormatDisplayShort
            )}
          />
        </DetailSectionColumn>

        <DetailSectionDivider />

        <DetailSectionColumn flexBasis="33%">
          <DetailSectionField label="Eligibility team members" showWhenEmpty>
            {eligibilityDecision.teamMembers.length && (
              <UnorderedList
                textStyle="sectionFieldViewValue"
                spacing={0}
                ml={6}
              >
                {eligibilityDecision.teamMembers.map(tm => (
                  <ListItem key={tm.id}>
                    {tm.name}, {tm.role}
                  </ListItem>
                ))}
              </UnorderedList>
            )}
          </DetailSectionField>
        </DetailSectionColumn>

        <DetailSectionDivider />

        <DetailSectionColumn flexBasis="33%">
          <DetailSectionField label="Diagnoses" showWhenEmpty>
            {selectedDiagnoses.length && (
              <UnorderedList
                textStyle="sectionFieldViewValue"
                spacing={0}
                ml={6}
              >
                {selectedDiagnoses.map(d => (
                  <ListItem key={d.id}>{d.label}</ListItem>
                ))}
              </UnorderedList>
            )}
          </DetailSectionField>
          <DetailSectionField label="Evaluation domains" showWhenEmpty>
            {presentedEvaluationDomains.length && (
              <UnorderedList
                textStyle="sectionFieldViewValue"
                spacing={0}
                ml={6}
              >
                {presentedEvaluationDomains.map(domain => (
                  <ListItem key={domain.name}>{domain.presentedValue}</ListItem>
                ))}
              </UnorderedList>
            )}
          </DetailSectionField>
        </DetailSectionColumn>
      </DetailSectionRow>
      <DetailSectionDivider orientation="horizontal" />
      <DetailSectionRow mb={4}>
        <DetailSectionColumn>
          <DetailSectionField label="Informed clinical opinion">
            <TruncatableTextBlock text={eligibilityDecision.icoText} />
          </DetailSectionField>
        </DetailSectionColumn>
      </DetailSectionRow>
      <DetailSectionRow>
        <DetailSectionColumn>
          <DetailSectionField
            label="ICO created"
            showWhenEmpty
            value={formatISODate(
              eligibilityDecision.icoCreated.toISOString(),
              dateFormatDisplayShort
            )}
          />
        </DetailSectionColumn>
      </DetailSectionRow>
    </Flex>
  );
};

export default EligbilityView;
