import * as yup from 'yup';
import { yupNullableString } from 'lib';

export const formSchema = yup.object({
  conferenceHappened: yup.boolean(),
  conferenceDeclinedDate: yupNullableString(),
  conferencePlannedDate: yupNullableString(),
  conferenceActualDate: yupNullableString(),
  conferenceCauseForDelay: yupNullableString(),
  conferenceCauseForDelayReason: yupNullableString(),
  conferenceDeclinedReason: yupNullableString(),
  conferenceSteps: yupNullableString(),
});

export type FormFields = yup.InferType<typeof formSchema>;

export const initialValues = {
  conferenceHappened: undefined,
};
