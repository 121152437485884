import { Button, ChakraProps, Flex } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';
import { spaceUnitless } from 'theme';
import { useHealthCheck, useStickyItemsOffset } from 'hooks';

export const SaveAndCancel: FC<{
  onCancel: () => void;
  onSubmit?: () => void;
  disabled: boolean;
  saveText?: string | ReactElement;
  cancelText?: string;
  sx?: ChakraProps['sx'];
}> = ({
  onCancel,
  onSubmit,
  disabled,
  saveText = 'Save',
  cancelText = 'Cancel',
  sx,
}) => {
  const offset = useStickyItemsOffset();
  const { error } = useHealthCheck();

  const buttonGroupStyles = {
    position: 'sticky',
    top: offset + spaceUnitless.two,
    ...sx,
  };

  return (
    <Flex ml="100px" sx={buttonGroupStyles}>
      <Button variant="ghost" onClick={() => onCancel()}>
        {cancelText}
      </Button>
      <Button
        onClick={() => {
          if (onSubmit) {
            onSubmit();
          }
        }}
        type={onSubmit ? 'button' : 'submit'}
        ml={2}
        disabled={Boolean(disabled) || error}
        data-testid="SaveAndCancel-save-button"
      >
        {saveText}
      </Button>
    </Flex>
  );
};
