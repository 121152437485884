import {
  Box,
  ChakraProps,
  Flex,
  HTMLChakraProps,
  Stack,
  StackProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { ReactComponent as CircleCheck } from 'icons/circle-check.svg';
import { ReactComponent as CircleExclamation } from 'icons/circle-exclamation.svg';
import { FC } from 'react';

export const DetailSectionHeader: FC<ChakraProps> = ({
  children,
  ...props
}) => {
  return (
    <Flex
      height="fit-content"
      borderRadius="md"
      bg="teal.100"
      justifyContent="space-between"
      alignItems="center"
      py={3}
      px={6}
      {...props}
    >
      {children}
    </Flex>
  );
};

export const DetailSectionContent: FC<ChakraProps> = ({
  children,
  ...props
}) => (
  <Flex
    direction="column"
    border="1px solid"
    borderColor="gray.300"
    borderRadius="md"
    padding={6}
    marginTop={4}
    {...props}
  >
    {children}
  </Flex>
);

interface DetailSectionFieldValueProps extends TextProps {
  readonly value?: string | null;
  readonly showWhenEmpty?: boolean;
}
export const DetailSectionFieldValue: FC<DetailSectionFieldValueProps> = ({
  value,
  showWhenEmpty,
  ...props
}) => {
  if (!value && !showWhenEmpty) return null;
  return (
    <Flex>
      <Text textStyle="sectionFieldViewValue" {...props}>
        {value || '-'}
      </Text>
    </Flex>
  );
};

interface DetailSectionFieldProps {
  readonly label: string;
  readonly value?: string | null;
  readonly showWhenEmpty?: boolean;
}

export const DetailSectionField: FC<DetailSectionFieldProps> = ({
  label,
  value,
  showWhenEmpty,
  children,
}) => {
  if (!showWhenEmpty && !value && !children) return null;
  return (
    <Flex flexDirection="column">
      <Text textStyle="sectionFieldViewLabel" marginBottom={1}>
        {label}
      </Text>
      {children ? (
        children
      ) : (
        <DetailSectionFieldValue value={value} showWhenEmpty={showWhenEmpty} />
      )}
    </Flex>
  );
};

interface DetailSectionNotesProps {
  readonly label?: string;
  readonly showWhenEmpty?: boolean;
  readonly notes?: string | null;
}

export const DetailSectionNotes: FC<DetailSectionNotesProps> = ({
  notes,
  label = 'Notes',
  showWhenEmpty = false,
}) =>
  showWhenEmpty || notes?.length ? (
    <>
      <DetailSectionDivider orientation="horizontal" />
      <DetailSectionRow>
        <DetailSectionField
          showWhenEmpty={showWhenEmpty}
          label={label}
          value={notes}
        ></DetailSectionField>
      </DetailSectionRow>
    </>
  ) : null;

interface DetailSectionCheckMarkProps {
  readonly text: string;
}

export const DetailSectionCheckMark: FC<DetailSectionCheckMarkProps> = ({
  text,
}) => {
  return (
    <Flex mt={1} alignItems="flex-start">
      <CircleCheck style={{ minWidth: 14, marginTop: 4 }} />
      <Text textStyle="sectionFieldViewValue" ml={2}>
        {text}
      </Text>
    </Flex>
  );
};

export const DetailSectionExclamation: FC<DetailSectionCheckMarkProps> = ({
  text,
}) => {
  return (
    <Flex mt={1} alignItems="center">
      <CircleExclamation style={{ minWidth: 14 }} />
      <Text textStyle="sectionFieldViewValue" ml={2}>
        {text}
      </Text>
    </Flex>
  );
};

interface DetailSectionDividerProps {
  readonly orientation?: 'vertical' | 'horizontal';
}

export const DetailSectionDivider: FC<DetailSectionDividerProps> = ({
  orientation = 'vertical',
}) => {
  const isVertical = orientation === 'vertical';
  const thickness = '2px';
  const margin = 6;

  return (
    <Box px={isVertical ? margin : 0} py={isVertical ? 0 : margin}>
      <Box
        aria-hidden
        width={isVertical ? thickness : '100%'}
        height={isVertical ? '100%' : thickness}
        backgroundColor="gray.300"
      />
    </Box>
  );
};

export const DetailSectionColumn: FC<StackProps> = ({ children, ...props }) => (
  <Stack
    spacing={4}
    display="flex"
    flexGrow={1}
    flexShrink={1}
    flexBasis={0}
    {...props}
  >
    {children}
  </Stack>
);

export const DetailSectionRow: FC<HTMLChakraProps<'div'>> = ({
  children,
  ...props
}) => (
  <Flex flexGrow={1} {...props}>
    {children}
  </Flex>
);
