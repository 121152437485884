import {
  ContactInputControlMap,
  PartialContactInputControlMap,
  defaultContactInputControls,
} from './constants';
import { FC } from 'react';
import { FormSection } from 'components/styled/forms/FormSection';
import { TextField } from 'components/formik';

interface Props {
  readonly fieldOverrides?: PartialContactInputControlMap;
}

const mergeFieldOverrides = (fieldOverrides: PartialContactInputControlMap) => {
  const mergedOverrides: PartialContactInputControlMap = {};

  for (const key in defaultContactInputControls) {
    let castedKey = key as keyof ContactInputControlMap;
    mergedOverrides[castedKey] = {
      ...defaultContactInputControls[castedKey],
      ...fieldOverrides[castedKey],
    };
  }

  return mergedOverrides as ContactInputControlMap;
};

const ContactInputSection: FC<Props> = ({ fieldOverrides }) => {
  const { phoneHome, phoneCell, phoneWork, phoneGeneric, fax, email } =
    mergeFieldOverrides(fieldOverrides ?? {});

  return (
    <FormSection>
      {phoneHome.isVisible && (
        <TextField
          name={phoneHome.name}
          label={phoneHome.label}
          required={phoneHome.required}
        />
      )}
      {phoneCell.isVisible && (
        <TextField
          name={phoneCell.name}
          label={phoneCell.label}
          required={phoneCell.required}
        />
      )}
      {phoneWork.isVisible && (
        <TextField
          name={phoneWork.name}
          label={phoneWork.label}
          required={phoneWork.required}
        />
      )}
      {phoneGeneric.isVisible && (
        <TextField
          name={phoneGeneric.name}
          label={phoneGeneric.label}
          required={phoneGeneric.required}
        />
      )}
      {fax.isVisible && (
        <TextField name={fax.name} label={fax.label} required={fax.required} />
      )}
      {email.isVisible && (
        <TextField
          name={email.name}
          label={email.label}
          required={email.required}
        />
      )}
    </FormSection>
  );
};

export default ContactInputSection;
