import { ChildContact, SessionUser } from '@cssat/acorn-api-shared';
import { Item } from 'components/formik/SearchableDropDownField/SearchableDropDownField';
import { toSentenceCase } from 'lib';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useChildContacts from 'pages/ChildRecord/OverviewTab/useChildContacts';
import useChildRecord from 'pages/ChildRecord/OverviewTab/useChildRecord';
import useOrganizationUsers from 'pages/ChildList/useOrganizationUsers';

interface TeamMember extends Item {
  role: string;
}

interface TeamMembers {
  readonly teamMemberSuggestions?: TeamMember[];
}

const buildContactTeamMember = (contact: ChildContact): TeamMember => ({
  id: contact.id,
  label: contact.name,
  role: toSentenceCase(contact.relationship ?? ''),
});

const buildAgencyTeamMember = (user: SessionUser): TeamMember => ({
  id: user.id,
  label: user.fullNameByGivenName || 'Unknown',
  role: 'Agency member',
});

export const useTeamMemberSuggestions = (): TeamMembers => {
  const { id: childId } = useParams<{ id: string }>();
  const { activeContacts } = useChildContacts(childId);
  const [contactsTeamMembers, setContactsTeamMembers] =
    useState<TeamMember[]>();

  const { data } = useChildRecord(childId);
  const childDetails = data?.childDetails;

  const { data: users } = useOrganizationUsers(childDetails?.assignedAgency);
  const [agencyTeamMembers, setAgencyTeamMembers] = useState<TeamMember[]>();

  useEffect(() => {
    if (!activeContacts) return;
    setContactsTeamMembers(
      activeContacts.map(contact => buildContactTeamMember(contact))
    );
  }, [activeContacts]);

  useEffect(() => {
    if (!users) return;
    setAgencyTeamMembers(users.map(user => buildAgencyTeamMember(user)));
  }, [users]);

  const builtTeamMembers: TeamMember[] | undefined = useMemo(() => {
    return contactsTeamMembers && agencyTeamMembers
      ? [...contactsTeamMembers, ...agencyTeamMembers]
      : undefined;
  }, [agencyTeamMembers, contactsTeamMembers]);

  return {
    teamMemberSuggestions: builtTeamMembers,
  };
};
