import { ChildSearch } from './useChildMatch';
import { FC } from 'react';
import {
  FormControl,
  FormErrorMessage,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FormFields } from '../ChildDetails/validation';
import { RadioGroupField } from 'components/formik';
import { space } from 'theme';
import { useFormikContext } from 'formik';
import ChildMatchCriteria from './ChildMatchCriteria';
import ContinueWithoutMatch from './ContinueWithoutMatch';
import PossibleChildMatchList from './PossibleChildMatchList/PossibleChildMatchList';

const Placeholder: FC = () => (
  <>
    <Heading as="h2" variant="tertiary" mb={space.one}>
      Enter required child details above
    </Heading>
    <Stack spacing={3}>
      <Text as="span" variant="bodyText">
        We'll use the info you enter about the child to search for any possible
        duplicate records.
      </Text>
      <ChildMatchCriteria />
    </Stack>
  </>
);

const Searching: FC = () => (
  <>
    <Heading as="h2" variant="tertiary" mb={space.one}>
      Searching for duplicate records...
    </Heading>
    <Text as="span" variant="bodyText">
      We're searching for possible matches based on the info you’ve entered
      about this child...
    </Text>
  </>
);

const NoMatches: FC<{ childMatch: ChildSearch }> = ({ childMatch }) => {
  return (
    <RadioGroupField name="childId" value={childMatch.state.childMatchValue}>
      <Heading as="h2" variant="tertiary" mb={space.one}>
        No duplicates found
      </Heading>
      <Text as="span" variant="bodyText">
        You're good to go. We didn't find any matching records for this child.
      </Text>
      <ContinueWithoutMatch childMatch={childMatch} />
    </RadioGroupField>
  );
};

interface ChildMatchSectionProps {
  childMatch: ChildSearch;
}

const ChildMatchSection: FC<ChildMatchSectionProps> = ({ childMatch }) => {
  let { errors, touched } = useFormikContext<FormFields>();
  const hasError =
    Boolean(touched.childMatch) && errors && Boolean(errors.childMatch);
  const { results, isSearching } = childMatch.state;

  return (
    <FormControl
      isInvalid={hasError}
      {...(hasError
        ? {
            borderRight: '1px solid',
            borderColor: 'red.500',
            paddingRight: 4,
          }
        : {})}
    >
      {isSearching ? (
        <Searching />
      ) : (
        <>
          {results ? (
            results.length > 0 ? (
              <PossibleChildMatchList
                results={results}
                childMatch={childMatch}
              />
            ) : (
              <NoMatches childMatch={childMatch} />
            )
          ) : (
            <Placeholder />
          )}
        </>
      )}
      <FormErrorMessage data-testid="errorForChildMatch">
        {errors['childMatch']}
      </FormErrorMessage>
    </FormControl>
  );
};

export default ChildMatchSection;
