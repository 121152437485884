import * as yup from 'yup';
import { ChildRecord } from '@cssat/acorn-api-shared';
import {
  dateFormatErrorString,
  sexOfChildOptions,
  yupOptionalString,
  yupRequiredDate,
  yupRequiredString,
} from 'lib';
import { startOfToday, startOfYesterday, subYears } from 'date-fns';

export type FormFields = Omit<
  ChildRecord['childDetails'],
  | 'id'
  | 'sequence'
  | 'createdAt'
  | 'updatedAt'
  | 'assignedAgency'
  | 'assignedUser'
  | 'dateOfBirth'
  | 'hispanicOrLatino'
  | 'race'
> & {
  dateOfBirth: Date;
  otherSexOfChild: string;
  hispanicOrLatino: string;
  race: string[];
};

export const formSchema = yup.object({
  firstName: yupRequiredString('Child first name is required'),

  lastName: yupRequiredString('Child last name is required'),

  dateOfBirth: yupRequiredDate('Child date of birth is required')
    .typeError(dateFormatErrorString)
    .min(
      subYears(startOfToday(), 3),
      'Child date of birth cannot be more than 3 years ago'
    )
    .max(startOfYesterday(), 'Child date of birth must be in past'),
  sex: yupRequiredString('Sex of child is required').oneOf(
    sexOfChildOptions,
    'Sex of child must be female, male, or other'
  ),

  otherSexOfChild: yupOptionalString(),
});
