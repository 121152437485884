import { CSSProperties } from 'react';
import { gridColumns } from 'theme';

export const formMaxWidthStyles = ['100%', '100%', gridColumns(5)];
export const hideOnMobile = ['none', 'none', 'block'];
export const hideOnDesktop = ['block', 'block', 'none'];
export const formStyles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};
