import { Box, Flex } from '@chakra-ui/react';
import { ComponentProps, FC } from 'react';
import { ReactComponent as ExclamationIcon } from 'icons/circle-exclamation.svg';
import { banners, space } from 'theme';

export const ErrorBanner: FC<ComponentProps<any>> = ({
  children,
  ...props
}) => {
  return (
    <>
      <Flex sx={{ ...banners.error, ...props }}>
        <Box as={ExclamationIcon} minWidth={4} minHeight={4} />
        <Box ml={space.half}>{children}</Box>
      </Flex>
    </>
  );
};
