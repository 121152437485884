import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { ReactComponent as DeleteIcon } from 'icons/delete.svg';
import { DispatchMaybeDirtyForm } from 'components/WarnUnsavedChanges/DispatchMaybeDirtyForm';
import { DocumentAssociation, FormFields, formSchema } from './validation';
import { FC, ReactElement, useCallback, useState } from 'react';
import { Formik } from 'formik';
import { ReactComponent as PaperClipIcon } from 'icons/paperclip.svg';
import {
  RadioGroupField,
  RadioOption,
  TextField,
  TextareaField,
} from 'components/formik';
import { SaveAndCancel } from 'components/styled/forms/SaveAndCancel';
import { SpacedFormLayout } from 'components/styled/forms';
import { useMutateUploadDocument } from './DocumentsTable/useDocuments';
import { useParams } from 'react-router-dom';
import { usePopupToast } from 'hooks';
import FileUpload from 'components/formik/FileUpload';
import InlineAlert from 'components/alerts/InlineAlert';
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const DocumentFormDrawer: FC<Props> = ({ isOpen, onClose }) => {
  const { id: childId } = useParams<{ id: string }>();
  const [showSubmitError, setShowSubmitError] = useState<boolean>(false);
  const uploadDocument = useMutateUploadDocument(childId);
  const [saveButtonContent, setSaveButtonContent] = useState<
    string | ReactElement
  >('Save');
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);

  const [successToast] = usePopupToast({
    status: 'success',
    title: 'Document uploaded',
  });

  const onSubmit = useCallback(
    async (values: FormFields) => {
      setSaveButtonDisabled(true);
      setShowSubmitError(false);
      try {
        setSaveButtonContent(<Spinner color="white" />);
        await uploadDocument(values);
        onClose();
        successToast();
      } catch (err) {
        setShowSubmitError(true);
      }
      setSaveButtonContent('Save');
      setSaveButtonDisabled(false);
    },
    [onClose, successToast, uploadDocument]
  );

  return (
    <Drawer size="sm" placement="right" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>New document</DrawerHeader>
        <DrawerBody>
          <Formik
            validationSchema={formSchema}
            onSubmit={onSubmit}
            initialValues={{
              title: '',
              notes: '',
              documentAssociation: DocumentAssociation.NONE,
              file: '',
            }}
          >
            {formik => {
              return (
                <form
                  noValidate
                  onSubmit={e => {
                    e.preventDefault();
                    formik.handleSubmit(e);
                  }}
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <DispatchMaybeDirtyForm
                    isDirty={formik.dirty}
                    humanReadableFormName="Document upload"
                  />
                  {showSubmitError && (
                    <InlineAlert
                      marginBottom={4}
                      status="error"
                      title="There was a problem uploading the document."
                    />
                  )}
                  <SpacedFormLayout>
                    <TextField name="title" label="Document name" required />
                    <RadioGroupField
                      name="documentAssociation"
                      required
                      label="Associated with"
                    >
                      <RadioOption
                        value={DocumentAssociation.NONE}
                        label="No association"
                      />
                      <RadioOption
                        value={DocumentAssociation.CONSENT}
                        label="Consent to evaluate"
                      />
                      <RadioOption
                        value={DocumentAssociation.EVALTOOLS}
                        label="Evaluation tools"
                      />
                    </RadioGroupField>
                    <TextareaField name="notes" label="Notes (optional)" />
                    <Box>
                      <FileUpload
                        name="file"
                        required
                        content={
                          <Flex opacity={formik.values.file ? 0.5 : 1}>
                            <Box as={PaperClipIcon} marginRight={1} />
                            <Text fontSize="sm">
                              <Text
                                as="span"
                                color={
                                  formik.values.file ? 'gray.600' : 'teal.600'
                                }
                                textDecoration="underline"
                              >
                                Attach Document
                              </Text>
                              <Text
                                as="span"
                                color={
                                  formik.values.file ? 'gray.700' : 'teal.700'
                                }
                                ml={1}
                              >
                                *
                              </Text>
                            </Text>
                          </Flex>
                        }
                      />
                      {formik.values.file && (
                        <UploadedFileCard
                          fileName={(formik.values.file as any).name}
                          onDeleteClick={() => formik.setFieldValue('file', '')}
                        />
                      )}
                    </Box>
                  </SpacedFormLayout>
                  <Flex alignSelf="flex-end">
                    <SaveAndCancel
                      saveText={saveButtonContent}
                      onCancel={onClose}
                      disabled={saveButtonDisabled}
                    />
                  </Flex>
                </form>
              );
            }}
          </Formik>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const UploadedFileCard: FC<{
  readonly fileName: string;
  onDeleteClick: () => void;
}> = ({ fileName, onDeleteClick }) => (
  <Flex alignItems="center" justifyContent="space-between">
    <Box
      padding={3}
      borderWidth="1px"
      borderStyle="solid"
      borderColor="gray.300"
      borderRadius="md"
      width="fit-content"
    >
      <Text
        wordBreak="break-word"
        textDecoration="underline"
        fontWeight="medium"
      >
        {fileName}
      </Text>
    </Box>
    <Box
      as={DeleteIcon}
      marginLeft={4}
      justifySelf="flex-end"
      onClick={onDeleteClick}
      cursor="pointer"
      aria-label="Remove document"
    />
  </Flex>
);

export default DocumentFormDrawer;
