import { ChildContact } from '@cssat/acorn-api-shared';
import { authenticatedFetch, authenticatedPost, paths } from 'lib';
import { useChildArchiveContactsContext } from 'contexts';
import { useMemo } from 'react';
import partition from 'lodash.partition';
import useSWR from 'swr';

const useChildContacts = (childId: string) => {
  const swr = useSWR<ChildContact[]>(
    paths.child.getContacts({ childId }),
    authenticatedFetch
  );
  const [activeContacts, inactiveContacts] = useMemo(
    () => partition(swr.data, ['isArchived', false]),
    [swr.data]
  );

  return { ...swr, activeContacts, inactiveContacts };
};

export const useMutateAddContact = (childId: string) => {
  const { mutate } = useChildContacts(childId);

  return async (newContact: ChildContact) => {
    await authenticatedPost(paths.child.addContact({ childId }), {
      body: newContact,
    });
    await mutate();
  };
};

export const useMutateUpdateContacts = (childId: string) => {
  const { mutate } = useChildContacts(childId);

  return async (updatedContact: ChildContact) => {
    await authenticatedPost(
      paths.child.updateContact({ contactId: updatedContact.id }),
      {
        body: updatedContact,
      }
    );
    await mutate();
  };
};

export const useMutateToggleArchivedContacts = (childId: string) => {
  const { mutate } = useChildContacts(childId);
  const [showArchived, setShowArchived] = useChildArchiveContactsContext();

  return async () => {
    setShowArchived(!showArchived);
    await mutate();
  };
};

export default useChildContacts;
