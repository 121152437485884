import { AnnounceChange } from 'components/styled/forms/AnnounceChange';
import { Box } from '@chakra-ui/react';
import {
  DateField,
  RadioGroupField,
  RadioOption,
  RadioPill,
  TextField,
} from 'components/formik';
import {
  ExtenuatingFamilyCircumstance,
  MissedDeliveryReason,
} from './validation';
import { FC } from 'react';
import { SecondaryBox } from 'components/styled/cards';
import { SpacedFormLayout } from 'components/styled/forms';
import { space } from 'theme';
import { startOfToday, subYears } from 'date-fns';

export const SectionOption03NotDelivered: FC = () => {
  return (
    <SpacedFormLayout>
      <DateField
        name="scheduledAppointmentDate"
        label="Scheduled service date"
        required
        disabledDays={{
          after: startOfToday(),
          before: subYears(startOfToday(), 3),
        }}
      />

      <RadioGroupField
        name="reasonForMissedDelivery"
        required
        textStyle="bigLabel"
        label="Why not?"
      >
        <RadioOption
          value={MissedDeliveryReason.ExtenuatingFamilyCircumstances}
          conditionalChildren={
            <SecondaryBox mt={space.one}>
              <RadioGroupField
                name="extenuatingFamilyCircumstance"
                label="What was the family circumstance?"
                direction="horizontal"
                required
              >
                <RadioPill value={ExtenuatingFamilyCircumstance.LossOfContact}>
                  Loss of contact
                </RadioPill>
                <RadioPill value={ExtenuatingFamilyCircumstance.FamilyNoShow}>
                  Family no-show
                </RadioPill>
                <RadioPill
                  value={ExtenuatingFamilyCircumstance.FamilyCancelled}
                >
                  Family cancelled
                </RadioPill>
              </RadioGroupField>
            </SecondaryBox>
          }
        >
          Exceptional family circumstances
        </RadioOption>

        <RadioOption value={MissedDeliveryReason.ProviderSick}>
          Provider sick
        </RadioOption>
        <RadioOption value={MissedDeliveryReason.ProviderNotAvailable}>
          Provider not available
        </RadioOption>
        <RadioOption value={MissedDeliveryReason.AgencyDelay}>
          Agency delay
        </RadioOption>

        <RadioOption
          value={MissedDeliveryReason.Other}
          conditionalChildren={
            <SpacedFormLayout>
              <Box ml={space.two}>
                <AnnounceChange>
                  <TextField
                    autoFocus={true}
                    name={'reasonForMissedDeliveryNote'}
                    aria-label="Specify reason for missed delivery"
                    required
                  />
                </AnnounceChange>
              </Box>
            </SpacedFormLayout>
          }
        >
          Other
        </RadioOption>
      </RadioGroupField>
    </SpacedFormLayout>
  );
};
