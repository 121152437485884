import { breakpoints } from 'theme';
import { useMediaQuery } from '@chakra-ui/react';

export const useScreenWidth = () => {
  const [isMobileInclusive, isTabletInclusive, isDesktopInclusive] =
    useMediaQuery([
      `(min-width: ${breakpoints.base})`,
      `(min-width: ${breakpoints.sm})`,
      `(min-width: ${breakpoints.md})`,
    ]);

  const isMobileScreenWidth = isMobileInclusive && !isTabletInclusive;
  const isTabletScreenWidth = isTabletInclusive && !isDesktopInclusive;
  const isDesktopScreenWidth = isDesktopInclusive;

  const isNarrowScreenWidth = isMobileScreenWidth || isTabletScreenWidth;
  const isWideScreenWidth = isDesktopScreenWidth;

  return {
    isNarrowScreenWidth,
    isWideScreenWidth,
    isMobileScreenWidth,
    isTabletScreenWidth,
    isDesktopScreenWidth,
  };
};
