import { JumpLinkRefIds } from 'molecules';
import { Text } from '@chakra-ui/react';
import { colors } from 'theme';
import { useChildArchiveContactsContext, useOpenFormContext } from 'contexts';
import { useLatestReferral } from 'hooks';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import BaseButton from 'components/BaseButton';
import useChildContacts, {
  useMutateToggleArchivedContacts,
} from '../useChildContacts';

const textButtonStyles = {
  color: colors.teal[700],
  textDecoration: 'underline',
  textDecorationColor: colors.teal[700],
  ':hover': {
    color: colors.teal[600],
    textDecorationColor: colors.teal[600],
  },
};

const useSecondaryButton = () => {
  const { id: childId } = useParams<{ id: string }>();
  const [openFormState] = useOpenFormContext();
  const [showArchived] = useChildArchiveContactsContext();
  const { inactiveContacts } = useChildContacts(childId);
  const referral = useLatestReferral(childId);
  const isActive = referral?.isActive;

  const toggleArchivedContacts = useMutateToggleArchivedContacts(childId);

  const isContactsFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.ChildContacts;

  const areThereInactiveContacts = !!inactiveContacts.length;

  return useMemo(
    () =>
      !isContactsFormOpen && areThereInactiveContacts ? (
        <BaseButton
          onClick={toggleArchivedContacts}
          mr={4}
          sx={textButtonStyles}
        >
          <Text sx={textButtonStyles} textStyle="bodyText">
            {isActive ? (showArchived ? 'Hide' : 'Show') : 'View'} inactive
            contacts
          </Text>
        </BaseButton>
      ) : null,
    [
      areThereInactiveContacts,
      isActive,
      isContactsFormOpen,
      showArchived,
      toggleArchivedContacts,
    ]
  );
};

export default useSecondaryButton;
