import { ReactComponent as ArchiveIcon } from 'icons/archive.svg';
import { EligibilityDecision } from 'typesToMigrate/EligibilityDecision';
import { FormIconButton } from 'molecules';
import { Placement } from '@chakra-ui/react';
import { useLatestReferral } from 'hooks';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

interface Props {
  readonly eligibilityDecision: EligibilityDecision;
  onArchiveClick(): void;
}

const useCardHeaderProps = ({ eligibilityDecision, onArchiveClick }: Props) => {
  const { id: childId } = useParams<{ id: string }>();
  const latestReferral = useLatestReferral(childId);
  return useMemo(() => {
    return {
      headerTags: eligibilityDecision.isArchived
        ? [{ text: 'Archived', key: 'archived' }]
        : [],
      isArchived: eligibilityDecision.isArchived,
      buttons: !eligibilityDecision.isArchived ? (
        <FormIconButton
          Icon={ArchiveIcon}
          aria-label="Archive eligibility decision"
          onClick={onArchiveClick}
          tooltipProps={{
            placement: 'auto' as Placement,
            openDelay: 250,
            label: 'Archive eligibility decision',
            'aria-label': 'Archive eligibility decision',
          }}
          disabled={!latestReferral?.isActive}
        />
      ) : null,
    };
  }, [
    eligibilityDecision.isArchived,
    latestReferral?.isActive,
    onArchiveClick,
  ]);
};

export default useCardHeaderProps;
