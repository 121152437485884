import { DataCard, JumpLinkRefIds } from 'molecules';
import { useOpenFormContext } from 'contexts';
import { useParams } from 'react-router-dom';
import CardSkeleton from 'pages/ChildRecord/CardSkeleton/CardSkeleton';
import CompleteTransitionForm from '../CompleteTransitionForm/CompleteTransitionForm';
import CompleteTransitionView from './CompleteTransitionView';
import InlineAlert from 'components/alerts/InlineAlert';
import useTransition from '../useTransition';

const CompleteTransitionCard = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data, error } = useTransition(childId);
  const [openFormState] = useOpenFormContext();

  const isFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.CompleteTransition;

  return (
    <>
      {error && (
        <InlineAlert
          marginTop={4}
          status="warning"
          title="Hang on! We can't load this section right now."
          description="We'll keep trying."
        />
      )}
      {data ? (
        <DataCard borderColor="teal.700">
          {isFormOpen ? <CompleteTransitionForm /> : <CompleteTransitionView />}
        </DataCard>
      ) : (
        <CardSkeleton />
      )}
    </>
  );
};

export default CompleteTransitionCard;
