import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

export type OnChange = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => void;
export type OnChangeParser<Result> = (value: string) => Result;

const debounceDisabled = process.env.REACT_APP_DISABLE_FORM_DEBOUNCE === '1';

export const useDebounceOnChange = <Value extends unknown>(
  value: Value,
  onChange: OnChange,
  defaultValue: Value,
  onChangeParser: OnChangeParser<Value>
): [Value, OnChange] => {
  const [innerValue, setInnerValue] = useState<Value>(defaultValue);

  useEffect(() => {
    if (value) {
      setInnerValue(value);
    } else {
      setInnerValue(defaultValue);
    }
  }, [value, defaultValue]);

  // The exhaustive deps rule can't analyze this usage, but I couldn't figure out a
  // reasonable way to make this an inline function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleOnChange = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(event);
    }, 100),
    [onChange]
  );

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      event.persist();

      const newValue = event.currentTarget.value;
      setInnerValue(onChangeParser(newValue));
      if (debounceDisabled) {
        onChange(event);
      } else {
        debouncedHandleOnChange(event);
      }
    },
    [setInnerValue, onChangeParser, debouncedHandleOnChange, onChange]
  );

  return [innerValue, handleOnChange];
};
