import { COS } from '@cssat/acorn-api-shared';
import { ChakraProps } from '@chakra-ui/system';
import { FC, useMemo } from 'react';
import { Flex } from '@chakra-ui/layout';
import { JumpLinkRefIds, JumpLinks } from 'molecules';
import { formattedCosDate } from '../TabContent/helpers';
import { useParams } from 'react-router-dom';

interface JumpLinkProps extends ChakraProps {
  coss: COS[];
}

const getJumplinkTitle = (cos: COS) => {
  if (!cos.dateCompleted) {
    return 'COS In Progress';
  }
  if (cos.isEntryCos) {
    return 'Entry COS';
  }
  if (cos.isLatestCompletedCos) {
    return 'Current COS';
  }
  return `COS - ${formattedCosDate(cos.dateCompleted)}`;
};

const JumpLinksSection: FC<JumpLinkProps> = ({ coss, ...props }) => {
  const { id } = useParams<{ id: string }>();

  const linkConfigList = useMemo(() => {
    const [latestCos, ...previousCoss] = coss;

    return [
      {
        to: `/children/${id}#${latestCos.id}`,
        key: latestCos.id,
        content: getJumplinkTitle(latestCos),
        subLinks: [
          {
            key: JumpLinkRefIds.CosInfo,
            content: 'Information',
            to: `/children/${id}#${latestCos.id}${JumpLinkRefIds.CosInfo}`,
          },
          {
            key: JumpLinkRefIds.PositiveSkills,
            content: 'Positive social-emotional skills',
            to: `/children/${id}#${latestCos.id}${JumpLinkRefIds.PositiveSkills}`,
          },
          {
            key: JumpLinkRefIds.AcquiringSkills,
            content: 'Acquiring and using knowledge and skills',
            to: `/children/${id}#${latestCos.id}${JumpLinkRefIds.AcquiringSkills}`,
          },
          {
            key: JumpLinkRefIds.TakingActions,
            content: 'Taking appropriate actions to meet needs',
            to: `/children/${id}#${latestCos.id}${JumpLinkRefIds.TakingActions}`,
          },
        ],
      },
      ...previousCoss.map(cos => ({
        to: `/children/${id}#${cos.id}`,
        key: cos.id,
        content: getJumplinkTitle(cos),
      })),
    ];
  }, [coss, id]);

  return (
    <Flex flexDir="column" pr={4} {...props}>
      <JumpLinks id={id} linkConfigList={linkConfigList} />
    </Flex>
  );
};

export default JumpLinksSection;
