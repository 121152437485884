import { DetailSectionHeader } from 'components/styled/forms/DetailSection';
import { FC } from 'react';
import { Flex, Heading } from '@chakra-ui/react';

export interface Props {
  readonly headerText: string;
  readonly isFormOpen?: boolean;
  readonly bgColor?: string;
  readonly textColor?: string;
  readonly iconButton?: JSX.Element | null;
  readonly secondaryButton?: JSX.Element | null;
}

const SectionHeader: FC<Props> = ({
  headerText,
  isFormOpen = false,
  iconButton,
  secondaryButton,
  bgColor,
  textColor,
}) => {
  return (
    <DetailSectionHeader
      bg={bgColor ? bgColor : isFormOpen ? 'teal.500' : 'teal.100'}
    >
      <Heading
        as="h3"
        variant="detailSection"
        textColor={textColor ? textColor : isFormOpen ? 'white' : 'teal.700'}
      >
        {headerText}
      </Heading>
      <Flex>
        {secondaryButton}
        {iconButton}
      </Flex>
    </DetailSectionHeader>
  );
};

export default SectionHeader;
