import { ReactComponent as AddIcon } from 'icons/new.svg';
import {
  DataCard,
  FormIconButton,
  JumpLinkRefIds,
  SectionHeader,
  SectionWrapper,
} from 'molecules';
import { Tabs, useOpenFormContext, useTeamMemberIdContext } from 'contexts';
import { useLatestReferral } from 'hooks';
import { useParams } from 'react-router-dom';
import CardSkeleton from 'pages/ChildRecord/CardSkeleton/CardSkeleton';
import InlineAlert from 'components/alerts/InlineAlert';
import TeamMemberForm from './TeamMemberForm/TeamMemberForm';
import TeamMembersCardList from './TeamMembersCardList';
import useTeamMembers from './useTeamMembers';

const ADD_FORM_ID = 'ADD_FORM_ID';

const TeamMembersSection = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { error, data } = useTeamMembers(childId);
  const [openFormState, setOpenFormState] = useOpenFormContext();
  const [teamMemberFormId, setTeamMemberFormId] = useTeamMemberIdContext();
  const referral = useLatestReferral(childId);
  const isActive = referral?.isActive;

  const isFormOpen =
    openFormState?.primaryJumpLink === JumpLinkRefIds.TeamMembers;

  return (
    <SectionWrapper id={JumpLinkRefIds.TeamMembers} mb={12}>
      <SectionHeader
        headerText="Team members"
        isFormOpen={isFormOpen}
        iconButton={
          <FormIconButton
            onClick={() => {
              if (openFormState) {
                return;
              }

              setOpenFormState({
                tab: Tabs.Evaluations,
                primaryJumpLink: JumpLinkRefIds.TeamMembers,
              });
              setTeamMemberFormId(ADD_FORM_ID);
            }}
            refId={JumpLinkRefIds.TeamMembers}
            aria-label={'Add'}
            Icon={AddIcon}
            tooltipProps={{ label: 'Add team member' }}
            disabled={!isActive}
            data-testid="add-team-member-icon"
          />
        }
      />
      {error && (
        <InlineAlert
          marginTop={4}
          status="warning"
          title="Hang on! We can't load this section right now."
          description="We'll keep trying."
        />
      )}
      {teamMemberFormId === ADD_FORM_ID && isFormOpen && (
        <DataCard>
          <TeamMemberForm />
        </DataCard>
      )}
      {data ? (
        <TeamMembersCardList isFormOpen={isFormOpen} />
      ) : (
        <CardSkeleton />
      )}
    </SectionWrapper>
  );
};

export default TeamMembersSection;
