import { Box, ChakraProps, Flex, Heading, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/ButtonGroup';
import { CenteredStack, JustifiedRow } from 'components/styled/layout';
import { FC } from 'react';

interface PageHeaderProps extends ChakraProps {
  title: string;
  subTitle?: string;
  badge?: React.ReactNode;
}

const PageHeader: FC<PageHeaderProps> = ({
  title,
  subTitle,
  children,
  badge,
}) => (
  <Flex as={CenteredStack} mt={4} mb={4}>
    <JustifiedRow flexWrap={['wrap', 'wrap', 'nowrap']}>
      <Box sx={{ flexBasis: '100%' }} mb={[8, 8, 0]}>
        <Heading as="h2" variant="secondary">
          <Flex alignItems="flex-end">
            <Box>{title}</Box>
            {badge && (
              <Box paddingLeft="8px" marginBottom="9px">
                {badge}
              </Box>
            )}
          </Flex>
        </Heading>
        {subTitle && (
          <Text mt={4} variant="bodyText">
            {subTitle}
          </Text>
        )}
      </Box>

      {children && <ButtonGroup>{children}</ButtonGroup>}
    </JustifiedRow>
  </Flex>
);

export default PageHeader;
