import { kebabToCamelCase } from 'lib';

export const dateFormatErrorString = 'Date must be formatted as mm/dd/yyyy';
export const UnspecifiedPreferredContact = 'unknown';
export const referringPartyPreferredContactMethodOptions = ['phone', 'email'];
export const sexOfChildOptions = ['female', 'male', 'other'] as const;
export const preferredContactMethods: Array<string> = [
  'mailing-address',
  'phone-home',
  'phone-cell',
  'phone-work',
  'email',
  UnspecifiedPreferredContact,
];

interface MailingAndPhysicalAddresses {
  readonly physicalAddress1?: string | null;
  readonly physicalAddress2?: string | null;
  readonly physicalCity?: string | null;
  readonly physicalState?: string | null;
  readonly physicalZip?: string | null;
  readonly mailingAddress1?: string | null;
  readonly mailingAddress2?: string | null;
  readonly mailingCity?: string | null;
  readonly mailingState?: string | null;
  readonly mailingZip?: string | null;
}
export interface PreferredContactFormFields
  extends MailingAndPhysicalAddresses {
  readonly isAddressSame: boolean;
  readonly homePhone?: string | null;
  readonly cellPhone?: string | null;
  readonly workPhone?: string | null;
  readonly phone?: string | null;
  readonly email?: string | null;
}

export const validateMailingAndPhysicalAddress = (
  props?: MailingAndPhysicalAddresses
) => {
  if (!props) {
    return {
      isAddressTheSame: false,
      doesMailingAddressExist: false,
      doesPhysicalAddressExist: false,
    };
  }

  const {
    physicalAddress1,
    physicalAddress2,
    physicalCity,
    physicalState,
    physicalZip,
    mailingAddress1,
    mailingAddress2,
    mailingCity,
    mailingState,
    mailingZip,
  } = props;

  return {
    isAddressTheSame: Boolean(
      physicalAddress1 === mailingAddress1 &&
        physicalAddress2 === mailingAddress2 &&
        physicalCity === mailingCity &&
        physicalState === mailingState &&
        physicalZip === mailingZip
    ),
    doesPhysicalAddressExist: Boolean(
      physicalAddress1 ||
        physicalAddress2 ||
        physicalCity ||
        physicalState ||
        physicalZip
    ),
    doesMailingAddressExist: Boolean(
      mailingAddress1 ||
        mailingAddress2 ||
        mailingCity ||
        mailingState ||
        mailingZip
    ),
  };
};

export const isPreferredMethodValid = (
  formFields: PreferredContactFormFields,
  method?: string | null
): boolean => {
  const formKey = kebabToCamelCase(method as string);

  if (formKey === 'mailingAddress') {
    const { doesMailingAddressExist, doesPhysicalAddressExist } =
      validateMailingAndPhysicalAddress(formFields);

    return formFields.isAddressSame
      ? doesPhysicalAddressExist
      : doesMailingAddressExist;
  }

  if (formKey === UnspecifiedPreferredContact) {
    return true;
  }

  const val = formFields[formKey as keyof PreferredContactFormFields];
  return val !== null && val !== '' && val !== undefined;
};
