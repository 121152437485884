import { FamilyEvaluationConcerns } from '@cssat/acorn-api-shared';
import { authenticatedFetch, authenticatedPost, paths } from 'lib';
import useSWR from 'swr';

const useConcerns = (childId: string) => {
  return useSWR<FamilyEvaluationConcerns>(
    paths.child.updateFamilyEvalConcerns({ childId }),
    authenticatedFetch
  );
};

export const useMutateConcerns = (childId: string) => {
  const { mutate } = useConcerns(childId);

  return async (concerns: FamilyEvaluationConcerns) => {
    await authenticatedPost(paths.child.updateFamilyEvalConcerns({ childId }), {
      body: concerns,
    });
    await mutate();
  };
};

export default useConcerns;
