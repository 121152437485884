import { FC } from 'react';
import { Text } from '@chakra-ui/react';

const formatAddressLine3 = (
  city?: string | null,
  state?: string | null,
  zip?: string | null
) => {
  const cityAndState = [city, state].filter(v => v);
  return `${cityAndState.join(', ')} ${zip ?? ''}`;
};

interface AddressProps {
  readonly label?: string | null;
  readonly line1?: string | null;
  readonly line2?: string | null;
  readonly city?: string | null;
  readonly state?: string | null;
  readonly zip?: string | null;
}

export const Address: FC<AddressProps> = ({
  line1,
  line2,
  city,
  state,
  zip,
}) => {
  if (!line1 && !line2 && !city && !zip) return null;

  return (
    <Text textStyle="sectionFieldViewValue">
      {line1 && (
        <>
          {line1}
          <br />
        </>
      )}
      {line2 && (
        <>
          {line2}
          <br />
        </>
      )}
      {formatAddressLine3(city, state, zip)}
    </Text>
  );
};
