import { ReactComponent as DeleteIcon } from 'icons/delete.svg';
import { ReactComponent as EditIcon } from 'icons/pencil.svg';
import { FormIconButton, JumpLinkRefIds } from 'molecules';
import { Participant } from '@cssat/acorn-api-shared';
import { Tabs, useOpenFormContext } from 'contexts';
import { useCallback, useMemo } from 'react';

interface CardHeaderPropsArgs {
  readonly teamMember: Participant;
  setTeamMemberFormId(teamMemberId: string | undefined): void;
  openRemoveTeamMemberWarning(): void;
}

const useCardHeaderProps = ({
  teamMember,
  setTeamMemberFormId,
  openRemoveTeamMemberWarning,
}: CardHeaderPropsArgs) => {
  const [openFormState, setOpenFormState] = useOpenFormContext();

  const onClick = useCallback(() => {
    if (openFormState) {
      return;
    }

    setOpenFormState({
      tab: Tabs.Evaluations,
      primaryJumpLink: JumpLinkRefIds.TeamMembers,
    });

    setTeamMemberFormId(teamMember.id);
  }, [openFormState, setOpenFormState, setTeamMemberFormId, teamMember.id]);

  return useMemo(() => {
    return {
      buttons: (
        <>
          <FormIconButton
            mr={2}
            Icon={DeleteIcon}
            onClick={openRemoveTeamMemberWarning}
            aria-label="Remove Team Member"
            tooltipProps={{ label: 'Remove' }}
          />
          <FormIconButton
            onClick={onClick}
            aria-label={'Edit Team Member'}
            Icon={EditIcon}
            refId={teamMember.id}
            tooltipProps={{ label: 'Edit' }}
          />
        </>
      ),
    };
  }, [teamMember, onClick, openRemoveTeamMemberWarning]);
};

export default useCardHeaderProps;
