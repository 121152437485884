import {
  addDays,
  differenceInDays,
  parseISO,
  startOfDay,
  startOfToday,
} from 'date-fns';

export const calculateDueDateDaysFromToday = (dueDate: Date) => {
  const dueDateStartOfDay = startOfDay(dueDate);
  return differenceInDays(dueDateStartOfDay, startOfToday());
};

export const relativeDueDateCopy = (numberOfDays: number) => {
  if (numberOfDays === 0) return `today`;
  if (numberOfDays < 0) return `${Math.abs(numberOfDays)} days ago`;
  return `in ${numberOfDays} days`;
};

export const calculateIFSPDueDateFromReferralDate = (
  referralDateString: string
) => {
  const date = parseISO(referralDateString);
  const referralDateStartOfDay = startOfDay(date);
  const IFSPDueDate = addDays(referralDateStartOfDay, 45);
  return IFSPDueDate;
};
