import {
  Box,
  Flex,
  RadioProps,
  Text,
  WrapItem,
  useRadio,
  useRadioGroupContext,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { forms } from 'theme';
import { useField } from 'formik';
import camelcase from 'lodash.camelcase';

import { ReactComponent as CircleCheck } from 'icons/circle-check.svg';

export interface RadioPillProps extends RadioProps {
  name?: string;
  label?: string;
  isDisabled?: boolean;
  conditionalChildren?: ReactNode;
}

export const RadioPill: FC<RadioPillProps> = ({
  value,
  label,
  isDisabled,
  children,
}) => {
  const group = useRadioGroupContext();
  const [field] = useField(group.name);
  const id = camelcase(`${group.name} is ${value}`);

  const isSelected = () => field.value === value;

  const labelVariant = isDisabled
    ? forms.radioPill.disabledLabel
    : isSelected()
    ? forms.radioPill.selectedLabel
    : forms.radioPill.unselectedLabel;

  const useRadioProps = {
    id,
    name: group.name,
    isChecked: isSelected(),
    onChange: group.onChange,
    value,
    isDisabled,
  };
  const { getInputProps, getCheckboxProps } = useRadio(useRadioProps);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <WrapItem>
      <Box as="label" sx={{ ...labelVariant }} data-testid={`label-${id}`}>
        <Flex {...checkbox} data-testid={id} alignItems="center">
          <input {...input} />
          {isSelected() && <CircleCheck />}
          {label && <Text>{label}</Text>}
          {children}
        </Flex>
      </Box>
    </WrapItem>
  );
};
