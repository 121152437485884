import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { Flex, Text } from '@chakra-ui/react';
import { Transition } from '@cssat/acorn-api-shared';
import { TruncatableTextBlock } from 'molecules';
import { displayUndefinedOrBoolean } from '../helpers';
import { useParams } from 'react-router-dom';
import useTransition from '../useTransition';

const getCauseForDelayValue = ({
  conferenceCauseForDelayReason,
  conferenceCauseForDelay,
}: Transition) => {
  return [conferenceCauseForDelay, conferenceCauseForDelayReason]
    .filter(x => x)
    .join(' — ');
};

const TransitionConferenceHeldView = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: transition } = useTransition(childId);

  const causeForDelayValue = transition && getCauseForDelayValue(transition);

  return (
    <Flex direction="column" flexGrow={1}>
      <DetailSectionRow>
        <DetailSectionColumn flexBasis={'33%'}>
          <DetailSectionField
            label="conference status"
            value={displayUndefinedOrBoolean(
              transition?.conferenceHappened,
              'Held',
              'Declined'
            )}
            showWhenEmpty
          />
          <DetailSectionField
            label="Planned conference Date"
            value={transition?.conferencePlannedDate}
            showWhenEmpty
          />
          {transition?.conferenceActualDate && (
            <DetailSectionField
              label="Actual conference Date"
              value={transition?.conferenceActualDate}
              showWhenEmpty
            />
          )}
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn flexBasis={'67%'}>
          <DetailSectionField
            label="Transition conference team members"
            value={undefined}
            showWhenEmpty
          />
        </DetailSectionColumn>
      </DetailSectionRow>

      {causeForDelayValue && (
        <>
          <DetailSectionDivider orientation="horizontal" />
          <DetailSectionColumn>
            <Text textStyle="sectionFieldViewLabel">Cause for Delay</Text>
            <TruncatableTextBlock text={causeForDelayValue} />
          </DetailSectionColumn>
        </>
      )}

      <DetailSectionDivider orientation="horizontal" />

      <DetailSectionColumn>
        <Text textStyle="sectionFieldViewLabel">Conference Steps</Text>
        <TruncatableTextBlock text={transition?.conferenceSteps} />
      </DetailSectionColumn>
    </Flex>
  );
};

export default TransitionConferenceHeldView;
