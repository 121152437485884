import * as yup from 'yup';
import { ChildOutcome } from '@cssat/acorn-api-shared';
import {
  dateFormatData,
  yupNullableLongString,
  yupOptionalDate,
  yupRequiredString,
} from 'lib';
import { parse } from 'date-fns';

export const formSchema = yup.object({
  title: yupRequiredString('Outcome title is required'),
  startDate: yupOptionalDate(),
  targetDate: yupOptionalDate(),
  outcomeQuestions: yupNullableLongString(),
});

export type FormFields = yup.InferType<typeof formSchema>;

export const coerceInitialValues = (outcome?: ChildOutcome) => {
  const initialValues = {
    ...outcome,
    startDate: outcome?.startDate
      ? parse(outcome.startDate, dateFormatData, new Date())
      : undefined,
    targetDate: outcome?.targetDate
      ? parse(outcome.targetDate, dateFormatData, new Date())
      : undefined,
  };

  return initialValues;
};
