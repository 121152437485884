import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import {
  Checkbox,
  DateField,
  DropDownField,
  RadioGroupField,
  RadioOption,
  TextareaField,
} from 'components/formik';
import { ContactInfoForm } from 'molecules';
import { EndOfFormSpacing } from 'components/styled/forms/EndOfFormSpacing';
import { FC, useCallback } from 'react';
import { Form, useFormikContext } from 'formik';
import { FormFields } from './validation';
import { FormSection } from 'components/styled/forms/FormSection';
import { HorizontalDivider } from 'components/styled/presentation';
import { Organization, useUserContext } from 'contexts';
import { PartialFieldControlMap } from 'molecules/ContactInfoForm/constants';
import { endOfToday, isAfter, isBefore } from 'date-fns';
import { formStyles } from '../../styles';
import { referralReasons } from 'lib/referralReasonData';
import { useChildDetailsContext } from 'contexts/ChildDetailsContext';
import ChildDetailsSummary from './ChildDetailsSummary';
import ReferringPartyRole from './ReferringPartyRole/ReferringPartyRole';
import ScrollToTop from 'components/ScrollToTop';

const ReferralForm: FC = () => {
  const formik = useFormikContext<FormFields>();
  const { user } = useUserContext();
  const [childDetails] = useChildDetailsContext();

  const assignedAgencyOptions = user
    ? user.organizations.map((o: Organization) => ({
        value: o.id,
        label: o.name,
      }))
    : [];

  const dateOfInitialReferralDisabledDays = useCallback(
    (day: Date): boolean => {
      if (!childDetails?.childDateOfBirth) return false;

      if (isBefore(day, childDetails?.childDateOfBirth)) {
        return true;
      }
      if (isAfter(day, endOfToday())) {
        return true;
      }
      return false;
    },
    [childDetails?.childDateOfBirth]
  );

  const familyContactDisabledDays = useCallback(
    (day: Date): boolean => {
      if (dateOfInitialReferralDisabledDays(day)) return true;
      if (formik.values.dateOfInitialReferral) {
        return isBefore(day, formik.values.dateOfInitialReferral);
      }
      return false;
    },
    [dateOfInitialReferralDisabledDays, formik.values.dateOfInitialReferral]
  );

  const contactInfoOverrides: PartialFieldControlMap = {
    relationship: { isVisible: false },
    isPrimaryContact: { isVisible: false },
    firstName: { isVisible: false },
    middleName: { isVisible: false },
    lastName: { isVisible: false },
    phoneHome: { isVisible: false },
    phoneWork: { isVisible: false },
    phoneCell: { isVisible: false },
    fax: { isVisible: false },

    name: { name: 'referringParty.name', label: 'Name', required: true },
    physicalAddress1: { name: 'referringParty.physicalAddress1' },
    physicalAddress2: { name: 'referringParty.physicalAddress2' },
    physicalCity: { name: 'referringParty.physicalCity' },
    physicalState: { name: 'referringParty.physicalState' },
    physicalZip: { name: 'referringParty.physicalZip' },
    mailingAddress1: { name: 'referringParty.mailingAddress1' },
    mailingAddress2: { name: 'referringParty.mailingAddress2' },
    mailingCity: { name: 'referringParty.mailingCity' },
    mailingState: { name: 'referringParty.mailingState' },
    mailingZip: { name: 'referringParty.mailingZip' },
    isAddressSame: { name: 'referringParty.isAddressSame' },
    preferredContactMethod: { name: 'referringParty.preferredContactMethod' },
    phoneGeneric: { name: 'referringParty.phoneGeneric', label: 'Phone' },
    email: { name: 'referringParty.email' },
  };

  return (
    <Form style={formStyles}>
      <ScrollToTop />
      <ChildDetailsSummary />
      <FormSection>
        <DateField
          name="dateOfInitialReferral"
          label="Date referral was received"
          variant="headingLabel"
          required
          disabledDays={dateOfInitialReferralDisabledDays}
        />
      </FormSection>
      <HorizontalDivider mb={8} borderColor="gray.100" />
      <Box data-testid="referring-party-section" mb={8}>
        <Heading as="h2" variant="tertiary" mb={1}>
          Referral source
        </Heading>
        <Text textStyle="bodyText">
          Please enter the contact information for the person who referred the
          child to Part C Services. If the referral source is a contact for the
          child or the child’s primary care provider, select the appropriate box
          in order to create contact cards in the chid record using the referral
          source information.
        </Text>
        <Box>
          <Stack spacing={4} mt={4}>
            <ReferringPartyRole />
            <Stack>
              <Checkbox
                value="true"
                name="referringPartyIsChildContact"
                label="Create a contact from referral source"
              />
              <Checkbox
                value="true"
                name="referringPartyIsPrimaryCareProvider"
                label="Create primary care provider contact from referral source"
              />
            </Stack>
            <ContactInfoForm fieldOverrides={contactInfoOverrides} />
          </Stack>
        </Box>
      </Box>
      <HorizontalDivider mb={8} borderColor="gray.100" />
      <Box data-testid="referral-details-section">
        <Heading as="h2" variant="tertiary" mb={4}>
          Referral Details
        </Heading>
        <Stack spacing={8}>
          <DateField
            name="familyContactDate"
            label="If the family has already been contacted, enter the date they were reached (optional)"
            disabledDays={familyContactDisabledDays}
          />

          <RadioGroupField
            name="referralReason"
            required
            label="Reason for referral"
          >
            <RadioOption
              value="development-concerns"
              data-testid="reasonIsDevelopmentConcerns"
              label={referralReasons['development-concerns']}
            />
            <RadioOption
              value="development-concerns.chet-screening"
              data-testid="reasonIsDevelopmentConcernsChet"
              label={referralReasons['development-concerns.chet-screening']}
            />
            <RadioOption
              value="doh-ehddi"
              data-testid="reasonIsDohEhddi"
              label={referralReasons['doh-ehddi']}
            />
          </RadioGroupField>

          <DropDownField
            name={'assignedAgency'}
            label={'Assign to agency'}
            required
            options={assignedAgencyOptions}
          />
          <TextareaField
            name={'referralNotes'}
            label="Additional referral notes (optional)"
            placeholder="Anything else you would like to make note of (such as contact attempts made)."
            rows={8}
          />
        </Stack>
      </Box>
      <EndOfFormSpacing />
    </Form>
  );
};

export default ReferralForm;
