export const displayUndefinedOrBoolean = (
  value: boolean | undefined | null,
  trueString: string,
  falseString: string
) => {
  if (value === undefined || value === null) {
    return value;
  }
  return value ? trueString : falseString;
};
