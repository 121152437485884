import { FC, Fragment } from 'react';
import { Flex } from '@chakra-ui/react';
import {
  SectionLink,
  SectionLinkContainer,
  SectionSubLink,
} from 'components/SectionLink';
import { useOpenFormContext } from 'contexts';
import EditDot from 'components/EditDot';

interface LinkConfig {
  readonly to: string;
  readonly content: string | JSX.Element;
  readonly key: string;
}

interface LinkConfigWithSubLinks extends LinkConfig {
  readonly subLinks?: LinkConfig[];
}

interface Props {
  readonly id: string;
  readonly linkConfigList: LinkConfigWithSubLinks[];
}

const JumpLinks: FC<Props> = ({ id, linkConfigList, ...props }) => {
  const [openFormState] = useOpenFormContext();

  return (
    <SectionLinkContainer id={id} {...props}>
      {linkConfigList.map(({ to, content: text, key, subLinks }) => (
        <Fragment key={key}>
          <Flex alignItems="center" position="relative">
            {openFormState?.primaryJumpLink === key &&
              openFormState.secondaryJumplink === undefined && (
                <EditDot
                  aria-label="Form is open in section"
                  position="absolute"
                />
              )}
            <SectionLink to={to} content={text} />
          </Flex>

          {subLinks &&
            subLinks?.map(({ key: subKey, content: subText, to: subTo }) => (
              <Flex key={subKey} alignItems="center" pl={4} position="relative">
                {openFormState?.primaryJumpLink === key &&
                  openFormState?.secondaryJumplink === subKey && (
                    <EditDot
                      aria-label="Form is open in sub-section"
                      position="absolute"
                      left={2}
                    />
                  )}
                <SectionSubLink to={subTo} content={subText} />
              </Flex>
            ))}
        </Fragment>
      ))}
    </SectionLinkContainer>
  );
};
export default JumpLinks;
