import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { ReactComponent as DeleteIcon } from 'icons/delete.svg';
import { FC, useCallback } from 'react';
import { ReactComponent as StatusChangeIcon } from 'icons/status-change.svg';
import { space } from 'theme';
import { useHistory } from 'react-router-dom';

const ActiveMatchPreview: FC = () => {
  const history = useHistory();
  const cancelReferral = useCallback(() => history.push('/'), [history]);

  return (
    <Box mt={space[14]}>
      <Heading as="h2" variant="tertiary" mb={space.one}>
        Matching an existing child record
      </Heading>
      <Text as="span" variant="bodyText" mb={space[7]}>
        We can't create a new referral for the child you selected because they
        already have a record. Please cancel the referral.
      </Text>
      <Flex mt={space[8]}>
        <Button
          colorScheme="teal"
          variant="solid"
          mr={space[4]}
          leftIcon={<DeleteIcon />}
          marginTop={4}
          onClick={cancelReferral}
        >
          Cancel referral
        </Button>
        <Button
          colorScheme="teal"
          variant="solid"
          disabled
          leftIcon={<StatusChangeIcon />}
          marginTop={4}
          onClick={cancelReferral}
        >
          Request Transfer
        </Button>
      </Flex>
    </Box>
  );
};

export default ActiveMatchPreview;
