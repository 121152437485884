import * as yup from 'yup';
import { yupOptionalLongString, yupRequiredBoolean } from 'lib';

export const routinesFormSchema = yup
  .object({
    setting: yupOptionalLongString(),
    enjoyedByChild: yupOptionalLongString(),
    enjoyedByFamily: yupOptionalLongString(),
    activitiesSucceeding: yupOptionalLongString(),
    activitiesChallenging: yupOptionalLongString(),
    activitiesInterest: yupOptionalLongString(),
  })
  .defined();

export const concernsFormSchema = yup
  .object({
    summary: yupOptionalLongString(),
    priorities: yupOptionalLongString(),
    resources: yupOptionalLongString(),
    familyDeclinesToShare: yupRequiredBoolean(false),
    additionalConcerns: yupOptionalLongString(),
  })
  .defined();

export type ConcernsFormFields = yup.InferType<typeof concernsFormSchema>;
export type RoutinesFormFields = yup.InferType<typeof routinesFormSchema>;

export const createEmptyFamilyConcerns = () => ({
  summary: '',
  priorities: '',
  resources: '',
  familyDeclinesToShare: false,
  additionalConcerns: '',
});

export const createEmptyFamilyRoutines = () => ({
  setting: '',
  enjoyedByChild: '',
  enjoyedByFamily: '',
  activitiesSucceeding: '',
  activitiesChallenging: '',
  activitiesInterest: '',
});
