import { ReactComponent as EditIcon } from 'icons/pencil.svg';
import { FormIconButton, JumpLinkRefIds } from 'molecules';
import {
  SkillsCardContent,
  skillHeaderTitleBuilder,
} from '../SkillsForm/helpers';
import { Tabs, useOpenFormContext } from 'contexts';
import { Text } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useLatestReferral } from 'hooks';
import { useParams } from 'react-router-dom';

const useCardHeaderProps = (
  cardContent: SkillsCardContent,
  cosIsCompleted: boolean
) => {
  const [openFormState, setOpenFormState] = useOpenFormContext();
  const { id: childId } = useParams<{ id: string }>();
  const referral = useLatestReferral(childId);
  const referralIsActive = referral?.isActive;

  const onClick = useCallback(() => {
    if (openFormState) {
      return;
    }

    setOpenFormState({
      tab: Tabs.COS,
      primaryJumpLink: JumpLinkRefIds.LatestCos,
      secondaryJumplink: cardContent.jumplinkRef,
    });
  }, [cardContent.jumplinkRef, openFormState, setOpenFormState]);

  return useMemo(() => {
    return {
      headerText: (
        <Text textStyle="detailSectionViewHeader">
          {skillHeaderTitleBuilder(cardContent)}
        </Text>
      ),
      buttons: cosIsCompleted ? null : (
        <FormIconButton
          onClick={onClick}
          aria-label={'Edit'}
          Icon={EditIcon}
          refId={cardContent.jumplinkRef}
          tooltipProps={{ label: 'Edit' }}
          disabled={!referralIsActive}
          data-testid="cos-skills-edit-button"
        />
      ),
    };
  }, [cardContent, cosIsCompleted, referralIsActive, onClick]);
};

export default useCardHeaderProps;
