import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { space } from 'theme';

export interface SpacedFormLayoutProps {
  fieldSpacing?: number | string;
}

export const SpacedFormLayout: FC<SpacedFormLayoutProps> = ({
  fieldSpacing = space.two,
  children,
}) => <Box sx={{ '& > div': { marginBottom: fieldSpacing } }}>{children}</Box>;
