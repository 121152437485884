import { createContext } from 'react';

export interface DirtyFormsState {
  [index: string]: boolean;
}

interface FormIsDirtyAction {
  type: 'formIsDirty';
  payload: {
    humanReadableFormName: string;
  };
}

interface FormIsCleanAction {
  type: 'formIsClean';
  payload: {
    humanReadableFormName: string;
  };
}

interface Reset {
  type: 'reset';
  payload?: {
    [index: string]: boolean;
  };
}

export type DirtyFormsActions = FormIsDirtyAction | FormIsCleanAction | Reset;

interface ContextState {
  hasDirtyForms: boolean;
  maybeDirtyFormsCollection: DirtyFormsState;
  dispatch: (action: DirtyFormsActions) => void;
}

export const DirtyFormsContext = createContext<ContextState>({
  hasDirtyForms: false,
  maybeDirtyFormsCollection: {},
  dispatch: () => {},
});
