import { ChakraProps } from '@chakra-ui/system';
import { FC, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { JumpLinkRefIds, JumpLinks } from 'molecules';
import { useParams } from 'react-router-dom';
import { useQualifyingDiagnosisTypes } from '../MedicalDiagnosesSection/useKnownMedicalDiagnosisTypes';
import QualifyingIconLabel from '../qualifyingIconLabel';
import useDiagnoses from '../useDiagnoses';
import useEvaluationsAndAssessments from '../EvaluationsSection/useEvaluations';

const JumpLinksSection: FC<ChakraProps> = props => {
  const { id } = useParams<{ id: string }>();
  const { data: evalPayload } = useEvaluationsAndAssessments(id);
  const { data: diagnoses } = useDiagnoses(id);
  const qualifyingDiagnosisTypes = useQualifyingDiagnosisTypes();
  const evaluations = evalPayload?.activeEvals;

  const linkConfigList = useMemo(() => {
    return [
      {
        to: `/children/${id}#${JumpLinkRefIds.EvaluationConsentForms}`,
        key: JumpLinkRefIds.EvaluationConsentForms,
        content: 'Evaluation consent forms',
      },
      {
        to: `/children/${id}#${JumpLinkRefIds.TeamMembers}`,
        key: JumpLinkRefIds.TeamMembers,
        content: 'Team members',
      },
      {
        to: `/children/${id}#${JumpLinkRefIds.FamilyDirectedAssessments}`,
        key: JumpLinkRefIds.FamilyDirectedAssessments,
        content: 'Family directed assessments',
        subLinks: [
          {
            key: JumpLinkRefIds.RoutinesAndActivities,
            content: 'Routines and activities',
            to: `/children/${id}#${JumpLinkRefIds.RoutinesAndActivities}`,
          },
          {
            key: JumpLinkRefIds.FamilyConcerns,
            content: 'Family concerns',
            to: `/children/${id}#${JumpLinkRefIds.FamilyConcerns}`,
          },
        ],
      },
      {
        to: `/children/${id}#${JumpLinkRefIds.MedicalDiagnoses}`,
        key: JumpLinkRefIds.MedicalDiagnoses,
        content: 'Medical diagnoses',
        subLinks: diagnoses?.map(diagnosis => {
          return {
            key: diagnosis.id,
            content: qualifyingDiagnosisTypes?.some(
              item =>
                item.label === diagnosis.diagnosisName ||
                diagnosis.determinesEligibility
            ) ? (
              <QualifyingIconLabel label={diagnosis.diagnosisName} />
            ) : (
              diagnosis.diagnosisName
            ),
            to: `/children/${id}#${diagnosis.id}`,
          };
        }),
      },
      {
        to: `/children/${id}#${JumpLinkRefIds.Evaluations}`,
        content: 'Evaluations and assessments',
        key: JumpLinkRefIds.Evaluations,
        subLinks: evaluations?.map(evaluationTool => ({
          key: evaluationTool.id,
          content: evaluationTool.evaluationToolName,
          to: `/children/${id}#${evaluationTool.id}`,
        })),
      },
      {
        to: `/children/${id}#${JumpLinkRefIds.Eligibility}`,
        key: JumpLinkRefIds.Eligibility,
        content: 'Eligibility',
      },
    ];
  }, [diagnoses, evaluations, id, qualifyingDiagnosisTypes]);

  return (
    <Flex flexDir="column" pr={4} {...props}>
      <JumpLinks id={id} linkConfigList={linkConfigList} />
    </Flex>
  );
};

export default JumpLinksSection;
