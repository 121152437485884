import { FC } from 'react';
import { useParams } from 'react-router-dom';
import CardSkeleton from '../../CardSkeleton/CardSkeleton';
import FamilyInfoCard from './FamilyInfoCard';
import FamilyInfoForm from './FamilyInfoForm';
import useFamilyInfo from './useFamilyInfo';
interface Props {
  readonly isFormOpen: boolean;
}

const FamilyInfoContent: FC<Props> = ({ isFormOpen }) => {
  const { id: childId } = useParams<{ id: string }>();
  const { data, error } = useFamilyInfo(childId);
  const loaded = !!data;

  if (!loaded && !error) {
    return <CardSkeleton />;
  }

  return isFormOpen ? <FamilyInfoForm /> : <FamilyInfoCard />;
};

export default FamilyInfoContent;
