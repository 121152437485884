import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionNotes,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { Flex } from '@chakra-ui/react';
import {
  counties,
  formLanguages,
  schoolDistricts,
} from '@cssat/acorn-api-shared';
import { useParams } from 'react-router-dom';
import useFamilyInfo from './useFamilyInfo';

interface Entity {
  readonly id: number;
  readonly name: string;
}

const findEntity = (entityList: Entity[], id?: number | null) => {
  if (!id) {
    return;
  }

  return entityList.find(entity => entity.id === id)?.name;
};

const FamilyInfoCard = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: familyInfo } = useFamilyInfo(childId);

  return (
    <Flex direction="column" flexGrow={1}>
      <DetailSectionRow>
        <DetailSectionColumn>
          <DetailSectionField
            label="County"
            value={findEntity(counties, familyInfo?.countyId)}
            showWhenEmpty
          />
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn>
          <DetailSectionField
            label="School District"
            value={findEntity(schoolDistricts, familyInfo?.schoolDistrictId)}
            showWhenEmpty
          />
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn>
          <DetailSectionField
            label="Form Language"
            value={findEntity(formLanguages, familyInfo?.formLanguageId)}
            showWhenEmpty
          />
        </DetailSectionColumn>
      </DetailSectionRow>
      <DetailSectionDivider />
      <DetailSectionNotes
        label="Lives with child"
        notes={familyInfo?.livesInHome}
        showWhenEmpty
      />
    </Flex>
  );
};

export default FamilyInfoCard;
