import { Box, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { badges } from 'theme';

interface Props {
  active: boolean;
}

const ReferralStatusBadge: FC<Props> = ({ active }) => {
  const status = active ? 'active' : 'inactive';
  return (
    <Box sx={{ ...badges[status] }} aria-label={status}>
      <Text color={badges[status].color} textTransform="uppercase">
        {status}
      </Text>
    </Box>
  );
};

export default ReferralStatusBadge;
