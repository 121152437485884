import { COS, CosInfoQuestion } from '@cssat/acorn-api-shared';
import {
  DetailSectionCheckMark,
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { dateFormatDisplayShort, formatISODate } from 'lib';
import EngagementQuestionView from './EngagementQuestionView';

interface Props {
  readonly cos: COS;
}

const isConfirmationAnswerDefined = (questionObj?: CosInfoQuestion) => {
  return Boolean(questionObj && questionObj.confirmationAnswer !== undefined);
};

export const formattedDate = (dateString?: string) =>
  formatISODate(dateString, dateFormatDisplayShort);

const InfoView: FC<Props> = ({
  cos: {
    dateCompleted,
    dateStarted,
    sources,
    usedDecisionTreeForPrep,
    didFamilyParticipateDiscussion,
    didFamilyParticipateSelection,
    usedESITDecisionTree,
  },
}) => {
  return (
    <Flex flexGrow={1} direction="column">
      <DetailSectionRow>
        <DetailSectionColumn flexBasis="25%">
          <DetailSectionField
            label="Date Started"
            showWhenEmpty
            value={formattedDate(dateStarted)}
          />
          <DetailSectionField
            label="Date Completed"
            showWhenEmpty
            value={formattedDate(dateCompleted)}
          />
        </DetailSectionColumn>

        <DetailSectionDivider />

        <DetailSectionColumn flexBasis="50%">
          <DetailSectionField label="COS Team Members" showWhenEmpty />
        </DetailSectionColumn>

        <DetailSectionDivider />

        <DetailSectionColumn flexBasis="25%" spacing={1}>
          <Text textStyle="sectionFieldViewLabel" marginBottom={1}>
            Sources
          </Text>
          {sources && sources.length > 0 ? (
            sources.map(source => (
              <DetailSectionCheckMark key={source} text={source} />
            ))
          ) : (
            <Text textStyle="sectionFieldViewValue">-</Text>
          )}
        </DetailSectionColumn>
      </DetailSectionRow>

      <DetailSectionDivider orientation="horizontal" />

      <DetailSectionRow mb={4}>
        <Text textStyle="sectionFieldViewLabel" marginBottom={1}>
          Family Engagement and Participation
        </Text>
      </DetailSectionRow>
      {usedDecisionTreeForPrep && (
        <EngagementQuestionView
          question={usedDecisionTreeForPrep}
          questionText="Did the provider team use the decision tree to prepare for the COS meeting with the family?"
        />
      )}
      {didFamilyParticipateDiscussion && (
        <EngagementQuestionView
          question={didFamilyParticipateDiscussion}
          questionText="Did the family participate in the COS discussion?"
          hasDivider={isConfirmationAnswerDefined(
            didFamilyParticipateSelection
          )}
        />
      )}
      {isConfirmationAnswerDefined(didFamilyParticipateSelection) && (
        <EngagementQuestionView
          question={didFamilyParticipateSelection!}
          questionText="Did the family participate in the selection of the descriptor statements?"
          hasDivider={isConfirmationAnswerDefined(usedESITDecisionTree)}
        />
      )}
      {isConfirmationAnswerDefined(usedESITDecisionTree) && (
        <EngagementQuestionView
          question={usedESITDecisionTree!}
          questionText="Was the ESIT Decision Tree used?"
          hasDivider={false}
        />
      )}
    </Flex>
  );
};

export default InfoView;
