import { FamilyInfo } from '@cssat/acorn-api-shared';
import { authenticatedFetch, authenticatedPost, paths } from 'lib';
import useSWR from 'swr';

export interface UpdateFamilyInfoRequest extends FamilyInfo {}

const useFamilyInfo = (childId: string) => {
  return useSWR<FamilyInfo>(
    paths.child.getFamilyInfo({ childId }),
    authenticatedFetch
  );
};

export const useMutateFamilyInfo = (childId: string) => {
  const { mutate } = useFamilyInfo(childId);

  return async (familyInfo: UpdateFamilyInfoRequest) => {
    await authenticatedPost(paths.child.updateFamilyInfo({ childId }), {
      body: familyInfo,
    });
    await mutate();
  };
};

export default useFamilyInfo;
