import { Role, Roles, roles } from '@cssat/acorn-api-shared';
import type { DropDownOption } from 'components/formik/DropDownField';

interface RoleOption extends DropDownOption {
  value: string;
}
interface RoleGroup {
  groupLabel: string;
  options: RoleOption[];
}
export type GroupedRoleOptions = RoleGroup[];
export const createGroupedSelectOptions = (data: Roles): GroupedRoleOptions => {
  const batchedByGroupCodeKey = Object.keys(data).reduce(
    (accumulator, current) => {
      const groupCode = parseGroupCode(current);
      const groupLabel = getGroupLabelFromCode(groupCode);

      const option = {
        label: createRoleLabel(data[current]),
        value: current,
      };

      if (accumulator[groupCode]) {
        accumulator[groupCode].options.push(option);
      } else {
        accumulator[groupCode] = {
          groupLabel,
          options: [option],
        };
      }

      return accumulator;
    },
    {} as { [groupCode: string]: RoleGroup }
  );

  sortGroupOptionsByLabel(batchedByGroupCodeKey);

  const groupList = Object.values(batchedByGroupCodeKey);
  sortGroupListByGroupLabel(groupList);

  return groupList;
};

const parseGroupCode = (string: string) => {
  const groupCodeDelimiter = string.indexOf('.');
  if (groupCodeDelimiter === -1) {
    throw new Error('No referring party role grouping category found');
  }
  return string.slice(0, groupCodeDelimiter);
};

const getGroupLabelFromCode = (code: string) => {
  const groupCodeToLabel: { [code: string]: string } = {
    ecp: 'Early Childhood Programs / Providers',
    med: 'Medical / Health Services',
    pff: 'Parent, Family, Friends',
    soc: 'Social Services',
  };

  return groupCodeToLabel[code];
};

export const createRoleLabel = (role: Role): string =>
  role.descriptions.length > 1
    ? role.descriptions.join(' — ')
    : role.descriptions[0];

const sortGroupOptionsByLabel = (groupedRoleOptions: {
  [groupCode: string]: RoleGroup;
}) => {
  for (const group in groupedRoleOptions) {
    groupedRoleOptions[group].options.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
  }
};

const sortGroupListByGroupLabel = (groupList: GroupedRoleOptions) =>
  groupList.sort((a, b) => a.groupLabel.localeCompare(b.groupLabel));

export const createRoleReadviewCopy = (roleId: string): string => {
  try {
    const groupCode = parseGroupCode(roleId);
    const groupCopy = getGroupLabelFromCode(groupCode);
    const descriptionsCopy = createRoleLabel(roles[roleId]);
    return `${groupCopy} — ${descriptionsCopy}`;
  } catch {
    return roleId;
  }
};
