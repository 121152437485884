import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { Text } from '@chakra-ui/react';
import { TruncatableTextBlock } from 'molecules';
import { displayUndefinedOrBoolean } from '../helpers';
import { useParams } from 'react-router-dom';
import useTransition from '../useTransition';

const TransitionConferenceDeclinedView = () => {
  const { id: childId } = useParams<{ id: string }>();
  const { data: transition } = useTransition(childId);

  return (
    <DetailSectionRow>
      <DetailSectionColumn flexBasis={'33%'}>
        <DetailSectionField
          label="conference status"
          value={displayUndefinedOrBoolean(
            transition?.conferenceHappened,
            'Held',
            'Declined'
          )}
          showWhenEmpty
        />
        <DetailSectionField
          label="Date Declined"
          value={transition?.conferenceDeclinedDate}
          showWhenEmpty
        />
      </DetailSectionColumn>
      <DetailSectionDivider />
      <DetailSectionColumn flexBasis={'67%'}>
        <Text textStyle="sectionFieldViewLabel">Reason for declining</Text>
        <TruncatableTextBlock text={transition?.conferenceDeclinedReason} />
      </DetailSectionColumn>
    </DetailSectionRow>
  );
};

export default TransitionConferenceDeclinedView;
