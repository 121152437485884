import * as yup from 'yup';
import { Alert, DataCard, JumpLinkRefIds } from 'molecules';
import { Box, Button, Grid, Heading, Text } from '@chakra-ui/react';
import { COS } from '@cssat/acorn-api-shared';
import { CosUnfilledRequirements } from './CosUnfilledRequirements';
import { DateField } from 'components/formik';
import { FC, useState } from 'react';
import { Form, Formik, FormikValues } from 'formik';
import {
  apiDateToClientDate,
  authenticatedPost,
  paths,
  yupRequiredDate,
} from 'lib';
import { formMaxWidthStyles } from 'pages/CreateReferral/styles';
import { isCompletable } from './validation';
import { startOfYesterday } from 'date-fns';
import { useCosByChildId } from '../useCosByChildId';
import { useLatestReferral, usePopupToast } from 'hooks';
import { useParams } from 'react-router-dom';
import InlineAlert from 'components/alerts/InlineAlert';

interface Props {
  readonly cos: COS;
}

const formSchema = yup.object().shape({
  dateCompleted: yupRequiredDate('Date of completion is required'),
});

const CosCompleteCard: FC<Props> = ({ cos }) => {
  const { id: childId } = useParams<{ id: string }>();
  const { mutate } = useCosByChildId(childId);
  const referral = useLatestReferral(childId);
  const [showSubmitError, setShowSubmitError] = useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const completable = referral?.isActive && isCompletable(cos);

  const [successToast] = usePopupToast({
    title: `COS completed`,
    status: 'success',
  });

  const [errorToast] = usePopupToast({
    title: `Sorry, we can't complete this COS right now.`,
    description: 'Please check your connection and try again.',
    status: 'error',
  });

  const apiRequest = async (values: FormikValues) => {
    const body = values;
    await authenticatedPost(paths.cos.upsert({ childId }), { body });
    await mutate();
  };

  return (
    <Formik
      initialValues={{ id: cos.id, dateCompleted: undefined }}
      validationSchema={formSchema}
      onSubmit={async values => {
        setShowSubmitError(false);
        try {
          await apiRequest(values);
          successToast();
        } catch (_e: any) {
          setShowSubmitError(true);
          errorToast();
        }
      }}
    >
      {formik => (
        <Form style={{ width: '100%' }}>
          <DataCard id={`${cos.id}${JumpLinkRefIds.CosComplete}`}>
            <Grid gap="4" flex="1">
              {showSubmitError && (
                <InlineAlert
                  marginBottom={4}
                  status="error"
                  title="There was a problem.."
                />
              )}
              <Heading variant="tertiary" as="p">
                {completable
                  ? 'This COS is ready to be completed'
                  : 'Please fill in the following fields to complete this COS:'}
              </Heading>
              {!completable && <CosUnfilledRequirements cos={cos} />}
              <Box maxW={formMaxWidthStyles}>
                <DateField
                  name="dateCompleted"
                  label="Date of completion"
                  required
                  disabledDays={{
                    // NOTE: safe to fallback since COS is not completable unless there's a `dateStarted`
                    before: apiDateToClientDate(cos.dateStarted || ''),
                    after: startOfYesterday(),
                  }}
                  inputProps={{
                    disabled: !completable,
                  }}
                />
              </Box>
            </Grid>

            <Box ml="auto">
              <Button
                ml="8"
                onClick={async () => {
                  const errors = await formik.validateForm();
                  if (Object.keys(errors).length === 0) {
                    setIsConfirmOpen(true);
                  } else {
                    formik.setTouched({ dateCompleted: true }, true);
                  }
                }}
                disabled={!completable}
                data-testid="cos-complete-edit-button"
                borderColor="white"
                border="1px solid"
              >
                Complete COS
              </Button>
            </Box>
          </DataCard>
          <Alert
            headerText="Confirm COS Completion"
            confirmText="Confirm COS and Continue"
            cancelText="Go back"
            isOpen={isConfirmOpen}
            setIsOpen={setIsConfirmOpen}
            onConfirm={formik.submitForm}
            hasCloseButton={true}
          >
            <Text textStyle="bodyText">
              This will save all data in the open COS In Progress and set it as
              the Current COS in the system.
            </Text>
            <Text textStyle="bodyText" mt={2} mb={2}>
              Once confirmed, you will be unable to make any edits to this COS.
            </Text>
          </Alert>
        </Form>
      )}
    </Formik>
  );
};

export default CosCompleteCard;
