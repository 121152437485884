import { COS } from '@cssat/acorn-api-shared';
import { DataCard, JumpLinkRefIds } from 'molecules';
import { FC } from 'react';
import { useOpenFormContext } from 'contexts';
import InfoForm from './InfoForm';
import InfoView from './InfoView';
import useCardHeaderProps from './useCardHeaderProps';

interface Props {
  readonly cos: COS;
}

const CosInfoCard: FC<Props> = ({ cos }) => {
  const [openFormState] = useOpenFormContext();
  const cosIsCompleted = !!cos.dateCompleted;
  const headerProps = useCardHeaderProps(cosIsCompleted);

  const isFormOpen =
    !cosIsCompleted &&
    openFormState?.primaryJumpLink === JumpLinkRefIds.LatestCos &&
    openFormState?.secondaryJumplink === JumpLinkRefIds.CosInfo;

  return (
    <DataCard
      id={`${cos.id}${JumpLinkRefIds.CosInfo}`}
      headerProps={headerProps}
    >
      {isFormOpen ? <InfoForm cos={cos} /> : <InfoView cos={cos} />}
    </DataCard>
  );
};

export default CosInfoCard;
