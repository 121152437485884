import { FC } from 'react';
import { TrackedSessionsList } from './TrackedSessionsList';
import { useTrainingUserContext } from 'contexts';
import LoginForm from './LoginForm/LoginForm';

export const Services: FC = () => {
  const { user, setUser } = useTrainingUserContext();

  if (user) return <TrackedSessionsList />;
  return (
    <LoginForm
      onSubmit={({ name, email }) => {
        const lowercaseEmail = email.toLowerCase();
        setUser({ name, email: lowercaseEmail });
      }}
    />
  );
};
