import { ReferralData } from '@cssat/acorn-api-shared';
import { authenticatedFetch, paths } from 'lib';
import useSWR from 'swr';

export const useReferralsByChildId = (childId: string) => {
  return useSWR<ReferralData[]>(
    paths.referrals.listByChildId({ childId }),
    authenticatedFetch
  );
};

export const usePastReferrals = (childId: string) => {
  const { data: referrals } = useReferralsByChildId(childId);
  const [_latest, ...past] = referrals ?? [];
  return past;
};

export const useLatestReferral = (childId: string) => {
  const { data: referrals } = useReferralsByChildId(childId);
  const [latest, ..._past] = referrals ?? [];
  return latest;
};

export default useReferralsByChildId;
