import { COS, cosSources } from '@cssat/acorn-api-shared';
import {
  Checkbox,
  DateField,
  RadioGroupField,
  RadioPill,
  TextareaField,
} from 'components/formik';
import { DetailSectionDivider } from 'components/styled/forms/DetailSection';
import { FC, useCallback } from 'react';
import { FormControl, FormErrorMessage, Heading, Text } from '@chakra-ui/react';
import { FormFields, formSchema } from './validation';
import { FormSection } from 'components/styled/forms/FormSection';
import { authenticatedPost, paths } from 'lib';
import { startOfYesterday } from 'date-fns';
import { useCosByChildId } from '../useCosByChildId';
import { useParams } from 'react-router-dom';
import ChildSectionFormikWrapper from 'pages/ChildRecord/ChildSectionFormikWrapper/ChildSectionFormikWrapper';
import UnderConstruction from 'components/UnderConstruction/UnderConstruction';
import adaptApiToForm from './adaptApiToForm';
import adaptFormToApi from './adaptFormToApi';

interface Props {
  readonly cos: COS;
}

const InfoForm: FC<Props> = ({ cos }) => {
  const { id: childId } = useParams<{ id: string }>();
  const { mutate } = useCosByChildId(childId);

  const onSubmit = useCallback(
    async (values: FormFields) => {
      const body = adaptFormToApi(values);
      await authenticatedPost(paths.cos.upsert({ childId }), { body });
      await mutate();
    },
    [childId, mutate]
  );

  const initialValues = adaptApiToForm(cos);

  return (
    <ChildSectionFormikWrapper
      apiRequest={onSubmit}
      formikProps={{ validationSchema: formSchema, initialValues }}
      successToastProps={{
        title: `COS Information Updated`,
      }}
      errorToastProps={{
        title: `Sorry, we can’t update the COS Information right now.`,
        description: 'Please check your connection and try again.',
      }}
      humanReadableFormName="Child COS - COS Info"
      inlineAlertMessage="There was a problem updating this COS Info."
    >
      {formik => {
        const disabledDays = {
          after: startOfYesterday(),
        };

        return (
          <>
            <FormSection>
              <Heading as="h4" variant="tertiary">
                Date COS started
              </Heading>
              <DateField
                name="dateStarted"
                label="Date COS started"
                disabledDays={disabledDays}
              />
            </FormSection>
            <DetailSectionDivider orientation="horizontal" />
            <FormSection>
              <Heading as="h4" variant="tertiary">
                COS team members
              </Heading>
              <Text textStyle="bodyText">
                To add or remove COS team members listed below, go to the team
                members tab
              </Text>
              <UnderConstruction />
            </FormSection>
            <DetailSectionDivider orientation="horizontal" />

            <FormControl
              name="sources"
              isInvalid={Boolean(
                formik.touched.sources && formik.errors.sources
              )}
            >
              <FormSection>
                <Heading as="h4" variant="tertiary">
                  Sources
                </Heading>
                <Text textStyle="bodyText">
                  Select all sources used in the creation of this COS. Two
                  different sources are required.{' '}
                  <Text color="gray.400" as="span">
                    (select at least two)
                  </Text>
                </Text>
                {cosSources.map(source => (
                  <Checkbox
                    name="sources"
                    key={source}
                    value={source}
                    label={source}
                  />
                ))}
                <FormErrorMessage data-testid={`error-sources`}>
                  {formik.errors.sources}
                </FormErrorMessage>
              </FormSection>
            </FormControl>

            <DetailSectionDivider orientation="horizontal" />

            <FormSection>
              <Heading as="h4" variant="tertiary">
                Family engagement and participation
              </Heading>

              <RadioGroupField
                name="usedDecisionTreeForPrep"
                label="Did the provider team use the decision tree to prepare for the COS meeting with the family?"
                direction="horizontal"
              >
                <RadioPill value="true" label="Yes" />
                <RadioPill value="false" label="No" />
                {formik.values.usedDecisionTreeForPrep === 'false' && (
                  <TextareaField
                    name="usedDecisionTreeForPrepNotes"
                    label="Please explain why the provider team did not use the decision tree to prepare for the COS meeting with the family."
                  />
                )}
              </RadioGroupField>

              <RadioGroupField
                name="didFamilyParticipateDiscussion"
                label="Did the family participate in the COS discussion?"
                direction="horizontal"
              >
                <RadioPill value="true" label="Yes" />
                <RadioPill value="false" label="No" />
                {formik.values.didFamilyParticipateDiscussion === 'false' && (
                  <TextareaField
                    name="didFamilyParticipateDiscussionNotes"
                    label="Please explain why the family did not participate in the COS discussion."
                  />
                )}
              </RadioGroupField>

              {formik.values.didFamilyParticipateDiscussion === 'true' && (
                <RadioGroupField
                  name="didFamilyParticipateSelection"
                  label="Did the family participate in the selection of the descriptor statements?"
                  direction="horizontal"
                >
                  <RadioPill value="true" label="Yes" />
                  <RadioPill value="false" label="No" />
                  {formik.values.didFamilyParticipateSelection === 'false' && (
                    <TextareaField
                      name="didFamilyParticipateSelectionNotes"
                      label="Please explain why the family did not participate in the selection of the descriptor statements."
                    />
                  )}
                </RadioGroupField>
              )}

              {formik.values.didFamilyParticipateSelection === 'true' &&
                formik.values.didFamilyParticipateDiscussion === 'true' && (
                  <RadioGroupField
                    name="usedESITDecisionTree"
                    label="Was the ESIT Decision Tree used?"
                    direction="horizontal"
                  >
                    <RadioPill value="true" label="Yes" />
                    <RadioPill value="false" label="No" />
                    {formik.values.usedESITDecisionTree === 'false' && (
                      <TextareaField
                        name="usedESITDecisionTreeNotes"
                        label="Please explain why the ESIT Decision Tree was not used."
                      />
                    )}
                  </RadioGroupField>
                )}
            </FormSection>
          </>
        );
      }}
    </ChildSectionFormikWrapper>
  );
};

export default InfoForm;
