import { FC } from 'react';
import { Flex, Link } from '@chakra-ui/react';

import { Address } from 'components/styled/Address';
import { DataCard } from 'molecules';
import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionField,
  DetailSectionNotes,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { ReferralData } from '@cssat/acorn-api-shared';
import { createRoleReadviewCopy } from 'lib/referringPartyRole/data';
import {
  getHumanDateFromISOString,
  validateMailingAndPhysicalAddress,
} from 'lib';
import { referralReasons } from 'lib/referralReasonData';

interface Props {
  readonly referral?: ReferralData;
  readonly isArchived?: boolean;
}

const ReferralCard: FC<Props> = ({ referral, isArchived = false }) => {
  if (!referral) {
    return null;
  }

  const referringPartyRoleCopy = createRoleReadviewCopy(
    referral.referringParty.role
  );

  const { isAddressTheSame, doesMailingAddressExist } =
    validateMailingAndPhysicalAddress(referral?.referringParty);

  const email = referral.referringParty?.email && (
    <Link
      variant="detailSectionFieldViewValue"
      width="fit-content"
      href={`mailto:${referral.referringParty?.email}`}
      isExternal
    >
      {referral.referringParty?.email}
    </Link>
  );

  return (
    <DataCard
      headerProps={{
        isArchived,
        headerTags: isArchived
          ? [
              {
                text: 'Previous referral',
                key: `${referral.referralId}-archived`,
              },
            ]
          : [],
      }}
    >
      <Flex direction="column" flexGrow={1}>
        <DetailSectionRow>
          <DetailSectionColumn>
            <DetailSectionField
              label="Referral Source"
              value={referral.referringParty.name}
              showWhenEmpty
            />
            <DetailSectionField
              label="Role"
              value={referringPartyRoleCopy}
              showWhenEmpty
            />
            <DetailSectionField label="Physical Address" showWhenEmpty>
              <Address
                line1={referral.referringParty.physicalAddress1 || '-'}
                line2={referral.referringParty.physicalAddress2}
                city={referral.referringParty.physicalCity}
                state={referral.referringParty.physicalState}
                zip={referral.referringParty.physicalZip}
              />
            </DetailSectionField>
            {!isAddressTheSame && doesMailingAddressExist && (
              <DetailSectionField label="Mailing Address">
                <Address
                  line1={referral.referringParty.mailingAddress1 || '-'}
                  line2={referral.referringParty.mailingAddress2}
                  city={referral.referringParty.mailingCity}
                  state={referral.referringParty.mailingState}
                  zip={referral.referringParty.mailingZip}
                />
              </DetailSectionField>
            )}
          </DetailSectionColumn>
          <DetailSectionDivider />
          <DetailSectionColumn>
            <DetailSectionField
              label="Phone Number"
              value={referral.referringParty.phoneGeneric}
              showWhenEmpty
            />
            <DetailSectionField
              label="Email Address"
              value={referral.referringParty.email}
              showWhenEmpty
            >
              {email}
            </DetailSectionField>
          </DetailSectionColumn>
          <DetailSectionDivider />
          <DetailSectionColumn>
            <DetailSectionField
              label="Referral Received"
              value={getHumanDateFromISOString(referral.dateOfInitialReferral)}
              showWhenEmpty
            />
            <DetailSectionField
              label="Reason for Referral"
              value={referralReasons[referral.reason]}
              showWhenEmpty
            />
            <DetailSectionField
              label="Family Reached"
              value={
                referral.familyContactDate
                  ? getHumanDateFromISOString(referral.familyContactDate)
                  : undefined
              }
              showWhenEmpty
            />
          </DetailSectionColumn>
        </DetailSectionRow>
        <DetailSectionNotes notes={referral?.notes} />
      </Flex>
    </DataCard>
  );
};

export default ReferralCard;
