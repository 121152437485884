import { FC, createContext, useContext, useState } from 'react';
import { ReferralData } from '@cssat/acorn-api-shared';

type PreviousReferralContextType = [
  ReferralData | undefined,
  (referral: ReferralData) => void
];

const PreviousReferralContext = createContext<PreviousReferralContextType>([
  undefined,
  () => {},
]);

export const usePreviousReferralContext = () =>
  useContext(PreviousReferralContext);

export const PreviousReferralContextProvider: FC = ({ children }) => {
  const value = useState<any>();

  return (
    <PreviousReferralContext.Provider value={value}>
      {children}
    </PreviousReferralContext.Provider>
  );
};
