import * as yup from 'yup';
import {
  TransitionTypeListAtAge3,
  TransitionTypeListOther,
  TransitionTypeListUnderAge3,
  TransitionTypes,
} from '@cssat/acorn-api-shared';
import {
  dateFormatErrorString,
  yupOptionalLongString,
  yupRequiredDate,
  yupRequiredString,
} from 'lib';

export const formSchema = yup.object({
  dateOfTransition: yupRequiredDate('Transition date is required').typeError(
    dateFormatErrorString
  ),
  notes: yupOptionalLongString(),
  transitionType: yupRequiredString('Transition type is required'),
});

export const transitionTypeOptions = [
  { value: '', label: '' },
  ...TransitionTypeListOther.map(transitionType => ({
    value: transitionType,
    label: transitionType,
  })),
  {
    label: 'Exited at age 3',
    options: TransitionTypeListAtAge3.map(transitionType => ({
      value: transitionType,
      label: transitionType,
    })),
  },
  {
    label: 'Exited under age 3',
    options: TransitionTypeListUnderAge3.map(transitionType => ({
      value: transitionType,
      label: transitionType,
    })),
  },
];

export type FormFields = yup.InferType<typeof formSchema>;

export const initialValues = {
  dateOfTransition: new Date(),
  notes: undefined,
  transitionType: transitionTypeOptions[0].value as TransitionTypes,
};
