import * as yup from 'yup';
import { Box, Button } from '@chakra-ui/react';
import { FC } from 'react';
import { Form, Formik } from 'formik';
import { PageHeader } from 'molecules';
import { SpacedFormLayout } from 'components/styled/forms';
import { TextField } from 'components/formik';
import { gridColumns } from 'theme';

const LoginSchema = yup
  .object({
    name: yup.string().default('').trim().required('Name is required'),
    email: yup
      .string()
      .email()
      .default('')
      .trim()
      .required('Email is required'),
  })
  .defined();

export type LoginCredentials = yup.InferType<typeof LoginSchema>;

export interface LoginFormProps {
  onSubmit: (values: LoginCredentials) => void;
}

const LoginForm: FC<LoginFormProps> = ({ onSubmit }) => (
  <Formik
    validationSchema={LoginSchema}
    initialValues={{ ...LoginSchema.default() }}
    onSubmit={onSubmit}
  >
    <>
      <PageHeader
        title="Start service tracking"
        subTitle="To get started with service tracking, enter your full name and the email used for DMS login."
      />
      <Box maxWidth={['100%', '100%', gridColumns(4)]}>
        <Form>
          <SpacedFormLayout>
            <TextField name="name" label="Full name" required />
            <TextField name="email" label="Email" type="email" required />

            <Button type="submit" data-testid="loginSubmitButton" width="100%">
              Sign in
            </Button>
          </SpacedFormLayout>
        </Form>
      </Box>
    </>
  </Formik>
);

export default LoginForm;
