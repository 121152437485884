import { DocumentMetadata } from '@cssat/acorn-api-shared';
import { FormFields } from '../validation';
import { authenticatedFetch, authenticatedPost, paths } from 'lib';
import { useCallback } from 'react';
import useSWR from 'swr';

const useDocuments = (childId: string) => {
  return useSWR<DocumentMetadata[]>(
    paths.docs.list({ childId }),
    authenticatedFetch
  );
};

export const useMutateArchiveDocument = (childId: string) => {
  const { mutate } = useDocuments(childId);

  return async (fileId: string) => {
    await authenticatedPost(paths.docs.archive({ childId, fileId }));
    await mutate();
  };
};

export const useMutateUploadDocument = (childId: string) => {
  const { mutate } = useDocuments(childId);

  return async (document: FormFields) => {
    const { file, title, documentAssociation, notes } = document;
    const body = new FormData();

    body.append('title', title);
    body.append('documentAssociation', documentAssociation);
    body.append('isArchived', 'false');
    body.append('file', file as File);

    if (notes) {
      body.append('notes', notes);
    }

    await authenticatedPost(paths.docs.upload({ childId }), {
      body,
      headers: undefined,
    });
    await mutate();
  };
};

export const useGetDocumentDownloadUrl = (childId: string) =>
  useCallback(
    (fileId: string) => paths.docs.download({ childId, fileId }),
    [childId]
  );

export default useDocuments;
