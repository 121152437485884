import { CosInfoQuestion } from '@cssat/acorn-api-shared';
import {
  DetailSectionColumn,
  DetailSectionDivider,
  DetailSectionRow,
} from 'components/styled/forms/DetailSection';
import { FC } from 'react';
import { Text } from '@chakra-ui/react';
import { TruncatableTextBlock } from 'molecules';

interface Props {
  readonly questionText: string;
  readonly question: CosInfoQuestion;
  readonly hasDivider?: boolean;
}

const confirmationAnswerDisplay = (
  confirmationAnswer: CosInfoQuestion['confirmationAnswer']
) => {
  if (confirmationAnswer === undefined) return '-';
  return confirmationAnswer ? 'Yes' : 'No';
};

const EngagementQuestionView: FC<Props> = ({
  questionText,
  question: { confirmationAnswer, notes },
  hasDivider = true,
}) => {
  return (
    <>
      <DetailSectionRow>
        <DetailSectionColumn flexBasis={'35%'}>
          <Text textStyle="bodyText" color="gray.600">
            {questionText}
          </Text>
        </DetailSectionColumn>
        <DetailSectionDivider />
        <DetailSectionColumn flexBasis={'65%'}>
          <Text fontWeight="bold">
            {confirmationAnswerDisplay(confirmationAnswer)}
          </Text>
          {notes && !confirmationAnswer && (
            <TruncatableTextBlock text={notes} />
          )}
        </DetailSectionColumn>
      </DetailSectionRow>

      {hasDivider && <DetailSectionDivider orientation="horizontal" />}
    </>
  );
};

export default EngagementQuestionView;
