import {
  AlertStatus,
  Box,
  RenderProps as ChakraToastRenderProps,
  UseToastOptions as ChakraUseToastOptions,
  CloseButton,
  Flex,
  Text,
  useToast as useChakraToast,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { getAlertStatusStyles } from './status';

export interface UsePopupToastOptions extends ChakraUseToastOptions {
  title: ReactNode;
  status: AlertStatus;
}

type PopupToastProps = UsePopupToastOptions &
  Pick<
    ReturnType<typeof useChakraToast>,
    'close' | 'closeAll' | 'update' | 'isActive'
  > &
  ChakraToastRenderProps;

export const PopupToast: FC<PopupToastProps> = ({
  status,
  title,
  description,
  isClosable,
  id,
  close,
  onCloseComplete,
}) => {
  const { backgroundColor, accentColor, statusIcon } =
    getAlertStatusStyles(status);

  return (
    <Flex
      padding={4}
      backgroundColor={backgroundColor}
      borderRadius="md"
      border="1px solid"
      borderColor={accentColor}
      justifyContent="space-between"
    >
      <Flex marginRight={2}>
        <Box marginRight={2} sx={{ 'svg path': { fill: accentColor } }}>
          {statusIcon}
        </Box>

        <Text
          as="span"
          textStyle="bodyText"
          lineHeight="20px"
          {...(description && { marginRight: 2 })}
        >
          <Text as="strong" fontWeight="bold">
            {title}
          </Text>
          {description && <Text as="span"> {description}</Text>}
        </Text>
      </Flex>
      {isClosable && (
        <CloseButton
          size="sm"
          onClick={() => {
            close(id);
            onCloseComplete && onCloseComplete();
          }}
        />
      )}
    </Flex>
  );
};
