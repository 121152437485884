import { Box } from '@chakra-ui/react';
import { CardHeader, InfoCard } from 'components/styled/cards';
import { FC } from 'react';
import { ListItem } from 'components/styled/text';
import { constructChildDetails } from '../helpers';
import { formatDateObject, toSentenceCase } from 'lib';
import { space } from 'theme';
import { useChildDetailsContext } from 'contexts/ChildDetailsContext';
import { usePreviousReferralContext } from 'contexts/PreviousReferralContext';

const ChildDetailsSummary: FC = () => {
  const [childDetails] = useChildDetailsContext();
  const [previousReferral] = usePreviousReferralContext();

  if (!childDetails) return null;

  const {
    childFirstName,
    childLastName,
    childDateOfBirth,
    sexOfChild,
    otherSexOfChild,
  } = constructChildDetails(childDetails, previousReferral);

  return childDetails ? (
    <Box data-testid="childDetails" mb={space.two}>
      <InfoCard>
        <CardHeader data-testid="childDetailsHeader" mb={space.half}>
          Child Details
        </CardHeader>
        <ul>
          <ListItem>{`${childFirstName} ${childLastName}`}</ListItem>
          <ListItem>Born {formatDateObject(childDateOfBirth)}</ListItem>

          {sexOfChild && (
            <ListItem>
              {sexOfChild === 'other' && otherSexOfChild
                ? toSentenceCase(otherSexOfChild || '')
                : toSentenceCase(sexOfChild)}
            </ListItem>
          )}
        </ul>
      </InfoCard>
    </Box>
  ) : null;
};

export default ChildDetailsSummary;
